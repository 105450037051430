<template>
    <label class="mb-2 inline-block custom-label" :class="disabled ? 'disabled' : ''" :for="name">
        {{ computedLabel }}
    </label>
</template>

<script>
    export default {
        name: "CustomLabel",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            required: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            computedLabel() {
                if (this.label === undefined) return "";
                return this.label + (this.required ? "*" : "");
            },
        },
    };

</script>

<style lang="scss">
    // @import "../assets/theme/mytheme/variables";
    .custom-label {
        width: max-content;
        &.disabled {
            color: var(--text-color-disabled);
        }
    }
</style>
