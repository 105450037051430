/* eslint-disable */
export const FiltersUtils = {
    saveToSessionStorage(name, filters) {
        sessionStorage.setItem(name, JSON.stringify(filters));
    },

    loadFromSessionStorage(name) {
        if (sessionStorage.getItem(name)) {
            return JSON.parse(sessionStorage.getItem(name));
        }
    },
};
