<template>
    <CustomCheckbox label="Nie stwierdzono gniazd" v-model="internalNoNests" :disabled="!editing" name="noNests"
                    :error="showError()"/>
    <div v-if="!internalNoNests">
        <NestsTable v-model="internalNests" :error="showError()"/>
    </div>
</template>

<script>
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import NestsTable from "@/components/formNests/NestsTable.vue";

    export default {
        name: "FormNests",

        components: {
            NestsTable,
            CustomCheckbox,
        },

        props: {
            noNests: {
                default: false,
            },
            nests: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "showErrorMessages"],

        emits: ["update:noNests", "update:nests"],

        methods: {
            showError() {
                return this.showErrorMessages && ((this.internalNests == null
                    || this.internalNests.length === 0) && !(this.internalNoNests === true));
            },
        },

        computed: {
            internalNoNests: {
                get() { return this.noNests === null ? true : this.noNests; },
                set(value) { this.$emit("update:noNests", value); },
            },
            internalNests: {
                get() { return this.nests; },
                set(value) { this.$emit("update:nests", value); },
            },
        },

        watch: {
            internalNests: {
                handler(value) { this.$emit("update:nests", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
