<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              v-model:additionalInformation="position">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition :field="field" v-model="position" :positions="positions" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <div class="flex gap-2">
                <CustomCalendar label="Data liczenia" name="controlDate" required class="w-4"
                                v-model="position.controlDate" :disabled="!editing"
                                :customErrorMessage="getDateErrorMessage(position.controlDate)" />
                <CustomTimePicker label="Czas rozpoczęcia" name="startTime" required class="w-4"
                                  :disabled="!editing" v-model="position.startTime" />
                <CustomTimePicker label="Czas zakończenia" name="endTime" required class="w-4"
                                  :disabled="!editing" v-model="position.endTime" />
            </div>
        </template>
        <template v-slot:birdsData>
            <FormBirds v-model:birds="position.birds" v-model:noBirds="position.noBirds"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomCalendar from "@/components/form/CustomCalendar";
    import CustomTimePicker from "@/components/form/CustomTimePicker";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMPDObservations",

        components: {
            FormObservationsTemplate,
            ObservationsSelectPosition,
            CustomCalendar,
            CustomTimePicker,
            FormBirds,
        },

        props: {
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            editable: {
                type: Boolean,
                default: false,
            },
            step: {
                type: Number,
                required: true,
            },
        },

        emits: ["update:step"],

        inject: ["showErrorMessages", "editing", "dicts", "controlTimeFrame"],

        data() {
            return {
                positions: [],
                position: _.cloneDeep(EmptyStructures.getEmptyMPDPosition()),
                activeIndex: 0,
                loaded: false,
            };
        },

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
        },

        methods: {
            getDateErrorMessage(value) {
                if (!ValidateUtils.validateIsDateInRange(value, this.controlTimeFrame.controlStart, this.controlTimeFrame.controlEnd)) {
                    return "Data musi być z przedziału " + this.controlTimeFrame.controlStart + " - "
                        + this.controlTimeFrame.controlEnd;
                }
                return "";
            },
        },

        computed: {
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            position: {
                handler(value) {
                    if (value.birds === null) {
                        this.position.birds = [];
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
