<template>
    <CustomCheckbox label="Brak ptaków" v-model="position.birds.noBirds" :disabled="!editing" name="noBirds" class="pb-3"/>
    <div v-if="!position.birds.noBirds" class="flex flex-column">
        <div class="flex gap-3">
            <CustomSelectOne v-if="$route.query.birdCode === 'GR'" label="Metoda liczenia" name="countingMethod" :items="dicts.countingMethod" required
                             v-model="position.birds.countingMethod"
                             :showErrors="showErrorMessages" :disabled="!editing" class="w-6"
                             :emptyValue="false" />
            <CustomSelectOne label="Kryterium lęgowości" name="nestingCriteria"
                             v-model="position.birds.nestingCriteria" class="w-6"
                             :items="$route.query.birdCode === 'GR' ? internalNestingCriteria : dicts.nestingCriteria"
                             required :showErrors="showErrorMessages" tooltip :emptyValue="false" />
        </div>
        <div class="flex gap-3">
            <CustomInputText label="Dodatkowe informacje" name="additionalInfo"
                             v-model="position.birds.additionalInfo" class="w-12" />

        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "FormBirdsMFGP",

        components: {
            CustomCheckbox,
            CustomInputText,
            CustomSelectOne,
        },

        props: {
            modelValue: null,
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:modelValue"],

        data() {
            return {
                internalNestingCriteria: [],
            };
        },

        beforeMount() {
            if (this.position && this.position.birds?.countingMethod?.label === "nasłuch") {
                this.internalNestingCriteria = [{code: "PG", label: "PG - para odzywająca się w duecie w siedlisku dogodnym do gniazdowania", species: ["GR"]}];
            } else {
                this.internalNestingCriteria = _.cloneDeep(this.dicts.nestingCriteria);
            }
        },

        methods: {
            showError() {
                return this.showErrorMessages && this.position.noBirds !== true;
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 80) {
                    return _.truncate(text, {
                        length: 80,
                        separator: " ",
                    });
                }
                return text;
            },
        },

        computed: {
            position: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:birds", value); },
                deep: true,
            },
            position: {
                handler(value) {
                    if (value && value.birds?.countingMethod?.label === "nasłuch") {
                        this.internalNestingCriteria = [{code: "PG", label: "PG - para odzywająca się w duecie w siedlisku dogodnym do gniazdowania", species: ["GR"]}];
                    } else {
                        this.internalNestingCriteria = _.cloneDeep(this.dicts.nestingCriteria);
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
