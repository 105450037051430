<template>
    <div class="point-habitat-data-table">
        <DataTable :value="habitatData" v-model:editingRows="editingRows" editMode="row" dataKey="pointId"
                   size="small" class="navigate-table" @click="arrowsNavigateTable">
            <template v-if="canAddRow" #header>
                <div class="flex justify-content-between align-items-center">
                    <div>Zmiany danych siedliskowych</div>
                    <CustomButton icon="pi pi-plus" label="Dodaj zmianę" @click="addRow" :disabled="!editing" />
                </div>
            </template>
            <Column v-if="canAddRow" header="Punkt" field="name" style="width: 4.1666%;">
                <template #editor="{data,field,index}">
                    <CustomSelectOne v-model="data[field]" name="pointName"
                                     @update:modelValue="(value) => habitatData[index][field] = value"
                                     :items="pointsName" itemLabel="" class="table-input-select-one"
                                     style="max-width: 400px" :emptyValue="false" :customErrorWithoutMessage="getPointNameError(data[field])"
                                     :filter="false"/>
                </template>
            </Column>
            <Column v-else header="Punkt" field="name" style="width: 4.1666%;" />
            <Column header="Typ siedliska I" class="w-3" field="habitatType">
                <template #body="slotProps">
                    {{ slotProps.data.habitatType?.label }}
                </template>
                <template #editor="{data,field,index}">
                    <CustomSelectOne v-model="data[field]" name="habitatType"
                                     @update:modelValue="(value) => habitatData[index][field] = value"
                                     :items="dicts.habitatType" class="table-input-select-one"
                                     style="max-width: 400px" :filter="false" />
                </template>
            </Column>
            <Column header="Typ siedliska II" field="habitatType2" class="w-3">
                <template #body="slotProps">
                    {{ slotProps.data.habitatType2?.label }}
                </template>
                <template #editor="{data,field,index}">
                    <CustomSelectOne v-model="data[field]" name="habitatType2"
                                     @update:modelValue="(value) => habitatData[index][field] = value"
                                     :items="dicts.habitatType" class="table-input-select-one"
                                     style="max-width: 400px" :filter="false" />
                </template>
            </Column>
            <Column header="Termomodernizacja" field="thermalModernization" class="w-2">
                <template #body="slotProps">
                    {{ slotProps.data.thermalModernization?.label }}
                </template>
                <template #editor="{data,field,index}">
                    <CustomSelectOne v-model="data[field]" name="thermalModernization" style="max-width: 245px;"
                                     @update:modelValue="(value) => habitatData[index][field] = value"
                                     :items="dicts.thermalModernization" class="table-input-select-one"
                                     :filter="false"/>
                </template>
            </Column>
            <Column header="Budki dla jerzyków" field="swiftBoxes" class="w-1">
                <template #body="slotProps">
                    {{ computedBoolean(slotProps.data.swiftBoxes) }}
                </template>
                <template #editor="{data,field,index}">
                    <CustomCheckbox v-model="data[field]" name="swiftBoxes"
                                    @update:modelValue="(value) => habitatData[index][field] = value"
                                    style="margin-bottom: 0 !important; margin-left: 25%;" />
                </template>
            </Column>
            <Column v-if="canAddRow" style="width: 60px; text-align: center;" class="w-1">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteHabitatData(slotProps.index)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import _ from "lodash";
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "FormMPPMHabitatDataTable",

        components: {
            DataTable,
            Column,
            CustomSelectOne,
            CustomCheckbox,
            CustomButton,
        },

        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
            canAddRow: {
                type: Boolean,
                default: false,
            },
            alwaysEditable: {
                type: Boolean,
                default: false,
            },
            form: {
                type: Object,
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        data() {
            return {
                editingRows: ref([]),
                index: 0,
                pointsName: [],
            };
        },

        emits: ["update:modelValue"],

        mounted() {
            this.editingRows = this.habitatData.filter((hd) => this.alwaysEditable || hd.editable);
            this.form.points.forEach((point) => this.pointsName.push(point.name));
            if (this.habitatData?.swiftBoxes === null) {
                this.habitatData.swiftBoxes = false;
            }
        },

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            computedBoolean(value) {
                if (value) return "Tak";
                if (value === false) return "Nie";
                return null;
            },
            addRow() {
                const newRow = _.cloneDeep(EmptyStructures.getEmptyMPPMHabitatData());
                newRow.name = null;
                newRow.editable = true;
                newRow.swiftBoxes = false;
                this.habitatData = [...this.habitatData, newRow];
                this.editingRows = [...this.editingRows, newRow];
            },
            deleteHabitatData(index) {
                this.habitatData.splice(index, 1);
            },
            getPointNameError(value) {
                if (value == null) return false;
                let found = 0;
                this.habitatData.forEach((hd) => {
                    if (hd.name === value) found += 1;
                });
                return found > 1;
            },
        },

        computed: {
            habitatData: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            habitatData: {
                handler(value) {
                    if (this.alwaysEditable) this.editingRows = [...value];
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    .point-habitat-data-table {
        .p-datatable .p-datatable-tbody > tr > td {
            padding: 0.25rem 0.25rem;
        }
    }
    .point-habitat-data-table {
        .table-input-select-one, .table-input-calendar {
            input {
                height: 32px !important;
            }
        }
    }
</style>
