<template>
    <div>
        <DataTable :value="observations" v-model:editingRows="editingRows"
                   editMode="row" dataKey="id" size="small" :rowClass="rowClass">
            <Column header="Odcinek transektu" field="ordinal" class="w-1">
                <template #body="slotProps">
                    {{ slotProps.data.ordinal ? slotProps.data.ordinal : 0 }}
                </template>
            </Column>
            <Column header="Godzina" field="startTime1" class="w-2">
                <template #body="slotProps">
                    {{ slotProps.data.startTime1 != null ? DateUtils.getTimeOnly(slotProps.data.startTime1) : ""}}
                </template>
                <template #editor="{data,field,index}">
                    <CustomTimePicker v-model="data[field]" name="startTime1"
                                      :customErrorWithoutMessage="getRequired(data[field])" required
                                      @update:modelValue="(value) => observations[index][field] = value"
                                      :disabled="!editing"/>
                </template>
            </Column>
            <Column header="Głębokość [m]" field="depth" class="w-2">
                <template #editor="{data,field,index}">
                    <CustomInputNumber v-model="data[field]" name="depth"
                                       :customErrorWithoutMessage="getRequired(data[field])" required
                                       @update:modelValue="(value) => observations[index][field] = value"
                                       :maxDigits="3" :disabled="!editing"/>
                </template>
            </Column>
            <Column header="Stan morza" field="seaState" class="w-2">
                <template #body="slotProps">
                    {{ slotProps.data.seaState?.label }}
                </template>
                <template #editor="{data,field,index}">
                    <CustomSelectOne v-model="data[field]" :items="dicts.seaState" :disabled="!editing"
                                     name="seaState" :customErrorWithoutMessage="getRequired(data[field])" required
                                     @update:modelValue="(value) => observations[index][field] = value"/>
                </template>
            </Column>
            <Column header="Opady" field="rain" class="w-2">
                <template #body="slotProps">
                    {{ slotProps.data.rain?.label }}
                </template>
                <template #editor="{data,field,index}">
                    <CustomSelectOne v-model="data[field]" :items="dicts.rain" :disabled="!editing"
                                     name="rain" :customErrorWithoutMessage="getRequired(data[field])" required
                                     @update:modelValue="(value) => observations[index][field] = value"/>
                </template>
            </Column>
            <Column header="Chmury [%]" field="clouds" class="w-2">
                <template #editor="{data,field,index}">
                    <CustomSelectOne v-model="data[field]" :items="dicts.clouds" :disabled="!editing"
                                     name="clouds" :customErrorWithoutMessage="getRequired(data[field])" required
                                     @update:modelValue="(value) => observations[index][field] = value"/>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomTimePicker from "@/components/form/CustomTimePicker";
    import CustomSelectOne from "../../../components/form/inner/CustomSelectOne.vue";
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "FormObservationsTableMZPM",

        components: {
            DataTable,
            Column,
            CustomInputNumber,
            CustomTimePicker,
            CustomSelectOne,
        },

        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        data() {
            return {
                editingRows: ref([]),
            };
        },

        beforeMount() {
            this.editingRows = this.observations;
        },

        methods: {
            getRequired(value) {
                return this.showErrorMessages && (value == null || value === "");
            },
            rowClass(data) {
                return data.notAudited ? "hidden" : "";
            },
        },

        computed: {
            DateUtils() {
                return DateUtils;
            },
            observations: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
