<template> <!-- TODO nie działa przenoszenie pliku event drop -->
    <div class="file-upload-container">
        <div class="file-upload-dropzone" @drop.prevent="fileUpload"
             @dragenter.prevent="toggleActive" @dragleave.prevent="toggleActive"
             @dragover.prevent :class="{'active-dropzone': active}">
            <input v-if="!disabled" type="file" ref="file" v-show="false" @change="handleFile"
                   id="importFile" multiple name="file" :accept="accept"/>
            <label class="file-label" for="importFile" v-if="files.length === 0 && !disabled">
                <div>
                    Aby dodać pliki <u>kliknij tutaj</u> <br> lub <br> przeciągnij i upuść
                </div>
            </label>
            <div v-if="files.length"
                 class="file-preview-container flex justify-content-center align-items-center">
                <div v-for="file in files" :key="file.name"
                     class="file-preview-single flex align-items-center gap-2">
                    <p>{{ file.name }}</p>
                    <div v-if="!disabled">
                        <PrimeButton @click="remove(files.indexOf(file))" class="file-upload-button">x</PrimeButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {ref} from "vue";
    import PrimeButton from "primevue/button";

    export default {
        name: "CustomFileUpload",

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            accept: {
                type: String,
                default: "",
            },
        },

        emits: ["importFile"],

        components: {
            PrimeButton,
        },

        data() {
            return {
                // isDragging: false,
                files: [],
            };
        },

        setup() {
            const active = ref(false);

            const toggleActive = () => {
                active.value = !active.value;
            };

            return {
                active,
                toggleActive,
            };
        },

        methods: {
            handleFile() {
                for (let i = 0; i < this.$refs.file.files.length; i += 1) {
                    this.files.push(this.$refs.file.files[i]);
                    const formData = new FormData();
                    console.log(this.$refs.file.files[i]);
                    formData.append("file", this.$refs.file.files[i]);
                    this.toggleActive();
                    this.$emit("importFile", formData);
                }
            },
            fileUpload(e) {
                this.$refs.file.files = e.dataTransfer.files;
                this.handleFile();
            },
            remove(i) {
                this.files.splice(i, 1);
            },
        },
    };
</script>

<style lang="scss">
@import "../assets/theme/mytheme/variables";
.file-label {
    text-align: center;
}
.file-upload-container {
    .file-upload-dropzone {
        .file-preview-container {
            .file-preview-single {
                .file-upload-button {
                    background-color: transparent;
                    border: none;
                }
            }
        }
    }
}
</style>
