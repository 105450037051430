<template>
    <div :class="containerClass">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="sidebar">
            <transition name="layout-sidebar">
                <div class="layout-sidebar" @click="onSidebarClick" @keydown="onSidebarClick"
                     v-show="isSidebarVisible()">
                    <router-link :to="{name: 'programsList'}">
                        <div class="logo-container">
                            <img class="logo-img" src="@/assets/logo.png" alt="strona główna">
                            <h1 class="logo-text">Inspekcja <br> ochrony <br> środowiska</h1>
                        </div>
                    </router-link>
                    <div class="flex justify-content-center">
                        <img src="@/assets/logotyp-NFOSiGW.png" alt="logo" class="w-full p-1">
                    </div>
                    <AppMenu @menu-toggle="onMenuToggle" />
                </div>
            </transition>
        </div>

        <div class="layout-main">
            <div class="content">
                <router-view/>
            </div>

            <footer></footer>
        </div>
    </div>
</template>

<script>
    import AppTopBar from "@/components/AppTopBar";
    import AppMenu from "../../components/AppMenu";

    export default {
        name: "LayoutDashboard",
        components: {
            AppTopBar,
            AppMenu,
        },

        data() {
            return {
                layoutMode: "static",
                staticMenuInactive: false,
                overlayMenuActive: false,
                mobileMenuActive: false,
            };
        },

        methods: {
            onWrapperClick() {
                if (!this.menuClick) {
                    this.overlayMenuActive = false;
                    this.mobileMenuActive = false;
                }

                this.menuClick = false;
            },
            onMenuToggle() {
                this.menuClick = true;

                if (this.isDesktop()) {
                    if (this.layoutMode === "overlay") {
                        if (this.mobileMenuActive === true) {
                            this.overlayMenuActive = true;
                        }

                        this.overlayMenuActive = !this.overlayMenuActive;
                        this.mobileMenuActive = false;
                    } else if (this.layoutMode === "static") {
                        this.staticMenuInactive = !this.staticMenuInactive;
                    }
                } else {
                    this.mobileMenuActive = !this.mobileMenuActive;
                }
            },
            onSidebarClick() {
                this.menuClick = true;
            },
            onMenuItemClick(event) {
                if (event.item && !event.item.items) {
                    this.overlayMenuActive = false;
                    this.mobileMenuActive = false;
                }
            },
            onLayoutChange(layoutMode) {
                this.layoutMode = layoutMode;
            },
            removeClass(element, className) {
                if (element.classList) {
                    element.classList.remove(className);
                } else {
                    element.className = element.className.replace(new RegExp("(^|\\b)"
                        + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
                }
            },
            isDesktop() {
                return window.innerWidth > 1024;
            },
            isSidebarVisible() {
                if (this.isDesktop()) {
                    if (this.layoutMode === "static") return !this.staticMenuInactive;
                    if (this.layoutMode === "overlay") return this.overlayMenuActive;
                    return true;
                }

                return true;
            },
        },
        computed: {
            containerClass() {
                return ["layout-wrapper", {
                    "layout-overlay": this.layoutMode === "overlay",
                    "layout-static": this.layoutMode === "static",
                    "layout-static-sidebar-inactive": this.staticMenuInactive && this.layoutMode === "static",
                    "layout-overlay-sidebar-active": this.overlayMenuActive && this.layoutMode === "overlay",
                    "layout-mobile-sidebar-active": this.mobileMenuActive,
                }];
            },
        },
    };
</script>

<style lang="scss">
    @import "../../components/assets/scss/sidebar";
    @import "../assets/scss/layout-dasboard";
    @import "../assets/scss/responsive";
    @import "../../assets/theme/mytheme/variables";
</style>
