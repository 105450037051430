<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep">
        <template v-slot:formMap>
            <FormPositions v-model="form.positions" :field="form.field"
                           @reloadFormData="getFormData" dynamicPositions/>
        </template>
        <template v-slot:formObservations>
            <FormMCYObservations :field="form.field.shape"
                                 :allPositions="form.positions"
                                 ref="observationsView" v-model:step="step"
                                 v-model:form="form" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    import FormMCYObservations from "./components/FormMCYObservations.vue";
    // import {
    //     getForm,
    //     getList as getDict,
    // } from "@/swagger/vue-api-client";
    // import {DateUtils} from "@/utils/DateUtils";
    // import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMCYView",

        components: {
            FormViewTemplate,
            FormPositions,
            FormMCYObservations,
        },

        data() {
            return {
                programCode: "MCY",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            // this.getFormData();
            this.dicts = {
                controlMethod: [
                    {code: "OBSERVATION", label: "Obserwacja wizualna"},
                    {code: "DRONE", label: "Kontrola dronem"},
                ],
                species: [
                    {code: "CCY", name: "łabedź krzykliwy"},
                    {code: "CY", name: "łabędź niemy"},
                    {code: "mix", name: "para mieszana łabędź krzykliwy x łabędź niemy"},
                ],
                nestingCriteria: [
                    {code: "O", label: "jednorazowa obserwacja pojedynczego ptaka w siedlisku lęgowym"},
                    {code: "PR", label: "para ptaków obserwowanych w siedlisku lęgowym"},
                    {code: "KT", label: "tokująca lub kopulująca para"},
                    {code: "NP", label: "zaniepokojenie jednego ptaka lub pary wskazujące na obecność lęgu"},
                    {code: "BU", label: "budowa gniazda"},
                    {code: "GNS", label: "świeże gniazdo"},
                    {code: "WYS", label: "gniazdo wysiadywane"},
                    {code: "JAJ", label: "gniazdo z jajami lub skorupy jaj w gnieździe"},
                    {code: "PIS", label: "gniazdo z pisklętami"},
                    {code: "MŁO", label: "młode poza gniazdem, nielotne lub słabo lotne"},
                ],
                younglingsSize: [
                    {code: "KOK", label: "jak kokoszka"},
                    {code: "KRZ", label: "jak krzyżówka"},
                    {code: "ANS", label: "jak gęgawa"},
                    {code: "CYG", label: "jak dorosły łabędź"},
                    {code: "NONE", label: "nie stwierdzono młodych"},
                ],
                breedingResult: [
                    {code: "SUCCESS", label: "sukces"},
                    {code: "LOSSES", label: "strata"},
                    {code: "UNKNOWN", label: "nieznany/nie dotyczy"},
                ],
                habitatType: [
                    {code: "OC", label: "oczko (do 1ha)"},
                    {code: "JZ", label: "jezioro"},
                    {code: "SE", label: "starorzecze"},
                    {code: "RZ", label: "rzeka"},
                    {code: "ZB", label: "zalewisko bobrowe"},
                    {code: "ZZ", label: "zbiornik zaporowy/retencyjny"},
                    {code: "ST", label: "staw"},
                    {code: "RL", label: "rozlewisko"},
                    {code: "GT", label: "glinianka, torfianka"},
                    {code: "ŻW", label: "żwirownia"},
                    {code: "IN", label: "inne"},
                ],
                habitatEnvironment: [
                    {code: "1", label: "las"},
                    {code: "2", label: "łozowisko"},
                    {code: "3", label: "łąka"},
                    {code: "4", label: "zboże"},
                    {code: "5", label: "okopowe"},
                    {code: "6", label: "nieużytek"},
                    {code: "7", label: "zabudowa"},
                    {code: "8", label: "inne"},
                ],
                emergentVegetation: [
                    {code: "1", label: "szuwar trzcinowy"},
                    {code: "2", label: "szuwar pałkowy"},
                    {code: "3", label: "pło torfowe"},
                    {code: "4", label: "turzycowisko"},
                    {code: "5", label: "skrzypy"},
                    {code: "6", label: "łozy"},
                    {code: "7", label: "sity"},
                    {code: "8", label: "inne"},
                ],
                waterLevel: [
                    {code: "1", label: "wysoki"},
                    {code: "2", label: "średni"},
                    {code: "3", label: "niski"},
                    {code: "4", label: "brak wody"},
                ],
                poligamia: [
                    {code: "1", label: "Tak", value: true},
                    {code: "2", label: "Nie", value: false},
                ],
                controlNotRealizedReason: [
                    {
                        key: "AREA_NA_HUMAN",
                        code: "AREA_NA_HUMAN",
                        label: "Obszar kontroli niedostępny z powodu działań człowieka (np. prace leśne, zamknięcie z powodu ptasiej grypy, niekorzystne uprawy, teren prywatny itd.)",
                    },
                    {
                        key: "AREA_NA_NATURAL",
                        code: "AREA_NA_NATURAL",
                        label: "Obszar kontroli niedostępny z powodów naturalnych (wysoki stan wody, powodzie itd.)",
                    },
                    {
                        key: "OBSERVER_ABANDON",
                        code: "OBSERVER_ABANDON",
                        label: "Zaniechanie obserwatora",
                    },
                    {
                        key: "METHODICAL_REASON",
                        code: "METHODICAL_REASON",
                        label: "Powody metodyczne (dotyczy to programów, w których liczba kontroli nie jest stała)",
                    },
                    {
                        key: "RESULT_NOT_ACCEPTED",
                        code: "RESULT_NOT_ACCEPTED",
                        label: "Brak akceptacji wyników ze strony koordynatora",
                    },
                ],
            };
            this.form = {
                programEditionId: 123,
                programName: "MCY",
                programFullName: "Monitoring Lęgowych Łabędzi",
                monitoringYear: 2024,
                editable: true,
                state: "DRAFT",
                observer: "Jan Kowalski",
                id: 1,
                field: {
                    id: 1,
                    program: "MCY",
                    name: "MCY01",
                    fieldType: "FIELD",
                    superField: null,
                    shape: [
                        {longitude: 23.02674900176643, latitude: 51.39735704253448},
                        {longitude: 23.170159002152793, latitude: 51.39233304244274},
                        {longitude: 23.178350002151205, latitude: 51.4820400423824},
                        {longitude: 23.03466000176621, latitude: 51.48707904247509},
                        {longitude: 23.02674900176643, latitude: 51.39735704253448},
                    ],
                },
                control: {
                    label: "Kontrola 1",
                    controlStart: "2024-04-10",
                    controlEnd: "2024-05-20",
                },
                noFormControl: false,
                noFormControlReason: null,
                noFormControlNotes: null,
                positions: [
                    {
                        position: {
                            accepted: false,
                            coordinates: {
                                longitude: 23.04107666015625,
                                latitude: 51.433892209165236,
                            },
                            name: "MCY01",
                            commonName: "",
                            positionHistoryId: 1,
                            positionId: 1,
                            status: "CREATED",
                        },
                        notAudited: false,
                        notAuditedReason: null,
                        notAuditedNotes: "",
                        controls: [
                            {
                                noControl: false,
                                noControlReason: null,
                                noControlNotes: null,
                                controlDate: "2024-05-11",
                                controlMethod: {code: "OBSERVATION", label: "Obserwacja wizualna"},
                                noBirds: false,
                                species: {code: "CCY", name: "łabedź krzykliwy"},
                                adults: 2,
                                nestingCriteria: {code: "KT", label: "tokująca lub kopulująca para"},
                                younglings: null,
                                younglingsSize: null,
                                breedingResult: {code: "UNKNOWN", label: "nieznany/nie dotyczy"},
                                poligamia: {code: "2", label: "Nie", value: false},
                                poligamiaNotes: null,
                                notes: "lorem ispum",
                            },
                        ],
                        habitatData: {
                            habitatType: {code: "JZ", label: "jezioro"},
                            otherHabitatType: null,
                            habitatEnvironment: [
                                {code: "3", label: "łąka"},
                                {code: "4", label: "zboże"},
                            ],
                            emergentVegetation: [
                                {code: "3", label: "pło torfowe"},
                            ],
                            waterLevel: {code: "3", label: "niski"},
                        },
                        habitatDataToChange: null,
                    },
                    {
                        position: {
                            accepted: false,
                            coordinates: {
                                longitude: 23.145446777343754,
                                latitude: 51.44373668041616,
                            },
                            name: "MCY02",
                            commonName: "",
                            positionHistoryId: 2,
                            positionId: 2,
                            status: "CREATED",
                        },
                        notAudited: false,
                        notAuditedReason: null,
                        notAuditedNotes: "",
                        controls: [],
                        habitatData: null,
                        habitatDataToChange: null,
                    },
                ],
                notes: "Lorem ipsum",
                additionalObservers: "Jan Kowalski",
            };
            if (this.$route.query.birdCode === "CY") {
                this.form.subprogramName = "Liczenie łabędzia niemego";
            }
            if (this.$route.query.birdCode === "CCY") {
                this.form.subprogramName = "Liczenie łabędzia krzykliwego";
            }
            ValidateUtils.flattenData(this.form.positions, "position");
            this.loaded = true;
            this.editing = true;
        },

        methods: {
            getFormData() {
                // getForm({program: this.programCode, formId: this.$route.params.id})
                //     .then((response) => {
                //         this.form = response.data;
                //         ValidateUtils.flattenData(this.form.positions, "position");
                //         for (let i = 0; i < this.form.positions.length; i += 1) {
                //             const position = this.form.positions[i];
                //             if (position.controls !== null) {
                //                 for (let j = 0; j < position.controls.length; j += 1) {
                //                     const control = position.controls[j];
                //                     if (control.startTime !== null) {
                //                         control.startTime = control.startTime.substring(0, 5);
                //                     }
                //                     if (control.endTime !== null) {
                //                         control.endTime = control.endTime.substring(0, 5);
                //                     }
                //                 }
                //             }
                //         }
                //         console.log(this.form);
                //         this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                //         return getDict({programCode: this.programCode, formId: this.$route.params.id});
                //     })
                //     .then((response) => {
                //         this.dicts = response.data;
                //         console.log(this.dicts);
                //         this.loaded = true;
                //     })
                //     .catch((err) => {
                //         if (err.response.status === 403) {
                //             this.$router.push({name: "forbidden"});
                //         }
                //         this.loaded = false;
                //     });
            },
            getFormRequest() {
                // const request = ValidateUtils.getFormRequest(this.form, this.programCode, "positions", true);
                // if (!this.form.noFormControl) {
                //     for (let i = 0; i < this.form.positions.length; i += 1) {
                //         if (this.form.positions[i].controls != null) {
                //             for (let j = 0; j < this.form.positions[i].controls.length; j += 1) {
                //                 ValidateUtils.formatControlDateStartTimeAndEndTime(this.form.positions[i].controls[j]);
                //             }
                //         }
                //         request.positions.push(this.form.positions[i]);
                //     }
                // }
                // return request;
            },
            addErrorToast(detailMessage) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            validateData() {
                // let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                // if (isCorrect && !this.form.noFormControl) {
                //     isCorrect = this.validateFirstStep() && isCorrect;
                //     for (let i = 0; i < this.form.positions.length; i += 1) {
                //         const position = this.form.positions[i];
                //         if (position.notAudited === false || position.notAudited === null
                //             || position.notAudited === undefined) {
                //             if (position.noNest !== true) {
                //                 isCorrect = ValidateUtils.validateNotEmpty(position, ["nestType", "treeType", "latitude", "longitude"]) && isCorrect;
                //             }
                //             isCorrect = this.validateControls(i) && isCorrect;
                //         }
                //     }
                // }
                // this.validationCorrect = isCorrect;
                // this.request = this.getFormRequest();
            },
            validateFirstStep() {
                // const errors = ValidateUtils.validateNotAudited(this.form.positions, "Stanowisko");
                // this.addErrorToasts(errors);
                // return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
