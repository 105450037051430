<!-- eslint-disable -->
<template>
    <div class="flex justify-content-end flex-column">
        <div class="flex gap-3">
            <GeographicalFilters v-model="searchCriteria" v-model:matchMode="searchCriteria.matchMode"
                                 :filterBy="matchModeOptions" />
            <div class="flex align-self-end justify-content-end field">
                <CustomButton label="Wyczyść" @click="clear"/>
            </div>
        </div>
        <!-- <div class="flex align-items-center gap-3 field justify-content-end">
            <CustomButton label="Eksportuj" @click="exportData"/>
        </div> -->
    </div>
    <div class="habitat-data-table">
        <DataTable :lazy="true" :value="habitatData" dataKey="id" :paginator="true" v-model:rows="page.limit"
                   v-model:first="page.offset" :totalRecords="totalRecords" @page="onPage" @sort="onPage"
                   removableSort :rows="10" :rowsPerPageOptions="[5,10,20,50,100]" :loading="loading"
                   scrollable scroll-height="64.5vh" size="small" show-gridlines
                   v-model:filters="searchCriteria" filter-display="menu" @update:filters="search">
            <Column v-for="(c,index) in columns" key="index" v-show="c.visible" :header="c.header"
                    :field="c.field"
                    :filterField="c.filterField" :showFilterMatchModes="c.showFilterMatchModes"
                    :filterMenuClass="c.filterMenuClass" :sortable="c.sortable">
                <template #body="slotProps">
                    <router-link v-if="c.bodyLink" :to="c.linkTo(slotProps.data)">
                        <span class="link">{{ c.bodyContent(slotProps.data) }}</span>
                    </router-link>
                    <div v-else-if="c.bodyTooltip" :class="c.bodyClass(slotProps.data)"
                         v-tooltip.top="c.bodyTooltipContent(slotProps.data)">
                         {{ c.bodyContent(slotProps.data) }}
                    </div>
                    <div v-else>
                        {{ computedList(c.bodyContent(slotProps.data), c.isList) }}
                    </div>
                </template>
                <template #filter="{filterModel}" v-if="c.filterField != null">
                    <CustomIntervalFilter v-if="c.filterType === 'range' && loadLists" v-model="filterModel.value"
                                          :range="c.range" v-bind="c.filterOtherAttrs" />
                    <CustomMultiSelect v-else-if="c.filterType === 'dictionary' && loadLists" :label="c.header"
                                       :name="c.field" v-model="filterModel.value" :filter="c.filterFilter"
                                       :items="filterLists[c.filterItemsKey]" :itemLabel="c.filterItemLabel"
                                       :itemValue="c.filterItemValue" :class="c.filterClass"
                                       :emptyValue="c.filterEmptyValue" v-bind="c.filterOtherAttrs"/>
                    <CustomSelectOne v-else-if="c.filterType === 'selectOne'" :label="c.header" :name="c.field"
                                     :items="c.filterItems" :itemLabel="c.filterItemLabel" v-model="filterModel.value"
                                     :itemValue="c.filterItemValue" :class="c.filterClass" v-bind="c.filterOtherAttrs"
                                     :emptyValue="c.filterEmptyValue" />
                    <CustomCalendar v-else-if="c.filterType === 'date-range' && loadLists" :name="c.field"
                                    :selectionMode="c.filterSelectionMode" v-model="filterModel.value"
                                    inline :min="new Date(filterLists.dateControl.min)"
                                    :max="new Date(filterLists.dateControl.max)" v-bind="c.filterOtherAttrs"/>
                    <CustomInputText v-else-if="c.editorType === 'inputText'" v-model="data[field]" :name="c.field" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear v-if="c.filterType !== null" :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply v-if="c.filterType !== null" :callback="filterCallback" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Tooltip from "primevue/tooltip";
    import GeographicalFilters from "@/components/filters/GeographicalFilters.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {
        getHabitatFilters,
        searchHabitats,
        searchHabitatsCount,
    } from "@/swagger/vue-api-client";
    import CustomIntervalFilter from "@/components/filters/CustomIntervalFilter.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import FilterApply from "@/components/filters/FilterApply.vue";

    export default {
        name: "HabitatDataTable",

        components: {
            DataTable,
            Column,
            GeographicalFilters,
            CustomButton,
            FilterClear,
            FilterApply,
            CustomCalendar,
            CustomSelectOne,
            CustomMultiSelect,
            CustomIntervalFilter,
            CustomInputText,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            columns: {
                type: Array,
                required: true,
            },
            filterTags: {
                type: Boolean,
                default: true,
            },
            programCode: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                habitatData: [],
                totalRecords: 0,
                loading: false,
                filterLists: {},
                loadLists: false,
                searchCriteria: {},
                page: SearchCriteria.getClearPage(),
                filterDescription: [],
                matchModeOptions: [
                    {value: "FIELD", label: "Powierzchni"},
                ],
            };
        },

        beforeMount() {
            this.columns.forEach((c) => {
                if (c.filterField != null) {
                    this.filterDescription.push({name: c.filterField, type: c.filterType, label: c.header});
                }
            });
        },

        mounted() {
            if (this.filterTags) {
                const type = {};
                // if (this.programCode.includes("MPPL")) {
                //     type.program = "MPPL";
                //     type.qualifier = this.programCode.substring(4).toLowerCase();
                // } else if (this.programCode === "MRCLosses") {
                //     type.program = "MRC";
                //     type.qualifier = "losses";
                // } else {
                // }
                type.program = this.programCode;
                getHabitatFilters({...type})
                    .then((response) => {
                        this.filterLists = response.data;
                        console.log(this.filterLists);
                        this.loadLists = true;
                    });
            }
            this.clear();
        },

        methods: {
            clear() {
                this.setClearSearchCriteria();
                this.search();
            },
            search() {
                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    const criteria = SearchCriteria.prepareCriteria(
                        this.searchCriteria,
                        this.page,
                        this.filterDescription,
                    );
                    criteria.program = this.programCode;
                    criteria.geography = {...this.searchCriteria.geography};
                    searchHabitats({body: criteria, program: this.programCode})
                        .then((response) => {
                            this.habitatData = response.data;
                            console.log(response.data);
                            return searchHabitatsCount({body: criteria, program: this.programCode});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }, 100);
            },
            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },
            setClearSearchCriteria() {
                // this.searchCriteria = {};
                this.columns.forEach((c) => {
                    if (c.filterField != null) {
                        if (c.filterType === "range" || c.filterType === "date-range") {
                            this.searchCriteria[c.filterField] = {value: null, matchMode: "between"};
                        } else {
                            this.searchCriteria[c.filterField] = "";
                        }
                    }
                });
            },
            computedList(element, isList) {
                if (isList) {
                    let list = "";
                    element.forEach((el) => {
                        list += el.label + "; ";
                    });
                    return list;
                }
                return element;
            },
        },

        watch: {
            searchCriteria: {
                handler() {
                    this.search();
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
