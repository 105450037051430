<template>
    <div class="flex align-items-center">
        <RadioButton v-model="internalValue" :inputId="inputId"
                     :name="name" :value="value" :disabled="disabled" />
        <label :for="name" class="ml-2" :class="!disabled ? 'cursor-pointer' : ''"
               @click="!disabled ? internalValue = value : ''">
            {{label}}
        </label>
    </div>
</template>

<script>
    import RadioButton from "primevue/radiobutton";

    export default {
        name: "CustomRadioButton",

        components: {
            RadioButton,
        },

        props: {
            value: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            label: {
                type: String,
            },
            modelValue: null,
            inputId: null,
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss>">
</style>
