<template>
    <div class="flex flex-column">
        <CustomCheckbox label="Brak gniazda" v-model="position.noNest" :disabled="!editing" name="noNest" />
        <div v-if="!position.noNest" class="flex gap-7">
            <div class="left-panel">
                <div class="observation-map" style="width: 400px;">
                    <MapSingleMarker mapId="nestMap" height="350px" v-model="nestCoordinates"
                                     :zoomControls="false" :field="field" @emitCoor="setLatLng" />
                </div>
            </div>
            <div class="right-panel flex flex-column gap-3">
                <div class="coordinates-box flex gap-5">
                    <CustomInputNumber class="w-15rem" label="Koordynaty N" v-model="position.latitude"
                                       :maxDigits="6" :minDigits="6" :disabled="!editing" name="nestLat"
                                       required :showErrors="showErrorMessages" />
                    <CustomInputNumber class="w-15rem" label="Koordynaty E" v-model="position.longitude"
                                       :maxDigits="6" :minDigits="6" :disabled="!editing" name="nestLng"
                                       required :showErrors="showErrorMessages" />
                </div>
                <div class="info-box flex gap-5">
                    <CustomSelectOne class="w-15rem" label="Rodzaj gniazda" :items="dicts.nestType" required
                                     v-model="position.nestType" :disabled="!editing" name="nestType"
                                     :showErrors="showErrorMessages" />
                </div>
                <div class="info-box flex gap-5">
                    <CustomSelectOne class="w-15rem" label="Nazwa drzewa" :items="dicts.treeType" required
                                     v-model="position.treeType" :disabled="!editing" name="treeType"
                                     :showErrors="showErrorMessages" />
                    <CustomSelectOne class="w-15rem" label="Kod drzewa" itemLabel="code" :items="dicts.treeType"
                                     v-model="position.treeType" :disabled="!editing" name="treeType"
                                     required :showErrors="showErrorMessages" />
                </div>
                <CustomInputTextArea label="Dodatkowe informacje" :rows="7" :disabled="!editing"
                                     v-model="position.additionalInfo" name="nestAdditionalInfo" />
            </div>
        </div>
    </div>
</template>

<script>
    import {MapMixin} from "@/mixins/MapMixin";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "NestDataMPS",

        components: {
            CustomSelectOne,
            CustomInputTextArea,
            CustomInputNumber,
            MapSingleMarker,
            CustomCheckbox,
        },

        mixins: [MapMixin],

        props: {
            modelValue: null,
            positions: null,
            field: null,
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        data() {
            return {
                nestCoordinates: [],
            };
        },

        beforeMount() {
            this.nestCoordinates = [this.position?.latitude, this.position?.longitude];
        },

        methods: {
            setLatLng(coordinates) {
                this.position.latitude = coordinates.lat;
                this.position.longitude = coordinates.lng;
            },
        },

        computed: {
            position: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            position: {
                handler(value) {
                    this.nestCoordinates = [value.latitude, value.longitude];
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
