<template>
    <HabitatDataTemplate :habitatData="habitatData" v-model:habitatDataToChange="localHabitatDataToChange"
                         :emptyHabitatData="EmptyStructures.getEmptyMSLHabitatData()">
        <template v-slot:data="slotProps">
            <div class="flex gap-3">
                <CustomMultiSelect label="Rodzaj siedliska" name="habitatType"
                                   v-model="slotProps.habitatData.habitatType" class="w-6" :items="dicts.habitatType"
                                   :showErrors="showErrorMessages" :emptyValue="false"
                                   :disabled="!editing" />
                <CustomInputTextArea label="Opis innych" class="w-6" name="habitatNotes"
                                     v-model="slotProps.habitatData.habitatNotes" :disabled="!editing" />
            </div>
        </template>
    </HabitatDataTemplate>
</template>

<script>
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import HabitatDataTemplate from "@/views/form/HabitatDataTemplate.vue";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";

    export default {
        name: "HabitatDataMSL",

        components: {
            HabitatDataTemplate,
            CustomMultiSelect,
            CustomInputTextArea,
        },

        props: {
            habitatData: {
                type: Object,
            },
            habitatDataToChange: {
                type: Object,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        emits: ["update:habitatDataToChange"],

        computed: {
            EmptyStructures() {
                return EmptyStructures;
            },
            localHabitatDataToChange: {
                get() { return this.habitatDataToChange; },
                set(value) { this.$emit("update:habitatDataToChange", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
