<template>
    <div>
        <MammalsDictSearchCriteria />
        <MammalsDictTable />
    </div>
</template>

<script>
    import MammalsDictTable from "./MammalsDictTable.vue";
    import MammalsDictSearchCriteria from "./MammalsDictSearchCriteria.vue";

    export default {
        name: "MammalsDictView",

        components: {
            MammalsDictTable,
            MammalsDictSearchCriteria,
        },
    };
</script>

<style lang="scss">
</style>
