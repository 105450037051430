<template>
    <div class="programs-table">
        <DataTable dataKey="id" :value="programEditions" selectionMode="single"
                   :lazy="true" :paginator="true" v-model:rows="page.limit"
                   v-model:first="page.offset"
                   :totalRecords="totalRecords"
                   @page="onPage" @sort="onPage" removableSort
                   :rows="10" :rowsPerPageOptions="[5,10,20,50,100]" :loading="loading"
                   scrollable scroll-height="64.5vh" size="small"
                   v-model:filters="searchCriteria" filter-display="menu" @update:filters="search">
            <template #header>
                <FilterTagsContainer v-model="searchCriteria" :filter-description="filterDescription"
                                     @filter-change="search" @clear="clear"/>

            </template>
            <Column header="Program" field="program" filter-field="program"
                    :show-filter-match-modes="false" filter-menu-class="w-16rem" sortable>
                <template #body="slotProps">
                    <router-link :to="`/monitoring-program/${slotProps.data.id}`">
                        <div>
                            {{ slotProps.data.program }}
                        </div>
                    </router-link>
                </template>
                <template #filter="{filterModel}">
                    <CustomMultiSelect v-if="loadedFilters" label="Program" name="" v-model="filterModel.value"
                                       :items="filters.program" itemLabel=""
                                       class="flex-1 w-0 mt-3" :empty-value="false" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
            </Column>
            <Column header="Rok" field="year" filter-field="year"
                    :showFilterMatchModes="false" filter-menu-class="w-16rem" sortable>
                <template #body="slotProps">
                    <router-link :to="`/monitoring-program/${slotProps.data.id}`">
                        <div>
                            {{ slotProps.data.year }}
                        </div>
                    </router-link>
                </template>
                <template #filter="{filterModel}">
                    <CustomIntervalFilter v-model="filterModel.value" :range="filters.year"/>
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
            </Column>
            <Column header="Status" filter-field="status"
                    :show-filter-match-modes="false" filter-menu-class="w-16rem">
                <template #body="slotProps">
                    <router-link :to="`/monitoring-program/${slotProps.data.id}`">
                        <div>
                            {{ state(slotProps.data.state) }}
                        </div>
                    </router-link>
                </template>
                <template #filter="{filterModel}">
                    <CustomMultiSelect v-if="loadedFilters" label="Status" name="" v-model="filterModel.value"
                                       :items="filters.status" item-label="label" item-value="key"
                                       class="flex-1 w-0 mt-3" :empty-value="false" prevent-sorting />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
            </Column>
            <!-- <Column>
                <template #body="slotProps">
                    <span @click="goToProgram(slotProps.data.id)" class="cursor-pointer">
                        Otwórz program
                    </span>
                </template>
            </Column> -->
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import CustomIntervalFilter from "@/components/filters/CustomIntervalFilter.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {
        searchProgramEditions,
        searchProgramEditionsCount,
        searchProgramEditionsFilters,
    } from "@/swagger/vue-api-client";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import FilterTagsContainer from "@/components/filters/FilterTagsContainer.vue";

    export default {
        name: "ProgramsTable",

        components: {
            FilterTagsContainer,
            CustomMultiSelect,
            FilterApply,
            FilterClear,
            CustomIntervalFilter,
            DataTable,
            Column,
        },

        data() {
            return {
                programEditions: [],
                searchCriteria: SearchCriteria.getClearSearchCriteriaProgramEditions(),
                page: SearchCriteria.getClearPage(),
                totalRecords: null,
                loading: false,
                loadedFilters: false,
                filters: [],
            };
        },

        mounted() {
            searchProgramEditionsFilters()
                .then((response) => {
                    this.filters = response.data;
                    this.loadedFilters = true;
                });
            this.search();
        },

        methods: {
            state(state) {
                return ProgramEditionState.getStateLabel(state);
            },

            clear() {
                this.searchCriteria = SearchCriteria.getClearSearchCriteriaProgramEditions();
                this.search();
            },
            search() {
                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    console.log(this.searchCriteria, this.page, this.filterDescription);
                    const criteria = SearchCriteria.prepareCriteria(
                        this.searchCriteria,
                        this.page,
                        this.filterDescription,
                    );
                    searchProgramEditions({body: criteria})
                        .then((response) => {
                            this.programEditions = response.data;
                            return searchProgramEditionsCount({body: criteria});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }, 100);
            },
            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },
            addNew() {

            },
        },
        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaProgramEditionFilterDescription();
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";

    .programs-table {
        .p-datatable .p-datatable-tbody > tr > td {
            // padding: 0;
        }
        a {
            display: block;
            color: var(--text-color);
            padding: 1rem;
        }
    }
</style>
