<template>
    <Dialog header="Potwierdź działanie" v-model:visible="isVisible" :modal="true">
        <div class="mb-8">
            <h4 class="mt-0">Aktualne stanowisko</h4>
            <p>Szerokość {{ currentEntry.coordinates.latitude }},
                Długość {{ currentEntry.coordinates.longitude }}</p>
            <h4>Poprzednie stanowisko</h4>
            <p v-if="previousEntry">Szerokość {{ previousEntry.coordinates.latitude }},
                Długość {{ previousEntry.coordinates.longitude }}</p>
            <p v-else>Poprzedni wpis nie jest dostępny</p>
        </div>
        <div class="flex gap-4 justify-content-center">
            <CustomButton label="Akceptuj" class="p-button-success" bgColor="var(--secondary-color)"
                          @click="emitAcceptPosition" color="var(--surface-a)"/>
            <CustomButton label="Odrzucić" @click="emitRejectPosition" />
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "ConfirmationPositionHistoryDialog",

        components: {
            Dialog,
            CustomButton,
        },

        props: {
            currentEntry: {
                type: Object,
            },
            previousEntry: {
                type: Object,
            },
            visible: {
                type: Boolean,
            },
        },

        data() {
            return {
            };
        },
        methods: {
            emitAcceptPosition() {
                this.$emit("acceptPosition");
            },
            emitRejectPosition() {
                this.$emit("rejectPosition");
            },
        },

        emits: ["acceptPosition", "rejectPosition"],

        computed: {
            isVisible: {
                get() { return this.visible; },
                set(value) { this.$emit("update:visible", value); },
            },
        },
    };
</script>
