import Vuex from "vuex";

export default Vuex.createStore({
  state: {
    fontSize: "normal",
    contrast: "normal",
    pageName: null,
  },
  mutations: {
    setNormalTheme(state) {
      state.theme = "normal";
    },
    setDarkTheme(state) {
      state.theme = "dark";
    },
    setBlackContrast(state) {
      state.contrast = "black";
    },
    setNormalContrast(state) {
      state.contrast = "normal";
    },
    setNormalFontSize(state) {
      state.fontSize = "normal";
    },
    setBigFontSize(state) {
      state.fontSize = "big";
    },
    setBiggerFontSize(state) {
      state.fontSize = "bigger";
    },
  },
});
