<template>
    <div v-if="!isCaptain" class="mb-4">
        <CustomInfoBox v-if="internalForm.noFormControl" text="Kontrola nie została wykonana" class="w-full" />
        <CustomInfoBox v-else class="w-full"
                       text="Dane podstawowe zostaną automatycznie uzupełnione na podstawie formularza kapitana."/>
    </div>
    <FormObservationsTemplate ref="observationsTemplate" noAdditionalInformation>
        <template v-slot:mainData>
            <div class="w-full flex gap-6">
                <CustomCalendar label="Data liczenia" class="flex-1 w-0"
                                :disabled="!editing || !isCaptain" name="controlDate" required
                                v-model="internalForm.controlDate" :showErrors="showErrorMessages"
                                :customErrorMessage="getDateErrorMessage(internalForm.controlDate)" />
                <CustomInputText label="Nazwa statku" class="flex-1 w-0"
                                 :disabled="!editing || !isCaptain" name="shipName" required
                                 v-model="internalForm.shipName" :showErrors="showErrorMessages"/>
            </div>
            <AdditionalInformation v-model="internalForm" :isCaptain="isCaptain"
                                   v-model:captainNotes="internalForm.captainNotes" mzpm />
            <CustomInputTextArea v-if="!isCaptain" label="Moje uwagi" class="w-full " name="myNotes"
                                 :disabled="!editing" v-model="internalForm.observerNotes" />
        </template>
        <template v-slot:observationConditions v-if="!internalForm.noFormControl && isCaptain">
            <p class="mt-0">Wprowadź obserwacje na każdym z odcinków:</p>
            <FormObservationsTableMZPM v-model="internalForm.formTransects" />
        </template>
        <template v-slot:birdsData v-if="!internalForm.noFormControl && !isCaptain">
            <FormBirdsMZPM v-model:birds="internalForm.formBirds" />
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import FormBirdsMZPM from "./formBirds/FormBirdsMZPM.vue";
    import CustomInfoBox from "@/components/form/CustomInfoBox";
    import AdditionalInformation from "@/components/formSharedComponents/AdditionalInformation.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomInputText from "../../../components/form/CustomInputText.vue";
    import FormObservationsTableMZPM from "./FormObservationsTableMZPM.vue";
    import CustomInputTextArea from "../../../components/form/CustomInputTextArea.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMZPMObservations",

        components: {
            FormObservationsTemplate,
            FormBirdsMZPM,
            CustomInfoBox,
            AdditionalInformation,
            CustomCalendar,
            FormObservationsTableMZPM,
            CustomInputText,
            CustomInputTextArea,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                formBirds: [],
                noBirds: false,
                activeIndex: null,
            };
        },

        inject: ["editing", "showErrorMessages", "controlTimeFrame"],

        methods: {
            getDateErrorMessage(value) {
                if (!ValidateUtils.validateIsDateInRange(value, this.controlTimeFrame.controlStart, this.controlTimeFrame.controlEnd)) {
                    return "Data musi być z przedziału " + this.controlTimeFrame.controlStart + " - "
                        + this.controlTimeFrame.controlEnd;
                }
                return "";
            },
        },

        computed: {
            isCaptain() {
                return (this.$route.query.controlCode === "K1C");
            },
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            // activeIndex(value) { this.transect = this.transects[value]; console.log(this.transect); },
        },
    };
</script>

<style lang="scss">
</style>
