<template>
    <HabitatDataTemplate :habitatData="habitatData" v-model:habitatDataToChange="localHabitatDataToChange"
                         :emptyHabitatData="EmptyStructures.getEmptyMZOHabitatData()">
        <template v-slot:data="slotProps">
            <div class="flex justify-content-between flex-column">
                <div class="flex gap-3">
                    <CustomSelectOne label="Siedlisko" name="habitatType" :items="dicts.habitatType" class="w-6" :emptyValue="false"
                                     v-model="slotProps.habitatData.habitatType"
                                     required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <CustomMultiSelect label="Uprawy w promieniu 500m" name="crops" :items="dicts.crops"
                                       class="w-6" :emptyValue="false" v-model="slotProps.habitatData.crops"
                                       required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                       @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)" />
                </div>
                <div class="flex gap-3">
                    <CustomInputNumber label="Przybliżona odległość do najbliższych zabudowań (m)" name="buildingDistance"
                                       v-model="slotProps.habitatData.buildingDistance" required class="w-6"
                                       :disabled="slotProps.disabled" :showErrors="showErrorMessages"
                                       @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                       :maxDigits="2" />
                    <CustomInputNumber label="Przybliżona odległość do najbliższej doliny rzecznej lub zbiornika wodnego (m)" name="waterDistance"
                                       v-model="slotProps.habitatData.waterDistance" required class="w-6"
                                       :disabled="slotProps.disabled" :showErrors="showErrorMessages"
                                       @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                       :maxDigits="2" />
                </div>
                <div class="flex gap-3">
                    <CustomInputNumber label="Szacunkowa wysokość skarpy (m)" name="slopeHeight"
                                       v-model="slotProps.habitatData.slopeHeight" required class="w-6 pr-2"
                                       :disabled="slotProps.disabled" :showErrors="showErrorMessages"
                                       @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                       :maxDigits="2" />
                </div>
            </div>
        </template>
    </HabitatDataTemplate>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import HabitatDataTemplate from "@/views/form/HabitatDataTemplate.vue";

    export default {
        name: "HabitatDataMZO",

        components: {
            HabitatDataTemplate,
            CustomSelectOne,
            CustomMultiSelect,
            CustomInputNumber,
        },

        props: {
            habitatData: {
                type: Object,
            },
            habitatDataToChange: {
                type: Object,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        emits: ["update:habitatDataToChange"],

        computed: {
            EmptyStructures() {
                return EmptyStructures;
            },
            localHabitatDataToChange: {
                get() { return this.habitatDataToChange; },
                set(value) { this.$emit("update:habitatDataToChange", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
