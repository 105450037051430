<template>
    <div class="users-search-criteria">
        <div class="flex gap-3">
            <CustomInputText name="firstName" label="Imię" v-model="internalValue.firstName" class="w-2"/>
            <CustomInputText name="lastName" label="Nazwisko" v-model="internalValue.lastName" class="w-2"/>
            <CustomInputText name="email" label="e-mail" v-model="internalValue.email" class="w-2"/>
            <CustomSelectOneSystemRole name="systemRole" label="Rola" v-model="internalValue.systemRole" class="w-2"/>
            <CustomButton label="Wyczyść" @click="clear"/>
        </div>
    </div>
</template>

<script>
    import CustomInputText from "../../../components/form/CustomInputText";
    import CustomSelectOneSystemRole from "../../../components/form/CustomSelectOneSystemRole";
    import CustomButton from "../../../components/form/CustomButton.vue";

    export default {
        name: "UserSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.firstName = "";
                this.internalValue.lastName = "";
                this.internalValue.email = "";
                this.internalValue.systemRole = null;
                // this.$emit("unselect");
                this.update();
            },
        },

        components: {CustomInputText, CustomSelectOneSystemRole, CustomButton},
    };
</script>

<style scoped>

</style>
