<template>
    <DataTable :value="areaDict" v-model:editingRows="editingRows" editMode="row" dataKey="code" lazy
               :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
               @page="onPage" @sort="onPage" @row-edit-save="onRowEditSave"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
               LastPageLink CurrentPageReport RowsPerPageDropdown" removableSort
               currentPageReportTemplate="Pozycji na stronie" paginator
               :rows="20" :rowsPerPageOptions="[10,20,50,100]" size="small"
               v-model:rows="page.limit" v-model:first="page.offset">
        <Column v-for="(c,index) in columns" :key="index" :header="c.header"
                :field="c.field">
            <template #body="slotProps">
                <div :class="c.bodyClass(slotProps.data)">
                    {{ c.bodyContent(slotProps.data) }}
                </div>
            </template>
        </Column>
        <Column header="Działania">
            <template #body="slotProps">
                <CustomButton label="Wyświetl szczegóły" @click="navigateToDetails(slotProps.data.id)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchGeography,
        countGeography,
    } from "@/swagger/vue-api-client";
    // import CustomInputText from "@/components/form/CustomInputText.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomButton from "@/components/form/CustomButton";

    export default {
        name: "AreaDictTable",

        components: {
            DataTable,
            Column,
            // CustomInputText,
            CustomButton,
        },

        props: {
            columns: {
                type: Array,
                required: true,
            },
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                areaDict: [],
                editingRows: ref([]),
                loading: false,
                totalRecords: 0,
                page: SearchCriteria.getClearPage(),
            };
        },

        mounted() {
            this.search();
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                console.log(event.first);

                this.page.offset = event.first;
                this.page.limit = event.rows;
                this.page.sortField = event.sortField;
                this.page.sortOrder = event.sortOrder;

                const criteria = {
                    ...this.searchCriteria,
                    page: this.page,
                };

                searchGeography({body: criteria})
                    .then((response) => {
                        this.areaDict = response.data;
                        this.loading = false;
                    });
            },

            updateTotalRecords() {
                countGeography({body: this.searchCriteria})
                    .then((response) => {
                        this.totalRecords = response.data;
                    });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            onRowEditSave(event) {
                const {newData, index} = event;
                this.areaDict[index] = newData;
            },

            navigateToDetails(id) {
                this.$router.push(`/areaDicts/${this.$route.params.dict}/${id}`);
            },
        },
    };
</script>

<style lang="scss">
</style>
