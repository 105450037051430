<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep">
        <template v-slot:formMap>
            <FormPositions v-model="form.points" :field="form.field" @reloadFormData="getFormData" objectBeingChanged="punkt"/>
        </template>
        <template v-slot:formObservations>
            <FormMRDObservations :field="form.field.shape"
                                 v-model:form="form" ref="observationsView" v-model:step="step"/>
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    // import {
    //     getForm,
    //     getList as getDict,
    // } from "@/swagger/vue-api-client";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import FormMRDObservations from "./components/FormMRDObservations.vue";
    // import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "FormMRDView",

        components: {
            FormMRDObservations,
            FormViewTemplate,
            FormPositions,
        },

        data() {
            return {
                programCode: "MRD",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
                ValidateUtils,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            // this.getFormData();
            this.dicts = {
                controlNotRealizedReason: [
                    {
                        key: "AREA_NA_HUMAN",
                        code: "AREA_NA_HUMAN",
                        label: "Obszar kontroli niedostępny z powodu działań człowieka (np. prace leśne, zamknięcie z powodu ptasiej grypy, niekorzystne uprawy, teren prywatny itd.)",
                    },
                    {
                        key: "AREA_NA_NATURAL",
                        code: "AREA_NA_NATURAL",
                        label: "Obszar kontroli niedostępny z powodów naturalnych (wysoki stan wody, powodzie itd.)",
                    },
                    {
                        key: "OBSERVER_ABANDON",
                        code: "OBSERVER_ABANDON",
                        label: "Zaniechanie obserwatora",
                    },
                    {
                        key: "METHODICAL_REASON",
                        code: "METHODICAL_REASON",
                        label: "Powody metodyczne (dotyczy to programów, w których liczba kontroli nie jest stała)",
                    },
                    {
                        key: "RESULT_NOT_ACCEPTED",
                        code: "RESULT_NOT_ACCEPTED",
                        label: "Brak akceptacji wyników ze strony koordynatora",
                    },
                ],
                clouds: [
                    {
                        key: "CLOUDS_1",
                        code: "1",
                        label: "pogodnie (<30%)",
                    },
                    {
                        key: "CLOUDS_2",
                        code: "2",
                        label: "zachmurzenie częściowe (30-90%)",
                    },
                    {
                        key: "CLOUDS_3",
                        code: "3",
                        label: "zachmurzenie pełne (>90%)",
                    },
                ],
                wind: [
                    {
                        key: "WIND_1",
                        code: "1",
                        label: "brak wiatru lub wiatr słaby",
                    },
                    {
                        key: "WIND_2",
                        code: "2",
                        label: "wiatr średni",
                    },
                ],
                enticement: [
                    {code: "1", label: "brak wabienia"},
                    {code: "2", label: "głos dzięcioła trójpalczastego"},
                    {code: "3", label: "głos dzięcioła białogrzbietego"},
                    {code: "4", label: "głos obu gatunków dzięciołów"},
                ],
                observationType: [
                    {code: "R0", label: "jednorazowa obserwacja pojedynczego ptaka (żerującego, przelatującego, głos kontaktowy)"},
                    {code: "R1", label: "obserwacje wskazujące na prawdopodobnie zajęte terytorium (werblowanie, para ptaków, ptak(i) zaniepokojone, tokujące, kopulacja, kucie dziupli)"},
                    {code: "R2", label: "obserwacje wskazujące z dużym prawdopodobieństwem na lęg w okolicy, w której dokonano stwierdzenia lub potwierdzające gniazdowanie (ptaki wlatujące/wylatujące z dziupli - zmiany przy wysiadywaniu jaj, ptaki z pokarmem, dziupla z pisklętami, podloty poza dziuplą, także w towarzystwie ptaków dorosłych)"},
                ],
                programBirds: [
                    {code: "DL", name: "dzięcioł białogrzbiety"},
                    {code: "PT", name: "dzięcioł trójpalczasty"},
                ],
                sex: [
                    {code: "M", label: "samiec ad."},
                    {code: "F", label: "samica ad."},
                    {code: "N", label: "płeć nieoznaczona"},
                    {code: "J", label: "pisklę/podlot"},
                ],
                otherSpecies: [
                    {code: "DS", name: "dzięcioł białoszyi"},
                    {code: "DM", name: "dzięcioł czarny"},
                    {code: "DA", name: "dzięcioł duży"},
                    {code: "DE", name: "dzięcioł średni"},
                    {code: "PU", name: "dzięcioł zielonosiwy"},
                    {code: "PV", name: "dzięcioł zielony"},
                    {code: "DI", name: "dzięciołek"},
                    {code: "J", name: "krętogłów"},
                ],
                otherBirds: [
                    {code: "TB", name: "jarząbek"},
                    {code: "GP", name: "sóweczka"},
                ],
            };
            this.form = {
                programEditionId: 123,
                programName: "MRD",
                programFullName: "Monitoring Rzadkich Dzięciołów",
                monitoringYear: 2024,
                editable: true,
                state: "DRAFT",
                observer: "Jan Kowalski",
                id: 1,
                field: {
                    id: 1,
                    program: "MRD",
                    name: "MRD01",
                    fieldType: "FIELD",
                    superField: null,
                    shape: [
                        {longitude: 23.02674900176643, latitude: 51.39735704253448},
                        {longitude: 23.170159002152793, latitude: 51.39233304244274},
                        {longitude: 23.178350002151205, latitude: 51.4820400423824},
                        {longitude: 23.03466000176621, latitude: 51.48707904247509},
                        {longitude: 23.02674900176643, latitude: 51.39735704253448},
                    ],
                },
                control: {
                    label: "Kontrola 1",
                    controlStart: "2024-04-10",
                    controlEnd: "2024-07-20",
                },
                noFormControl: false,
                noFormControlReason: null,
                noFormControlNotes: null,
                controlDate: "2024-05-22",
                startTime: "07:00",
                endTime: "09:00",
                clouds: {key: "CLOUDS_2", code: "2", label: "zachmurzenie częściowe (30-90%)"},
                wind: {key: "WIND_1", code: "1", label: "brak wiatru lub wiatr słaby"},
                additionalObservers: "Jan Kowalski",
                notes: "Lorem ipsum",
                birds: [
                    {
                        species: {code: "TB", name: "jarząbek"},
                        count: 3,
                        sex: {code: "M", label: "samiec ad."},
                        observationType: {code: "R1", label: "obserwacje wskazujące na prawdopodobnie zajęte terytorium (werblowanie, para ptaków, ptak(i) zaniepokojone, tokujące, kopulacja, kucie dziupli)"},
                    },
                    {
                        species: {code: "GP", name: "sóweczka"},
                        count: 1,
                        sex: {code: "M", label: "samiec ad."},
                        observationType: {code: "R0", label: "jednorazowa obserwacja pojedynczego ptaka (żerującego, przelatującego, głos kontaktowy)"},
                    },
                ],
                noBirds: false,
                points: [
                    {
                        point: {
                            accepted: false,
                            coordinates: {
                                longitude: 23.04107666015625,
                                latitude: 51.433892209165236,
                            },
                            name: "1",
                            pointHistoryId: 1,
                            pointId: 1,
                            status: "CREATED",
                        },
                        notAudited: false,
                        notAuditedReason: null,
                        notAuditedNotes: "",
                        birds: [
                            {
                                species: {code: "PT", name: "dzięcioł trójpalczasty"},
                                count: 2,
                                sex: {code: "M", label: "samiec ad."},
                                observationType: {code: "R1", label: "obserwacje wskazujące na prawdopodobnie zajęte terytorium (werblowanie, para ptaków, ptak(i) zaniepokojone, tokujące, kopulacja, kucie dziupli)"},
                            },
                        ],
                        noBirds: false,
                        otherSpecies: null,
                        pointStartTime: "07:35",
                        enticement: {code: "2", label: "głos dzięcioła trójpalczastego"},
                        noCounting: false,
                        noCountingReason: null,
                    },
                    {
                        point: {
                            accepted: false,
                            coordinates: {
                                longitude: 23.145446777343754,
                                latitude: 51.44373668041616,
                            },
                            name: "2",
                            pointHistoryId: 2,
                            pointId: 2,
                            status: "CREATED",
                        },
                        notAudited: false,
                        notAuditedReason: null,
                        notAuditedNotes: "",
                        birds: [],
                        noBirds: true,
                        otherSpecies: [
                            {code: "DE", name: "dzięcioł średni"},
                            {code: "PV", name: "dzięcioł zielony"},
                        ],
                        pointStartTime: "08:00",
                        enticement: {code: "4", label: "głos obu gatunków dzięciołów"},
                        noCounting: false,
                        noCountingReason: null,
                    },
                ],
            };
            ValidateUtils.flattenData(this.form.points, "point");
            console.log(this.form);
            console.log(this.dicts);
            this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
            this.loaded = true;
        },

        methods: {
            getFormData() {
                // getForm({program: this.programCode, formId: this.$route.params.id})
                //     .then((response) => {
                //         this.form = response.data;
                //         ValidateUtils.flattenData(this.form.positions, "position");
                //         console.log(this.form);
                //         this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                //         return getDict({programCode: this.programCode, formId: this.$route.params.id});
                //     })
                //     .then((response) => {
                //         this.dicts = response.data;
                //         console.log(this.dicts);
                //         this.loaded = true;
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //         this.loaded = false;
                //     });
            },
            getFormRequest() {
            },
            validateData() {
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.points, "Punkt");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.points, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
