<template>
    <div class="flex gap-7 select-geobject-section">
        <SelectPositionMap :positions="positions" :field="field" :customHeight="customHeight"
                           v-model:hoverPosition="hoverPosition" v-model:clickedPosition="clickedPosition" />
        <div class="w-full">
            <div class="flex justify-content-between">
                <CustomButton :label="backButtonTitle" icon="pi pi-chevron-left" rowReverse @click="goBack"/>
                <div class="arrows-box flex gap-2">
                    <CustomIconButton icon="pi pi-chevron-left" label="Poprzednie" rowReverse
                                      @click="goNext(positions,true)" />
                    <CustomIconButton icon="pi pi-chevron-right" label="Następne" @click="goNext(positions)" />
                </div>
            </div>
            <div class="positions-list">
                <h2 class="positions-list-header uppercase">{{ header }}</h2>
                <ul class="positions-list-container">
                    <li v-for="position in positions" :key="position.name" :title="position.name"
                        class="positions-list-single-element" :class="position.error ? 'colorRed' : ''"
                        @click="() => clickedPosition = position.positionId"
                        @mouseover="() => hoverPosition = position.positionId"
                        @focus="() => hoverPosition = position.positionId"
                        @mouseout="() => hoverPosition = null" @blur="() => hoverPosition = null">
                        {{ getName(position) }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import {MapMixin} from "@/mixins/MapMixin";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomIconButton from "@/components/form/CustomIconButton.vue";
    import SelectPositionMap from "@/components/formSharedComponents/SelectPositionMap.vue";

    export default {
        name: "ObservationsSelectPosition",

        components: {
            SelectPositionMap,
            CustomIconButton,
            CustomButton,
        },

        mixins: [MapMixin],

        props: {
            modelValue: null,
            positions: {
                type: Array,
                default: () => [],
            },
            field: null,
            header: {
                type: String,
                default: "Stanowiska",
            },
            step: {
                type: Number,
            },
        },

        data() {
            return {
                customHeight: "480px",
                hoverPosition: null,
                clickedPosition: null,
            };
        },

        emits: ["update:modelValue", "update:step"],

        mounted() {
            // zwiększenie wysokości kolumn z listą stanowisk, gdy zbyt duża liczba stanowisk
            let styleHeight = 360;
            const listContainer = document.querySelector(".positions-list-container");
            const map = document.querySelector(".observation-map");
            if (listContainer.scrollWidth > listContainer.offsetWidth) {
                map.style.minHeight = "480px";
                map.style.height = "100%";
                this.customHeight = "100%";
            }
            while (listContainer.scrollWidth > listContainer.offsetWidth) {
                styleHeight += 24;
                listContainer.style.height = `${styleHeight}px`;
            }
            if (this.positions.length > 0) {
                this.goNext(this.positions);
            }
        },

        methods: {
            goNext(data, previous = false) {
                const active = document.querySelector(".focus-position");
                const positionList = Array.from(document.getElementsByClassName(
                    "positions-list-single-element",
                ));
                let index = positionList.indexOf(active);
                if (active === null) {
                    index = previous ? (positionList.length - 1) : 0;
                } else if (index < positionList.length) {
                    document.querySelector(".focus-position").classList.remove("focus-position");
                    if (previous) {
                        index = Math.max(0, index - 1);
                    } else {
                        index = Math.min(positionList.length - 1, index + 1);
                    }
                }
                positionList[index].classList.add("focus-position");
                positionList[index].classList.add("clicked");
                const localPosition = data[index];
                if (localPosition.coordinates === null) {
                    localPosition.coordinates = {
                        longitude: null,
                        latitude: null,
                    };
                }
                this.clickedPosition = localPosition.positionId;
                this.position = localPosition;
            },
            goBack() {
                this.$emit("update:step", 1);
                let query = "?step=1";
                if (this.$route.query.controlCode != null) query += `&controlCode=${this.$route.query.controlCode}`;
                window.scrollTo({ top: 0, behavior: "smooth" });
                window.history.pushState(null, "", this.$route.path + query);
            },
            getName(position) {
                let name = position.name;
                if (position.commonName != null && position.commonName !== "") name += " (" + position.commonName + ")";
                return name;
            },
        },

        computed: {
            position: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
            backButtonTitle() {
                let title = "Powrót do mapy z wyborem ";
                if (this.header === "Stanowiska") title += "stanowisk";
                else if (this.header === "Punkty") title += "punktów";
                else if (this.header === "Kolonie") title += "kolonii";
                else title = "Powrót do mapy";
                return title;
            },
        },

        watch: {
            clickedPosition(value) {
                if (document.querySelector(".focus-position")) {
                    document.querySelector(".focus-position").classList.remove("focus-position");
                }
                if (document.querySelector(".clicked")) {
                    document.querySelector(".clicked").classList.remove("clicked");
                }
                this.positions.forEach((position) => {
                    if (position.positionId === value) {
                        const index = this.positions.indexOf(position);
                        const positionsList = Array.from(document.getElementsByClassName(
                            "positions-list-single-element",
                        ));
                        positionsList[index].classList.add("focus-position");
                        positionsList[index].classList.add("clicked");
                        if (position.coordinates === null) {
                            position.coordinates = {
                                longitude: null,
                                latitude: null,
                            };
                        }
                        this.clickedPosition = position.positionId;
                        this.position = position;
                    }
                });
            },
            hoverPosition(value) {
                if (value === null) {
                    const positionsList = Array.from(document.getElementsByClassName(
                        "focus-position",
                    ));
                    for (let i = 0; i < positionsList.length; i += 1) {
                        if (!positionsList[i].classList.contains("clicked")) {
                            positionsList[i].classList.remove("focus-position");
                        }
                    }
                } else {
                    this.positions.forEach((position) => {
                        if (position.positionId === value) {
                            const index = this.positions.indexOf(position);
                            const positionsList = Array.from(document.getElementsByClassName(
                                "positions-list-single-element",
                            ));
                            positionsList[index].classList.add("focus-position");
                        }
                    });
                }
            },
            modelValue(value) {
                this.clickedPosition = value.positionId;
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";

.colorRed {
    color: $color-red !important;
}
</style>
