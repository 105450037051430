<template>
    <CustomInfoBox v-if="editing" :text="text" />
</template>

<script>
    import CustomInfoBox from "@/components/form/CustomInfoBox.vue";

    export default {
        name: "ChangeCoordinatesInfo",

        components: {
            CustomInfoBox,
        },

        props: {
            objectBeingChanged: {
                type: String,
                default: "stanowisko",
            },
        },

        inject: ["editing"],

        methods: {
            getDopelniacz() {
                if (this.objectBeingChanged === "stanowisko") return "stanowiska";
                if (this.objectBeingChanged === "punkt") return "punktu";
                if (this.objectBeingChanged === "odcinek") return "odcinka";
                return "";
            },
            getZaimek() {
                if (this.objectBeingChanged === "stanowisko") return "w nie";
                return "go";
            },
        },

        computed: {
            text() {
                return "Aby zmienić położenie " + this.getDopelniacz() + ", kliknij " + this.getZaimek()
                    + " i zgłoś zmianę lokalizacji";
            },
        },
    };
</script>

<style lang="scss">
</style>
