<template>
    <div>
        <!--filterDisplay="row"-->
        <DataTable dataKey="id" :value="program.details" size="small"
                   paginator :rows="10" :rowsPerPageOptions="[5,10,20,50]" removableSort
                   v-model:filters="searchCriteria" filterDisplay="menu">
            <Column field="field" header="Powierzchnia" class="w-2"
                    filterField="field" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomSelectOne label="Powierzchnia" name="field" v-model="filterModel.value" :items="fields"
                                     :emptyValue="false" filter itemLabel="" class="flex-1 w-0 mt-3" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column v-if="program.programType === 'POSITION'" header="Stanowiska" field="positions" class="w-2"
                    filterField="positions" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomSelectOne label="Stanowiska" name="positions" v-model="filterModel.value" :items="positions"
                                     :emptyValue="false" filter itemLabel="" class="flex-1 w-0 mt-3" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
                <template #body="{data}">
                    <div v-if="data.positions.length > 1" v-tooltip.left="getPositionsTooltip(data.positions)">
                        {{ getPositionsLabel(data.positions) }}
                    </div>
                    <div v-else>
                        {{ getPositionsLabel(data.positions) }}
                    </div>
                </template>
            </Column>
            <Column header="Kontrola" field="control" class="w-3"
                    filterField="control" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomSelectOne label="Kontrola" name="control" v-model="filterModel.value" :items="controls"
                                     :emptyValue="false" filter itemLabel="" class="flex-1 w-0 mt-3" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Rok" field="year" class="w-1" sortable
                    filterField="year" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomIntervalFilter v-model="filterModel.value" :range="{min: 2007,max: new Date().getFullYear()}" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Status" field="status" class="w-2"
                    filterField="status" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomSelectOne label="Status" name="status" v-model="filterModel.value" :items="statuses"
                                     :emptyValue="false" filter itemValue="code" class="flex-1 w-0 mt-3" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
                <template #body="slotProps">
                    <div :class="slotProps.data.status === 'ACCEPTED'
                        ? 'accepted-style' : 'in-progress-style'">
                        <span>{{ computedStatus(slotProps.data.status) }}</span>
                    </div>
                </template>
            </Column>
            <Column header="Start" field="startTime" class="w-1"/>
            <Column header="Koniec" field="endTime" class="w-1"/>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Tooltip from "primevue/tooltip";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import CustomIntervalFilter from "@/components/filters/CustomIntervalFilter.vue";

    export default {
        name: "ProgramsListInternalTable",

        components: {
            CustomIntervalFilter,
            FilterClear,
            FilterApply,
            CustomSelectOne,
            DataTable,
            Column,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            program: {
                required: true,
            },
        },

        data() {
            return {
                programEditions: [],
                searchCriteria: {
                    field: "",
                    positions: "",
                    control: "",
                    year: {
                        value: null,
                        matchMode: "between",
                    },
                    status: "",
                },
                fields: [],
                positions: [],
                controls: [],
                statuses: [
                    {code: "IN_PROGRESS", label: "W toku"},
                    {code: "ACCEPTED", label: "Zaakceptowano"},
                ],
            };
        },

        beforeMount() {
            this.fields = this.computeFilterOptionsFromData("field");
            this.positions = this.computeFilterOptionsFromArrayData("positions");
            this.controls = this.computeFilterOptionsFromData("control");
        },

        methods: {
            computeFilterOptionsFromData(key) {
                const set = new Set();
                this.program.details.forEach((form) => {
                    if (!set.has(form[key])) set.add(form[key]);
                });
                return Array.from(set);
            },
            computeFilterOptionsFromArrayData(key) {
                const set = new Set();
                this.program.details.forEach((form) => {
                    if (form[key] != null) {
                        form[key].forEach((item) => {
                            if (!set.has(item)) set.add(item);
                        });
                    }
                });
                return Array.from(set);
            },
            computedStatus(status) {
                if (status === "IN_PROGRESS") return "W toku";
                if (status === "ACCEPTED") return "Zaakceptowano";
                return "";
            },
            getPositionsLabel(positions) {
                if (positions.length === 0) return "";
                if (positions.length === 1) return positions[0];
                if (positions.length < 5) return positions.length + " stanowiska";
                return positions.length + " stanowisk";
            },
            getPositionsTooltip(positions) {
                return positions.join(", ");
            },
        },
    };
</script>

<style lang="scss">
@import "../../../assets/theme/mytheme/variables";

.programs-table-observer {
    .p-column-title {
        font-size: 1rem;
        color: var(--surface-a);
    }
    .p-datatable .p-datatable-thead > tr > th {
        background-color: var(--secondary-color);
    }
    .p-datatable .p-datatable-tbody .p-datatable .p-datatable-thead > tr > th {
        background-color: rgba(5, 104, 57, 0.27);
        border-width: 0;
        .p-column-title {
            color: var(--color-black);
        }
        .p-datatable {
            border: 1px solid var(--surface-border);
        }
    }
    .p-datatable .p-datatable-tbody > tr:focus {
        outline: none;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        background-color: var(--primary-color);
        color: var(--color-black);
        font-weight: normal;
        border: 1px solid var(--surface-border);
        border-top: none;
        border-left: none;
        &:first-child {
            border-left: 1px solid var(--surface-border);
        }
        &.header-border:nth-of-type(1) {
            border-right: none;
        }
        &.header-border:nth-of-type(2) {
            border-left: none;
            border-right: none;
        }
        &.header-border:nth-of-type(3) {
            border-left: none;
            border-right: none;
        }
        &.header-border:nth-of-type(4) {
            border-left: none;
        }
    }
    .p-datatable .p-datatable-tbody > tr > td.program-header {
        font-weight: bold;
    }
    .p-datatable .p-datatable-wrapper {
        & .p-datatable-row-expansion, .p-datatable-row-expansion td .p-datatable .p-datatable-wrapper {
            // border: 1px solid rgba(5, 104, 57, 0.27);
        }
    }
    a {
        display: block;
        color: var(--text-color);
        padding: 1rem;
    }
    .p-datatable-row-expansion, .p-datatable-row-expansion td {
        background-color: var(--surface-a) !important;
    }
    .p-datatable-tbody tr.p-datatable-row-expansion td, .p-datatable-tbody tr.p-datatable-row-expansion th {
        padding: 1rem 2.5rem;
    }
}
.accepted-style, .in-progress-style {
    padding: 2px 8px;
    width: fit-content;
    border-radius: 4px;
}
.accepted-style {
    color: var(--secondary-color);
    // background-color: $secondaryLightColor;
}
.in-progress-style {
    color: var(--red);
    // background-color: $colorLightRed;
}
</style>
