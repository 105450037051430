<template>
    <HabitatDataTemplate :habitatData="habitatData" v-model:habitatDataToChange="localHabitatDataToChange"
                         :emptyHabitatData="EmptyStructures.getEmptyMPOHabitatData()">
        <template v-slot:data="slotProps">
            <div class="flex justify-content-between flex-column">
                <div class="flex gap-3">
                    <CustomSelectOne label="Typ zbiornika" name="habitatType" :items="dicts.habitatType" class="w-5" :emptyValue="false"
                                     v-model="slotProps.habitatData.habitatType"
                                     required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <CustomInputText v-if="slotProps.habitatData?.habitatType?.code === 'IN'"
                                     label="Podaj inny typ zbiornika" name="otherHabitatType" class="w-7"
                                     v-model="slotProps.habitatData.otherHabitatType" :disabled="slotProps.disabled"
                                     :required="slotProps.habitatData.habitatType.code === 'IN'" :showErrors="showErrorMessages"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <div v-else class="w-7"/>
                </div>
                <div class="flex gap-3">
                    <CustomSelectOne label="Roślinność wynurzona" name="treeType" :items="dicts.treeType" class="w-5" :emptyValue="false"
                                     v-model="slotProps.habitatData.treeType"
                                     required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <CustomInputText v-if="slotProps.habitatData?.treeType?.code === 'INNA'"
                                     label="Podaj inną roślinność" name="otherTreeType" class="w-7"
                                     v-model="slotProps.habitatData.otherTreeType" :disabled="slotProps.disabled"
                                     :required="slotProps.habitatData.treeType.code === 'INNA'" :showErrors="showErrorMessages"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <div v-else class="w-7"/>
                </div>
            </div>
        </template>
    </HabitatDataTemplate>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import HabitatDataTemplate from "@/views/form/HabitatDataTemplate.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";

    export default {
        name: "HabitatDataMPO",

        components: {
            CustomInputText,
            HabitatDataTemplate,
            CustomSelectOne,
        },

        props: {
            habitatData: {
                type: Object,
            },
            habitatDataToChange: {
                type: Object,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        emits: ["update:habitatDataToChange"],

        computed: {
            EmptyStructures() {
                return EmptyStructures;
            },
            localHabitatDataToChange: {
                get() { return this.habitatDataToChange; },
                set(value) { this.$emit("update:habitatDataToChange", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
