<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      :noPositions="form.noPositions">
        <template v-slot:formMap>
            <div class="px-3">
                <CustomCheckbox class="w-4" label="Brak stanowisk" name="noPositions" v-model="form.noPositions" />
                <ControlTypeAndDates v-if="form.noPositions" v-model="form" />
            </div>
            <FormPositions v-if="!form.noPositions" v-model="form.positions" :field="form.field"
                           @reloadFormData="getFormData" dynamicPositions/>
        </template>
        <template v-slot:formObservations>
            <FormMZOObservations :field="form.field.shape"
                                 :allPositions="form.positions"
                                 ref="observationsView" v-model:step="step"/>
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    import FormMZOObservations from "./components/FormMZOObservations.vue";
    import {
        getForm,
        getList as getDict,
    } from "@/swagger/vue-api-client";
    // import {DateUtils} from "@/utils/DateUtils";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        name: "FormMZOView",

        components: {
            FormViewTemplate,
            FormPositions,
            FormMZOObservations,
            ControlTypeAndDates,
            CustomCheckbox,
        },

        data() {
            return {
                programCode: "MZO",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getFormData();
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        ValidateUtils.flattenData(this.form.positions, "position");
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((err) => {
                        if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                        this.loaded = false;
                    });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "positions");
                if (!this.form.noFormControl) {
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        const p = this.form.positions[i];
                        ValidateUtils.formatControlDateStartTimeAndEndTime(p);
                        const positionRequest = ValidateUtils.getGeobjectRequestWithHabitatData(p, p.controlDate, this.programCode);
                        request.positions.push(positionRequest);
                    }
                }
                return request;
            },
            addErrorToast(detailMessage) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = this.validateFirstStep() && isCorrect;
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        let positionIsCorrect = true;
                        const position = this.form.positions[i];
                        if (position.notAudited === false || position.notAudited === null
                            || position.notAudited === undefined) {
                            positionIsCorrect = ValidateUtils.validateNotEmpty(position, ["controlDate", "startTime", "endTime"]) && positionIsCorrect;
                            if (!position.noZolnaHabitat) {
                                if (!position.noZolna) {
                                    positionIsCorrect = ValidateUtils.validateNotEmpty(position, ["count", "zolnaHoles"]) && positionIsCorrect;
                                }
                                if (!position.noBrzegowka && !position.breedingNotCount) {
                                    positionIsCorrect = ValidateUtils.validateNotEmpty(position, ["brzegowkaHoles"]) && positionIsCorrect;
                                }
                            }
                            positionIsCorrect = ValidateUtils.validateIsDateInRange(position.controlDate, this.form.control.controlStart, this.form.control.controlEnd) && positionIsCorrect;
                            if (this.form.habitatDataToChange != null && this.form.habitatDataToChange.habitatType == null) positionIsCorrect = false;
                        }
                        if (position.error) delete position.error; // remove previous checks
                        if (!positionIsCorrect) {
                            position.error = true;
                            isCorrect = false;
                        }
                    }
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.positions, "Stanowisko");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
