<template>
    <div class="m-1">
        <CustomButton label="Wyczyść" @click="callback()" />
    </div>
</template>

<script>
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "FilterClear",
        components: {CustomButton},
        props: {
            callback: {
                type: Function,
                required: true,
            },
        },
    };
</script>
