<template>
    <div v-if="showNumbers" class="flex align-items-center gap-2 w-full">
        <div class="progress-bar-text w-3 text-center">
            {{ progressNumber }} z {{ progressAll }}
        </div>
        <div class="progress-bar w-7">
            <div :style="{width: `${progressPercentage}%`,backgroundColor: `${progressBarColor}`}"
                 class="progress-bar-value"></div>
        </div>
        <div class="progress-bar-text w-2 text-center">
            {{ progressPercentage }}%
        </div>
    </div>
    <div v-else class="progress-bar">
        <div :style="{width: `${progressPercentage}%`,backgroundColor: `${progressBarColor}`}"
             class="progress-bar-value"></div>
    </div>
</template>

<script>
    export default {
        name: "ProgressBar",

        props: {
            progressPercentage: {
                type: Number,
                default: 0,
            },
            progressNumber: null,
            progressAll: null,
            showNumbers: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                progressBarColor: "#DEE2E6",
            };
        },

        methods: {
            getColor(value) {
                const hue = ((1 - value) * 154).toString(10);
                const saturation = `${82 / (2 - value)}%`;
                const lightness = `${55 / (1.617 - (value / 1.617))}%`;
                this.progressBarColor = ["hsl(", hue, `,${saturation},${lightness})`].join("");
            },
        },

        mounted() {
            this.getColor(1 - (this.progressPercentage / 100));
        },

        watch: {
            progressPercentage() {
                this.getColor(1 - (this.progressPercentage / 100));
            },
        },
    };
</script>

<style lang="scss">
    @import "../assets/theme/mytheme/variables";

    .progress-bar {
        height: 1rem;
        background-color: #dee2e6;
        border-radius: 16px;
        overflow: hidden;
        &-value {
            height: 100%;
        }
    }
</style>
