<template>
    <Dialog :header="isNewPositionHistory ? 'Utwórz historię stanowiska' : 'Edytuj historię stanowiska'"
            v-model:visible="isVisible" :modal="true">
        <div class="flex justify-content-between mb-5 gap-8">
            <div class="edit-positions-dialog-content__right">
                <div class="p-field">
                    <CustomInputNumber label="Koordynaty N" v-model="editingDataObject.latitude"
                                       @update:modelValue="(v) => updatePosition(v,'latitude')"
                                       :maxDigits="6" name="latitude" :showLabel="true"
                                       :showErrors="showErrorMessages" required />
                </div>
                <div class="p-field">
                    <CustomInputNumber label="Koordynaty E" v-model="editingDataObject.longitude"
                                       @update:modelValue="(v) => updatePosition(v,'longitude')"
                                       :maxDigits="6" name="longitude" :showLabel="true"
                                       :showErrors="showErrorMessages" required />
                </div>
                <div class="p-field">
                    <CustomInputNumber id="year" v-model="editingDataObject.year"
                                       label="Rok" type="number" name="year" required :showErrors="showErrorMessages"/>
                </div>
                <div class="p-field" v-if="isNewPositionHistory">
                    <CustomSelectOne id="controlVersion" v-model="selectedControlVersion" :items="controlVersions"
                                     label="Wersja kontrolna" name="controlVersion" :emptyValue="false"
                                     required :showErrors="showErrorMessages" :filter="false" itemLabel="" />
                </div>
                <CustomButton label="Zatwierdź" class="justify-content-center mt-4"
                              bgColor="var(--secondary-color)" color="var(--surface-a)" @click="save" />
            </div>
            <div class="edit-positions-dialog-content__left">
                <div class="edit-positions-map"
                     style="height: 400px; width: 600px;">
                    <MapSingleMarker v-model="mapCoordinatesPosition" mapId="editPositionMap" height="400px"
                                     @emitCoor="setLatLng" :zoomControls="false"
                                     :field="position.field?.shape" />
                </div>
            </div>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import {
        getAvailablePositionControlVersions,
    } from "@/swagger/vue-api-client";

    export default {
        name: "CreateOrEditPositionHistoryDialog",

        components: {
            CustomInputNumber,
            Dialog,
            CustomButton,
            MapSingleMarker,
            CustomSelectOne,
        },

        props: {
            visible: {
                type: Boolean,
            },
            position: {
                type: Object,
            },
            editingData: {
                type: Object,
            },
            mapCoordinates: {
                type: Array,
            },
            isNewPositionHistory: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                showErrorMessages: false,
                newData: {
                    latitude: null,
                    longitude: null,
                    year: null,
                },
                selectedControlVersion: null,
                controlVersions: [],
            };
        },

        beforeMount() {
            this.loadControlVersions();
        },

        methods: {
            loadControlVersions() {
                getAvailablePositionControlVersions({
                    year: this.year ? this.year : new Date().getFullYear(),
                    id: this.$route.params.id,
                })
                    .then((response) => {
                        this.controlVersions = response.data;
                    });
            },
            save() {
                if (this.validate()) {
                    this.showErrorMessages = false;
                    if (this.isNewPositionHistory) {
                        const positionHistoryData = {
                            year: this.editingDataObject.year,
                            coordinates: {
                                longitude: this.editingDataObject.longitude,
                                latitude: this.editingDataObject.latitude,
                            },
                            positionId: this.$route.params.id,
                            controlVersion: this.selectedControlVersion,
                        };
                        this.$emit("saveNewPosition", positionHistoryData);
                    } else {
                        this.$emit("save");
                    }
                } else {
                    this.showErrorMessages = true;
                }
            },
            setLatLng(editingDataObject) {
                this.editingDataObject.latitude = editingDataObject.lat;
                this.editingDataObject.longitude = editingDataObject.lng;
            },
            updatePosition(value, type) {
                if (value != null && value !== "" && value !== 0) {
                    if (type === "latitude") this.mapCoordinatesPosition[0] = value;
                    if (type === "longitude") this.mapCoordinatesPosition[1] = value;
                }
            },
            isControlVersionValid(year, positionId) {
                getAvailablePositionControlVersions({year, id: positionId})
                    .then((response) => {
                        this.controlVersions = response.data;
                    });
                return this.controlVersions.includes(this.selectedControlVersion);
            },
            validate() {
                if (this.isNewPositionHistory) {
                    return !(this.editingDataObject.latitude == null
                        || this.editingDataObject.longitude == null
                        || this.editingDataObject.year === null
                        || (this.isNewPositionHistory && (this.selectedControlVersion == null
                            || this.selectedControlVersion === "")));
                }
                return !(this.editingData.latitude == null || this.editingData.longitude == null
                    || this.editingData.year === null);
            },
        },

        emits: ["update:editingData", "save", "update:visible", "update:mapCoordinates", "saveNewPosition"],

        computed: {
            isVisible: {
                get() { return this.visible; },
                set(value) { this.$emit("update:visible", value); },
            },
            editingDataObject: {
                get() { return this.isNewPositionHistory ? this.newData : this.editingData; },
                set(value) { this.$emit("update:editingData", value); },
            },
            mapCoordinatesPosition: {
                get() { return this.mapCoordinates; },
                set(value) { this.$emit("update:mapCoordinates", value); },
            },
        },

        watch: {
            "newData.year": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.loadControlVersions();
                }
            },
        },
    };
</script>
