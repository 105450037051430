<template>
    <CustomCheckbox label="Brak ptaków" v-model="internalNoBirds" :disabled="!editing"
                    name="noBirds" :error="showError()"/>
    <DataTable v-if="!internalNoBirds" :value="internalBirds" v-model:editingRows="editingRows"
               editMode="row" dataKey="ordinal" @row-edit-save="onRowEditSave" size="small"
               :rowClass="() => 'rowWidth'"
               class="navigate-table" @click="arrowsNavigateTable">
        <template #header>
            <AddBirdsMCZ v-model="internalBirds" :error="showError()" @addHeron="updateHeronCounters"/>
        </template>
        <Column header="Lp." field="ordinal" style="width: 4.1666%;" />
        <Column header="Kod" class="w-1" field="species">
            <template #body="slotProps">
                {{ slotProps.data.species?.code }}
            </template>
            <template #editor="{data,field}">
                <CustomOfflineAutocomplete label="Kod" name="speciesCode" v-model="data[field]"
                                           :items="dicts.programBirds" optionLabel="code" searchField="code"
                                           @update:modelValue="() => updateBirds(data)"
                />
            </template>
        </Column>
        <Column header="Gatunek" class="w-1" field="species">
            <template #body="slotProps">
                {{ slotProps.data.species?.namePl ? slotProps.data.species?.namePl : slotProps.data.species?.name }}
            </template>
            <template #editor="{data,field}">
                <CustomOfflineAutocomplete label="Gatunek" name="speciesName" v-model="data[field]"
                                           :items="dicts.programBirds" optionLabel="name" searchField="name"
                                           @update:modelValue="() => updateBirds(data)"
                />
            </template>
        </Column>
        <Column header="Liczono" class="w-1" field="counted">
            <template #body="slotProps">
                <div :class="slotProps.data.counted ? 'true-style' : 'false-style'">
                    {{ computedBoolean(slotProps.data.counted) }}
                </div>
            </template>
            <template #editor="{data,field}">
                <CustomCheckbox label="Liczono" name="counted" v-model="data[field]" :disabled="MAIN_SPECIES.includes(data.species?.code)" />
            </template>
        </Column>
        <Column header="Kod" class="w-1" field="treeType">
            <template #body="slotProps">
                {{ slotProps.data.treeType?.label }}
            </template>
            <template #editor="{data,field}">
                <CustomOfflineAutocomplete label="Rodzaj drzewa/ typ szuwaru" name="treeType" v-model="data[field]"
                                           :items="dicts.treeType"
                                           :optionLabel="getDictLabelWithCodePrefix" searchField="code"
                                           :disabled="!data.counted"/>
            </template>
        </Column>
        <Column header="Nazwa drzewa/ typ szuwaru" class="w-2" field="treeType">
            <template #body="slotProps">
                {{ slotProps.data.treeType?.label }}
            </template>
            <template #editor="{data,field}">
                <CustomOfflineAutocomplete label="Rodzaj drzewa/ typ szuwaru" name="treeType" v-model="data[field]"
                                           :items="dicts.treeType"
                                           :optionLabel="getDictLabelWithCodePrefix" searchField="label"
                                           :disabled="!data.counted"/>
            </template>
        </Column>
        <Column header="L. gniazd" class="w-1" field="nests">
            <template #editor="{data,field}">
                <CustomInputNumber label="L. gniazd" name="nests" v-model="data[field]" :disabled="!data.counted"/>
            </template>
        </Column>
        <Column header="Liczby gniazd na poszczególnych drzewach" class="w-3" field="nestsOnTrees">
            <template #editor="{data,field}">
                <CustomInputText label="Liczby gniazd na poszczególnych drzewach" name="nestsOnTrees"
                                 v-model="data[field]" :disabled="!data.counted"/>
            </template>
        </Column>
        <Column class="w-2"/>
        <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
        <Column v-if="editing" style="width: 4.1666%; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteBirdObservation(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import AddBirdsMCZ from "@/views/formMCZ/components/AddBirdsMCZ.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomOfflineAutocomplete from "@/components/form/inner/CustomOfflineAutocomplete.vue";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "FormBirdsMCZ",

        components: {
            CustomOfflineAutocomplete,
            CustomInputText,
            AddBirdsMCZ,
            DataTable,
            Column,
            CustomInputNumber,
            CustomCheckbox,
            CustomButton,
        },

        props: {
            noBirds: {
                type: Boolean,
            },
            birds: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],
        provide() {
            return { MAIN_SPECIES: this.MAIN_SPECIES };
        },

        data() {
            return {
                editingRows: ref([]),
                MAIN_SPECIES: ["EGRALB", "ARDCIN"],
            };
        },

        emits: ["update:noBirds", "update:birds", "updateWhiteHeronCount", "updateGreyHeronCount"],

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            updateBirds(data) {
                if (this.MAIN_SPECIES.includes(data.species?.code)) {
                    data.counted = true;
                }
            },
            computedBoolean(value) {
                if (value) return "Tak";
                if (value === false) return "Nie";
                return null;
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.internalBirds[index] = newData;
                this.updateHeronCounters();
            },
            deleteBirdObservation(item) {
                this.updateHeronCounters();
                const index = this.birds.indexOf(item);
                this.internalBirds.splice(index, 1);
            },
            updateHeronCounters() {
                let white = 0;
                let grey = 0;
                this.internalBirds.forEach((b) => {
                    if (b.species.code === "ARDCIN") {
                        grey += b.nests;
                    }
                    if (b.species.code === "EGRALB") {
                        white += b.nests;
                    }
                });
                this.$emit("updateGreyHeronCount", grey);
                this.$emit("updateWhiteHeronCount", white);
            },
            getDictLabelWithCodePrefix(item) {
                return item.code + " - " + item.label;
            },
        },

        computed: {
            internalNoBirds: {
                get() { return this.noBirds; },
                set(value) { this.$emit("update:noBirds", value); },
            },
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:birds", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
