<template>
    <div class="monitoring-program-event-logs">
        <HeaderComponent headerText="Dziennik zdarzeń" class="my-0" />
        <EventLogsTable ref="eventLogsTable" />
    </div>
</template>

<script>
    import EventLogsTable from "./EventLogsTable.vue";
    import HeaderComponent from "@/components/HeaderComponent";

    export default {
        name: "MonitoringProgramEventLogs",

        components: {
            HeaderComponent,
            EventLogsTable,
        },

        methods: {
            search() {
                this.$refs.eventLogsTable.search();
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../../assets/theme/mytheme/variables";
    .monitoring-program-event-logs {
        border: 1px solid var(--surface-border);
        margin-top: 1.5rem;
    }
</style>
