<template>
    <CustomAutocomplete :completeFunction="getMammals" :emptyValue="false" :dropdown="false" v-model="internalValue"
                        :name="name" :label="label" :width="width" :customErrorMessage="customErrorMessage"
                        :itemFromQuery="false" :disabled="disabled" :required="required" :field="searchParam"
                        :showErrors="showErrors" />
</template>

<script>
    import {getMammalsForForm} from "@/swagger/vue-api-client";
    import CustomAutocomplete from "./inner/CustomAutocomplete";

    export default {
        name: "CustomAutocompleteBirdSpecies",

        components: {CustomAutocomplete},

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            dropdown: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
            searchParam: {
                type: String,
                default: "name",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            width: {
                type: String,
                default: null,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        data() {
            return {
                items: [],
                selectedValue: null,
                getMammals: async (obj) => {
                    const { query } = obj;
                    if (this.searchParam === "code") {
                        return getMammalsForForm({formId: this.$route.params.id, code: query});
                    }
                    return getMammalsForForm({formId: this.$route.params.id, name: query});
                },
            };
        },
    };
</script>

<style scoped>
</style>
