<template>
    <div class="flex">
        <div class="add-observer-container flex gap-2" style="width: 100%">
            <CustomSelectOne label="Obserwator" :items="observers" itemLabel="fullName"
                             :emptyValue="false" v-model="newObserver.observer" class="flex-1 w-0 mb-0" name="observer"
                             :showErrors="showErrors" required style="max-width: 300px" virtual-scrolling />
            <CustomSelectOne v-if="hasRegionalCoordinators" label="Koordynator regionalny" :items="regionalCoordinators"
                             itemLabel="name" itemValue="id" :emptyValue="false"
                             v-model="newObserver.regionalCoordinator" class="flex-1 w-0 mb-0" name="observer"
                             :showErrors="showErrors" required style="max-width: 300px"/>
            <CustomMultiSelect label="Powierzchnie" :items="fieldItems"
                               itemLabel="name" :emptyValue="false" v-model="newObserver.fields"
                               class="flex-1 w-0 mb-0" name="fields" required :showErrors="showErrors"
                               :disabled="newObserver.regionalCoordinator === null && hasRegionalCoordinators" />
            <CustomMultiSelect v-if="hasSubfields" label="Podpowierzchnie" :items="availableSubfields"
                               :itemLabel="subfieldWithField" :emptyValue="false" v-model="newObserver.subfields"
                               class="flex-1 w-0 mb-0" name="subfields" required :showErrors="showErrors"
                               :disabled="newObserver.fields == null || newObserver.fields.length === 0" />
            <CustomMultiSelect v-if="programType === 'POSITION'" label="Stanowiska" :items="availablePositions"
                               itemLabel="name" :emptyValue="false" v-model="newObserver.positions"
                               class="flex-1 w-0 mb-0" name="positions" required :showErrors="showErrors"/>
            <div v-if="!hasAllControls" class="w-2">
                <div v-if="!hasSubfields">
                    <div v-for="f in newObserver.fields" :key="'f_' + f.id">
                        <CustomMultiSelect :items="availableControls(f)" :label="f.name + ' (kontrole)'"
                                           name="controls" :emptyValue="false" v-model="newObserver.controls[f.id]"/>
                    </div>
                </div>
                <div v-else>
                    <div v-for="f in newObserver.subfields" :key="'f_' + f.id">
                        <CustomMultiSelect :items="availableControls(f)" :label="f.name"
                                           name="controls" :emptyValue="false" v-model="newObserver.controls[f.id]"/>
                    </div>
                </div>
            </div>
            <CustomButton label="Dodaj obserwatora" icon="pi pi-plus" @click="addNewObserver" />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import {
        addProgramObserverToCast,
        getFreeObservationsInProgramEdition, getFreeRegionalCoordinators,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
import {computed} from "vue";

    export default {
        name: "AddObserver",

        components: {
            CustomMultiSelect,
            CustomSelectOne,
            CustomButton,
        },

        props: {
            programObserver: {
                type: Array,
                default: () => [],
            },
            availableRegCoordinatorFields: {
                type: Map,
            },
            availableFieldSubfields: {
                type: Map,
            },
        },

        inject: ["hasRegionalCoordinators", "hasSubfields", "programType",
                 "freeFieldsWithRegionalMap", "freeSubfieldsOnFieldMap", "freeFields",
                 "freePositionsOnFieldMap", "freeControlsOnFieldMap", "hasAllControls"],

        data() {
            return {
                newObserver: {
                    observer: null,
                    fields: null,
                    subfields: null,
                    positionIDs: null,
                    regionalCoordinator: null,
                    controls: {},
                },
                showErrors: false,
                observers: [],
                availableFields: [],
                regionalCoordinators: [],
            };
        },

        emits: ["newObserver", "getFields"],

        beforeMount() {
            this.updateAvailableObservers();
        },

        methods: {
            subfieldWithField(item) {
                if (item) {
                    return item.name + " " + `(${item.superField.name})`;
                }
                return "";
            },
            addNewObserver() {
                if (this.newObserver.observer === null || this.newObserver.fields === null) {
                    this.showErrors = true;
                } else {
                    const observerRequest = {
                        userId: this.newObserver.observer.id,
                        observations: [],
                        positionIDs: this.newObserver.positions ? this.newObserver.positions.map((pos) => pos.id) : null,
                        regionalCoordinatorId: this.newObserver.regionalCoordinator,
                    };
                    if (this.hasSubfields) {
                        observerRequest.observations = this.newObserver.subfields.map((field) => ({fieldId: field.id}));
                    } else {
                        observerRequest.observations = this.newObserver.fields.map((field) => ({fieldId: field.id}));
                    }
                    if (!this.hasAllControls) {
                        observerRequest.observations.forEach((o) => {
                            o.control = this.newObserver.controls[o.fieldId].map((c) => c.key);
                        });
                    }
                    addProgramObserverToCast({
                        programEditionId: this.$route.params.id,
                        body: {observerRequestList: [observerRequest]},
                    })
                        .then(() => {
                            this.$emit("newObserver", this.newObserver);
                            this.newObserver = {
                                observer: null,
                                fields: null,
                                subfields: null,
                                positionIDs: null,
                                regionalCoordinator: null,
                                controls: {},
                            };
                        });
                    this.showErrors = false;
                }
            },
            availableControls(field) {
                return this.freeControlsOnFieldMap[field.id] || [];
            },
            updateAvailableObservers() {
                getFreeObservationsInProgramEdition({programEditionId: this.$route.params.id, requestType: "observers"})
                    .then((response) => {
                        this.observers = response.data;
                    });
            },
        },
        computed: {
            fieldItems() {
                if (this.hasRegionalCoordinators) {
                    if (this.newObserver.regionalCoordinator) {
                        return this.freeFieldsWithRegionalMap[this.newObserver.regionalCoordinator];
                    }
                    return [];
                }
                return this.freeFields;
            },
            availableSubfields() {
                if (this.newObserver.fields) {
                    return this.newObserver.fields.reduce(
                        (acc, field) => acc.concat(this.freeSubfieldsOnFieldMap[field.id] || []), [],
                    );
                }
                return [];
            },
            availablePositions() {
                if (!this.hasSubfields && this.newObserver.fields) {
                    return this.newObserver.fields.reduce(
                        (acc, field) => acc.concat(this.freePositionsOnFieldMap[field.id] || []), [],
                    );
                }
                if (this.hasSubfields && this.newObserver.subfields) {
                    return this.newObserver.subfields.reduce(
                        (acc, field) => acc.concat(this.freePositionsOnFieldMap[field.id] || []), [],
                    );
                }
                return [];
            },
        },

        watch: {
            "newObserver.observer": {
                handler(value) {
                    if (value && this.hasRegionalCoordinators) {
                        getFreeRegionalCoordinators({programEditionId: this.$route.params.id, observerId: value.id})
                            .then((response) => {
                                this.regionalCoordinators = response.data;
                                this.$emit("getFields");
                            });
                    }
                },
            },
            "newObserver.regionalCoordinator": {
                handler(value) {
                    if (!value) {
                        this.newObserver.fields = [];
                        this.newObserver.subfields = [];
                        this.newObserver.positions = [];
                        this.newObserver.controls = {};
                    }
                },
            },
            programObserver() { this.updateAvailableObservers(); },
        },
    };
</script>

<style lang="scss">
.add-observer-container {
    .custom-multi-select {
        .p-multiselect {
            width: 100%;
            max-width: 400px;
        }
    }
}
</style>
