<template>
    <div class="p-3">
        <InfoTiles class="mb-4" />
        <div class="flex justify-content-between align-items-center">
            <CustomCheckbox label="Pokaż zakończone" name="showCompleted" />
            <CustomButton label="Eksportuj" class="mb-3"/>
        </div>
        <ProgramsListTable />
    </div>
</template>

<script>
    import InfoTiles from "./components/InfoTiles.vue";
    import ProgramsListTable from "./components/ProgramsListTable.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "ProgramsListView",

        components: {
            CustomButton,
            CustomCheckbox,
            InfoTiles,
            ProgramsListTable,
        },
    };
</script>

<style lang="scss">
</style>
