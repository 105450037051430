<template>
    <div class="text-center flex h-screen align-items-center flex-column justify-content-center gap-3">
        TO JEST PUBLICZNA STRONA DOSTĘPNA DLA WSZYSTKICH
        <div>
            <router-link :to="{name: 'login'}">{{ $t("message.login.action.login") }} </router-link>
        </div>
        <div>
            <router-link :to="{name: 'register'}">{{ $t("message.register.action.register") }} </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PublicView",
    };
</script>

<style lang="less" scoped>
</style>
