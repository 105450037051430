<template>
    <PrimeButton :class="`custom-button ${computedClasses}`"
                 :style="error ? {} : {backgroundColor: bgColor,borderColor: borderColor}"
                 :disabled="disabled">
        <div :class="'custom-button-box flex ' + computedFlexDirection">
            <label v-if="label" class="cursor-pointer" for="custom-button"
                   :style="error ? {} : {color: color}" :class="error ? 'error-color' : ''">
                {{ label }}
            </label>
            <i v-if="icon" :class="computedIconClasses" :style="error ? {} : {color: color}" class="icon-style"
            />
        </div>
    </PrimeButton>
</template>

<script>
    import PrimeButton from "primevue/button";

    export default {
        name: "CustomButton",

        props: {
            icon: {
                type: String,
            },
            bgColor: {
                type: String,
                default: "var(--surface-a)",
            },
            color: {
                type: String,
                default: "var(--text-color)",
            },
            label: {
                type: String,
                default: "",
            },
            rowReverse: {
                type: Boolean,
                default: false,
            },
            borderColor: {
                type: String,
                default: "var(--custom-button-border-color)",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            classes: {
                type: String,
                default: "",
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        components: {
            PrimeButton,
        },

        computed: {
            computedFlexDirection() {
                return this.rowReverse ? "flex-row-reverse" : "flex-row";
            },
            computedClasses() {
                return this.error ? this.classes + " error-button" : this.classes;
            },
            computedIconClasses() {
                let classes = "cursor-pointer " + this.icon;
                if (this.error) classes += " error-color";
                return classes;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/theme/mytheme/variables";
    .custom-button {
        height: 42px;
        padding: 8px 16px;
        &-box {
            gap: 20px;
        }
        label {
            font-size: 1rem;
            font-weight: 600;
        }
        .icon-style {
            font-size: 1.15rem;
            display: flex;
            align-items: center;
        }
    }
    .error-button {
        background-color: var(--red-500) !important;
        border-color: var(--red-500) !important;
    }
    .error-color {
        color: var(--red-50) !important;
    }
</style>
