<template>
    <div class="flex gap-8">
        <div class="position-map w-4" v-if="field.shape.length > 0">
            <MapSingleMarker mapId="positionDictMap" height="600px" :isOn="false" :hideNamePopups="true"
                             :field="field.shape" :markerPopup="false" />
        </div>
        <!--        <div class="edit-field-view">-->
        <VeeForm @submit.prevent="submitForm">
            <h2>Edytuj powierzchnię</h2>
            <div class="field">
                <CustomInputText id="name" v-model="field.name" name="name" label="Nazwa" />
            </div>
            <div class="field">
                <CustomInputText id="program" v-model="field.program" name="program" label="Program" disabled />
            </div>
            <div v-if="field.program === 'MZPW'" class="field">
                <CustomCheckbox id="program" v-model="field.includeInMZPWP" name="program" label="MZPWP" />
            </div>
            <div class="flex justify-content-end">
                <CustomButton type="submit" @click="submitForm" label="Zapisz zmiany"/>
            </div>
        </VeeForm>
        <!--        </div>-->
    </div>
</template>

<script>
    import {Form as VeeForm} from "vee-validate";
    import {
        getFieldById,
        updateField,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        components:
            {
                CustomCheckbox,
                MapSingleMarker,
                CustomButton,
                CustomInputText,
                VeeForm,
            },
        data() {
            return {
                field: {
                    name: "",
                    program: "",
                    shape: [],
                    includeInMZPWP: false,
                },
            };
        },

        beforeMount() {
            const fieldId = this.$route.params.id;
            this.loadField(fieldId);
        },

        methods: {
            loadField(fieldId) {
                getFieldById({id: fieldId})
                    .then((response) => {
                        this.field = response.data;
                    });
            },
            submitForm() {
                const fieldId = this.$route.params.id;
                const body = {name: this.field.name, includeInMZPWP: this.field.includeInMZPWP};
                updateField({
                    id: fieldId,
                    body,
                }).then((response) => {
                    if (response.status === 200) {
                        this.$router.push({ name: "fieldDictTable" });
                    }
                });
            },
        },
    };

</script>

<style scoped lang="scss">
.edit-field-view {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    .format-toggle {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        .current-format {
            margin-left: 10px;
            font-weight: bold;
        }
    }

    .coordinates {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 1rem;

        input {
            margin-left: 10px;
            width: 150px;
        }

        span {
            margin-right: 10px;
        }
    }

    button {
        margin-right: 10px;
    }

    .toggle-format {
        margin-bottom: 20px;
    }
}
</style>
