<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :validationCorrect="validationCorrect">
        <template v-slot:formMap>
            <div class="form-map mt-3 mb-5">
                <MapSingleMarker mapId="mainMap" :field="form.field.superField.shape" :subfield="form.field.shape" :isOn="false" />
            </div>
        </template>
        <template v-slot:formObservations>
            <FormMWOPObservations v-model:form="form" ref="observationsView" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import FormMWOPObservations from "./components/FormMWOPObservations.vue";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {getForm, getList as getDict} from "@/swagger/vue-api-client";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMWOPView",

        components: {
            MapSingleMarker,
            FormMWOPObservations,
            FormViewTemplate,
        },

        data() {
            return {
                programCode: "MWOP",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getFormData();
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loaded = false;
                    });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, null);
                if (!this.form.noFormControl && this.form.control1 != null && this.form.control2 != null) {
                    ValidateUtils.formatControlDateStartTimeAndEndTime(this.form.control1);
                    ValidateUtils.formatControlDateStartTimeAndEndTime(this.form.control2);
                    ValidateUtils.copyFieldValues(this.form, request, ["control1", "control2"]);
                }
                return request;
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = ValidateUtils.validateControls(this.form, 2, ["controlDate", "startTime", "endTime", "clouds", "wind", "rain", "visibility", "singingMales"]);
                    isCorrect = ValidateUtils.validateIsDateInRange(this.form.controlDate, this.form.control.controlStart, this.form.control.controlEnd) && isCorrect;
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
                console.log(this.request);
            },
        },

    };
</script>

<style lang="scss" scoped>
</style>
