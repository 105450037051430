<template>
    <div class="transect-history-table">
        <div class="transect-details border-1 surface-border mt-7">
            <HeaderComponent headerText="Historia stanowiska" :buttons="buttons"
                             class="mt-0 mb-3" @clickButton="openCreateHistoryDialog" />
            <div class="position-history-table p-3">
                <DataTable :value="position.histories" paginator :rows="10" :totalRecords="totalRecords">
                    <Column header="Współrzędne" field="coordinates">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.coordinates">
                                {{ slotProps.data.coordinates.latitude }}, {{ slotProps.data.coordinates.longitude }}
                            </div>
                        </template>
                    </Column>
                    <Column header="Zaakceptowany" field="accepted">
                        <template #body="slotProps">
                            <div :class="slotProps.data.accepted ? 'true-style' : 'false-style'">
                                {{ computedAccepted(slotProps.data.accepted) }}
                            </div>
                        </template>
                    </Column>
                    <Column header="Rok" field="year"></Column>
                    <Column header="Działania">
                        <template #body="slotProps">
                            <div class="flex gap-2">
                                <CustomButton :disabled="slotProps.data.coordinates == null"
                                              label="Edycja" icon="pi pi-pencil"
                                              @click="openEditDialog(slotProps.data)" />
                                <CustomButton v-if="!slotProps.data.accepted" label="Akceptuj"
                                              :disabled="slotProps.data.coordinates == null"
                                              class="p-button-success" @click="openConfirmationDialog(slotProps.data)"
                                              bgColor="var(--secondary-color)" color="var(--surface-a)"/>
                                <CustomButton v-if="position.programHasHabitatData"
                                              label="Zobacz dane siedliskowe" class="p-button-success"
                                              @click="showHabitatDataDialog = true; gHistoryId = slotProps.data.id" />
                                <CustomButton label="Usuń" class="p-button-danger" icon="pi pi-trash"
                                              :disabled="slotProps.data.coordinates == null"
                                              @click="confirmDeletePosition(slotProps.data)" />
                                <HabitatDataDialog v-if="position.programHasHabitatData"
                                                   v-model:visible="showHabitatDataDialog" :gHistoryId=slotProps.data.id
                                                   :programCode="position.field.program" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <CreateOrEditPositionHistoryDialog v-model:editingData="editingEntry" v-model:visible="editDialogVisible"
                                           v-model:position="position" v-model:mapCoordinates="mapCoordinates"
                                           @save="saveEdited"/>
        <CreateOrEditPositionHistoryDialog v-model:editingData="editingEntry"
                                           v-model:visible="createPositionHistoryDialogVisible"
                                           v-model:position="position" v-model:mapCoordinates="mapCoordinates"
                                           @saveNewPosition="saveNewHistoryPosition" isNewPositionHistory/>
        <ConfirmationPositionHistoryDialog :current-entry="currentEntry" :previous-entry="previousEntry"
                                           v-model:visible="confirmationPositionDialogVisible"
                                           @rejectPosition="rejectPosition" @acceptPosition="acceptPosition"/>
        <DeletePositionHistoryDialog v-model:visible="deleteDialogVisible" @deletePosition="deleteEntry"/>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CreateOrEditPositionHistoryDialog from "./CreateOrEditPositionHistoryDialog";
    import ConfirmationPositionHistoryDialog from "./ConfirmationPositionHistoryDialog";
    import DeletePositionHistoryDialog from "./DeletePositionHistoryDialog";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import HabitatDataDialog from "@/views/transectTable/HabitatDataDialog.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {
        listFields as getListFields,
        getAllObserversAsCoordinator,
        updatePositionHistory,
        deletePositionHistory,
        createPositionHistory,
    } from "@/swagger/vue-api-client";

    export default {
        name: "PositionHistoryTable",

        components: {
            HeaderComponent,
            CustomButton,
            DataTable,
            Column,
            CreateOrEditPositionHistoryDialog,
            ConfirmationPositionHistoryDialog,
            DeletePositionHistoryDialog,
            HabitatDataDialog,
        },

        beforeMount() {
            this.loadFields();
            this.loadObservers();
        },

        props: {
            modelValue: {
                type: Object,
            },
            totalRecords: {
                type: Number,
            },
        },

        data() {
            return {
                isEditPositionActive: false,
                tmpPosition: {},
                statuses: [
                    {
                        label: "Aktywne",
                        key: "ACTIVE",
                    },
                    {
                        label: "Zarchiwizowane",
                        key: "ARCHIVED",
                    },
                ],
                fields: [],
                field: null,
                observers: [],
                editDialogVisible: false,
                editingEntry: {
                    coordinates: "",
                    year: null,
                    accepted: false,
                },
                mapCoordinates: [],
                currentEntry: {},
                previousEntry: {},
                confirmationPositionDialogVisible: false,
                deleteDialogVisible: false,
                entryToDelete: {},
                createPositionHistoryDialogVisible: false,
                buttons: [
                    {
                        label: "Utwórz nową historię stanowiska",
                        show: true,
                    },
                ],
                showHabitatDataDialog: false,
            };
        },
        emits: ["positionHistoryEdited"],
        watch: {
            "coordinates.latitude"() {
                this.updateMap();
            },
            "coordinates.longitude"() {
                this.updateMap();
            },
        },
        methods: {
            async saveNewHistoryPosition(newPostionHistoryData) {
                await createPositionHistory({id: this.$route.params.id, body: newPostionHistoryData})
                    .then((response) => {
                        if (response.status === 201) {
                            this.$emit("positionHistoryEdited");
                        }
                    });
                this.createPositionHistoryDialogVisible = false;
            },
            editPositionDetails() {
                this.isEditPositionActive = true;
                this.tmpPosition = { ...this.position };
            },

            loadFields() {
                if (this.program) {
                    getListFields({ programCode: this.program })
                        .then((response) => {
                            this.fields = response.data;
                        })
                        .catch((error) => {
                            console.error("Error loading fields:", error);
                        });
                }
            },
            loadObservers() {
                getAllObserversAsCoordinator()
                    .then((response) => {
                        this.observers = response.data;
                    });
            },
            openCreateHistoryDialog() {
                this.createPositionHistoryDialogVisible = true;
            },
            openEditDialog(entry) {
                this.editingEntry = {
                    ...entry,
                    longitude: entry.coordinates?.longitude,
                    latitude: entry.coordinates?.latitude,

                };
                this.mapCoordinates = [
                    this.editingEntry.latitude,
                    this.editingEntry.longitude,
                ];
                this.editDialogVisible = true;
            },
            updateMap() {
                if (this.editingEntry.latitude == null
                    || this.editingEntry.longitude == null) return;
                this.mapCoordinates = [
                    this.editingEntry.latitude,
                    this.editingEntry.longitude,
                ];
            },
            openConfirmationDialog() {
                const size = this.position.histories.length;
                this.currentEntry = this.position.histories[size - 1];

                if (size < 2) {
                    this.previousEntry = null;
                } else {
                    this.previousEntry = this.position.histories[size - 2];
                }
                this.confirmationPositionDialogVisible = true;
            },
            saveEdited() {
                const requestBody = {
                    accepted: this.editingEntry.accepted,
                    coordinates: {
                        longitude: this.editingEntry.longitude,
                        latitude: this.editingEntry.latitude,
                    },
                    year: this.editingEntry.year,
                    controlVersion: this.editingEntry.controlVersion,
                };
                updatePositionHistory({
                    id: this.editingEntry.id,
                    body: requestBody,
                }).then((response) => {
                    if (response.status === 200) {
                        // getPositionHistoryById(this.$route.params.id);
                        this.$emit("positionHistoryEdited");
                    }
                });
                this.editDialogVisible = false;
            },
            acceptPosition() {
                const positionHistoryRequest = {
                    accepted: true,
                    id: this.currentEntry.id,
                };
                updatePositionHistory({
                    id: this.currentEntry.id,
                    body: positionHistoryRequest,
                }).then((response) => {
                    if (response.status === 200) {
                        this.currentEntry.accepted = true;
                        this.updatePositionHistoryInTable(this.currentEntry);
                        this.confirmationPositionDialogVisible = false;
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            updatePositionHistoryInTable(entry) {
                const index = this.position.histories.findIndex((h) => h.id === entry.id);
                if (index !== -1) {
                    this.position.histories.splice(index, 1, entry);
                }
            },
            rejectPosition() {
                this.confirmationDialogVisible = false;
            },
            confirmDeletePosition(entry) {
                this.entryToDelete = entry;
                this.deleteDialogVisible = true;
            },
            deleteEntry() {
                this.deleteConfirmationVisible = false;
                if (this.entryToDelete) {
                    deletePositionHistory({ id: this.entryToDelete.id })
                        .then((response) => {
                            if (response.status === 200) {
                                this.position.histories = this.position.histories
                                    .filter((h) => h.id !== this.entryToDelete.id);
                                this.entryToDelete = null;
                                this.deleteDialogVisible = false;
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 409) {
                                alert("You cannot delete linked PositionHistory");
                            } else {
                                console.error("Error during delete operation:", error);
                            }
                        });
                }
            },
            computedAccepted(value) {
                if (value) return "Tak";
                return "Nie";
            },
        },
        computed: {
            position: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";

    .true-style, .false-style {
        padding: 2px 8px;
        width: fit-content;
        border-radius: 4px;
    }
    .true-style {
        color: var(--secondary-color);
        background-color: $secondaryLightColor;
    }
    .false-style {
        color: var(--red);
        background-color: $colorLightRed;
    }
</style>
