<template>
    <div class="forbidden-view">
        <div class="top-panel">
            <div class="code">
                403
                <div class="icon">
                    <i class="pi pi-lock"/>
                </div>
            </div>
        </div>
        <div class="bottom-panel">
            <div class="content">
                <h3>Dostęp zabroniony</h3>
                <span>Prosimy skontaktować się z administratorem systemu</span>
                <router-link custom v-slot="{ navigate }" :to="{name: 'home'}">
                    <Button icon="pi pi-undo" @click="navigate" @keypress.enter="navigate"
                            label="Powrót na stronę główną" />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "ForbiddenView",
        components: {Button},
    };
</script>

<style lang="scss" scoped>
    @import "assets/scss/status-page";
</style>
