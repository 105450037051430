<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      showControlType transect>
        <template v-slot:formMap>
            <TransectFormMap v-model:form="form" />
        </template>
        <template v-slot:formObservations>
            <FormMWOTObservations v-model:form="form" ref="observationsView" v-model:step="step"/>
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import {
        getForm,
        getList as getDict,
    } from "@/swagger/vue-api-client";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import TransectFormMap from "@/components/transectForm/TransectFormMap.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import FormMWOTObservations from "@/views/formMWOT/components/FormMWOTObservations.vue";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {

        name: "FormMWOTView",

        components: {
            TransectFormMap,
            FormViewTemplate,
            FormMWOTObservations,
        },

        data() {
            return {
                programCode: "MWOT",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getForm();
        },

        methods: {
            getForm() {
                getForm({program: "MWOT", formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        ValidateUtils.flattenData(this.form.formTransects, "transect");
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: "MWOT", formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((err) => {
                        if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                        this.loaded = false;
                    });
            },
            addErrorToast(detailMessage) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "formTransects");
                if (!this.form.noFormControl) {
                    this.form.controlDate = DateUtils.formatFormDate(this.form.controlDate);
                    ValidateUtils.formatTimesValue(this.form, ["startTime1", "endTime1"]);
                    ValidateUtils.copyFieldValues(this.form, request, ["controlDate", "clouds", "rain", "wind", "visibility", "startTime1", "endTime1", "additionalObservers"]);
                    for (let i = 0; i < this.form.formTransects.length; i += 1) {
                        const transectRequest = ValidateUtils.getGeobjectRequestWithHabitatData(this.form.formTransects[i], this.form.controlDate, this.programCode);
                        request.formTransects.push(transectRequest);
                    }
                }
                return request;
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = this.validateFirstStep() && isCorrect;
                    isCorrect = ValidateUtils.validateNotEmpty(this.form, ["controlDate", "startTime1", "endTime1", "clouds", "wind", "rain", "visibility"]) && isCorrect;
                    isCorrect = ValidateUtils.validateIsDateInRange(this.form.controlDate, this.form.control.controlStart, this.form.control.controlEnd) && isCorrect;
                    if (DateUtils.isTimeEarlier(this.form.endTime1, this.form.startTime1)) isCorrect = false;
                    for (let i = 0; i < this.form.formTransects.length; i += 1) {
                        let transectIsCorrect = true;
                        const t = this.form.formTransects[i];
                        if (t.notAudited == null || t.notAudited === false) {
                            transectIsCorrect = ValidateUtils.validateBirds(t.formBirds, t.noBirds, ["count", "distance"]) && transectIsCorrect;
                            if (this.form.habitatDataToChange != null) {
                                transectIsCorrect = ValidateUtils.validateNotEmpty(t.habitatDataToChange, ["utilization", "clumps", "treeType", "water", "when", "plantHeight", "bushHeight", "plantLitter", "rut", "treeBushesArea", "cane", "nestPlace"]) && transectIsCorrect;
                                if (t.habitatDataToChange.utilization != null && t.habitatDataToChange.utilization?.code === "5") {
                                    transectIsCorrect = ValidateUtils.validateNotEmpty(t.habitatDataToChange, ["differentUtilization"]) && transectIsCorrect;
                                }
                            }
                        }
                        if (t.error) delete t.error; // remove previous checks
                        if (!transectIsCorrect) {
                            t.error = true;
                            isCorrect = false;
                        }
                    }
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
                console.log(this.request);
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.formTransects, "Transekt");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.formTransects, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
