<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <CustomButton icon="pi pi-copy" style="margin-bottom: 1rem"
                          title="Skopiuj ostatnio dodany gatunek"
                          :disabled="!editing" @click="copyLastSpecies"
                          v-on:keydown.enter="copyLastSpecies" />
            <div class="w-2">
                <CustomSelectOne label="Kod" name="speciesCode" v-model="newItem.mammal"
                                 :items="dicts.programMammal" itemLabel="code"
                                 required :showErrors="showErrors" :disabled="!editing"
                                 :emptyValue="false"/>
            </div>
            <div class="w-2">
                <CustomSelectOne label="Gatunek" name="speciesName" v-model="newItem.mammal"
                                 :items="dicts.programMammal" itemLabel="name"
                                 required :showErrors="showErrors" :disabled="!editing"
                                 :emptyValue="false"/>
            </div>
            <div class="w-2">
                <CustomInputNumber label="Liczba osobników" name="count" v-model="newItem.count"
                                   required :showErrors="showErrors" :disabled="!editing" />
            </div>
            <div class="w-2">
                <CustomSelectOne label="Kategoria odległości" name="distance" v-model="newItem.distance"
                                 :items="dicts.distance" :showErrors="showErrors"
                                 :disabled="!editing" :emptyValue="false" required/>
            </div>
            <div class="w-1"> </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem" v-on:keydown.tab="add"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "AddMammalsMLS",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyMammals()),
                showErrors: false,
            };
        },

        methods: {
            add() {
                if (!ValidateUtils.validateNotEmpty(this.newItem, ["mammal", "count", "distance"])) {
                    this.showErrors = true;
                } else {
                    this.mammals.push({
                        ...this.newItem,
                        ordinal: this.mammals.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyMammals());
                    this.showErrors = false;
                }
            },
            copyLastSpecies() {
                if (this.mammals?.length > 0) {
                    this.newItem.mammal = this.mammals[this.mammals.length - 1].mammal;
                }
            },
        },

        computed: {
            mammals: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            mammals: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
.disabled-not-opacity {
    .p-disabled, .p-component:disabled {
        opacity: 1;
    }
}
</style>
