<template>
    <ConfirmActionDataTransectDialog v-model="showAcceptDialog" @accept="acceptTransect" />
    <EditTransectDialog v-model:visible="createTransectHistoryDialogVisible"
                        :field="transect.transect.field.shape" :showYear=true @save="saveNewTransectHistory"
                        :isNewHistoryTransect="true"/>
    <Dialog v-model:visible="deleteConfirmationVisible" class="delete-transect-dialog"
            header="Czy na pewno chcesz usunąć ten wpis?" :draggable="false" modal
            :closable="false">
        <div class="delete-transect-dialog-container">
            <div class="delete-transect-dialog-buttons">
                <CustomButton label="Tak" @click="deleteEntry" />
                <CustomButton label="Nie" @click="() => deleteConfirmationVisible = false" />
            </div>
        </div>
    </Dialog>
    <div class="transect-history-table">
        <div class="transect-details border-1 surface-border mt-7">
            <HeaderComponent headerText="Historia transektu" :buttons="buttons"
                             class="mt-0 mb-3" @clickButton="createTransectHistoryDialogVisible = true" />
            <div class="p-3">
                <div class="transect-history-actions">
                </div>
                <DataTable :value="transect.histories" paginator :rows="10" :totalRecords="totalRecords">
                    <Column header="Zaakceptowany" field="accepted">
                        <template #body="slotProps">
                            <div :class="slotProps.data.accepted ? 'true-style' : 'false-style'">
                                <span>{{ slotProps.data.accepted ? "Tak" : "Nie" }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Rok" field="year"/>
                    <Column header="Działania">
                        <template #body="slotProps">
                            <div class="flex gap-3">
                                <CustomButton label="Edycja" icon="pi pi-pencil"
                                              @click="slotProps.data.showEditDialog = true" />
                                <CustomButton v-if="!slotProps.data.accepted" label="Akceptuj" class="p-button-success"
                                              @click="acceptedItem = slotProps.data; showAcceptDialog = true" />
                                <CustomButton v-if="transect.transect.programHasHabitatData"
                                              label="Zobacz dane siedliskowe" class="p-button-success"
                                              @click="showHabitatDataDialog = true; gHistoryId = slotProps.data.id" />
                                <CustomButton label="Usuń" class="p-button-danger"
                                              @click="confirmDeleteEntry(slotProps.data)" />
                                <HabitatDataDialog v-if="transect.transect.programHasHabitatData"
                                                   v-model:visible="showHabitatDataDialog" :gHistoryId=slotProps.data.id
                                                   :hObj=transect.transect.line
                                                   :programCode="transect.transect.program" />
                                <EditTransectDialog v-model:visible="slotProps.data.showEditDialog"
                                                    :field="transect.transect.field.shape"
                                                    :mapIdIndex="slotProps.index" :showYear=true
                                                    v-model:transect="slotProps.data" @save="saveEdited"/>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Dialog from "primevue/dialog";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import {
        updateTransectHistory,
        deleteTransectHistoryById,
        createTransectHistory,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import HabitatDataDialog from "@/views/transectTable/HabitatDataDialog.vue";
    import ConfirmActionDataTransectDialog from "./ConfirmActionDataTransectDialog.vue";
    import EditTransectDialog from "@/components/transectForm/EditTransectDialog.vue";

    export default {
        name: "TransectHistoryTable",

        props: {
            modelValue: {
                type: Object,
            },
        },

        components: {
            EditTransectDialog,
            CustomButton,
            DataTable,
            Column,
            Dialog,
            HabitatDataDialog,
            HeaderComponent,
            ConfirmActionDataTransectDialog,
        },

        data() {
            return {
                totalRecords: 0,
                editDialogVisible: false,
                deleteConfirmationVisible: false,
                entryToDelete: null,
                showAcceptDialog: false,
                acceptedItem: null,
                showHabitatDataDialog: false,
                gHistoryId: null,
                hObj: null,
                loaded: false,
                createTransectHistoryDialogVisible: false,
                buttons: [
                    {
                        label: "Utwórz nową historię transektu",
                        show: true,
                    },
                ],
            };
        },
        emits: ["getTransect", "update:modelValue"],

        methods: {
            confirmDeleteEntry(entry) {
                this.entryToDelete = entry;
                this.deleteConfirmationVisible = true;
            },
            saveEdited(editedTransect) {
                updateTransectHistory({
                    id: editedTransect.id,
                    body: {
                        ...editedTransect,
                        transectId: this.transect.id,
                    },
                })
                    .then((response) => {
                        console.log(response);
                        this.showEditDialog = false;
                        this.$emit("getTransect");
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się zapisać",
                            life: 3000,
                        });
                    });
            },
            deleteEntry() {
                deleteTransectHistoryById({ id: this.entryToDelete.id })
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            this.$emit("getTransect");
                        }
                    })
                    .catch((error) => {
                        console.error("Error during delete operation:", error);
                    });

                this.deleteConfirmationVisible = false;
                this.entryToDelete = null;
            },
            acceptTransect() {
                updateTransectHistory({
                    id: this.acceptedItem.id,
                    body: {
                        ...this.acceptedItem,
                        accepted: true,
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        this.acceptedItem.accepted = true;
                        this.fetchTransectHistory(this.transect.id);
                    }
                    this.showAcceptDialog = false;
                }).catch((error) => {
                    console.error("Error during accept operation:", error);
                    this.showAcceptDialog = false;
                });
            },
            async saveNewTransectHistory(newTransectHistoryData) {
                this.transect.histories.forEach((th) => {
                    if (th.year === newTransectHistoryData.year) {
                        const controlVersionArr = [];
                        controlVersionArr.push(th.controlVersion);
                        controlVersionArr.sort((a, b) => a - b);
                        newTransectHistoryData.controlVersion = controlVersionArr[controlVersionArr.length - 1] + 1;
                    } else {
                        newTransectHistoryData.controlVersion = 1;
                    }
                });
                await createTransectHistory({
                    body: {
                        ...newTransectHistoryData,
                        transectId: this.transect.id,
                    },
                });
                this.createTransectHistoryDialogVisible = false;
                this.$emit("getTransect");
            },
        },
        computed: {
            transect: {
                get() { console.log(this.modelValue); return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../assets/theme/mytheme/variables";
    @import "../assets/scss/dialogs.scss";

    .true-style, .false-style {
        padding: 2px 8px;
        width: fit-content;
        border-radius: 4px;
    }
    .true-style {
        color: var(--secondary-color);
        background-color: $secondaryLightColor;
    }
    .false-style {
        color: var(--red);
        background-color: $colorLightRed;
    }
</style>
