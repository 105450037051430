<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <CustomButton icon="pi pi-copy" style="margin-bottom: 1rem"
                          title="Skopiuj ostatnio dodany gatunek"
                          :disabled="!editing" @click="copyLastSpecies"
                          v-on:keydown.enter="copyLastSpecies" />
            <div class="w-2">
                <CustomInputNumber label="Odcinek transektu" name="transectSection" v-model="newItem.transectSection"
                                   required
                                   :customErrorMessage="getCustomMessage(newItem.transectSection)"
                                   :disabled="!editing" />
            </div>
            <div class="w-1">
                <CustomCheckbox label="Brak ptaków" name="noBirds" v-model="newItem.noBirds"
                                :disabled="!editing" />
            </div>
            <div class="w-1">
                <CustomAutocompleteBirdSpecies label="Kod" name="species" v-model="newItem.species"
                                               searchParam="code" :required="!newItem.noBirds"
                                               :showErrors="showErrorMessagesBirds"
                                               :disabled="!editing || newItem.noBirds" :emptyValue="false"
                                               :customErrorMessage="getIncorrectSpeciesMessage()" />
            </div>
            <div class="w-1">
                <CustomAutocompleteBirdSpecies label="Gatunek" name="species" v-model="newItem.species"
                                               searchParam="name" :required="!newItem.noBirds"
                                               :showErrors="showErrorMessagesBirds"
                                               :disabled="!editing || newItem.noBirds"
                                               :customErrorMessage="getIncorrectSpeciesMessage()" />
            </div>
            <div class="w-1">
                <CustomInputNumber label="L. osobników" name="count" v-model="newItem.count"
                                   :required="!newItem.noBirds"
                                   :showErrors="showErrorMessagesBirds" :disabled="!editing || newItem.noBirds" />
            </div>
            <div class="w-1">
                <CustomCheckbox label="W locie" name="inFlight" v-model="newItem.inFlight"
                                :disabled="!editing || newItem.noBirds" />
            </div>
            <div class="w-2">
                <CustomSelectOne label="Strefa transektu" name="transectZone" v-model="newItem.transectZone"
                                 :items="dicts.transectZone" :required="!newItem.noBirds"
                                 :showErrors="showErrorMessagesBirds"
                                 :disabled="!editing || newItem.noBirds" :emptyValue="false" />
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="addBirdsControl" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem"
                          v-on:keydown.tab="addBirdsControl"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomAutocompleteBirdSpecies from "@/components/form/CustomAutocompleteBirdSpecies.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "AddBirdsMZPM",

        components: {
            CustomAutocompleteBirdSpecies,
            CustomCheckbox,
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts", "transectInfo"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyBirds()),
                showErrorMessagesBirds: false,
                showErrorMessageTransectNotAudited: false,
                speciesName: "",
                speciesCode: "",
                auditedChecker: [],
            };
        },

        methods: {
            setSpecies(species) {
                this.newItem.species = species;
            },
            addBirdsControl() {
                if (this.newItem.transectSection !== null && this.newItem.transectSection !== ""
                    && this.newItem.noBirds) {
                    this.transectInfo.forEach((t, index) => {
                        if (t.ordinal === this.newItem.transectSection && t.notAudited) {
                            this.showErrorMessageTransectNotAudited = true;
                            this.auditedChecker[index] = false;
                        } else {
                            this.auditedChecker[index] = true;
                        }
                    });
                    const transectValidation = this.auditedChecker.every((el) => el);
                    if (transectValidation && ValidateUtils.validateCorrectSpecies(this.newItem, ["species"])) {
                        let transectId = null;
                        this.transectInfo.forEach((t) => {
                            if (t.ordinal === this.newItem.transectSection) {
                                transectId = t.transectId;
                            }
                        });
                        this.birds.push({
                            ...this.newItem,
                            transectId,
                            id: this.birds.length > 0 ? this.birds[this.birds.length - 1].id + 1 : 1,
                        });
                        this.newItem = _.cloneDeep(EmptyStructures.getEmptyBirds());
                        this.showErrorMessagesBirds = false;
                        this.showErrorMessageTransectNotAudited = false;
                    }
                } else if (this.newItem.transectSection === null || this.newItem.transectSection === ""
                    || this.newItem.species === null || this.newItem.species === ""
                    || this.newItem.count === null || this.newItem.count === ""
                    || this.newItem.transectZone === null || this.newItem.transectZone === ""
                    || !ValidateUtils.validateCorrectSpecies(this.newItem, ["species"])) {
                    this.showErrorMessagesBirds = true;
                } else {
                    this.transectInfo.forEach((t, index) => {
                        if (t.ordinal === this.newItem.transectSection && t.notAudited) {
                            this.showErrorMessageTransectNotAudited = true;
                            this.auditedChecker[index] = false;
                        } else {
                            this.auditedChecker[index] = true;
                        }
                    });
                    const transectValidation = this.auditedChecker.every((el) => el);
                    if (transectValidation && ValidateUtils.validateCorrectSpecies(this.newItem, ["species"])) {
                        let transectId = null;
                        this.transectInfo.forEach((t) => {
                            if (t.ordinal === this.newItem.transectSection) {
                                transectId = t.transectId;
                            }
                        });
                        this.birds.push({
                            ...this.newItem,
                            transectId,
                            id: this.birds.length > 0 ? this.birds[this.birds.length - 1].id + 1 : 1,
                        });
                        this.newItem = _.cloneDeep(EmptyStructures.getEmptyBirds());
                        this.showErrorMessagesBirds = false;
                        this.showErrorMessageTransectNotAudited = false;
                    }
                }
            },
            getCustomMessage(value) {
                if (this.showErrorMessagesBirds && value == null) return this.$t("message.validations.required");
                if (this.showErrorMessageTransectNotAudited) return this.$t("message.validations.transectValidation");
                return "";
            },
            copyLastSpecies() {
                if (this.birds?.length > 0) {
                    this.newItem.species = this.birds[this.birds.length - 1].species;
                }
            },
            getIncorrectSpeciesMessage() {
                if (!ValidateUtils.validateCorrectSpecies(this.newItem, ["species"])) return "Niepoprawny gatunek";
                return "";
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            speciesName(value) {
                if (value.name != null) {
                    this.newItem.species = value;
                    this.speciesCode = value;
                } else {
                    this.newItem.species = null;
                    this.speciesCode = "";
                }
            },
            speciesCode(value) {
                if (value.code != null) {
                    this.newItem.species = value;
                    this.speciesName = value;
                } else {
                    this.newItem.species = null;
                    this.speciesName = "";
                }
            },
            /* eslint-disable-next-line */
            "newItem.noBirds"(value) {
                if (value) {
                    this.newItem.species = null;
                    this.newItem.count = null;
                    this.newItem.inFlight = null;
                    this.newItem.transectZone = null;
                }
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
</style>
