<template>
    <div>
        <Toast/>
        <DictSearchCriteria v-model="searchCriteria" label="punkt" @import="importData"
                            @export="exportData" @create="navigateToCreate"/>
        <PointsTable v-model="searchCriteria" />
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import axios from "axios";
    import { SearchCriteria } from "@/utils/SearchCriteria";
    import {FiltersUtils} from "@/utils/FiltersUtils";
    import PointsTable from "@/views/pointTable/components/PointsTable.vue";
    import DictSearchCriteria from "@/views/dict/DictSearchCriteria.vue";
    import {FileUtils} from "@/utils/FileUtils";

    export default {

        name: "TransectDictView",

        components: {
            DictSearchCriteria,
            PointsTable,
            Toast,
        },

        data() {
            return {
                searchCriteria: SearchCriteria.getClearSearchCriteriaPoints(),
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        mounted() {
            if (this.$route.query.saveCorrectly === "true") {
                setTimeout(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zmiany zostały zapisane",
                        life: 2000,
                    });
                }, 100);
                this.$router.replace({query: null});
            }
        },

        methods: {
            navigateToCreate() {
                this.$router.push("/point/create");
            },
            exportData() {
                FileUtils.downloadFile({}, "punkty.xlsx", "/point/export");
            },
            importData(importFile) {
                axios.post(
                    `${process.env.VUE_APP_API_URL}/point/import`,
                    importFile,
                    {headers: {"Content-Type": "multipart/form-data"}})
                    .then((response) => {
                        console.log(response);
                        this.$toast.add({
                            severity: "success",
                            summary: "Zaimportowano pomyślnie",
                            life: 3000,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            life: 3000,
                        });
                    });
            },
        },

        watch: {
            searchCriteria: {
                handler(value) { FiltersUtils.saveToSessionStorage(this.$route.path, value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss"></style>
