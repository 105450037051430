<template>
    <div class="create-field-view">
        <h2>Tworzenie powierzchni</h2>
        <VeeForm @submit.prevent="submitForm">
            <div class="field">
                <CustomInputText id="name" v-model="field.name" name="name" label="Nazwa" />
            </div>
            <div class="field">
                <CustomSelectOne id="program" v-model="field.program" :items="programs"
                                 label="Program" name="program" :show-clear="false"
                                 itemLabel="" />
            </div>
            <div v-if="field.program === 'MZPW'" class="field">
                <CustomCheckbox id="program" v-model="field.includeInMZPWP" name="program" label="MZPWP" />
            </div>
            <h3>Współrzędne</h3>
            <div class="coordinates" v-for="(coordinate,index) in field.shape" :key="index">
                <span v-if="index < maxShowIndex">{{ index + 1 }}.</span>
                <CustomInputText v-if="index < maxShowIndex" id="longitude" type="number" step="any"
                                 min="-180" max="180" v-model="coordinate.longitude" name="longitude" label="Długość"
                                 :disabled="index === field.shape.length - 1 || field.shape.length > 10
                                     || index > maxShowIndex"/>
                <CustomInputText v-if="index < maxShowIndex" id="latitude" type="number" step="any"
                                 min="-180" max="180" v-model="coordinate.latitude" name="latitude" label="Szerokość"
                                 :disabled="index === field.shape.length - 1 || field.shape.length > 10"/>
                <CustomButton v-if="index > 0 && index < field.shape.length - 1 && index < maxShowIndex"
                              @click="removeCoordinate(index)" label="Usuń" class="field" />
            </div>
            <div class="flex justify-content-center mb-3">
                <CustomButton v-if="maxShowIndex < field?.shape?.length" @click="showMoreCoordinates"
                              label="Pokaż więcej"/>
                <CustomButton v-if="field?.shape?.length < 15" type="button"
                              @click="addCoordinate" label="Dodaj współrzędne"/>
            </div>
            <div class="flex justify-content-end">
                <CustomButton type="submit" @click="submitForm" label="Zapisz"/>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {Form as VeeForm} from "vee-validate";
    import {
        createField,
        getListOfPrograms,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        components: {
            CustomCheckbox,
            CustomSelectOne,
            CustomButton,
            CustomInputText,
            VeeForm,
        },
        data() {
            return {
                field: {
                    name: "",
                    program: "",
                    shape: [
                        {
                            longitude: "",
                            latitude: "",
                        },
                        {
                            longitude: "",
                            latitude: "",
                        },

                    ],
                    includeInMZPWP: false,
                },
                programs: [],
                maxShowIndex: 10,
                edited: false,
            };
        },

        watch: {
            "field.shape": {
                handler(newShape, oldShape) {
                    if (JSON.stringify(newShape) !== JSON.stringify(oldShape)) {
                        this.edited = true;
                    }
                    if (newShape.length > 1) {
                        this.field.shape[this.field.shape.length - 1] = { ...newShape[0] };
                    }
                },
                deep: true,
            },
        },

        beforeMount() {
            this.loadPrograms();
        },

        methods: {
            loadPrograms() {
                getListOfPrograms()
                    .then((response) => {
                        this.programs = response.data;
                    });
            },

            addCoordinate() {
                const newCoord = { longitude: 0, latitude: 0 };
                this.field.shape.splice(this.field.shape.length - 1, 0, newCoord);
                this.maxShowIndex += 1;
            },
            showMoreCoordinates() {
                this.maxShowIndex *= 2;
            },
            removeCoordinate(index) {
                if (index > 0 && index < this.field.shape.length - 1) {
                    this.edited = true;
                    this.field.shape.splice(index, 1);
                }
            },
            submitForm() {
                if (this.field.shape.length > 15 || this.edited === false) {
                    this.field.shape = null;
                }
                const payload = JSON.parse(JSON.stringify(this.field));
                payload.format = 4326;
                createField({
                    body: payload,
                }).then((response) => {
                    if (response.status === 200) {
                        this.$router.push({ name: "fieldDictTable" });
                    }
                });
            },
        },
    };

</script>

<style scoped lang="scss">
    .create-field-view {
        max-width: 700px;
        margin: 0 auto;
        padding: 20px;
        .format-toggle {
            display: flex;
            align-items: center;
            margin-bottom: 1.5rem;

            .current-format {
                margin-left: 10px;
                font-weight: bold;
            }
        }

        .coordinates {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            gap: 1rem;

            input {
                margin-left: 10px;
                width: 150px;
            }

            span {
                margin-right: 10px;
            }
        }

        button {
            margin-right: 10px;
        }
    }
</style>
