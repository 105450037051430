<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      showControlType>
        <template v-slot:formMap>
            <FormPositions v-model="form.positions" :field="form.field" @reloadFormData="getFormData"/>
        </template>
        <template v-slot:formObservations>
            <FormMMCObservations v-model:form="form" v-model:step="step" ref="observationsView" :field="form.field.shape" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    // import {getForm, getList as getDict} from "@/swagger/vue-api-client";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import FormMMCObservations from "@/views/formMMC/components/FormMMCObservations.vue";
    // import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "FormMMCView",

        components: {
            FormMMCObservations,
            FormViewTemplate,
            FormPositions,
        },

        data() {
            return {
                programCode: "MSL",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
                ValidateUtils,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            // this.getFormData();
            this.dicts = {
                controlMethod: [
                    {code: "VISIT", label: "Bezpośrednia wizyta w kolonii"},
                    {code: "DRONE", label: "Kontrola dronem"},
                    {code: "DISTANCE", label: "Obserwacje z dystansu"},
                ],
                nestingCriteria: [
                    {code: "BRAK1", label: "BRAK - w przypadku, kiedy para została stwierdzona tylko podczas kontroli 2"},
                    {code: "BRAK2", label: "BRAK - w przypadku, kiedy podczas pierwszej kontroli para nie wykazywała pewnego gniazdowania i nie da się jednoznacznie stwierdzić jej gniazdowania podczas drugiej kontroli (nieoznakowane osobniki itd.)"},
                    {code: "O", label: "O - jednorazowa obserwacja pojedynczego ptaka w kolonii lęgowej (z terytorium lub bez)"},
                    {code: "PR", label: "PR - para ptaków obserwowana w kolonii lęgowej (z terytorium lub bez)"},
                    {code: "KT", label: "KT - tokująca lub kopulująca para"},
                    {code: "NP", label: "NP - zaniepokojenie jednego ptaka lub pary wskazujące na obecność lęgu"},
                    {code: "BU", label: "BU - budowa gniazda"},
                    {code: "GNS", label: "GNS - świeże gniazdo (bez zniesienia)"},
                    {code: "WYS", label: "WYS - gniazdo wysiadywane"},
                    {code: "JAJ", label: "JAJ - gniazdo z jajami"},
                    {code: "PIS", label: "PIS - gniazdo z małymi pisklętami"},
                    {code: "MŁO", label: "MŁO - pisklęta poza gniazdem lub podloty"},
                ],
                pairMixType: [
                    {code: "N", label: "Nie"},
                    {code: "TS", label: "Tak, ze śmieszką"},
                    {code: "TMM", label: "Tak, z mieszańcem międzygatunkowym"},
                    {code: "TMS", label: "Tak, z mewą siwą"},
                    {code: "TIN", label: "Tak, z innym gatunkiem (wpisz w uwagach)"},
                ],
                programBirds: [
                    {code: "LAR", name: "śmieszka"},
                    {code: "SOB", name: "sieweczka obrożna"},
                    {code: "SRZ", name: "sieweczka rzeczna"},
                    {code: "MMA", name: "mewa mała"},
                    {code: "MSI", name: "mewa siwa"},
                    {code: "MŻÓ", name: "mewa żółtonoga"},
                    {code: "MSR", name: "mewa srebrzysta"},
                    {code: "MRO", name: "mewa romańska"},
                    {code: "MBI", name: "mewa białogłowa"},
                    {code: "RCZ", name: "rybitwa czubata"},
                    {code: "RRZ", name: "rybitwa rzeczna"},
                    {code: "RBI", name: "rybitwa białoczelna"},
                ],
                countingMethod: [
                    {code: "DOK", label: "Dokładne liczenie"},
                    {code: "SZA", label: "Szacunek"},
                    {code: "NIE", label: "Nie liczono"},
                ],
                control: [
                    {code: "K1", label: "Kontrola 1"},
                    {code: "K2", label: "Kontrola 2"},
                ],
                habitatType: [
                    {code: "STA", label: "staw/stawy"},
                    {code: "ZBI", label: "zbiornik zaporowy"},
                    {code: "ZWI", label: "żwirownia"},
                    {code: "RZE", label: "rzeka"},
                    {code: "OSA", label: "osadniki/odstojniki"},
                    {code: "WYR", label: "wyrobisko pokopalniane"},
                    {code: "JEZ", label: "jezioro"},
                    {code: "INNE", label: "inne"},
                ],
                location: [
                    {code: "GRO", label: "grobla"},
                    {code: "WTW", label: "wyspa o twardym podłożu"},
                    {code: "WGR", label: "wyspa o grząskim podłożu"},
                    {code: "POL", label: "półwysep"},
                    {code: "LAC", label: "łacha"},
                    {code: "SZU", label: "szuwar"},
                    {code: "PLY", label: "pływająca roślinność wynurzona"},
                    {code: "INNE", label: "inne"},
                ],
                plantsType: [
                    {code: "1", label: "do 10 cm"},
                    {code: "2", label: "11 do 50 cm"},
                    {code: "3", label: "> 50 cm"},
                ],
                treeBushesArea: [
                    {code: "1", label: "brak lub pojedyncze (do 10% powierzchni)"},
                    {code: "2", label: "średnio liczne (11-50% powierzchni)"},
                    {code: "3", label: ">50% powierzchni"},
                ],
                controlNotRealizedReason: [
                    {code: "1", label: "powód 1"},
                    {code: "2", label: "powód 2"},
                    {code: "3", label: "powód 3"},
                ],
            };
            this.form = {
                programEditionId: 123,
                programName: "MMC",
                programFullName: "Monitoring Mewy Czarnogłowej",
                monitoringYear: 2024,
                editable: true,
                state: "DRAFT",
                observer: "Jan Kowalski",
                id: 1,
                field: {
                    id: 1,
                    program: "MMC",
                    name: "MMC01",
                    fieldType: "FIELD",
                    superField: null,
                    shape: [
                        {longitude: 23.02674900176643, latitude: 51.39735704253448},
                        {longitude: 23.170159002152793, latitude: 51.39233304244274},
                        {longitude: 23.178350002151205, latitude: 51.4820400423824},
                        {longitude: 23.03466000176621, latitude: 51.48707904247509},
                        {longitude: 23.02674900176643, latitude: 51.39735704253448},
                    ],
                },
                control: {
                    label: "Kontrola",
                    controlStart: "2024-04-10",
                    controlEnd: "2024-07-20",
                },
                noFormControl: false,
                noFormControlReason: null,
                noFormControlNotes: null,
                positions: [
                    {
                        position: {
                            accepted: false,
                            coordinates: {
                                longitude: 23.04107666015625,
                                latitude: 51.433892209165236,
                            },
                            name: "MMC01",
                            positionHistoryId: 1,
                            positionId: 1,
                            status: "CREATED",
                        },
                        notAudited: false,
                        notAuditedReason: null,
                        notAuditedNotes: "",
                        control1: {
                            noControl: false,
                            noControlReason: null,
                            noControlNotes: null,
                            controlDate: "2024-04-10",
                            startTime: "20:00",
                            endTime: "22:00",
                            controlMethod: {code: "VISIT", label: "Bezpośrednia wizyta w kolonii"},
                        },
                        control2: {
                            noControl: false,
                            noControlReason: null,
                            noControlNotes: null,
                            controlDate: "2024-05-08",
                            startTime: "10:00",
                            endTime: "12:00",
                            controlMethod: null,
                        },
                        noBirds: false,
                        birds: [
                            {
                                ordinal: 1,
                                nestingCriteria1: {code: "O", label: "O - jednorazowa obserwacja pojedynczego ptaka w kolonii lęgowej (z terytorium lub bez)"},
                                nestingCriteria2: {code: "O", label: "O - jednorazowa obserwacja pojedynczego ptaka w kolonii lęgowej (z terytorium lub bez)"},
                                lossBetweenControls: false,
                                pairMixType: {code: "TAK", label: "Tak, ze śmieszką"},
                                additionalInfo: "Para miała taką cechę charakterystyczną: lorem ipsum dolor sit amet",
                            },
                            {
                                ordinal: 1,
                                nestingCriteria1: {code: "MŁO", label: "MŁO - pisklęta poza gniazdem lub podloty"},
                                nestingCriteria2: null,
                                lossBetweenControls: true,
                                pairMixType: {code: "TAK", label: "Tak, ze śmieszką"},
                                additionalInfo: "Para miała taką cechę charakterystyczną: lorem ipsum dolor sit amet",
                            },
                        ],
                        noOtherBirds: false,
                        otherBirds: [
                            {
                                species: {code: "LAR", name: "śmieszka"},
                                countingMethod: {code: "DOK", label: "Dokładne liczenie"},
                                pairs: 4,
                                whenCounted: {code: "K1", label: "Kontrola 1"},
                            },
                        ],
                        habitatData: null,
                        habitatDataToChange: null,
                    },
                ],
            };
            ValidateUtils.flattenData(this.form.positions, "position");
            console.log(this.form);
            console.log(this.dicts);
            this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
            this.loaded = true;
        },

        methods: {
            getFormData() {
                // getForm({program: this.programCode, formId: this.$route.params.id})
                //     .then((response) => {
                //         this.form = response.data;
                //         ValidateUtils.flattenData(this.form.positions, "position");
                //         console.log(this.form);
                //         this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                //         return getDict({programCode: this.programCode, formId: this.$route.params.id});
                //     })
                //     .then((response) => {
                //         this.dicts = response.data;
                //         console.log(this.dicts);
                //         this.loaded = true;
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //         this.loaded = false;
                //     });
            },
            getFormRequest() {
            },
            validateData() {
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.points, "Punkt");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
