<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      showControlType>
        <template v-slot:formMap>
            <TransectFormMap v-model:form="form" />
        </template>
        <template v-slot:formObservations>
            <FormMPPLObservations v-model:form="form" ref="observationsView" v-model:step="step"/>
        </template>
    </FormViewTemplate>
</template>

<script>
    import _ from "lodash";
    import {computed} from "vue";
    import {getForm, getList as getDict} from "@/swagger/vue-api-client";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import FormMPPLObservations from "@/views/formMPPL/components/FormMPPLObservations.vue";
    import TransectFormMap from "@/components/transectForm/TransectFormMap.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMPPLView",

        components: {
            TransectFormMap,
            FormMPPLObservations,
            FormViewTemplate,
        },

        data() {
            return {
                programCode: "MPPL",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getForm();
        },

        methods: {
            getForm() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        this.form.noNests = this.form.nests == null ? false : !this.form.nests;
                        ValidateUtils.flattenData(this.form.formTransects, "transect");
                        this.mergeHabitatData();
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((err) => {
                        if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                        this.loaded = false;
                    });
            },
            flattenHabitatData(hd, transect, editable) {
                hd.transectId = transect.transectId;
                hd.ordinal = transect.ordinal;
                hd.editable = editable;
                return hd;
            },
            mergeHabitatData() {
                const habitatData = [];
                const habitatDataToChange = [];
                for (let i = 0; i < this.form.formTransects.length; i += 1) {
                    const transect = this.form.formTransects[i];
                    // eslint-disable-next-line
                    if (transect.notAudited === true) continue;
                    if (transect.habitatData == null) {
                        if (transect.habitatDataToChange == null) {
                            const emptyHabitatData = _.cloneDeep(EmptyStructures.getEmptyTransectHabitatData(this.programCode));
                            habitatData.push(this.flattenHabitatData(emptyHabitatData, transect, true));
                            transect.habitatData = emptyHabitatData;
                        } else {
                            transect.habitatData = transect.habitatDataToChange;
                            transect.habitatDataToChange = null;
                            habitatData.push(this.flattenHabitatData(transect.habitatData, transect, true));
                        }
                    } else {
                        habitatData.push(this.flattenHabitatData(transect.habitatData, transect, false));
                        transect.habitatData = null;
                    }
                    if (transect.habitatDataToChange !== null) {
                        habitatDataToChange.push(this.flattenHabitatData(transect.habitatDataToChange, transect, true));
                    }
                }
                this.form.habitatData = habitatData;
                this.form.habitatDataToChange = habitatDataToChange;
            },
            addErrorToast(detailMessage) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            updateHabitatDataToChangeIndexes() {
                this.form.habitatDataToChange.map((hd) => {
                    if (hd.ordinal !== null) {
                        hd.transectId = this.form.habitatData[hd.ordinal - 1].transectId;
                        return hd;
                    }
                    return [];
                });
            },
            isHabitatDataEmpty(hd) {
                return hd.hab1Lev1 == null && hd.hab1Lev2 == null && hd.hab1Lev3_1 == null && hd.hab1Lev3_2 == null
                    && hd.hab1Lev3_3 == null && hd.hab2Lev1 == null && hd.hab2Lev2 == null && hd.hab2Lev3_1 == null
                    && hd.hab2Lev3_2 == null && hd.hab2Lev3_3 == null;
            },
            getHabitatDataRequest(transect) {
                if (transect.habitatData !== null && !this.isHabitatDataEmpty(transect.habitatData)) {
                    return transect.habitatData;
                }
                let foundHabitatDataToChange = null;
                this.form.habitatDataToChange.forEach((hd) => {
                    if (hd.transectId === transect.transectId) {
                        foundHabitatDataToChange = {...hd};
                        delete foundHabitatDataToChange.ordinal;
                    }
                });
                return foundHabitatDataToChange;
            },
            getTransectsRequest() {
                this.updateHabitatDataToChangeIndexes();
                const allTransectsRequest = [];
                this.form.formTransects.forEach((transect) => {
                    const transectRequest = {...transect};
                    const habitatDataRequest = this.getHabitatDataRequest(transect);
                    if (habitatDataRequest == null) {
                        delete transectRequest.habitatData;
                    } else {
                        habitatDataRequest.program = this.programCode;
                        transectRequest.habitatData = habitatDataRequest;
                    }
                    delete transectRequest.habitatDataToChange;
                    delete transectRequest.id;
                    delete transectRequest.line;
                    delete transectRequest.name;
                    delete transectRequest.transectHistoryId;
                    allTransectsRequest.push(transectRequest);
                });
                return allTransectsRequest;
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "formTransects");
                if (!this.form.noFormControl) {
                    this.form.controlDate = DateUtils.formatFormDate(this.form.controlDate);
                    ValidateUtils.formatTimesValue(this.form, ["startTime1", "endTime1", "startTime2", "endTime2"]);
                    ValidateUtils.copyFieldValues(this.form, request, ["controlDate", "clouds", "rain", "wind", "visibility", "mammals", "sex", "startTime1", "endTime1", "startTime2", "endTime2", "nests", "nestsList", "additionalObservers"]);
                    request.formTransects = this.getTransectsRequest();
                }
                return request;
            },
            validateTransect(t) {
                let isCorrect = true;
                if (t.notAudited == null || t.notAudited === false) {
                    isCorrect = ValidateUtils.validateBirds(t.formBirds, t.noBirds, ["count", "distance", "species"]) && isCorrect;
                    if (t.noBirds !== true && this.form.sex) {
                        for (let j = 0; j < t.formBirds.length; j += 1) {
                            isCorrect = ValidateUtils.validateNotEmpty(t.birds[j], ["sex"]) && isCorrect;
                        }
                    }
                    if (this.form.mammals) {
                        isCorrect = ValidateUtils.validateListItemsIfCounted(t.formMammals, t.noMammals, ["count", "distance", "species"]) && isCorrect;
                    }
                }
                if (t.error) delete t.error; // remove previous checks
                if (!isCorrect) t.error = true;
                return isCorrect;
            },
            validateHabitatData(hd, notAudited = false) {
                const regexAll = /[A-F]/;
                return notAudited || (ValidateUtils.validateNotEmpty(hd, ["controlDate", "hab1Lev1"])
                    && regexAll.test(hd.hab1Lev1)
                    && (hd.hab1Lev1 === "F" || ValidateUtils.validateNotEmpty(hd, ["hab1Lev2"])));
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = this.validateFirstStep();
                    if (!ValidateUtils.validateNotEmpty(this.form, ["clouds", "wind", "rain", "visibility", "controlDate", "startTime1", "endTime1", "startTime2", "endTime2"])
                        || DateUtils.isDateEarlier(this.form.controlDate, this.form.control.controlStart)
                        || DateUtils.isDateEarlier(this.form.control.controlEnd, this.form.controlDate)
                        || DateUtils.isTimeEarlier(this.form.endTime1, this.form.startTime1)
                        || DateUtils.isTimeEarlier(this.form.endTime2, this.form.startTime2)
                        || !ValidateUtils.validateIsDateInRange(this.form.controlDate, this.form.control.controlStart, this.form.control.controlEnd)) {
                        isCorrect = false;
                    }
                    isCorrect = ValidateUtils.validateListItemsIfCounted(this.form.nestsList, !this.form.nests, ["count", "nestlings", "species"]) && isCorrect;
                    this.form.formTransects.forEach((t) => {
                        isCorrect = this.validateTransect(t) && isCorrect;
                    });
                    this.form.habitatData.forEach((hd, i) => {
                        isCorrect = isCorrect && this.validateHabitatData(hd, this.form.formTransects[i].notAudited);
                    });
                    this.form.habitatDataToChange.forEach((hd, i) => {
                        isCorrect = isCorrect && this.validateHabitatData(hd, this.form.formTransects[i].notAudited);
                    });
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
                console.log(this.request);
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.formTransects, "Transekt");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.formTransects, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
