<template>
    <Dialog class="edit-positions-dialog" :header="header" :draggable="false"
            v-model:visible="internalVisible" :closable="false" modal>
        <div class="edit-positions-dialog-container flex flex-column">
            <div class="edit-positions-dialog-content flex justify-content-between mb-5 gap-8">
                <div class="edit-positions-dialog-content__right">
                    <CustomInputNumber label="Koordynaty N" v-model="coordinates.latitude" :maxDigits="6"
                                       name="editPositionLat" required :showErrors="showErrorMessages"/>
                    <CustomInputNumber label="Koordynaty E" v-model="coordinates.longitude" :maxDigits="6"
                                       name="editPositionLng" required :showErrors="showErrorMessages"/>
                </div>
                <div class="edit-positions-dialog-content__left">
                    <div class="edit-positions-map" style="height: 400px; width: 600px;">
                        <MapSingleMarker v-model="mapCoordinates" mapId="editPositionMap" height="400px"
                                         @emitCoor="updateCoordinates" :zoomControls="false" :field="field.shape" />
                    </div>
                </div>
            </div>
            <div class="flex justify-content-between">
                <CustomButton label="Zamknij" @click="internalVisible = false" />
                <CustomButton label="Zatwierdź" class="justify-content-center"
                              bgColor="var(--secondary-color)" color="var(--surface-a)" @click="editPosition" />
            </div>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import {MapMixin} from "@/mixins/MapMixin";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import {editGeographicalHistoryInForm, editPositionHistory} from "@/swagger/vue-api-client";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "EditPositionDialog",

        mixins: [MapMixin],

        components: {
            Dialog,
            CustomButton,
            CustomInputNumber,
            MapSingleMarker,
        },

        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            field: {
                type: Object,
                default: () => {},
            },
            objectBeingChanged: {
                type: String,
                default: "stanowisko",
            },
            positionWithoutCoordinates: null,
            index: null,
            programCode: null,
            dynamicPositions: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                coordinates: {
                    longitude: null,
                    latitude: null,
                },
                mapCoordinates: [],
                showErrorMessages: false,
            };
        },

        emits: ["hideEdit", "positionEdited", "update:visible"],

        inject: ["programEditionId"],

        methods: {
            backToProgramView() {
                this.$router.push(`/monitoring-program/${this.programEditionId}`);
            },
            updateCoordinates(coordinates) {
                this.coordinates.latitude = coordinates.lat;
                this.coordinates.longitude = coordinates.lng;
            },
            updateMapCoordinates() {
                if (this.coordinates.latitude == null
                    || this.coordinates.longitude == null) return;
                this.mapCoordinates = [
                    this.coordinates.latitude,
                    this.coordinates.longitude,
                ];
            },
            clearDialogDataAndClose() {
                this.coordinates = {
                    longitude: null,
                    latitude: null,
                };
                this.mapCoordinates = [];
                this.showErrorMessages = false;
                this.internalVisible = false;
            },
            editPosition() {
                if (ValidateUtils.validateNotEmpty(this.coordinates, ["latitude", "longitude"])) {
                    const body = {
                        coordinates: {
                            latitude: this.coordinates.latitude,
                            longitude: this.coordinates.longitude,
                        },
                    };
                    let editApiMethod = editGeographicalHistoryInForm;
                    if (this.dynamicPositions) {
                        editApiMethod = editPositionHistory;
                        body.positionId = this.positionWithoutCoordinates.positionId;
                    } else {
                        body.objectId = this.positionWithoutCoordinates.positionId;
                        body.type = this.objectBeingChanged === "stanowisko" ? "position" : "point";
                    }
                    console.log({programCode: this.programCode, formId: this.$route.params.id, body: body});
                    editApiMethod({programCode: this.programCode, formId: this.$route.params.id, body: body})
                        .then((response) => {
                            console.log(response);
                            this.$emit("positionEdited", response.data);
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zgłoszono zmianę lokalizacji",
                                life: 3000,
                            });
                            this.clearDialogDataAndClose();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: error.response?.data != null ? error.response?.data : error.message,
                                life: 3000,
                            });
                        });
                } else {
                    this.showErrorMessages = true;
                }
            },
        },

        computed: {
            internalVisible: {
                get() { return this.visible; },
                set(value) { this.$emit("update:visible", value); },
            },
            header() {
                if (this.positionWithoutCoordinates != null) {
                    return "Stanowisko " + this.positionWithoutCoordinates.name + " nie ma wprowadzonej lokalizacji. "
                        + "Wprowadź ją przed uzupełnieniem formularza.";
                }
                return "Wprowadź lokalizację stanowiska";
            },
        },

        watch: {
            "coordinates.latitude"() {
                this.updateMapCoordinates();
            },
            "coordinates.longitude"() {
                this.updateMapCoordinates();
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";
    @import "../../../views/assets/scss/dialogs";
</style>
