<template>
    <CustomCheckbox label="Brak ptaków" v-model="internalNoBirds" :disabled="!editing || !disabled"
                    name="noBirds" :error="showError()"/>
    <DataTable v-if="!internalNoBirds" :value="internalBirds" v-model:editingRows="editingRows" editMode="row"
               dataKey="ordinal" @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'" class="navigate-table" @click="arrowsNavigateTable">
        <template #header>
            <AddBirdsMPO v-model="internalBirds" :error="showError()" style="margin-left: 4.1666%" :disabled="disabled" />
        </template>
        <Column header="Lp." field="ordinal" style="width: 4.1666%;" />
        <Column header="Kryterium lęgowości" class="w-2" field="nestingCriteria">
            <template #body="slotProps">
                {{ slotProps.data.nestingCriteria?.label }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne label="Kryterium lęgowości" name="nestingCriteria" v-model="data[field]"
                                 :items="dicts.nestingCriteria" :emptyValue="false" filter required :showErrors="showErrorMessages"/>
            </template>
        </Column>
        <Column header="Liczba par lub os. dorosłych" class="w-2" field="pairs">
            <template #editor="{data,field}">
                <CustomInputNumber label="Liczba par lub os. dorosłych" name="pairs" v-model="data[field]"
                                   required :showErrors="showErrorMessages"/>
            </template>
        </Column>
        <Column header="Liczba młodych w rodzinie" class="w-2" field="young">
            <template #editor="{data,field}">
                <CustomInputNumber label="Liczba młodych w rodzinie" name="young" v-model="data[field]"
                                   :disabled="data.nestingCriteria?.code !== 'PIS' && data.nestingCriteria?.code !== 'MŁO'"/>
            </template>
        </Column>
        <Column header="Dodatkowe informacje" class="w-3" field="additionalInfo">
            <template #editor="{data,field}">
                <CustomInputText label="Dodatkowe informacje" name="additionalInfo" v-model="data[field]"/>
            </template>
        </Column>
        <Column v-if="editing && disabled" :rowEditor="true" style="text-align: center;" class="w-1"/>
        <Column v-if="editing && disabled" style="width: 4.1666%; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteBirdObservation(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
    <div v-if="!internalNoBirds" class="flex gap-4 justify-content-start pt-4 pb-5">
        <div class="p-2" style="border: 1px solid #dee2e6;">
            <span class="font-bold">Liczba par w kategorii B:</span> {{ computedSummary('B') }}
        </div>
        <div class="p-2" style="border: 1px solid #dee2e6;">
            <span class="font-bold">Liczba par w kategorii C:</span> {{ computedSummary('C') }}
        </div>
    </div>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import TableUtils from "@/utils/TableUtils";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import AddBirdsMPO from "@/views/formMPO/components/AddBirdsMPO.vue";

    export default {
        name: "FormBirdsMPO",

        components: {
            AddBirdsMPO,
            CustomInputText,
            DataTable,
            Column,
            CustomInputNumber,
            CustomSelectOne,
            CustomCheckbox,
            CustomButton,
        },

        props: {
            noBirds: {
                type: Boolean,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            birds: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:noBirds", "update:birds"],

        data() {
            return {
                editingRows: ref([]),
                criteriaB: ["OF", "PR", "KT", "NP", "BU"],
                criteriaC: ["GNS", "WYS", "JAJ", "PIS", "MŁO"],
            };
        },

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.internalBirds[index] = newData;
            },
            deleteBirdObservation(item) {
                const index = this.internalBirds.indexOf(item);
                this.internalBirds.splice(index, 1);
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            getCategory(nestingCriteria) {
                if (nestingCriteria == null) return null;
                if (this.criteriaC.includes(nestingCriteria.code)) return "C";
                if (this.criteriaB.includes(nestingCriteria.code)) return "B";
                return null;
            },
            getBirdCategory(bird) {
                const nc = this.getCategory(bird.nestingCriteria);
                return nc;
            },
            computedSummary(category) {
                let counter = 0;
                this.birds.forEach((bird) => {
                    const birdCategory = this.getBirdCategory(bird);
                    if (birdCategory === category) counter += bird.pairs;
                });
                return counter;
            },
        },

        computed: {
            internalNoBirds: {
                get() { return this.noBirds; },
                set(value) { this.$emit("update:noBirds", value); },
            },
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) {
                    value.forEach((b) => {
                        b.count = b.males + b.females + b.otherAdults + b.young;
                    });
                    this.$emit("update:birds", value);
                },
                deep: true,
            },
            birds: {
                handler(value) {
                    TableUtils.addOrdinal(value);
                },
                immediate: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
