<template>
    <div class="flex flex-column field relative" :style="'width: ' + width + 'px;'">
        <span class="p-float-label p-input-icon-right">
            <i v-if="searchIcon" class="pi pi-search" />
            <InputText :id="name" type="text" v-model="internalValue" :disabled="disabled"
                       :class="computedClasses" @input="internalHandleChange" @blur="handleBlur"
                       @keypress="inputLock" />
            <CustomLabel v-if="label" :name="name" :label="label" :required="required" :disabled="disabled" />
        </span>
        <CustomValidationMessage :errorMessage="errorMessage" />
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
    </div>
</template>

<script>
    import InputText from "primevue/inputtext";
    import Tooltip from "primevue/tooltip";
    import {useField} from "vee-validate";
    import CustomValidationMessage from "./inner/CustomValidationMessage";
    import CustomLabel from "./inner/CustomLabel";

    export default {
        name: "CustomInputText",

        props: {
            name: {
                type: String,
                default: "",
            },
            label: {
                type: String,
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            rules: {
                type: String,
                default: "",
            },
            modelValue: null,
            customErrorMessage: {
                type: String,
                default: "",
            },
            customErrorWithoutMessage: {
                type: Boolean,
                default: false,
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            searchIcon: {
                type: Boolean,
                default: false,
            },
            upperCaseOnly: {
                type: Boolean,
                default: false,
            },
            activeInputLock: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue", "input"],

        directives: {
            tooltip: Tooltip,
        },

        methods: {
            internalHandleChange(ctx) {
                if (this.upperCaseOnly) {
                    this.internalValue = ctx.target.value.toUpperCase();
                }
            },
            inputLock(e) {
                if (this.activeInputLock) {
                    const regex = /[A-F]/;
                    if (!regex.test(e.key.toUpperCase()) || e.target.value.length === 1) {
                        e.preventDefault();
                    }
                }
            },
        },

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.handleChange(value); this.$emit("update:modelValue", value); },
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue === "");
            },
            computedClasses() {
                if (this.customErrorMessage !== "" || this.customErrorWithoutMessage || this.showRequiredError) {
                    return "p-invalid";
                }
                return "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {InputText, CustomValidationMessage, CustomLabel},
    };
</script>

<style scoped lang="scss">
</style>
