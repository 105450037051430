<template>
    <div>
        <div class="top-panel">
            <div class="code">
                500
            </div>
        </div>
        <div class="bottom-panel">
            <div class="icon">
                <i class="pi pi-times"/>
            </div>
            <div class="content">
                <h3>Błąd</h3>
                <span>Prosimy skontaktować się z administratorem systemu</span>
                <router-link class="redirect-button" custom v-slot="{ navigate }" :to="{name: 'home'}">
                    <Button icon="pi pi-undo" @click="navigate" @keypress.enter="navigate"
                            label="Powrót na stronę główną" />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "ErrorView",
        components: {Button},
    };
</script>

<style lang="scss" scoped>
    @import "assets/scss/status-page";

    .top-panel {
        background-color: orangered;
        .code {
            color: #ff8e73;
        }
    }

    .bottom-panel {
        .icon {
            background-color: orangered;
        }
    }
</style>
