<template>
    <div class="flex flex-row gap-4 separator relative">
        <div class="flex flex-1 flex-column gap-4">
            <CustomSelectRadio :options="dicts.clouds" v-model="internalClouds" :disabled="!editing"
                               :showErrors="showErrorMessages" label="Zachmurzenie" :required="required"/>
            <CustomSelectRadio :options="dicts.wind" v-model="internalWind" :disabled="!editing"
                               :showErrors="showErrorMessages" label="Wiatr" :required="required"/>
        </div>
        <div class="flex flex-1 flex-column gap-4">
            <CustomSelectRadio :options="dicts.rain" v-model="internalRain" :disabled="!editing"
                               :showErrors="showErrorMessages" label="Deszcz" :required="required"/>
            <CustomSelectRadio :options="dicts.visibility" v-model="internalVisibility" :disabled="!editing"
                               :showErrors="showErrorMessages" label="Widoczność" :required="required"/>
        </div>
    </div>
</template>

<script>
    import CustomSelectRadio from "@/components/form/CustomSelectRadio.vue";

    export default {
        name: "FormWeather",

        components: {
            CustomSelectRadio,
        },

        props: {
            clouds: {
                type: Object,
            },
            wind: {
                type: Object,
            },
            rain: {
                type: Object,
            },
            visibility: {
                type: Object,
            },
            required: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["dicts", "editing", "showErrorMessages"],

        emits: ["update:clouds", "update:wind", "update:rain", "update:visibility"],

        computed: {
            internalClouds: {
                get() { return this.clouds; },
                set(value) { this.$emit("update:clouds", value); },
            },
            internalWind: {
                get() { return this.wind; },
                set(value) { this.$emit("update:wind", value); },
            },
            internalRain: {
                get() { return this.rain; },
                set(value) { this.$emit("update:rain", value); },
            },
            internalVisibility: {
                get() { return this.visibility; },
                set(value) { this.$emit("update:visibility", value); },
            },
        },
    };
</script>

<style lang="scss">
    .separator::after {
        position: absolute;
        content: "";
        height: 85%;
        width: 3px;
        background-color: var(--surface-border);
        left: 48%;
    }
</style>
