<template>
    <div class="header-acc-options flex gap-3 align-items-center border-right-2 border-0 pr-4">
        <AccToolsFontSize/>
        <div class="acc-contrast-high-black clickable flex" tabindex="0" @click="setBlackContrastForAllApp"
             @keyup.enter="setBlackContrastForAllApp">
            <img src="@/assets/download.svg" alt="">
            <span class="visually-hidden">Wysoki kontrast - czarne tło</span>
        </div>
        <div class="acc-reset clickable flex" tabindex="0"
             @click="resetSettingsForAllApp" @keyup.enter="resetSettingsForAllApp">
            <span class="visually-hidden">Resetuj ustawienia wielkości czcionki i kontrastu</span>
            <svg style="" xmlns="http://www.w3.org/2000/svg"
                 width="15.093" height="16" viewBox="0 0 15.093 16.681">
                <defs/>
                <g transform="translate(-4.396 -0.001)">
                    <!-- eslint-disable-next-line -->
                    <path class="a" d="M16.357,3.027a.692.692,0,0,0-.81,1.123,6.157,6.157,0,1,1-4.521-1.1.327.327,0,0,0,.038-.01l.028,1.368a.375.375,0,0,0,.645.252L13.707,2.6a.375.375,0,0,0-.011-.53L11.64.1a.375.375,0,0,0-.529.011.381.381,0,0,0-.1.267l.027,1.3a.693.693,0,0,0-.215,0,7.541,7.541,0,1,0,5.538,1.346Z" transform="translate(0 0)"/>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
    import {ThemeSettingsHelper} from "@/mixins/themeSettings";
    import AccToolsFontSize from "@/components/AccToolsFontSize";

    export default {
        name: "AccTools",

        components: { AccToolsFontSize },

        methods: {
            setBlackContrastForAllApp() {
                ThemeSettingsHelper.setBlackContrastForAllApp();
            },
            resetSettingsForAllApp() {
                ThemeSettingsHelper.resetSettingsForAllApp();
            },
        },

        data() {
            return {
            };
        },
    };
</script>

<style lang="scss">
    .a {
        fill:var(--text-color);
    }
</style>
