<template>
    <div class="point-table">
        <DataTable :lazy="true" :value="points" dataKey="id" :paginator="true" :totalRecords="totalRecords"
                   :loading="loading" @page="onPage" @sort="onPage" removableSort :rows="10"
                   :rowsPerPageOptions="[5,10,20,50]" :search-criteria="searchCriteria" size="small"
                   v-model:first="searchCriteria.page.offset" v-model:rows="searchCriteria.page.limit"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink RowsPerPageDropdown"
                   :currentPageReportTemplate="currentPageReportTemplate">
            <Column header="Nazwa" field="name" sortable/>
            <Column header="Kod powierzchni" field="field.name"/>
            <Column header="Program" field="program"/>
            <Column header="Numer Kolejności" field="order" sortable/>
            <Column header="Działania">
                <template #body="slotProps">
                    <div class="flex gap-3">
                        <CustomButton label="Wyświetl szczegóły" @click="viewDetails(slotProps.data.id)" />
                        <CustomButton label="Edytuj" @click="navigateToEditPoint(slotProps.data.id)" />
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchPoints,
        searchPointsCount,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "PointsTable",
        components: {
            CustomButton,
            DataTable,
            Column,
        },

        props: {
            modelValue: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                points: [],
                loading: false,
                totalRecords: 0,
            };
        },

        mounted() {
            this.search();
        },

        methods: {
            async search() {
                this.loading = true;
                let fieldNames;
                if (this.searchCriteria.fieldNames != null) {
                    fieldNames = this.searchCriteria.fieldNames.map((field) => field.name);
                }
                searchPoints({body: {...this.searchCriteria, fieldNames}})
                    .then((response) => {
                        this.points = response.data;
                        console.log(this.points);
                        return searchPointsCount({body: {...this.searchCriteria, fieldNames}});
                    })
                    .then((response) => {
                        this.totalRecords = response.data;
                        this.loading = false;
                    });
            },
            onPage(event) {
                this.searchCriteria.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
            },
            viewDetails(id) {
                this.$router.push(`point/${id}`);
            },
            navigateToEditPoint(id) {
                this.$router.push({path: `/point/${id}`});
            },
            exportData() {
                FileUtils.downloadFile({}, "transekty.xlsx", "/point/export");
            },
        },

        computed: {
            searchCriteria: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            currentPageReportTemplate() {
                return "Rekordy od "
                    + "{first}"
                    + " do "
                    + "{last}"
                    + " z "
                    + "{totalRecords}"
                    + " (Strona {currentPage}"
                    + " z "
                    + "{totalPages})";
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    this.search();
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },

    };
</script>

<style scoped lang="scss">

</style>
