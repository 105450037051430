<template>
    <DataTable :value="enumDict" v-model:editingRows="editingRows" editMode="row" dataKey="id" lazy
               :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
               @row-edit-save="onRowEditSave" removableSort size="small">
        <Column header="Klucz" field="id" />
        <Column header="Kod" field="code" />
        <Column header="Etykieta" field="label">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="label" />
            </template>
        </Column>
        <Column header="Programy" field="supportedTypes" >
            <template #body="{data,field}">
                {{data[field].join(", ")}}
            </template>
        </Column>
        <Column :rowEditor="true" style="text-align: center;" class="w-1"/>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchList, updateDict,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "@/components/form/CustomInputText.vue";

    export default {
        name: "EnumDictTable",

        components: {
            DataTable,
            Column,
            CustomInputText,
        },

        props: {
            searchCriteria: null,
        },

        data() {
            return {
                enumDict: [],
                editingRows: ref([]),
                loading: false,
                totalRecords: 0,
            };
        },

        mounted() {
            this.search();
        },

        methods: {
            async search() {
                this.loading = true;
                const criteria = {
                    ...this.searchCriteria,
                };

                searchList({dictType: this.$route.params.dict, body: criteria})
                    .then((response) => {
                        this.enumDict = response.data;
                        this.totalRecords = response.data.length;
                        this.loading = false;
                    });
            },

            onRowEditSave(event) {
                const {newData, index} = event;
                updateDict({body: {dictType: this.$route.params.dict, name: newData.id, newLabel: newData.label}})
                    .then(() => {
                        this.enumDict[index] = newData;
                    });
            },
        },
    };
</script>

<style lang="scss">
</style>
