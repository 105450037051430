const ListUtils = {
    join(originalList, ...params) {
        if (originalList === null || originalList === undefined) return originalList;
        let list = JSON.parse(JSON.stringify(originalList));
        params.forEach((param) => {
            list = list?.map((el) => (el ? el[param] : el));
        });
        return list.sort().join(", ");
    },
};

export default ListUtils;
