<!-- eslint-disable -->
<template>
    <DataTable :value="habitat" v-model:editingRows="editingRows" editMode="row"
               dataKey="id" @row-edit-save="onRowEditSave"
               :class="columns.length > 11 ? 'vertical-rows' : ''"
               :totalRecords="totalRecords" removableSort
               :rows="10" :rowsPerPageOptions="[5,10,20,50]" small>
        <template #header>
            <HabitatDataDialogAddHabitat v-model="habitat" :columns="columns" :gHistoryId="gHistoryId"
                                         :programCode="programCode" @reloadTable="getHabitatData" />
        </template>
        <Column v-for="(c,index) in columns" :key="index" :header="c.header"
                :field="c.field">
                <template #body="slotProps">
                <div v-if="c.bodyTooltip" :class="c.bodyClass(slotProps.data)"
                            v-tooltip.top="c.bodyTooltipContent(slotProps.data)">
                            {{ c.bodyContent(slotProps.data) }}
                </div>
                <div v-else :class="c.bodyClass(slotProps.data)">
                    {{ computedList(c.bodyContent(slotProps.data), c.isList) }}
                </div>
            </template>
            <template #editor="{data,field}">
                <CustomInputText v-if="c.editorType === 'inputText'" v-model="data[field]" :name="c.field"
                                 :disabled="c.field === 'differentUtilization' && data['utilization']?.code !== '5'" />
                <CustomInputNumber v-else-if="c.editorType === 'inputNumber'" v-model="data[field]" :name="c.field" />
                <CustomSelectOne v-else-if="c.editorType === 'selectOne'" v-model="data[field]" :items="[{label: 'LOREM IPSUM DOLOR SUL AMET sdfsdf sgfdsg g fdgf'}]" :itemLabel="c.editorItemLabel" :itemValue="c.editorItemValue" :emptyValue="c.editorEmptyValue" :name="c.field" />
                <CustomMultiSelect v-else-if="c.editorType === 'multiselect'" v-model="data[field]" :items="[{label: 'LOREM IPSUM DOLOR SUL AMET sdfsdf sgfdsg g fdgf'}]" :itemLabel="c.editorItemLabel" :itemValue="c.editorItemValue" :emptyValue="c.editorEmptyValue" :name="c.field" />
                <CustomCalendar v-else-if="c.editorType === 'calendar'" v-model="data[field]" :name="c.field" />
                <CustomCheckbox v-else-if="c.editorType === 'checkbox'" v-model="data[field]" :name="c.field" />
            </template>
        </Column>
        <Column :rowEditor="true" style="width: 6.2499%; text-align: center;"/>
        <Column style="text-align: center; padding-left: 0; padding-right: 0;">
            <template #body="slotProps">
                <div class="flex">
                    <CustomButton v-if="slotProps.data.accepted === false" icon="pi pi-check-square"
                                  borderColor="transparent" @click="acceptHabitatData(slotProps.data)"
                                  title="Zaakceptuj" />
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteHabitatData(slotProps.data)" />
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import Tooltip from "primevue/tooltip";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import HabitatDataDialogAddHabitat from "./HabitatDataDialogAddHabitat.vue";
    import {
        getHabitatByGeographicalHistoryId,
        deleteHabitatById,
        acceptHabitatById,
        updateHabitat,
    } from "@/swagger/vue-api-client";
    import CustomCheckbox from "@/components/form/CustomCheckbox";

    export default {
        name: "HabitatDataDialogTable",

        components: {
            DataTable,
            Column,
            CustomInputText,
            CustomInputNumber,
            CustomSelectOne,
            CustomButton,
            CustomCalendar,
            HabitatDataDialogAddHabitat,
            CustomCheckbox,
            CustomMultiSelect,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            columns: {
                type: Array,
                required: true,
            },
            programCode: null,
            gHistoryId: null,
        },

        data() {
            return {
                editingRows: ref([]),
                totalRecords: 0,
                habitat: null,
                dicts: {},
            };
        },

        beforeMount() {
            this.getHabitatData();
        },

        methods: {
            getHabitatData() {
                getHabitatByGeographicalHistoryId({type: this.type, gHistoryId: this.gHistoryId})
                    .then((response) => {
                        this.habitat = response.data;
                        this.totalRecords = response.data.length;
                    })
                    .catch((err) => {
                        if (err.response?.status === 403) {
                            this.$router.push({ name: "forbidden" });
                        }
                    });
            },

            onRowEditSave(event) {
                const { newData, index } = event;
                this.habitat[index] = newData;
                this.updateHabitatData(newData);
            },

            updateHabitatData(newData) {
                updateHabitat({
                    id: newData.id,
                    body: newData,
                });
            },

            deleteHabitatData(habitatRow) {
                if (habitatRow.id) {
                    deleteHabitatById({
                        program: habitatRow.program,
                        id: habitatRow.id,
                    }).then(() => {
                        const index = this.habitat.indexOf(habitatRow);
                        this.habitat.splice(index, 1);
                    });
                } else {
                    const index = this.habitat.indexOf(habitatRow);
                    this.habitat.splice(index, 1);
                }
            },

            acceptHabitatData(habitatRow) {
                acceptHabitatById({
                    program: this.programCode,
                    id: habitatRow.id,
                }).then(() => {
                    habitatRow.accepted = true;
                });
            },

            computedList(element, isList) {
                if (isList) {
                    let list = "";
                    element.forEach((el) => {
                        list += el.label + "; ";
                    });
                    return list;
                }
                return element;
            },
        },

        computed: {
            type() {
                if (["MPM", "MPPL", "MWOT"].includes(this.programCode)) return "Transect";
                if (["MZO"].includes(this.programCode)) return "Position";
                return "Point";
            },
        },
    };
</script>

<style lang="scss">
    .vertical-rows {
        table {
            display: flex;
            tbody {
                display: flex;
                overflow-x: scroll;
                flex-wrap: nowrap;
            }
            tr {
                display: block;
                float: left;
            }
            td, th {
                display: block;
                width: 200px !important;
                height: 73px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                .custom-select-one {
                    width: 100%;
                }
            }
        }
    }
</style>
