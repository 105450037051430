<template>
    <div class="program-details flex">
        <div class="single-detail">
            <p class="detail-type">Program</p>
            <p class="detail-value">{{ form?.programName }}{{ form?.subprogramName ? ` - ${form?.subprogramName}` : "" }}</p>
        </div>
        <div class="single-detail">
            <p class="detail-type">Obserwator</p>
            <p class="detail-value">{{ form?.observer }}</p>
        </div>
        <div class="single-detail">
            <p class="detail-type">{{ transect ? "Transekt" : "Powierzchnia" }}</p>
            <p class="detail-value">{{ form?.field?.name }}</p>
        </div>
        <div v-if="programCode === 'MZPM'" class="single-detail">
            <p class="detail-type">Strona statku</p>
            <p class="detail-value">{{ form?.control?.label }}</p>
        </div>
        <div class="single-detail">
            <p class="detail-type">Rok monitoringu</p>
            <p class="detail-value">{{ form?.monitoringYear }}</p>
        </div>
        <div v-if="showControlType" class="single-detail">
            <p class="detail-type">Rodzaj kontroli</p>
            <p class="detail-value">{{ form?.control?.label }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProgramDetails",

        props: {
            form: null,
            showControlType: {
                type: Boolean,
                default: false,
            },
            transect: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["programCode"],
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";
.program-details {
    .single-detail {
        margin-right: 80px;
        @media screen and (max-width: 1280px) {
            margin-right: 40px;
        }
        @media screen and (min-width: 1920px) {
            margin-right: 120px;
        }
        .detail-type {
            font-size: 1rem;
            font-weight: bold;
            width: max-content;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
        .detail-value {
            font-size: 1.07rem;
            font-weight: 600;
            color: var(--color-black);
            width: max-content;
            margin-top: 0;
            margin-bottom: 0.5rem;
        }
    }
}
</style>
