<template>
    <div class="monitoring-program-semiraw-view my-4">
        <div class="monitoring-program-semiraw-search-criteria flex justify-content-end flex-column">
            <div class="flex gap-3">
                <GeographicalFilters v-model="searchCriteria" v-model:matchMode="searchCriteria.matchMode"
                                     ref="geographicalFilters" :filterBy="matchModeOptions" />
                <div class="flex align-self-end justify-content-end field">
                    <CustomButton label="Wyczyść" @click="clear"/>
                </div>
            </div>
            <div class="flex align-items-center gap-3 field justify-content-end">
                <CustomButton label="Eksportuj" @click="exportSemiRawData"/>
            </div>
        </div>
        <SemiRawDataTable v-model="searchCriteria" :data="data" :useGivenData="useGivenData"
                          :sortable="sortable" :dicts="dicts"/>
    </div>
</template>

<script>
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomButton from "@/components/form/CustomButton.vue";
    import SemiRawDataTable from "@/views/semiRawData/components/SemiRawDataTable.vue";
    import {FileUtils} from "@/utils/FileUtils";
    import GeographicalFilters from "@/components/filters/GeographicalFilters.vue";

    export default {
        name: "MonitoringProgramSemiRawData",

        components: {
            SemiRawDataTable,
            CustomButton,
            GeographicalFilters,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                matchModeOptions: [
                    {value: "FIELD", label: "Powierzchni"},
                ],
            };
        },

        props: {
            programEditionYear: null,
            sortable: {
                type: Boolean,
                default: true,
            },
            data: {
                type: Array,
                default: () => [],
            },
            useGivenData: {
                type: Boolean,
                default: false,
            },
            dicts: null,
        },

        inject: ["programCode"],

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            clear() {
                this.$refs.geographicalFilters.clear();
            },
            getClearSearchCriteria() {
                return SearchCriteria.getClearSearchCriteriaSemiRawData(this.programCode, this.programEditionYear);
            },
            exportSemiRawData() {
                FileUtils.downloadFile(
                    {body: this.searchCriteria}, "dane-skumulowane.xlsx", "/semiRawData/search/export");
            },
        },
    };
</script>

<style lang="scss">
</style>
