<template>
    <div>
        <CustomCalendar :label="calendarLabel" name="controlDate" required :showErrors="showErrorMessages"
                        class="w-3 pr-1" v-model="form.controlDate" :disabled="!editing"/>
        <div class="flex gap-2">
            <CustomInputNumber label="Temperatura min. (°C)" name="temperatureMin" required class="w-3"
                               v-model="form.temperatureMin" :showErrors="showErrorMessages" :disabled="!editing"
                               :min="-99" />
            <CustomInputNumber label="Temperatura max. (°C)" name="temperatureMax" required class="w-3"
                               v-model="form.temperatureMax" :showErrors="showErrorMessages" :disabled="!editing"
                               :customErrorMessage="getMinMaxErrorMessage(form.temperatureMin,form.temperatureMax)"
                               :min="-99" />
            <CustomInputNumber label="Pokrywa śnieżna min. (cm)" name="snowMin" required class="w-3"
                               v-model="form.snowMin" :showErrors="showErrorMessages" :disabled="!editing"/>
            <CustomInputNumber label="Pokrywa śnieżna max. (cm)" name="snowMax" required class="w-3"
                               v-model="form.snowMax" :showErrors="showErrorMessages" :disabled="!editing"
                               :customErrorMessage="getMinMaxErrorMessage(form.snowMin,form.snowMax)"/>
        </div>
        <div :class="timePicker ? 'flex gap-2' : 'flex gap-2 pr-4'">
            <CustomInputNumber label="Ciśnienie min. (hPa)" name="pressureMin" required class="w-3"
                               v-model="form.pressureMin" :showErrors="showErrorMessages" :disabled="!editing"/>
            <CustomInputNumber label="Ciśnienie max. (hPa)" name="pressureMax" required class="w-3"
                               v-model="form.pressureMax" :showErrors="showErrorMessages" :disabled="!editing"
                               :customErrorMessage="getMinMaxErrorMessage(form.pressureMin,form.pressureMax)"/>
            <CustomTimePicker v-if="timePicker" label="Czas rozpoczęcia kontroli" name="startTime" required class="w-3"
                              v-model="form.startTime" :showErrors="showErrorMessages" :disabled="!editing"/>
            <CustomTimePicker v-if="timePicker" label="Czas zakończenia kontroli" name="endTime" required class="w-3"
                              v-model="form.endTime" :showErrors="showErrorMessages" :disabled="!editing"/>
        </div>
    </div>
</template>

<script>
    import CustomCalendar from "@/components/form/CustomCalendar";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomTimePicker from "@/components/form/CustomTimePicker";

    export default {
        name: "BasicInformations",

        components: {
            CustomCalendar,
            CustomInputNumber,
            CustomTimePicker,
        },

        props: {
            modelValue: {
                type: Object,
            },
            timePicker: {
                type: Boolean,
                default: false,
            },
            calendarLabel: {
                type: String,
                default: "Data liczenia",
            },
        },

        inject: ["editing", "showErrorMessages"],

        emits: ["update:modelValue"],

        methods: {
            getMinMaxErrorMessage(min, max) {
                if (min != null && max != null && min > max) return "max mniejsze niż min";
                return "";
            },
        },

        computed: {
            form: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            form: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
