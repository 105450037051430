<template>
    <div class="monitoring-program-data-aggregates">
        <div class="flex gap-3">
            <GeographicalFilters v-model="searchCriteria" v-model:matchMode="searchCriteria.matchMode"
                                 :filterBy="matchModeOptions" />
            <div class="data-aggregates-search-criteria flex align-self-end justify-content-end field">
                <CustomButton label="Wyczyść" @click="clear"/>
            </div>
        </div>
        <DataAggregatesTable v-model="searchCriteria" :monitoringProgram="monitoringProgram" :data="data"
                             :useGivenData="useGivenData" :sortable="sortable" :dicts="dicts"/>
    </div>
</template>

<script>
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import DataAggregatesTable from "../../../dataAggregates/components/DataAggregatesTable";
    import CustomButton from "@/components/form/CustomButton.vue";
    import GeographicalFilters from "@/components/filters/GeographicalFilters.vue";

    export default {
        name: "MonitoringProgramDataAggregates",

        components: {
            CustomButton,
            DataAggregatesTable,
            GeographicalFilters,
        },

        props: {
            monitoringProgram: {
                type: Boolean,
                default: false,
            },
            sortable: {
                type: Boolean,
                default: true,
            },
            data: {
                type: Array,
                default: () => [],
            },
            useGivenData: {
                type: Boolean,
                default: false,
            },
            dicts: null,
            programEditionYear: null,
        },

        inject: ["programCode"],

        data() {
            return {
                searchCriteria: this.getClearSearchCriteriaDataAggregates(),
                matchModeOptions: [
                    {value: "FIELD", label: "Powierzchni"},
                ],
            };
        },

        methods: {
            getClearSearchCriteriaDataAggregates() {
                const clearSearchCriteria = SearchCriteria.getClearSearchCriteriaDataAggregates();
                clearSearchCriteria.year = this.programEditionYear;
                clearSearchCriteria.programs = this.programCode ? [this.programCode] : [];
                return clearSearchCriteria;
            },
            clear() {
                this.searchCriteria = this.getClearSearchCriteriaDataAggregates();
            },
        },
    };
</script>

<style lang="scss">
</style>
