<template>
    <div>
        <Toast />
        <h2 class="page-header"><i class="pi pi-users"></i> Dane użytkownika</h2>
        <div class="container">
            <VeeForm v-if="loaded" @submit="saveUser" v-slot="{ errors }">
                <div class="grid">
                    <div class="col-12 md:col-4">
                        <CustomInputText name="firstName" label="Imię" v-model="user.firstName" rules="required"/>
                        <CustomInputText name="lastName" label="Nazwisko" v-model="user.lastName" rules="required"/>
                        <CustomInputText name="email" label="e-mail" v-model="user.email" rules="required|email"/>
                        <!-- <CustomPassword name="password" label="Hasło"
                        v-model="user.password" rules="validPassword"/> -->
                        <!-- <CustomSelectOneSystemRole name="systemRole" label="Rola" v-model="user.systemRole" /> -->
                        <CustomMultiSelect name="systemRole" :items="userRoles" label="Rola"
                                           v-model="user.systemRole" itemValue="key"
                                           :filter="false" />
                        <CustomMultiSelect v-if="user.systemRole.includes('REGIONAL_COORDINATOR')"
                                           name="regionalPrograms" :items="programs"
                                           label="Programy koordynatora regionalnego"
                                           v-model="user.regionalPrograms" item-label="" item-value=""
                                           :filter="false" />
                        <CustomMultiSelect v-if="user.systemRole.includes('NATIONAL_COORDINATOR')"
                                           name="nationalPrograms" :items="programs" itemLabel=""
                                           label="Programy koordynatora krajowego" v-model="user.nationalPrograms"
                                           :filter="false" />
                        <div class="flex justify-content-end">
                            <CustomButton label="Zapisz" type="submit" icon="pi pi-check"
                                          :disabled="isDisabled(errors)" />
                        </div>
                    </div>
                </div>
            </VeeForm>
        </div>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import {
        createOrUpdateUser, getListOfPrograms,
        getUser,
        getUserRoles,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "../../components/form/CustomInputText";
    // import {SystemRole} from "../../utils/SystemRole";
    // import CustomSelectOneSystemRole from "../../components/form/CustomSelectOneSystemRole.vue";
    import CustomButton from "../../components/form/CustomButton.vue";
    import CustomMultiSelect from "../../components/form/inner/CustomMultiSelect.vue";

    export default {
        name: "UserFormView",

        components: {
            CustomInputText,
            Toast,
            VeeForm,
            // CustomSelectOneSystemRole,
            CustomButton,
            CustomMultiSelect,
        },

        props: {
            value: {},
        },

        data() {
            return {
                user: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    password: null,
                    systemRole: [],
                    regionalPrograms: [],
                    nationalPrograms: [],
                },
                loaded: false,
                userRoles: [],
                programs: [],
            };
        },

        beforeMount() {
            getUserRoles()
                .then((response) => {
                    this.userRoles = response.data;
                    console.log(response.data);
                });
            getListOfPrograms().then((response) => {
                this.programs = response.data;
            });
            if (this.$route.params.id) {
                getUser({id: this.$route.params.id}).then((response) => {
                    this.user = response.data;
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
            saveUser() {
                createOrUpdateUser({body: this.user}).then(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zapisano dane użytkownika",
                        life: 3000,
                    });
                });
            },
        },
    };
</script>

<style lang="less" scoped>
</style>
