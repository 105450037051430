<template>
    <div class="border-1 surface-border">
        <HeaderComponent headerText="Informacje" class="m-0" />
        <div class="flex gap-8 p-5">
            <div class="position-map w-4">
                <MapMultiMarkers mapId="archiveMap" height="400px" :zoomControls="false"
                                 :positions="position.histories" :field="position.field?.shape"
                                 :markerTypes="markerTypes" :isOn="false" />
            </div>
            <div class="position-details">
                <VeeForm v-slot="{ errors }">
                    <ul class="list-none p-0 m-0">
                        <li class="my-2 flex align-items-center gap-2 h-3rem">
                            <span class="font-semibold w-11rem text-right">Nazwa:</span>
                            <template v-if="!isEditPositionActive">{{ position.name }}</template>
                            <CustomInputText v-if="isEditPositionActive" name="name" label="" v-model="tmpPosition.name"
                                             rules="required" class="mb-0 flex-1" />
                        </li>
                        <li class="my-2 flex align-items-center gap-2 h-3rem" v-if="position.status">
                            <span class="font-semibold w-11rem text-right">Status:</span>
                            <template v-if="!isEditPositionActive">{{ position.status.label }}</template>
                            <CustomSelectOne v-if="isEditPositionActive" itemValue="key" id="status"
                                             v-model="tmpPosition.status.key" :items="statuses"
                                             name="status" label="" :emptyValue="false" class="mb-0 flex-1"/>
                        </li>
                        <li class="my-2 flex align-items-center gap-2 h-3rem" v-if="position.owner">
                            <span class="font-semibold w-11rem text-right">Właściciel:</span>
                            <template v-if="!isEditPositionActive">{{ position.owner.fullName }}</template>
                            <CustomSelectOne v-if="isEditPositionActive" id="owner" v-model="tmpPosition.owner.id"
                                             :items="observers" label="" item-label="fullName"
                                             item-value="id" name="owner" class="mb-0 flex-1" />
                        </li>
                        <template v-if="position.field">
                            <li class="my-2 flex align-items-center gap-2 h-3rem">
                                <span class="font-semibold w-11rem text-right">Program:</span>
                                <template v-if="!isEditPositionActive">{{ position.field.program }}</template>
                                <CustomInputText v-if="isEditPositionActive" name="fieldProgram" label=""
                                                 v-model="tmpPosition.field.program"
                                                 rules="required" class="mb-0 flex-1" />
                            </li>
                            <li class="my-2 flex align-items-center gap-2 h-3rem">
                                <span class="font-semibold w-11rem text-right">Nazwa powierzchni:</span>
                                {{ position.field.name }}
                            </li>
                        </template>
                        <li class="my-2 flex align-items-center gap-2 h-3rem" v-if="position && position.coordinates">
                            <span class="font-semibold w-11rem text-right">Ostatnie współrzędne:</span>
                            {{ position.coordinates.latitude }}, {{ position.coordinates.longitude }}
                        </li>
                    </ul>
                    <div class="flex gap-3 mt-3">
                        <div v-if="!isEditPositionActive" class="w-10rem flex justify-content-end">
                            <CustomButton label="Edytuj" icon="pi pi-pencil" class="mt-0" @click="editPositionDetails"/>
                        </div>
                        <template v-if="isEditPositionActive">
                            <CustomButton label="Anuluj edycję" icon="pi pi-times"
                                          @click="isEditPositionActive = false" />
                            <CustomButton label="Zapisz" bgColor="var(--secondary-color)" color="var(--surface-a)"
                                          @click="submitForm" :disabled="isDisabled(errors)" />
                        </template>
                    </div>
                </VeeForm>
            </div>
        </div>
    </div>
</template>

<script>
    import {Form as VeeForm} from "vee-validate";
    import MapMultiMarkers from "@/components/map/MapMultiMarkers.vue";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import {
        updatePosition,
        getAllObserversAsCoordinator,
    } from "@/swagger/vue-api-client";

    export default {
        name: "PositionMainInfo",

        components: {
            VeeForm,
            MapMultiMarkers,
            HeaderComponent,
            CustomButton,
            CustomInputText,
            CustomSelectOne,
        },

        props: {
            position: {
                type: Object,
            },
            program: null,
        },
        beforeMount() {
            this.loadObservers();
            this.setMarkerTypes();
        },

        data() {
            return {
                isEditPositionActive: false,
                tmpPosition: {},
                statuses: [
                    {
                        label: "Aktywne",
                        key: "ACTIVE",
                    },
                    {
                        label: "Zarchiwizowane",
                        key: "ARCHIVED",
                    },
                ],
                field: null,
                observers: [],
                markerTypes: new Map(),
            };
        },
        mounted() {
            if (this.$route.query.editMode === "true") {
                this.editPositionDetails();
            }
        },
        methods: {
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
            setMarkerTypes() {
                // creates actual map which keeps for every positionId an array with icon color and icon size
                this.position.histories.forEach((position, index) => {
                    if (index === 0) {
                        this.markerTypes.set(position.positionId, {
                            color: "red",
                            size: "small",
                            popup: false,
                            dontHidePopup: false,
                        });
                    } else {
                        this.markerTypes.set(position.positionId, {
                            color: "grey",
                            size: "small",
                            popup: false,
                            dontHidePopup: false,
                        });
                    }
                });
            },
            editPositionDetails() {
                this.isEditPositionActive = true;
                this.tmpPosition = { ...this.position };
            },
            loadObservers() {
                getAllObserversAsCoordinator()
                    .then((response) => {
                        this.observers = response.data;
                    });
            },
            submitForm() {
                const positionRequest = {
                    status: this.position.status,
                    name: this.position.name,
                    fieldId: this.position.field,
                    ownerId: this.position.owner,
                    lastCoordinates: {
                        longitude: this.position.coordinates.longitude,
                        latitude: this.position.coordinates.latitude,
                    },

                };
                updatePosition({
                    id: this.position.id,
                    body: positionRequest,
                }).then((response) => {
                    if (response.status === 200) {
                        this.$router.push({ name: "positionDictView" });
                    }
                });
            },
        },
    };
</script>
