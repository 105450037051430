<template>
    <div class="create-transect-view">
        <h1>Tworzenie transektu</h1>
        <VeeForm @submit.prevent="submitForm">
            <div class="form-group">
                <CustomInputText id="name" v-model="transect.name" name="name" label="Nazwa" />
            </div>
            <div class="form-group">
                <CustomInputText id="orderNum" v-model="transect.order_num"
                                 name="order_num" label="Numer kolejności" />
            </div>
            <div class="form-group">
                <CustomSelectOne id="field" v-model="transect.field" :items="fields"
                                 name="field" label="Powierzchnia" item-value="id" item-label="name"
                                 virtual-scrolling />
            </div>
            <CustomButton label="Zapisz" @click="submitForm" type="submit" />
        </VeeForm>
    </div>
</template>

<script>
    import VeeForm from "vee-validate";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import {
        createTransect,
        getListOfPrograms,
        listFieldsForProgram,
    } from "@/swagger/vue-api-client";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        components: {
            CustomSelectOne,
            VeeForm,
            CustomInputText,
            CustomButton,
        },

        data() {
            return {
                transect: {
                    name: "",
                    order_num: null,
                    field: null,
                },
                fields: [],
            };
        },
        beforeMount() {
            this.loadPrograms();
        },
        methods: {
            loadPrograms() {
                getListOfPrograms()
                    .then((response) => {
                        this.programs = response.data;
                        this.programs.forEach((program) => {
                            this.loadFields(program);
                        });
                    })
                    .catch((error) => {
                        console.error("Error loading programs:", error);
                    });
            },

            loadFields(programCode) {
                listFieldsForProgram({ program: programCode })
                    .then((response) => {
                        this.fields = [...this.fields, ...response.data];
                    })
                    .catch((error) => {
                        console.error(`Error loading fields for program ${programCode}:`, error);
                    });
            },
            submitForm() {
                const transectRequest = {
                    name: this.transect.name,
                    order: this.transect.order_num,
                    fieldId: this.transect.field,
                };
                createTransect({
                    body: transectRequest,
                }).then((response) => {
                    if (response.status === 201) {
                        this.$router.push({ name: "transectTable" });
                    }
                });
            },
        },
    };
</script>

<style>
.form-group {
    margin-bottom: 1rem;
}
.form-group label {
    display: block;
    margin-bottom: 0.5rem;
}
.form-group input,
.form-group select {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}
</style>
