<template>
    <HabitatDataTemplate :habitatData="habitatData" v-model:habitatDataToChange="localHabitatDataToChange"
                         :emptyHabitatData="EmptyStructures.getEmptyMSLHabitatData()">
        <template v-slot:data="slotProps">
            <div class="flex justify-content-between flex-column">
                <div class="flex gap-3">
                    <CustomSelectOne label="Typ zbiornika" name="habitatType" :items="dicts.habitatType" class="w-5" :emptyValue="false"
                                     v-model="slotProps.habitatData.habitatType" :disabled="slotProps.disabled"
                                     required :showErrors="showErrorMessages"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <CustomInputText v-if="slotProps.habitatData?.habitatType?.code === 'INNE'"
                                     label="Podaj inny typ" name="otherHabitatType" class="w-7"
                                     v-model="slotProps.habitatData.otherHabitatType" :disabled="slotProps.disabled"
                                     required :showErrors="showErrorMessages"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <div v-else class="w-7"/>
                </div>
                <div class="flex gap-3">
                    <CustomSelectOne label="Usytuowanie kolonii" name="location" :items="dicts.location" class="w-5"
                                     :emptyValue="false" v-model="slotProps.habitatData.location"
                                     :disabled="slotProps.disabled" required :showErrors="showErrorMessages"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <CustomInputText v-if="slotProps.habitatData?.location?.code === 'INNE'"
                                     label="Podaj inne usytowanie" name="otherLocation" class="w-7"
                                     v-model="slotProps.habitatData.otherLocation" :disabled="slotProps.disabled"
                                     required :showErrors="showErrorMessages"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <div v-else class="w-7"/>
                </div>
                <div class="flex gap-3">
                    <CustomSelectOne label="Roślinność w kolonii - roślinność zielna (dominująca wysokość)"
                                     name="plantsType" :items="dicts.plantsType" class="w-4" :emptyValue="false"
                                     v-model="slotProps.habitatData.plantsType" :disabled="slotProps.disabled"
                                     required :showErrors="showErrorMessages"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <CustomSelectOne label="Roślinność w kolonii - krzewy" name="bushes"
                                     :items="dicts.treeBushesArea" class="w-4" :emptyValue="false"
                                     v-model="slotProps.habitatData.bushes" :disabled="slotProps.disabled"
                                     required :showErrors="showErrorMessages"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <CustomSelectOne label="Roślinność w kolonii - drzewa" name="trees"
                                     :items="dicts.treeBushesArea" class="w-4" :emptyValue="false"
                                     v-model="slotProps.habitatData.trees" :disabled="slotProps.disabled"
                                     required :showErrors="showErrorMessages"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                </div>
            </div>
        </template>
    </HabitatDataTemplate>
</template>

<script>
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import HabitatDataTemplate from "@/views/form/HabitatDataTemplate.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";

    export default {
        name: "HabitatDataMMC",

        components: {
            CustomInputText,
            CustomSelectOne,
            HabitatDataTemplate,
        },

        props: {
            habitatData: {
                type: Object,
            },
            habitatDataToChange: {
                type: Object,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        emits: ["update:habitatDataToChange"],

        computed: {
            EmptyStructures() {
                return EmptyStructures;
            },
            localHabitatDataToChange: {
                get() { return this.habitatDataToChange; },
                set(value) { this.$emit("update:habitatDataToChange", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
