<template>
    <Toast />
    <div>
        <ImportCastDialog v-model="showImportCastDialog" />
        <Dialog header="Dane zagregowane" :draggable="false" v-model:visible="showAggregatesDialogVisible"
                style="width: 98%;">
            <MonitoringProgramDataAggregates :programEditionYear="programEditionYear" monitoringProgram />
        </Dialog>
        <Dialog header="Dane skumulowane" :draggable="false" v-model:visible="showSemiRawDialogVisible"
                style="width: 98%;">
            <MonitoringProgramSemiRawData :programEditionYear="programEditionYear" />
        </Dialog>
        <ImportControlsDialog v-model="showImportControlsDialog"/>
        <GenerateSemiRawDataDialog v-model="generateSemiRawDialogVisible" v-model:mzpwp="mzpwp" />
        <GenerateAggregatesDataDialog v-model="generateAggregatesDialogVisible" v-model:mzpwp="mzpwp" />
        <div class="flex mx-2 gap-2">
            <div class="flex gap-2 align-items-center flex-wrap row-gap-0">
                <!-- <CustomButton class="field" label="Importuj obsadę" @click="showImportCastDialog = true" /> -->
                <CustomButton class="field" label="Importuj obserwatorów"
                              bgColor="var(--secondary-color)" color="var(--surface-a)"
                              @click="this.showImportCastDialog = true;" />
                <CustomButton class="field" :label="editCast ? 'Zakończ edycję' : 'Edytuj obsadę'"
                              @click="onCastEdit" />
                <CustomButton v-if="canDoAnything && canImportControls" class="field" label="Importuj dane"
                              @click="showImportControlsDialog = true"/>
                <CustomButton v-if="canDoAnything && canUseAggregatesAndSemiRaw" class="field"
                              @click="showAggregatesDialogVisible = true" label="Zobacz dane zagregowane" />
                <CustomButton v-if="canDoAnything && canUseAggregatesAndSemiRaw" class="field"
                              @click="generateAggregatesDialogVisible = true" label="Wygeneruj dane zagregowane" />
                <CustomButton v-if="canDoAnything && canUseAggregatesAndSemiRaw && programCode === 'MZPW'"
                              class="field" @click="showMZPWPAggregatesDialogVisible"
                              label="Wygeneruj dane zagregowane MZPWP" />
                <CustomButton v-if="canDoAnything && canUseAggregatesAndSemiRaw" class="field"
                              @click="showSemiRawDialogVisible = true" label="Zobacz dane skumulowane" />
                <CustomButton v-if="canDoAnything && canUseAggregatesAndSemiRaw" class="field"
                              @click="generateSemiRawDialogVisible = true" label="Wygeneruj dane skumulowane" />
                <CustomButton v-if="canDoAnything && canUseAggregatesAndSemiRaw && programCode === 'MZPW'" class="field"
                              @click="showMZPWPSemiRawDialogVisible" label="Wygeneruj dane skumulowane MZPWP" />
                <CustomButton v-if="canDoAnything" class="field" label="Pobierz dane surowe" @click="getRawData" />
            </div>
        </div>
    </div>
</template>

<script>
    import Dialog from "primevue/dialog";
    import Toast from "primevue/toast";
    import CustomButton from "../../../components/form/CustomButton";
    import MonitoringProgramDataAggregates from "./dataAggregates/MonitoringProgramDataAggregates";
    import {FileUtils} from "@/utils/FileUtils";
    import {SystemRole} from "@/utils/SystemRole";
    import GenerateSemiRawDataDialog from "@/views/monitoringProgram/components/GenerateSemiRawDataDialog.vue";
    import GenerateAggregatesDataDialog from "@/views/monitoringProgram/components/GenerateAggregatesDataDialog.vue";
    import ImportControlsDialog
        from "@/views/monitoringProgram/components/controls/ImportControlsDialog.vue";
    import MonitoringProgramSemiRawData
        from "@/views/monitoringProgram/components/MonitoringProgramSemiRawData.vue";
    import ImportCastDialog from "@/views/monitoringProgram/components/observer/ImportCastDialog.vue";

    export default {
        name: "MonitoringProgramDataManagement",

        components: {
            ImportCastDialog,
            MonitoringProgramSemiRawData,
            GenerateAggregatesDataDialog,
            GenerateSemiRawDataDialog,
            CustomButton,
            MonitoringProgramDataAggregates,
            ImportControlsDialog,
            Dialog,
            Toast,
        },

        props: {
            programEditionYear: null,
            programEdition: null,
            modelValue: null,
        },

        inject: ["programEditionState", "programCode"],

        data() {
            return {
                showAggregatesDialogVisible: false,
                showSemiRawDialogVisible: false,
                showImportControlsDialog: false,
                generateAggregatesDialogVisible: false,
                generateSemiRawDialogVisible: false,
                nationalCoordinators: this.programEdition.nationalCoordinators,
                showImportCastDialog: false,
                mzpwp: false,
            };
        },

        methods: {
            getRawData() {
                FileUtils.downloadFileUsingGET(
                    `dane_surowe_${this.programCode}_${this.programEditionYear}.xlsx`,
                    `/data-raw/export/${this.$route.params.id}`,
                );
            },
            onCastEdit() {
                //     if (this.editCast) {
                //         const nationalCoordinatorsIds = this.nationalCoordinators.map((c) => c.id);
                //         addNationalCoordinatorToCast({
                //             programEditionId: this.$route.params.id,
                //             body: {nationalCoordinatorsIds},
                //         });
                //     }
                this.editCast = !this.editCast;
            },
            showMZPWPAggregatesDialogVisible() {
                this.generateAggregatesDialogVisible = true;
                this.mzpwp = true;
            },
            showMZPWPSemiRawDialogVisible() {
                this.generateSemiRawDialogVisible = true;
                this.mzpwp = true;
            },
        },

        computed: {
            isMainCoordinator() {
                return SystemRole.isMainCoordinator();
            },
            isNationalCoordinator() {
                return SystemRole.isNationalCoordinator();
            },
            canImportControls() {
                return this.programEditionState === "ENTERING_DATA"
                    || this.programEditionState === "RESULTS_WAITING_FOR_NATIONAL_APPROVAL"
                    || this.programEditionState === "RESULTS_WAITING_FOR_MAIN_APPROVAL"
                    || (this.programEditionState === "PROGRAM_FINISHED" && this.isMainCoordinator);
            },
            canUseAggregatesAndSemiRaw() {
                return (this.programEditionState === "RESULTS_WAITING_FOR_NATIONAL_APPROVAL"
                    && (this.isMainCoordinator || this.isNationalCoordinator))
                    || (this.programEditionState === "RESULTS_WAITING_FOR_MAIN_APPROVAL" && this.isMainCoordinator)
                    || (this.programEditionState === "PROGRAM_FINISHED" && this.isMainCoordinator);
            },
            canDoAnything() {
                return (this.programEditionState !== "CAST_NOT_ENTERED"
                    && this.programEditionState !== "CAST_WAITING_FOR_NATIONAL_APPROVAL"
                    && this.programEditionState !== "CAST_WAITING_FOR_MAIN_APPROVAL");
            },
            editCast: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
@import "../../../assets/theme/mytheme/variables";
@import "../../assets/scss/dialogs.scss";

.monitoring-program-data-management {
    border: 1px solid var(--surface-border);
    margin-top: 1.5rem;
}
</style>
