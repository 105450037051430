import {createWebHistory, createRouter} from "vue-router";
import LayoutDashboard from "./views/layout/LayoutDashboard";
import LoginView from "./views/LoginView";
import RegisterView from "./views/RegisterView";
import UserListView from "./views/user/UserListView";
import UserFormView from "./views/user/UserFormView";
import {SystemRole} from "./utils/SystemRole";
import LayoutSimple from "./views/layout/LayoutSimple";
import ForbiddenView from "./views/ForbiddenView";
import NotFoundView from "./views/NotFoundView";
import ErrorView from "./views/ErrorView";
import PasswordRemindView from "./views/PasswordRemindView";
import ChangePasswordView from "./views/ChangePasswordView";
import LayoutPublic from "./views/layout/LayoutPublic";
import PublicView from "./views/public/PublicView";
import PositionDictView from "@/views/positionsTable/PositionDictView";
import PositionDetailsView from "@/views/positionsTable/PositionDetailsView";
import FieldDictView from "@/views/fieldTable/FieldDictView";
import FormMPSView from "./views/formMPS/FormMPSView";
import FormMCHView from "./views/formMCH/FormMCHView";
import FormMNGView from "./views/formMNG/FormMNGView";
import MonitoringProgramView from "./views/monitoringProgram/MonitoringProgramView";
import FieldDetails from "@/views/fieldTable/FieldDetails";
import EditFieldView from "@/views/fieldTable/EditFieldView";
import CreateFieldView from "@/views/fieldTable/CreateFieldView";
import CreatePositionView from "@/views/positionsTable/CreatePositionView";
import ProgramsView from "@/views/programs/ProgramsView";
import ProgramsListView from "@/views/programsList/ProgramsListView";
import RawDataView from "./views/rawData/RawDataView";
import DataAggregatesView from "./views/dataAggregates/DataAggregatesView";
import TransectDictView from "@/views/transectTable/TransectDictView.vue";
import CreateTransectView from "@/views/transectTable/CreateTransectView.vue";
import TransectDetailsView from "@/views/transectTable/TransectDetailsView.vue";
import ToCreateForm from "./views/monitoringProgram/components/controls/ToCreateForm.vue";
import SemiRawDataView from "@/views/semiRawData/SemiRawDataView.vue";
import FormMPPLView from "@/views/formMPPL/FormMPPLView.vue";
import FormMPMView from "@/views/formMPM/FormMPMView.vue";
import HabitatDataView from "@/views/habitatData/HabitatDataView.vue";
import FormMZPMView from "@/views/formMZPM/FormMZPMView.vue";
import FormMWOTView from "@/views/formMWOT/FormMWOTView.vue";
import FormMKRView from "@/views/formMKR/FormMKRView.vue";
import FormMGRView from "@/views/formMGR/FormMGRView.vue";
import FormMKOView from "@/views/formMKO/FormMKOView.vue";
import FormMSKRView from "@/views/formMSKR/FormMSKRView.vue";
import FormMRCView from "@/views/formMRC/FormMRCView.vue";
import FormMZPWView from "@/views/formMZPW/FormMZPWView.vue";
import FormMGZView from "@/views/formMGZ/FormMGZView.vue";
import FormMCZView from "@/views/formMCZ/FormMCZView.vue";
import FormMPPMView from "@/views/formMPPM/FormMPPMView.vue";
import FormTransectZeroView from "@/views/formTransectZero/FormTransectZeroView.vue";
import SendEmail from "@/views/email/SendEmail";
import EmailHistory from "@/views/email/EmailHistory";
import EnumDictView from "@/views/enumDict/EnumDictView.vue";
import AreaDictView from "@/views/areaDict/AreaDictView.vue";
import AreaDictDetails from "@/views/areaDict/AreaDictDetails.vue";
import MammalsDictView from "@/views/mammalsDict/MammalsDictView.vue";
import BirdsDictView from "@/views/birdsDict/BirdsDictView.vue";
import BirdsDetailsView from "@/views/birdsDict/BirdsDetailsView.vue";
import FormMWOPView from "@/views/formMWOP/FormMWOPView.vue";
import TrendsView from "@/views/trendsTable/TrendsView";
import IndicatorsView from "@/views/indicatorsTable/IndicatorsView";
import FormMPDView from "@/views/formMPD/FormMPDView.vue";
import PointDictView from "@/views/pointTable/PointDictView.vue";
import PointDetailsView from "@/views/pointTable/PointDetailsView.vue";
import FormMDUView from "@/views/formMDU/FormMDUView.vue";
import FormMLSView from "@/views/formMLS/FormMLSView.vue";
import FormMLSLView from "@/views/formMLSL/FormMLSLView.vue";
import FormMNZView from "@/views/formMNZ/FormMNZView.vue";
import TransZeroWorkSpace from "@/views/newFormTransectZeroDraft/TransZeroWorkSpace.vue";
import FormMPGView from "@/views/formMPG/FormMPGView.vue";
import FormMPWRView from "@/views/formMPWR/FormMPWRView.vue";
import FormMPOView from "@/views/formMPO/FormMPOView.vue";
import FormMZOView from "@/views/formMZO/FormMZOView.vue";
import FormMSLView from "@/views/formMSL/FormMSLView.vue";
import FormMMCView from "@/views/formMMC/FormMMCView.vue";
import FormMFGPView from "@/views/formMFGP/FormMFGPView.vue";
import FormMRDView from "@/views/formMRD/FormMRDView.vue";
import FormMCYView from "@/views/formMCY/FormMCYView.vue";

const routes = [
        {
            path: "/home",
            name: "home",
            component: LayoutDashboard,
            meta: {requiresAuth: true},
            children: [
                {
                    path: "/forbidden",
                    name: "forbidden",
                    component: ForbiddenView,
                },
                {
                    path: "/user",
                    children: [
                        {
                            path: "list",
                            name: "userList",
                            component: UserListView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                        {
                            path: "create",
                            name: "userCreate",
                            component: UserFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                        {
                            path: "edit/:id",
                            name: "userEdit",
                            component: UserFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                    ],
                },
                {
                    path: "/programs-list",
                    component: ProgramsView,
                    name: "programsList",
                },
                {
                    path: "/programs-list-draft",
                    component: ProgramsListView,
                    name: "programsListDraft",
                },
                {
                    path: "/form/MPS/:id",
                    component: FormMPSView,
                    name: "formMPS",
                },
                {
                    path: "/form/MCH/:id",
                    component: FormMCHView,
                    name: "formMCH",
                },
                {
                    path: "/form/MLS/:id",
                    component: FormMLSView,
                    name: "formMLS",
                },
                {
                    path: "/form/MLSL/:id",
                    component: FormMLSLView,
                    name: "formMLSL",
                },
                {
                    path: "/form/MNG/:id",
                    component: FormMNGView,
                    name: "formMNG",
                },
                {
                    path: "/form/MPD/:id",
                    component: FormMPDView,
                    name: "formMPD",
                },
                {
                    path: "/form/MPPL/:id",
                    component: FormMPPLView,
                    name: "formMPPL",
                },
                {
                    path: "/form/MPM/:id",
                    component: FormMPMView,
                    name: "formMPM",
                },
                {
                    path: "/form/MZPM/:id",
                    component: FormMZPMView,
                    name: "formMZPM",
                },
                {
                    path: "/form/MWOT/:id",
                    component: FormMWOTView,
                    name: "formMWOT",
                },
                {
                    path: "/form/MWOP/:id",
                    component: FormMWOPView,
                    name: "formMWOP",
                },
                {
                    path: "/form/MOP/:id",
                    component: FormMGRView,
                    name: "formMOP",
                },
                {
                    path: "/form/MPB/:id",
                    component: FormMGRView,
                    name: "formMPB",
                },
                {
                    path: "/form/MRY/:id",
                    component: FormMGRView,
                    name: "formMRY",
                },
                {
                    path: "/form/MOG/:id",
                    component: FormMGRView,
                    name: "formMOG",
                },
                {
                    path: "/form/MKR/:id",
                    component: FormMKRView,
                    name: "formMKR",
                },
                {
                    path: "/form/MKO/:id",
                    component: FormMKOView,
                    name: "formMKO",
                },
                {
                    path: "/form/MSKR/:id",
                    component: FormMSKRView,
                    name: "formMSKR",
                },
                {
                    path: "/form/MRC/:id",
                    component: FormMRCView,
                    name: "formMRC",
                },
                {
                    path: "/form/MZPW/:id",
                    component: FormMZPWView,
                    name: "formMZPW",
                },
                {
                    path: "/form/MGZ/:id",
                    component: FormMGZView,
                    name: "formMGZ",
                },
                {
                    path: "/form/MPPM/:id",
                    component: FormMPPMView,
                    name: "formMPPM",
                },
                {
                    path: "/form/MCZ/:id",
                    component: FormMCZView,
                    name: "formMCZ",
                },
                {
                    path: "/form/MDU/:id",
                    component: FormMDUView,
                    name: "formMDU",
                },
                {
                    path: "/form/MMC/:id",
                    component: FormMMCView,
                    name: "formMMC",
                },
                {
                    path: "/form/MNZ/:id",
                    component: FormMNZView,
                    name: "formMNZ",
                },
                {
                    path: "/form/MPG/:id",
                    component: FormMPGView,
                    name: "formMPG",
                },
                {
                    path: "/form/MPO/:id",
                    component: FormMPOView,
                    name: "formMPO",
                },
                {
                    path: "/form/MPWR/:id",
                    component: FormMPWRView,
                    name: "formMPWR",
                },
                {
                    path: "/form/MZO/:id",
                    component: FormMZOView,
                    name: "formMZO",
                },
                {
                    path: "/form/MSL/:id",
                    component: FormMSLView,
                    name: "formMSL",
                },
                {
                    path: "/form/MFGP/:id",
                    component: FormMFGPView,
                    name: "formMFGP",
                },
                {
                    path: "/form/MRD/:id",
                    component: FormMRDView,
                    name: "formMRD",
                },
                {
                    path: "/form/MCY/:id",
                    component: FormMCYView,
                    name: "formMCY",
                },
                {
                    path: "/form-zero/transect/:id",
                    component: FormTransectZeroView,
                    name: "formTransectZero",
                },
                {
                    path: "/workspace-form-zero/transect/:id",
                    component: TransZeroWorkSpace,
                    name: "transeZeroWorkSpace",
                },
                {
                    path: "/monitoring-program/:id",
                    component: MonitoringProgramView,
                    name: "monitoringProgram",
                },
                {
                    path: "/createForm",
                    component: ToCreateForm,
                    name: "toCreateForm",
                },
                {
                    path: "/position",
                    component: PositionDictView,
                    name: "positionDictView",
                },
                {
                    path: "/position/:id",
                    component: PositionDetailsView,
                    name: "positionDetails",
                },
                {
                    path: "/create-position",
                    component: CreatePositionView,
                    name: "createPosition",
                },
                {
                    path: "/field",
                    name: "fieldDictTable",
                    component: FieldDictView,
                },
                {
                    path: "/field/:id",
                    name: "FieldDetails",
                    component: FieldDetails,
                },
                {
                    path: "/edit-field/:id",
                    name: "EditFieldView",
                    component: EditFieldView,
                },
                {
                    path: "/create-field",
                    component: CreateFieldView,
                    name: "CreateFieldView",
                },
                {
                    path: "/point",
                    component: PointDictView,
                    name: "pointDictView",
                },
                {
                    path: "/point/create",
                    component: PointDetailsView,
                    name: "createPoint",
                },
                {
                    path: "/point/:id",
                    component: PointDetailsView,
                    name: "PointDetailsView",
                },
                {
                    path: "/transect",
                    component: TransectDictView,
                    name: "transectTable",
                },
                {
                    path: "/create-transect",
                    component: CreateTransectView,
                    name: "createTransectView",
                },
                {
                    path: "/transect/:id",
                    component: TransectDetailsView,
                    name: "TransectDetailsView",
                },
                {
                    path: "/raw-data/:programCode",
                    name: "RawDataView",
                    component: RawDataView,
                },
                {
                    path: "/semi-raw-data",
                    name: "SemiRawDataView",
                    component: SemiRawDataView,
                },
                {
                    path: "/data-aggregates",
                    name: "DataAggregatesView",
                    component: DataAggregatesView,
                },
                {
                    path: "/habitat-data/:programCode",
                    name: "HabitatDataView",
                    component: HabitatDataView,
                },
                {
                    path: "/send-email",
                    name: "SendEmail",
                    component: SendEmail,
                },
                {
                    path: "/email-history",
                    name: "EmailHistory",
                    component: EmailHistory,
                },
                {
                    path: "/trends",
                    name: "TrendsView",
                    component: TrendsView,
                },
                {
                    path: "/indicators",
                    name: "IndicatorsView",
                    component: IndicatorsView,
                },
                {
                    path: "/dicts/:dict",
                    name: "EnumDictView",
                    component: EnumDictView,
                },
                {
                    path: "/areaDicts/:dict",
                    name: "AreaDictView",
                    component: AreaDictView,
                },
                {
                    path: "/areaDicts/:dict/:id",
                    name: "AreaDictDetails",
                    component: AreaDictDetails,
                },
                {
                    path: "/dicts/mammals",
                    name: "MammalsDictView",
                    component: MammalsDictView,
                },
                {
                    path: "/dicts/birds",
                    name: "BirdsDictView",
                    component: BirdsDictView,
                },
                {
                    path: "/bird-dict/:id",
                    name: "BirdsDetailsView",
                    component: BirdsDetailsView,
                },
            ],
        },
        {
            component: LayoutPublic,
            path: "/",
            children: [
                {
                    path: "/",
                    name: "public",
                    component: PublicView,
                },
            ],
        },
        {
            name: "layoutSimple",
            component: LayoutSimple,
            path: "/",
            children: [
                {
                    path: "/login",
                    name: "login",
                    component: LoginView,
                },
                {
                    path: "/register",
                    name: "register",
                    component: RegisterView,
                },
                {
                    path: "/remind",
                    name: "remindPassword",
                    component: PasswordRemindView,
                },
                {
                    path: "/change-password",
                    name: "changePassword",
                    component: ChangePasswordView,
                },
                // {
                //     path: "/forbidden",
                //     name: "forbidden",
                //     component: ForbiddenView,
                // },
                {
                    path: "/error",
                    name: "error",
                    component: ErrorView,
                },
                {
                    path: "/:pathMatch(.*)*",
                    name: "notFound",
                    component: NotFoundView,
                },
            ],
        },
    ];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
