<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :validationCorrect="validationCorrect">
        <template v-slot:formMap>
            <CustomInfoBox text="UWAGA! Aby wprowadzić obserwacje na innym stanowisku powierzchniowym wróć do strony głównej i wejdź do odpowiedniego formularza."
                           class="mx-3" />
            <div class="form-map mt-3 mb-5">
                <MapSingleMarker mapId="mainMap" :field="form.field.superField.shape" :subfield="form.field"
                                 :otherSubfields="form.field.otherSubfields" :isOn="false" subfieldPopup>
                    <template v-slot:subfieldPopup="slotProps">
                        <div class="marker-popup-header">
                            {{ slotProps.subfield.name }}
                        </div>
                    </template>
                </MapSingleMarker>
            </div>
        </template>
        <template v-slot:formObservations>
            <FormMPOObservations v-model:form="form" ref="observationsView" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import {
        getForm,
        getList as getDict,
    } from "@/swagger/vue-api-client";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import FormMPOObservations from "@/views/formMPO/components/FormMPOObservations.vue";
    import CustomInfoBox from "@/components/form/CustomInfoBox.vue";

    export default {
        name: "FormMPOView",

        components: {
            FormMPOObservations,
            MapSingleMarker,
            FormViewTemplate,
            CustomInfoBox,
        },

        data() {
            return {
                programCode: "MPO",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            // this.getFormData();
            this.dicts = {
                sex: [
                    {code: "P", label: "P - para"},
                    {code: "F", label: "F - samica"},
                    {code: "M", label: "M - samiec"},
                    {code: "N", label: "N - nieoznaczony"},
                ],
                nestingCriteria: [
                    {code: "O", label: "O – jednorazowa obserwacja pojedynczego ptaka (samca lub nieoznaczonego) w siedlisku lęgowym"},
                    {code: "OF", label: "OF - obserwacja samicy w siedlisku lęgowym"},
                    {code: "PR", label: "PR - para ptaków obserwowana w siedlisku lęgowym"},
                    {code: "KT", label: "KT - tokująca lub kopulująca para"},
                    {code: "NP", label: "NP - zaniepokojenie jednego ptaka lub pary wskazujące na obecność lęgu"},
                    {code: "BU", label: "BU - budowa gniazda"},
                    {code: "GNS", label: "GNS - świeże gniazdo"},
                    {code: "WYS", label: "WYS - gniazdo wysiadywane"},
                    {code: "JAJ", label: "JAJ - gniazdo z jajami lub skorupy jaj w gnieździe"},
                    {code: "PIS", label: "PIS - gniazdo z pisklętami"},
                    {code: "MŁO", label: "MŁO - młode poza gniazdem, nielotne lub słabo lotne"},
                ],
                habitatType: [
                    {code: "OC", label: "OC - oczko (do 1ha)"},
                    {code: "JZ", label: "JZ - jezioro"},
                    {code: "SE", label: "SE - starorzecze"},
                    {code: "RZ", label: "RZ - rzeka"},
                    {code: "ZZ", label: "ZZ - zbiornik zaporowy"},
                    {code: "ST", label: "ST - staw"},
                    {code: "RL", label: "RL - rozlewisko"},
                    {code: "GT", label: "GT - glinianka, torfianka"},
                    {code: "ŻW", label: "ŻW - żwirownia"},
                    {code: "IN", label: "IN - inne"},
                ],
                treeType: [
                    {code: "TRZ", label: "Szuwar trzcinowy"},
                    {code: "PAL", label: "Szuwar pałkowy"},
                    {code: "PLO", label: "Pło torfowe"},
                    {code: "TUR", label: "Turzycowisko"},
                    {code: "SKR", label: "Skrzypy"},
                    {code: "LOZ", label: "Łozy"},
                    {code: "SIT", label: "Sity"},
                    {code: "INNA", label: "Inna"},
                ],
                controlNotRealizedReason: [
                    {
                        key: "AREA_NA_HUMAN",
                        code: "AREA_NA_HUMAN",
                        label: "Obszar kontroli niedostępny z powodu działań człowieka (np. prace leśne, zamknięcie z powodu ptasiej grypy, niekorzystne uprawy, teren prywatny itd.)",
                    },
                    {
                        key: "AREA_NA_NATURAL",
                        code: "AREA_NA_NATURAL",
                        label: "Obszar kontroli niedostępny z powodów naturalnych (wysoki stan wody, powodzie itd.)",
                    },
                    {
                        key: "OBSERVER_ABANDON",
                        code: "OBSERVER_ABANDON",
                        label: "Zaniechanie obserwatora",
                    },
                    {
                        key: "METHODICAL_REASON",
                        code: "METHODICAL_REASON",
                        label: "Powody metodyczne (dotyczy to programów, w których liczba kontroli nie jest stała)",
                    },
                    {
                        key: "RESULT_NOT_ACCEPTED",
                        code: "RESULT_NOT_ACCEPTED",
                        label: "Brak akceptacji wyników ze strony koordynatora",
                    },
                ],
            };
            this.form = {
                programEditionId: 123,
                programName: "MPO",
                programFullName: "Monitoring Podgorzałki",
                monitoringYear: 2024,
                editable: true,
                state: "DRAFT",
                observer: "Jan Kowalski",
                id: 1,
                field: {
                    fieldType: "FIELD",
                    id: 5538,
                    name: "MPO01",
                    program: "MPO",
                    shape: [{longitude: 16.064941898345175, latitude: 50.97094848309419}, {longitude: 16.0655084347655, latitude: 50.961965041321406}, {longitude: 16.079733704269792, latitude: 50.962321863362696}, {longitude: 16.07916990465175, latitude: 50.97130541878268}, {longitude: 16.064941898345175, latitude: 50.97094848309419}],
                    superField: {
                        fieldType: "FIELD",
                        id: 5539,
                        name: "MPO-P",
                        program: "MPO",
                        shape: [{longitude: 16.06437507127035, latitude: 50.97993190642257}, {longitude: 16.0655084347655, latitude: 50.961965041321406}, {longitude: 16.09395926283533, latitude: 50.96267695470999}, {longitude: 16.092836849735942, latitude: 50.98064427338799}, {longitude: 16.06437507127035, latitude: 50.97993190642257}],
                        superField: null,
                    },
                    otherSubfields: [
                        {
                            fieldType: "FIELD",
                            id: 5540,
                            name: "MPO-R",
                            program: "MPO",
                            shape: [{longitude: 16.078605960503, latitude: 50.980288089905}, {longitude: 16.07916990465175, latitude: 50.97130541878268}, {longitude: 16.093398056286, latitude: 50.971660614049}, {longitude: 16.092836849735942, latitude: 50.98064427338799}, {longitude: 16.078605960503, latitude: 50.980288089905}],
                        },
                        {
                            fieldType: "FIELD",
                            id: 5540,
                            name: "MPO-S",
                            program: "MPO",
                            shape: [{longitude: 16.06437507127035, latitude: 50.97993190642257}, {longitude: 16.064658484808, latitude: 50.975440194758}, {longitude: 16.071772488, latitude: 50.9756186626}, {longitude: 16.071490515887, latitude: 50.980109998164}, {longitude: 16.06437507127035, latitude: 50.97993190642257}],
                        },
                    ],
                },
                control: {
                    label: "Kontrola",
                    controlStart: "2024-05-20",
                    controlEnd: "2024-07-20",
                },
                noFormControl: false,
                noFormControlReason: null,
                noFormControlNotes: null,
                control1: {
                    noControl: false,
                    noControlReason: null,
                    noControlNotes: null,
                    controlDate: "2024-05-20",
                    startTime: "6:00",
                    endTime: "8:30",
                    noWater: false,
                    noBirds: false,
                    birds: [
                        {
                            pairs: 3,
                            sex: {code: "P", label: "P - para"},
                            nestingCriteria: {code: "OF", label: "OF - jednorazowa obserwacja pojedynczej samicy w siedlisku lęgowym"},
                            young: 5,
                            additionalInfo: "lorem ipsum dolor sit amet",
                        },
                        {
                            pairs: 3,
                            sex: {code: "P", label: "P - para"},
                            nestingCriteria: {code: "WYS", label: "WYS - gniazdo wysiadywane"},
                            young: 5,
                            additionalInfo: "lorem ipsum dolor sit amet",
                        },
                        {
                            pairs: 3,
                            sex: {code: "F", label: "F - samica"},
                            nestingCriteria: {code: "WYS", label: "WYS - gniazdo wysiadywane"},
                            young: 5,
                            additionalInfo: "lorem ipsum dolor sit amet",
                        },
                    ],
                },
                control2: {
                    noControl: false,
                    noControlReason: null,
                    noControlNotes: null,
                    controlDate: "2024-06-25",
                    startTime: "6:00",
                    endTime: null,
                    noWater: true,
                    noBirds: false,
                    birds: [],
                },
                habitatData: null,
                habitatDataToChange: null,
            };
            console.log(this.form);
            console.log(this.dicts);
            this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
            this.loaded = true;
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loaded = false;
                    });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "positions", true);
                if (!this.form.noFormControl) {
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        if (this.form.positions[i].controls != null) {
                            for (let j = 0; j < this.form.positions[i].controls.length; j += 1) {
                                ValidateUtils.formatControlDateStartTimeAndEndTime(this.form.positions[i].controls[j]);
                            }
                        }
                        request.positions.push(this.form.positions[i]);
                    }
                }
                return request;
            },
            addErrorToast(detailMessage) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            validateData() {
            //     let isCorrect = ValidateUtils.validateNoFormControl(this.form);
            //     if (isCorrect && !this.form.noFormControl) {
            //         isCorrect = this.validateFirstStep() && isCorrect;
            //         for (let i = 0; i < this.form.positions.length; i += 1) {
            //             const position = this.form.positions[i];
            //             if (position.notAudited === false || position.notAudited === null
            //                 || position.notAudited === undefined) {
            //                 if (position.noNest !== true) {
            //                     isCorrect = ValidateUtils.validateNotEmpty(position, ["nestType", "treeType", "latitude", "longitude"]) && isCorrect;
            //                 }
            //                 isCorrect = this.validateControls(i) && isCorrect;
            //             }
            //         }
            //     }
            //     this.validationCorrect = isCorrect;
            //     this.request = this.getFormRequest();
            },
        },
    };
</script>

<style lang="scss">
</style>
