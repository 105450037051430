<template>
    <Dialog header="Określ współrzędne odcinka" :draggable="false" v-model:visible="internalVisible" :closable="true">
        <div class="flex flex-column my-5 px-4">
            <div class="flex justify-content-between mt-5 mb-4 gap-8">
                <div>
                    <div v-if="showYear">
                        <CustomInputNumber id="year" v-model="internalTransect.year"
                                           label="Rok" type="number" name="year"
                                           :required="showYear" :showErrors="showErrorMessages" />
                    </div>
                    <div class="mb-4">Kolejne punkty wyznaczające odcinek:</div>
                    <div v-for="(line,index) in editableLineArray" :key="index"
                         class="flex gap-3 align-items-center">
                        <div class="mb-3">{{index + 1}}.</div>
                        <CustomInputNumber label="Koordynaty N" v-model="line.latitude"
                                           @update:modelValue="(v) => updateTransect(v,index,'latitude')"
                                           :maxDigits="6" name="latitude" :showLabel="true"
                                           :showErrors="showErrorMessages" required />
                        <CustomInputNumber label="Koordynaty E" v-model="line.longitude"
                                           @update:modelValue="(v) => updateTransect(v,index,'longitude')"
                                           :maxDigits="6" name="longitude" :showLabel="true"
                                           :showErrors="showErrorMessages" required />
                        <div class="flex flex-column mb-3 pr-0 align-items-center">
                            <CustomIconButton icon="pi pi-chevron-up" small @click="moveBefore(index)" />
                            <CustomIconButton icon="pi pi-chevron-down" small @click="moveAfter(index)" />
                        </div>
                        <div class="flex flex-column mb-3 pl-0 align-items-center">
                            <CustomIconButton icon="pi pi-times" small @click="remove(index)" />
                        </div>
                    </div>
                    <div>
                        <CustomButton label="Dodaj punkt" class="justify-content-center" bgColor="#33795B"
                                      color="white" @click="add()" />
                    </div>
                    <div v-if="showErrorMessages && editableLineArray.length < 2" class="mt-3 error-info">
                        Transekt musi być wyznaczony przez co najmniej dwa punkty
                    </div>
                </div>
                <div style="height: 400px; width: 600px;">
                    <MapSingleTransect :mapId="'editTransectMap' + mapIdIndex" v-model="internalTransect"
                                       height="400px" :field="field" :deleteCircle="deleteCircle" />
                </div>
            </div>
            <div class="flex justify-content-end">
                <CustomButton label="Zatwierdź" class="justify-content-center"
                              bgColor="var(--secondary-color)" color="var(--surface-a)" @click="save" />
            </div>
        </div>
    </Dialog>
</template>

<script>
    import _ from "lodash";
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomIconButton from "@/components/form/CustomIconButton.vue";
    import MapSingleTransect from "@/components/map/MapSingleTransect.vue";

    export default {
        name: "EditTransectDialog",

        components: {
            MapSingleTransect,
            CustomIconButton,
            CustomInputNumber,
            CustomButton,
            Dialog,
        },

        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            field: {
                type: Array,
                default: () => [],
            },
            transect: {
                type: Object,
            },
            mapIdIndex: {
                type: Number,
            },
            showYear: {
                type: Boolean,
                default: false,
            },
            isNewHistoryTransect: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                year: null,
                showErrorMessages: false,
                editableLineArray: [],
                polandCenter: {latitude: 52.65221, longitude: 19.252482},
                newTransect: {
                    line: [],
                },
                deleteCircle: null,
            };
        },

        emits: ["update:visible", "update:transect", "save"],

        beforeMount() {
            // map needs non-nullable coordinates, but input number fields may be empty during editing;
            // so to prevent map crash it works using internalTransect and input number fields
            // work on editableLineArray;
            // obviously both lists should update the second one unless null appeared

            this.internalTransect.line.forEach((l) => {
                this.editableLineArray.push({...l});
            });
            if (this.isNewHistoryTransect) {
                this.editableLineArray.push({
                    latitude: null,
                    longitude: null,
                });
            }
        },

        methods: {
            moveBefore(index) {
                if (index > 0) {
                    const segment = this.internalTransect.line[index];
                    this.internalTransect.line.splice(index, 1);
                    this.internalTransect.line.splice(index - 1, 0, segment);
                }
            },
            moveAfter(index) {
                if (index < this.internalTransect.line.length - 1) {
                    const segment = this.internalTransect.line[index];
                    this.internalTransect.line.splice(index, 1);
                    this.internalTransect.line.splice(index + 1, 0, segment);
                }
            },
            remove(index) {
                this.deleteCircle = [this.internalTransect.line[index], index];
                this.editableLineArray.splice(index, 1);
                this.internalTransect.line.splice(index, 1);
            },
            add() {
                if (this.internalTransect.line.length > 0
                    && this.internalTransect.line[0].latitude != null
                    && this.internalTransect.line[0].longitude != null) {
                    const lastPoint = _.cloneDeep(this.internalTransect.line[this.editableLineArray.length - 1]);
                    lastPoint.latitude += 0.001;
                    lastPoint.longitude += 0.001;
                    this.internalTransect.line.push(lastPoint);
                } else if (this.field.length > 0) {
                    this.internalTransect.line = [];
                    const pointFromField = this.field[0];
                    this.internalTransect.line.push(_.cloneDeep(pointFromField));
                } else {
                    this.internalTransect.line = [];
                    this.internalTransect.line.push(this.polandCenter);
                }
            },
            updateTransect(value, index, type) {
                if (this.internalTransect.line[index] == null) {
                    this.internalTransect.line[index] = {longitude: null, latitude: null};
                }
                if (value != null && value !== "" && value !== 0) {
                    if (type === "longitude") {
                        this.internalTransect.line[index].longitude = value;
                    }
                    if (type === "latitude") {
                        this.internalTransect.line[index].latitude = value;
                    }
                }
            },
            save() {
                if (this.validate()) {
                    this.$emit("save", this.internalTransect);
                    this.showErrorMessages = false;
                } else {
                    this.showErrorMessages = true;
                }
            },
            validate() {
                let isCorrect = true;
                this.editableLineArray.forEach((l) => {
                    if (l.latitude === null || l.longitude === null) isCorrect = false;
                });
                if (this.showYear && this.internalTransect.year === null) isCorrect = false;
                if (this.isNewHistoryTransect && this.editableLineArray.length < 2) isCorrect = false;
                return isCorrect;
            },
        },

        watch: {
            internalTransect: {
                handler(value) {
                    value.line.forEach((l, i) => {
                        if (this.editableLineArray.length - 1 < i) {
                            this.editableLineArray.push({...l});
                        } else {
                            const editableElem = this.editableLineArray[i];
                            if (l.latitude !== editableElem.latitude) editableElem.latitude = l.latitude;
                            if (l.longitude !== editableElem.longitude) editableElem.longitude = l.longitude;
                        }
                    });
                    this.$emit("update:transect", value);
                },
                deep: true,
            },
        },

        computed: {
            internalVisible: {
                get() { return this.visible; },
                set(value) { this.$emit("update:visible", value); },
            },
            internalTransect: {
                get() { return !this.isNewHistoryTransect ? this.transect : this.newTransect; },
                set(value) { this.$emit("update:transect", value); },
            },
        },
    };
</script>
<style lang="scss">
@import "../../assets/theme/mytheme/variables";
@import "../../views/assets/scss/dialogs";
.error-info {
    color: var(--red);
}
</style>
