<template>
    <Dialog header="Importuj" v-model:visible="visible" class="import-cast-dialog" :closable="false" modal
            :draggable="false">
        <template #header>
            <CustomFileUpload @importFile="importFile" :multiple="false" accept=".xlsx, .xls"
                              class="import-cast-file-upload" />
        </template>
        <div class="dialog-file-upload-buttons import-cast-dialog-buttons w-100 h-100">
            <CustomButton label="Anuluj" class="flex justify-content-center red-button" @click="visible = false"
                          bgColor="transparent" />
            <CustomButton label="Importuj plik" class="green-button" @click="importField"
                          :disabled="waitForServer" />
        </div>
    </Dialog>
</template>

<script>
    import axios from "axios";
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomFileUpload from "@/components/CustomFileUpload.vue";

    export default {
        name: "ImporTransectDialog",

        components: {
            CustomFileUpload,
            CustomButton,
            Dialog,
        },

        props: {
            modelValue: null,
        },

        data() {
            return {
                importTransectFile: null,
                waitForServer: false,
            };
        },

        methods: {
            importFile(value) {
                this.importTransectFile = value;
            },

            importField() {
                this.waitForServer = true;

                axios.post(
                    `${process.env.VUE_APP_API_URL}/field/import`,
                    this.importTransectFile,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        this.waitForServer = false;
                        this.visible = false;
                        this.$toast.add({
                            severity: "success",
                            summary: "Zaimportowano pomyślnie",
                            life: 3000,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.waitForServer = false;
                        this.visible = false;
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            life: 3000,
                        });
                    });
            },
        },

        computed: {
            visible: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
    @import "../../assets/theme/mytheme/variables";
    @import "../assets/scss/dialogs.scss";
</style>
