<template>
    <div class="border-1 surface-border">
        <HeaderComponent headerText="Informacje" class="m-0" />
        <div class="flex gap-8 p-5">
            <div style="width: 400px;">
                <MapMultiTransectsFormZero mapId="transectsMap" height="400px" :field="field" popup
                                           :transects="coloredTransects" @onClick="onClick" @onHover="onHover"
                                           v-model:transect="transect.transect" :isOn="false">
                    <template v-slot:popup="slotProps">
                        <div class="marker-popup-header">
                            {{ slotProps.transect.name }}
                        </div>
                    </template>
                </MapMultiTransectsFormZero>
            </div>
            <VeeForm @submit="submitForm" v-slot="{ errors }">
                <ul class="list-none p-0 m-0">
                    <li class="my-2 flex align-items-center gap-2 h-3rem">
                        <span class="font-semibold w-9rem text-right">Nazwa:</span>
                        <template v-if="!isEditTransectActive">{{ transect.transect.name }}</template>
                        <CustomInputText v-if="isEditTransectActive" name="name" label=""
                                         v-model="tmpTransect.transect.name"
                                         rules="required" class="mb-0" />
                    </li>
                    <li class="my-2 flex align-items-center gap-2 h-3rem">
                        <span class="font-semibold w-9rem text-right">Numer kolejności:</span>
                        <template v-if="!isEditTransectActive">{{ transect.transect.order }}</template>
                        <CustomInputNumber v-if="isEditTransectActive" name="order" label=""
                                           v-model="tmpTransect.transect.order"
                                           rules="required" class="mb-0" />
                    </li>
                    <li class="my-2 flex align-items-center gap-2 h-3rem" v-if="transect.transect.field">
                        <span class="font-semibold w-9rem text-right">Program:</span>
                        {{ transect.transect.program }}
                    </li>
                    <li class="my-2 flex align-items-center gap-2 h-3rem">
                        <span class="font-semibold w-9rem text-right">Powierzchnia:</span>
                        <template v-if="!isEditTransectActive">
                            <router-link :to="{name: 'FieldDetails',params: {id: transect.transect.field.id}}"
                                         style="color: var(--text-color);">
                                {{ transect.transect.field.name }}
                            </router-link>
                        </template>
                        <template v-else>
                            <CustomSelectOne v-if="fields?.length > 1 && isEditTransectActive" id="field"
                                             v-model="tmpTransect.transect.field.id" :items="fields" name="field"
                                             label="" item-value="id" item-label="name" class="mb-0" rules="required"/>
                            <template v-else>{{ transect.transect.field.name }}</template>
                        </template>
                    </li>
                </ul>
                <div class="flex gap-3 mt-3">
                    <div v-if="!isEditTransectActive" class="w-9rem flex justify-content-end">
                        <CustomButton label="Edytuj" icon="pi pi-pencil" class="mt-0" v-if="!isEditTransectActive"
                                      @click="editTransectDetails" />
                    </div>
                    <template v-if="isEditTransectActive">
                        <CustomButton label="Anuluj edycję" icon="pi pi-times" @click="isEditTransectActive = false" />
                        <CustomButton label="Zapisz" bgColor="var(--secondary-color)" color="var(--surface-a)"
                                      @click="submitForm" :disabled="isDisabled(errors)" />
                    </template>
                </div>
            </VeeForm>
        </div>
    </div>
</template>

<script>
    import {Form as VeeForm} from "vee-validate";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import {
        updateTransect,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import MapMultiTransectsFormZero from "@/components/map/MapMultiTransectsFormZero.vue";

    export default {
        name: "TransectMainInfo",

        components: {
            MapMultiTransectsFormZero,
            CustomButton,
            HeaderComponent,
            CustomInputText,
            CustomInputNumber,
            CustomSelectOne,
            VeeForm,
        },

        props: {
            modelValue: {
                type: Object,
            },
            field: {
                type: Array,
                default: () => [],
            },
            fields: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                isEditTransectActive: false,
                tmpTransect: {},
                color: "grey",
                colorClickedOrHover: "red",
                coloredTransects: [],
                hoverIndex: null,
                activeIndex: null,
            };
        },
        beforeMount() {
            this.makeColoredTransects();
        },
        mounted() {
            if (this.$route.query.isEditMode === "true") {
                this.editTransectDetails();
            }
        },

        emits: ["getTransect", "update:modelValue"],

        methods: {
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
            submitForm() {
                const transectId = this.$route.params.id;
                const transectRequest = {
                    name: this.tmpTransect.transect.name,
                    order: this.tmpTransect.transect.order,
                    fieldId: this.tmpTransect.transect.field.id,
                };
                updateTransect({
                    id: transectId,
                    body: transectRequest,
                }).then((response) => {
                    if (response.status === 200) {
                        this.isEditTransectActive = false;
                        this.$emit("getTransect");
                    }
                });
            },
            makeColoredTransects() {
                this.coloredTransects = [];
                this.transect.otherTransects.forEach((t) => {
                    this.coloredTransects.push({
                        ...t,
                        color: this.color,
                        openPopup: false,
                        zIndexOffset: 1,
                    });
                });
                this.coloredTransects.push({
                    ...this.transect.transect,
                    color: this.colorClickedOrHover,
                    openPopup: true,
                    zIndexOffset: 1000,
                });
                this.activeIndex = this.coloredTransects.length - 1;
            },
            onClick(index) {
                this.$router.push(`/transect/${this.coloredTransects[index].id}`);
            },
            onHover(index) {
                if (index !== this.hoverIndex && index !== this.activeIndex) {
                    if (this.hoverIndex != null) {
                        this.coloredTransects[this.hoverIndex].color = this.color;
                        this.coloredTransects[this.hoverIndex].openPopup = false;
                        this.coloredTransects[this.hoverIndex].zIndexOffset = 1;
                    }
                    if (index != null) {
                        this.coloredTransects[index].color = this.colorClickedOrHover;
                        this.coloredTransects[index].openPopup = true;
                        this.coloredTransects[index].zIndexOffset = 1000;
                    }
                    this.hoverIndex = index;
                }
            },
            editTransectDetails() {
                this.isEditTransectActive = true;
                this.tmpTransect = { ...this.transect };
            },
        },
        computed: {
            transect: {
                get() { console.log(this.modelValue); return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style scoped lang="scss"></style>
