<template>
    <div>
        <TransectControlDate v-model:form="internalForm" class="border-bottom-1 border-200 pb-3 mb-3"
                             :numberOfTransects="numberOfTransects"/>
        <FormWeather v-model:clouds="internalForm.clouds" v-model:wind="internalForm.wind"
                     v-model:rain="internalForm.rain" v-model:visibility="internalForm.visibility"
                     class="border-bottom-1 border-200 pb-5 mb-4" :required="required"/>
        <CustomCheckbox v-if="showCountMammals" label="Liczono ssaki" v-model="internalForm.mammals"
                        :disabled="!editing" name="mammals" />
        <CustomCheckbox v-if="showCountSex" label="Notowano płeć" v-model="internalForm.sex"
                        :disabled="!editing" name="sex" />
    </div>
</template>

<script>
    import FormWeather from "@/components/formSharedComponents/FormWeather.vue";
    import TransectControlDate from "@/components/transectForm/TransectControlDate.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        name: "TransectFormMainData",

        components: {
            CustomCheckbox,
            TransectControlDate,
            FormWeather,
        },

        props: {
            form: {
                type: Object,
            },
            showCountMammals: {
                type: Boolean,
                default: false,
            },
            showCountSex: {
                type: Boolean,
                default: false,
            },
            numberOfTransects: {
                type: Number,
                default: 1,
            },
            required: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing"],

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
