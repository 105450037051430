<template>
    <div :id="mapId" :style="height ? `height: ${this.height}` : ''" />
</template>

<script>
    import L from "leaflet";
    import {MapMixin} from "@/mixins/MapMixin";

    export default {
        name: "MapArea",

        mixins: [MapMixin],

        props: {
            mapId: {
                type: String,
                default: "map",
            },
            height: {
                type: String,
                default: null,
            },
            isOn: {
                type: Boolean,
                default: true,
            },
            zoomControls: {
                type: Boolean,
                default: true,
            },
            areaField: null,
        },

        data() {
            return {
                polandBoundaries: [
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                ],
                fieldOnMap: [],
            };
        },

        mounted() {
            this.initCustomIdMap(this.mapId);
            this.addZoomControls();
            if (this.areaField !== null && this.areaField.length > 0) {
                this.makeField();
            } else {
                this.zoomToPoland();
            }
        },

        methods: {
            addZoomControls() {
                if (this.zoomControls) {
                    const zoom = L.control.zoom({
                        position: "bottomright",
                    });
                    zoom.addTo(this.map);
                }
            },
            zoomToPoland() {
                for (let i = 0; i < this.polandBoundaries.length; i += 1) {
                    this.fieldOnMap.push([this.polandBoundaries[i].latitude, this.polandBoundaries[i].longitude]);
                }
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 1);
            },
            makeField() {
                const field = [];
                this.areaField.forEach((aF) => {
                    const fieldAndHoles = [];
                    for (let i = 0; i < aF.length; i += 1) {
                        fieldAndHoles.push([aF[i].latitude, aF[i].longitude]);
                    }
                    field.push(fieldAndHoles);
                });
                const fieldShape = L.polygon(field);
                fieldShape.setStyle({fillColor: "transparent", color: "#33795B"});
                fieldShape.addTo(this.map);
                this.map.fitBounds(fieldShape.getBounds());
                this.map.setMinZoom(this.map.getZoom() - 6);
            },
        },
    };
</script>
