<template>
    <div class="flex gap-8">
        <div class="position-map w-4" v-if="field.shape">
            <MapSingleMarker mapId="positionDictMap" height="600px" :isOn="false" :hideNamePopups="true"
                             :field="field.shape" :markerPopup="false"/>
        </div>
        <div class="field-details">
            <h2>Szczegóły powierzchni</h2>
            <ul class="list-none">
                <li><b>Program:</b> {{ field.program }}</li>
                <li><b>Nazwa:</b> {{ field.name }}</li>
                <li><b>Rodzaj powierzchni:</b> {{ field.fieldType }}</li>
                <li v-if="field.program === 'MZPW'"><b>MZPWP: </b>{{ field.includeInMZPWP ? "Tak" : "Nie" }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {getFieldById} from "@/swagger/vue-api-client";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";

    export default {

        name: "FieldDetails",

        components: {
            MapSingleMarker,
        },

        data() {
            return {
                field: "",
            };
        },

        beforeMount() {
            const fieldId = this.$route.params.id;
            this.loadFieldById(fieldId);
        },

        methods: {
            async loadFieldById(fieldId) {
                getFieldById({id: fieldId})
                    .then((response) => {
                        this.field = response.data;
                    });
            },
        },
    };

</script>

<style scoped lang="scss">
</style>
