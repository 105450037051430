<template>
    <div class="data-aggregates-table">
        <DataTable :lazy="true" :value="dataAggregates"
                   dataKey="id" :paginator="!useGivenData" v-model:rows="searchCriteria.page.limit"
                   v-model:first="searchCriteria.page.offset"
                   :totalRecords="useGivenData ? dataAggregates.length : totalRecords" :search-criteria="searchCriteria"
                   @page="onPage" @sort="onPage" removableSort size="small"
                   :rows="10" :rowsPerPageOptions="[5,10,20,50]" :loading="loading"
                   v-model:editingRows="editingRows" editMode="row" @row-edit-save="onRowEditSave"
                   scrollable>
            <!--<Column v-if="!monitoringProgram"
             header="programEditionId" :sortable="sortable" field="programEdition">-->
            <!--                <template #body="slotProps">-->
            <!--                    {{ slotProps.data.programEdition.id }}-->
            <!--                </template>-->
            <!--            </Column>-->
            <Column header="Program" :sortable="sortable" field="program" />
            <Column header="Powierzchnia" :sortable="sortable" field="field.name">
                <template #body="slotProps">
                    {{ slotProps.data.field.name }}
                </template>
            </Column>
            <Column header="Rok" :sortable="sortable" field="year" />
            <Column header="Liczenie" :sortable="sortable" field="countingNumber" />
            <Column header="Obserwator" :sortable="sortable" field="observer" >
                <template #editor="{data,field}">
                    <CustomSelectOne :items="observers" filter itemLabel="fullName" itemValue="fullName"
                                     :emptyValue="false" v-model="data[field]" name="observer" />
                </template>
            </Column>
            <Column header="Gatunek" :sortable="sortable" field="species">
                <template #body="slotProps">
                    {{ slotProps.data.species?.namePl }}
                </template>
            </Column>
            <Column header="Liczba stanowisk/kolonii (kontrolowane)" :sortable="sortable" field="positionsControled" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="positionsControled" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Liczba stanowisk/kolonii (aktywne)" :sortable="sortable" field="positionsActive" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="positionsActive" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Liczba par (kat. A)" :sortable="sortable" field="noPairA" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="noPairA" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Liczba par (kat. B)" :sortable="sortable" field="noPairB" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="noPairB" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Liczba par (kat. C)" :sortable="sortable" field="noPairC" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="noPairC" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Liczba par/osobników (łącznie)" :sortable="sortable" field="noPairCount" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="noPairCount" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Liczba osobników" :sortable="sortable" field="noBirdsCount" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="noBirdsCount" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Liczba par z sukcesem lęgowym" :sortable="sortable" field="noPairSuccess" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="noPairSuccess" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Liczba par o znanym wyniku lęgu" :sortable="sortable" field="noPairRate" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="noPairRate" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Liczba piskląt" :sortable="sortable" field="noChicksCount" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="noChicksCount" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Produktywność (wszystkie pary)" :sortable="sortable" field="prodNoPairRate" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="prodNoPairRate" class="w-4rem"
                                       :maxDigits="6"/>
                </template>
            </Column>
            <Column header="Produktywność (pary z udanym lęgiem)" :sortable="sortable" field="prodNoPairSuccess" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="prodNoPairSuccess" class="w-4rem"
                                       :maxDigits="6"/>
                </template>
            </Column>
            <Column header="rateCount" :sortable="sortable" field="rateCount" >
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="rateCount" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Data wprowadzenia (wygenerowania)" :sortable="sortable" field="entryDate" >
                <template #body="slotProps">
                    {{ dateFormat(slotProps.data.entryDate) }}
                </template>
                <template #editor="{data,field}">
                    <CustomCalendar v-model="data[field]" name="entryDate" class="w-9rem" />
                </template>
            </Column>
            <Column header="Koordynator" :sortable="sortable" field="username" >
                <template #editor="{data,field}">
                    <CustomSelectOne :items="observers" itemLabel="fullName" itemValue="fullName"
                                     :emptyValue="false" v-model="data[field]" name="observer" />
                </template>
            </Column>
            <Column header="Źródło" :sortable="sortable" field="source" />
            <Column v-if="editable" :rowEditor="true" style="width: 120px; text-align: center;" />
        </DataTable>
    </div>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchAggregatesCount,
        searchAggregates,
        getAllObserversAsCoordinator,
        updateAggregates,
    } from "@/swagger/vue-api-client";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "DataAggregatesTable",

        components: {
            CustomCalendar,
            CustomInputNumber,
            CustomSelectOne,
            DataTable,
            Column,
        },

        props: {
            modelValue: null,
            monitoringProgram: {
                type: Boolean,
                default: false,
            },
            sortable: {
                type: Boolean,
                default: true,
            },
            editable: {
                type: Boolean,
                default: false,
            },
            data: {
                type: Array,
                default: () => [],
            },
            useGivenData: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                dataAggregates: [],
                observers: [],
                editingRows: ref([]),
                totalRecords: 0,
                loading: false,
            };
        },

        emits: ["update:modelValue"],

        beforeMount() {
            getAllObserversAsCoordinator()
                .then((response) => {
                    this.observers = response.data;
                });
        },

        mounted() {
            if (this.useGivenData) {
                this.dataAggregates = this.data;
            } else {
                this.search();
            }
        },

        methods: {
            search() {
                if (!this.useGivenData) {
                    this.loading = true;
                    searchAggregates({body: this.searchCriteria})
                        .then((response) => {
                            this.dataAggregates = response.data;
                            return searchAggregatesCount({body: this.searchCriteria});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }
            },

            onPage(event) {
                this.searchCriteria.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
            },

            onRowEditSave(event) {
                const {newData, index} = event;
                this.dataAggregates[index] = newData;

                const request = {...this.dataAggregates[index]};
                request.fieldId = request.field?.id;
                delete request.field;
                request.speciesId = request.species?.id;
                delete request.species;
                request.programEditionId = request.programEdition?.id;
                delete request.programEdition;

                updateAggregates({
                    id: request.id,
                    body: request,
                });
            },

            dateFormat(date) {
                if (date == null) return "";
                return DateUtils.displayDate(date);
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    this.search();
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },

        computed: {
            searchCriteria: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
</style>
