<template>
    <Dialog v-model:visible="visible" :draggable="false" modal :closable="false"
            header="Czy chcesz zaakceptować historię transektu?"
            class="save-info-dialog">
        <div class="save-info-dialog-buttons">
            <CustomButton label="Akceptuj" @click="acceptTransect" />
            <CustomButton label="Anuluj" @click="visible = false" />
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "ConfirmActionDataTransectDialog",

        components: {
            CustomButton,
            Dialog,
        },

        props: {
            modelValue: null,
        },

        emits: ["accept", "update:modelValue"],

        methods: {
            acceptTransect() {
                this.$emit("accept");
            },
        },

        computed: {
            visible: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";
@import "../assets/scss/dialogs.scss";
</style>
