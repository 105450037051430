<template>
    <div class="flex justify-content-between gap-4">
        <!-- <div class="position-map w-4" v-if="allPositions !== null">
            <MapMultiMarkersSimple mapId="positionDictMap" height="600px" :markerPopup="false"
                                   :isOn="false" :hideNamePopups="true" @clickedMarker="searchClickedMarker"
                                   :positions="allPositions" :markerIndexClicked="markerIndexClicked" />
        </div> -->
    </div>
    <div class="position-table">
        <DataTable :value="positions" v-model:editingRows="editingRows" editMode="row"
                   dataKey="id" lazy
                   v-model:selection="selectedPosition" selectionMode="single" @rowSelect="onRowSelect"
                   :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
                   @page="onPage" @sort="onPage" removableSort
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink RowsPerPageDropdown"
                   :currentPageReportTemplate="currentPageReportTemplate" paginator
                   :rows="10" :rowsPerPageOptions="[5,10,20,50]"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset">
            <Column header="Nazwa" field="name"/>
            <Column header="Kod powierzchni" field="field.name"/>
            <Column header="Program" field="field.program"/>
            <Column header="Współrzędne" field="coordinates">
                <template #body="slotProps">
                    <div v-if="slotProps.data.coordinates">
                        {{ slotProps.data.coordinates.latitude }}, {{ slotProps.data.coordinates.longitude }}
                    </div>
                </template>
            </Column>
            <Column header="Status" field="status.label"/>
            <Column header="Działania">
                <template #body="slotProps">
                    <div class="position-dic-table-buttons flex flex-wrap gap-2">
                        <CustomButton label="Wyświetl szczegóły" @click="navigateToPosition(slotProps.data.id)" />
                        <CustomButton label="Aktywuj" v-if="slotProps.data.status === 'CREATED'
                                          || slotProps.data.status === 'REPORTED_RESTORE'"
                                      @click="updateStatus(slotProps.data,'ACTIVE')" />
                        <CustomButton label="Zaarchiwuj" v-if="slotProps.data.status === 'REPORTED_REMOVE'"
                                      @click="updateStatus(slotProps.data,'ARCHIVED')" />
                        <CustomButton label="Edytuj" @click="navigateToEditPosition(slotProps.data.id)"
                                      icon="pi pi-pencil" />
                        <CustomButton label="Akceptuj" class="p-button-success"
                                      v-if="!slotProps.data.acceptedLastPositionHistory
                                          && slotProps.data.coordinates !== null"
                                      @click="openConfirmationDialog(slotProps.data)"
                                      bgColor="rgba(51, 121, 91, 0.3)" />
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
    <Dialog header="Potwierdź działanie" v-model:visible="confirmationPositionDialogVisible"
            :modal="true" :closable="true">
        <div class="mb-8">
            <h4>Aktualne stanowisko</h4>
            <p>Długość {{ currentEntry.coordinates.longitude }},
                Szerokość {{ currentEntry.coordinates.latitude }}</p>
            <h4>Poprzednie stanowisko</h4>
            <p v-if="previousEntry">Długość {{ previousEntry.coordinates.longitude }},
                Szerokość {{ previousEntry.coordinates.latitude }}</p>
            <p v-else>Poprzedni wpis nie jest dostępny</p>
        </div>
        <div>
            <div class="flex gap-4 justify-content-center">
                <CustomButton label="Akceptuj" class="p-button-success" @click="acceptPosition()" />
                <CustomButton label="Odrzuć" class="p-button-danger" @click="rejectPosition()" />
            </div>
        </div>
    </Dialog>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Dialog from "primevue/dialog";
    import {
        searchPositions,
        searchPositionsCount,
        updatePosition_1 as updatePosition,
        getPositionHistoryById, updatePositionHistory,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton";
    // import MapMultiMarkersSimple from "../../../components/MapMultiMarkersSimple";

    export default {
        name: "PositionDictTable",

        components: {
            CustomButton,
            DataTable,
            Column,
            Dialog,
            // MapMultiMarkersSimple,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                positions: null,
                allPositions: null,
                fields: [],
                programs: [],
                statuses: [],
                editingRows: ref([]),
                loading: false,
                totalRecords: 0,
                confirmationPositionDialogVisible: false,
                currentEntry: {},
                previousEntry: {},
                selectedPosition: null,
                markerIndexClicked: null,
            };
        },

        beforeMount() {
            searchPositions({body: {}})
                .then((response) => {
                    this.allPositions = response.data;
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    }
                });
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchPositions({body: this.searchCriteria})
                    .then((response) => {
                        this.positions = response.data;
                        this.loading = false;
                    });
            },

            updateTotalRecords() {
                searchPositionsCount({body: this.searchCriteria})
                    .then((response) => {
                        this.totalRecords = response.data;
                    });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            updateStatus(position, newStatus) {
                const positionRequest = {
                    status: newStatus,
                };

                updatePosition({
                    id: position.id,
                    body: positionRequest,
                }).then((response) => {
                    if (response.status === 200) {
                        position.status = newStatus;
                        this.$forceUpdate();
                    } else {
                        console.error("Error updating position status");
                    }
                }).catch((error) => {
                    console.error("Error updating position status", error);
                });
            },
            openConfirmationDialog(position) {
                getPositionHistoryById({ id: position.id })
                    .then((response) => {
                        const history = response.data;
                        if (history.length > 0) {
                            this.currentEntry = history[history.length - 1];
                            this.previousEntry = history.length > 1 ? history[history.length - 2] : null;
                            this.confirmationPositionDialogVisible = true;
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching position history:", error);
                    });
            },
            acceptPosition() {
                const positionHistoryRequest = {
                    accepted: true,
                    pointId: this.currentEntry.id,
                };
                updatePositionHistory({
                    id: this.currentEntry.id,
                    body: positionHistoryRequest,
                }).then((response) => {
                    if (response.status === 200) {
                        this.currentEntry.accepted = true;
                        this.confirmationPositionDialogVisible = false;
                        this.search();
                    }
                }).catch((error) => {
                    console.error("Error during position history update:", error);
                });
            },
            navigateToPosition(id) {
                this.$router.push(`/position/${id}`);
            },

            navigateToEditPosition(id) {
                this.$router.push({path: `position/${id}`, query: {editMode: "true"}});
            },

            navigateToCreatePosition() {
                this.$router.push("/create-position");
            },

            onRowSelect() {
                this.markerIndexClicked = this.selectedPosition.id;
            },

            searchClickedMarker(value) {
                this.selectedPosition = value;
                this.markerIndexClicked = value.id;
                this.searchParams.programs = [value.field.program];
                this.searchParams.fieldNames = [value.field.name];
                this.searchParams.statuses = [value.status];
                this.searchParams.name = value.name;
                this.search();
            },

            unselect() {
                this.selectedPosition = null;
                this.markerIndexClicked = null;
            },
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
            currentPageReportTemplate() {
                return "Rekordy od "
                    + "{first}"
                    + " do "
                    + "{last}"
                    + " z "
                    + "{totalRecords}"
                    + " (Strona {currentPage}"
                    + " z "
                    + "{totalPages})";
            },
        },
    };
</script>
