<template>
    <RejectCastDialog v-model="showRejectCastDialog"
                      @reloadProgramState="$emit('reloadProgramState')" />
    <div class="flex mx-2 gap-2">
        <template v-if="isMainCoordinator">
            <CustomButton v-if="programEditionState === 'CAST_WAITING_FOR_NATIONAL_APPROVAL'"
                          label="Zatwierdź obsadę na poziomie krajowym" bgColor="var(--secondary-color)"
                          @click="acceptCast" color="var(--surface-a)" class="field w-max" />
            <CustomButton v-if="programEditionState === 'CAST_WAITING_FOR_MAIN_APPROVAL'"
                          label="Zatwierdź obsadę" bgColor="var(--secondary-color)" color="var(--surface-a)"
                          @click="acceptCast" class="field" />
            <CustomButton v-if="programEditionState === 'CAST_WAITING_FOR_MAIN_APPROVAL'"
                          label="Odrzuć obsadę" bgColor="var(--red)" color="var(--surface-a)"
                          @click="showRejectCastDialog = true" class="field" />
            <CustomButton v-if="programEditionState === 'RESULTS_WAITING_FOR_NATIONAL_APPROVAL'"
                          label="Zatwierdź dane na poziomie krajowym" bgColor="var(--secondary-color)"
                          @click="acceptResults" color="var(--surface-a)" class="field w-max" />
            <CustomButton v-if="programEditionState === 'RESULTS_WAITING_FOR_MAIN_APPROVAL'"
                          label="Zatwierdź dane" bgColor="var(--secondary-color)"
                          color="var(--surface-a)" @click="acceptResults" class="field" />
        </template>
        <template v-else-if="isNationalCoordinator">
            <CustomButton v-if="programEditionState === 'CAST_WAITING_FOR_NATIONAL_APPROVAL'"
                          label="Zatwierdź obsadę" bgColor="var(--secondary-color)"
                          color="var(--surface-a)" @click="acceptCast" class="field" />
            <CustomButton v-if="programEditionState === 'RESULTS_WAITING_FOR_NATIONAL_APPROVAL'"
                          label="Zatwierdź dane" bgColor="var(--secondary-color)"
                          color="var(--surface-a)" @click="acceptResults" class="field" />
        </template>
    </div>
</template>

<script>
    import {SystemRole} from "@/utils/SystemRole";
    import {
        approveCast,
        approveResults,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import RejectCastDialog from "@/views/monitoringProgram/components/RejectCastDialog.vue";

    export default {
        name: "CastAndResultsManagementButtons",

        components: {
            RejectCastDialog,
            CustomButton,
        },

        inject: ["programEditionState"],

        data() {
            return {
                showRejectCastDialog: false,
            };
        },

        emits: ["reloadProgramState"],

        methods: {
            acceptCast() {
                approveCast({programEditionId: this.$route.params.id}).then(() => {
                    this.$emit("reloadProgramState", true);
                });
            },

            acceptResults() {
                approveResults({programEditionId: this.$route.params.id}).then(() => {
                    this.$emit("reloadProgramState", true);
                });
            },
        },

        computed: {
            isMainCoordinator() {
                return SystemRole.isMainCoordinator();
            },
            isNationalCoordinator() {
                return SystemRole.isNationalCoordinator();
            },
        },
    };
</script>

<style lang="scss">
</style>
