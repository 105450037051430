<template>
    <div v-if="loaded >= 2">
        <Toast/>
        <DictDetailsTemplate :item="point" :itemHistory="pointHistory" mianownik="punkt" dopelniacz="punktu"
                             showCoordinatesInHistoryTable :hasHabitatData="point.programHasHabitatData"
                             :hasSubfields="point.programHasSubfields" :createPoint="createPoint"
                             @deleteItemHistory="deletePointHistory" @exit="exit"
                             @acceptItemHistory="acceptPointHistory" @saveItem="savePoint"
                             @reloadItemData="getPointData" @editItemHistory="editPointHistory"
                             @createItemHistory="onCreatePointHistory">
            <template v-slot:map>
                <MapMultiMarkers mapId="pointsMap" height="350px" :zoomControls="false" :positions="pointHistory"
                                 :field="point.field?.shape" :markerTypes="markerTypes" :isOn="false" idField="id"/>
            </template>
        </DictDetailsTemplate>
        <EditPointDialog v-if="editPointHistoryDialogVisible" v-model:visible="editPointHistoryDialogVisible"
                         v-model:item="pointHistoryToEdit" :field="point.field?.shape" :create="createPointHistory"
                         @reloadHistory="getPointHistoryData"/>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import DictDetailsTemplate from "@/views/dict/DictDetailsTemplate.vue";
    import {
        createPoint,
        deletePointHistoryById,
        getPointById,
        getPointHistoryById,
        updatePoint,
        updatePointHistory,
    } from "@/swagger/vue-api-client";
    import MapMultiMarkers from "@/components/map/MapMultiMarkers.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import EditPointDialog from "@/views/pointTable/components/EditPointDialog.vue";

    export default {
        name: "PointDetailsView",

        components: {
            EditPointDialog,
            MapMultiMarkers,
            Toast,
            DictDetailsTemplate,
        },

        data() {
            return {
                createPoint: false,
                point: null,
                pointHistory: [],
                loaded: 0,
                createPointHistory: false,
                pointHistoryToEdit: null,
                editPointHistoryDialogVisible: false,
                markerTypes: new Map(),
            };
        },

        beforeMount() {
            if (this.$route.name === "createPoint") {
                this.createPoint = true;
                this.point = EmptyStructures.getEmptyDictPoint();
                this.pointHistory = [];
                this.loaded = 2;
            } else {
                this.getPointData();
                this.getPointHistoryData();
            }
        },

        methods: {
            getPointData() {
                getPointById({ id: this.pointId })
                    .then((response) => {
                        this.point = response.data;
                        this.point.program = this.point.field?.program;
                        this.point.fieldId = this.point.field?.id;
                        this.point.subfieldId = this.point.subfield?.id;
                        console.log(this.point);
                        this.loaded += 1;
                    });
            },
            getPointHistoryData() {
                getPointHistoryById({ id: this.pointId })
                    .then((response) => {
                        this.pointHistory = response.data;
                        this.pointHistory.forEach((ph) => { ph.year = new Date(ph.year.toString()); });
                        this.setMarkerTypes();
                        console.log(this.pointHistory);
                        this.loaded += 1;
                    });
            },
            deletePointHistory(pointHistoryId) {
                deletePointHistoryById({ id: pointHistoryId })
                    .then(() => { this.getPointHistoryData(); });
            },
            acceptPointHistory(pointHistoryId) {
                updatePointHistory({id: pointHistoryId, body: {pointId: this.pointId, accepted: true}})
                    .then(() => { this.getPointHistoryData(); });
            },
            savePoint(exit) {
                const pointRequest = {
                    name: this.point.name,
                    order: this.point.order,
                    fieldId: this.point.fieldId,
                };
                if (this.point.subfieldId != null) pointRequest.subfieldId = this.point.subfieldId;

                let apiMethod = updatePoint;
                const parameters = {body: pointRequest};
                if (this.createPoint) apiMethod = createPoint;
                else parameters.id = this.pointId;

                apiMethod(parameters)
                    .then((response) => {
                        // this.$toast.add({
                        //     severity: "success",
                        //     summary: "Sukces",
                        //     detail: "Zmiany zostały zapisane",
                        //     life: 2000,
                        // });
                        if (exit) {
                            this.$router.push({ name: "pointDictView", query: {saveCorrectly: true} });
                            // setTimeout(() => this.$router.push({ name: "pointDictView" }), 2000);
                        } else if (this.createPoint) {
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zmiany zostały zapisane",
                                life: 2000,
                            });
                            this.$router.replace({path: `/point/${response.data}`});
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się zapisać zmian",
                            life: 3000,
                        });
                    });
            },
            exit() {
                this.$router.push({ name: "pointDictView" });
            },
            editPointHistory(item) {
                this.createPointHistory = false;
                this.pointHistoryToEdit = item;
                this.editPointHistoryDialogVisible = true;
            },
            onCreatePointHistory() {
                this.createPointHistory = true;
                this.pointHistoryToEdit = EmptyStructures.getEmptyDictPoint();
                this.editPointHistoryDialogVisible = true;
            },
            setMarkerTypes() {
                // creates actual map which keeps for every pointId an array with icon color and icon size
                this.pointHistory.forEach((ph, index) => {
                    if (index === 0) {
                        this.markerTypes.set(ph.id, {
                            color: "red",
                            size: "small",
                            popup: false,
                            dontHidePopup: false,
                        });
                    } else {
                        this.markerTypes.set(ph.id, {
                            color: "grey",
                            size: "small",
                            popup: false,
                            dontHidePopup: false,
                        });
                    }
                });
            },
        },

        computed: {
            pointId() { return this.$route.params.id; },
        },
    };
</script>
