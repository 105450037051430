<template>
    <div class="remind-panel-outer p-4 sm:py-4 md:px-8">
        <div class="remind-password-panel-header text-center">
            <img src="@/assets/logo.png" alt="logo" class="max-w-full">
        </div>
        <div class="remind-password-panel-content">
            <h1 class="py-3 text-center">Przypomnienie hasła</h1>
            <div v-if="sending">
                Na wskazany adres e-mail została wysłana wiadomość z linkiem do zmiany hasła.
            </div>
            <VeeForm v-else @submit="remind" v-slot="{ errors }">
                <CustomInputText name="email" v-model="passwordRemindForm.email"
                                 label="Adres e-mail" rules="required|email"/>
                <Button label="Przypomnij hasło" type="submit" class="mt-3"
                        :disabled="isDisabled(errors)"/>
            </VeeForm>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {Form as VeeForm} from "vee-validate";
    import {remindPassword} from "@/swagger/vue-api-client";
    import CustomInputText from "../components/form/CustomInputText";

    export default {
        name: "PasswordRemindView",
        components: {CustomInputText, Button, VeeForm},

        data() {
            return {
                sending: false,
                passwordRemindForm: {
                    email: "",
                },
            };
        },

        methods: {
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
            remind() {
                this.sending = true;
                remindPassword({passwordRemindRequest: this.passwordRemindForm});
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "assets/scss/remind-password";
</style>
