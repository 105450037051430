<template>
    <div class="monitoring-program-event-logs-table">
        <DataTable lazy :loading="loading" :value="eventLogs" :totalRecords="totalRecords"
                   dataKey="id" paginator v-model:rows="page.limit" v-model:first="page.offset"
                   @page="onPage" :rows="10" :rowsPerPageOptions="[10,25,50,100,10000]" size="small"
                   @sort="onPage" removableSort
                   v-model:filters="searchCriteria" filter-display="menu" @update:filters="search">
            <Column field="dateTime" header="Data i czas" sortable></Column>
            <Column field="fieldList" header="Powierzchnie"
                    filterField="fieldList" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomSelectOne label="Powierzchnia" name="eventLogsField" v-model="filterModel.value"
                                     :items="fields" :emptyValue="false" filter
                                     itemLabel="name" itemValue="name" class="flex-1 w-0 mt-3" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
            </Column>
            <Column field="user" header="Użytkownik"
                    filterField="userIds" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #body="slotProps">
                    {{ slotProps.data.user.fullName }}
                </template>
                <template #filter="{filterModel}">
                    <CustomMultiSelect class="flex-1 w-0 mt-3" label="Użytkownik" :items="observers"
                                       itemLabel="fullName" itemValue="id" v-model="filterModel.value"
                                       name="eventLogsObservers" :emptyValue="false" dataKey="id" />
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply :callback="filterCallback" />
                </template>
            </Column>
            <Column field="log" header="Log"></Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchEventLogs,
        searchEventLogsCount,
    } from "@/swagger/vue-api-client";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "EventLogsTable",

        components: {
            CustomMultiSelect,
            CustomSelectOne,
            FilterClear,
            FilterApply,
            DataTable,
            Column,
        },

        inject: ["fields", "observers"],

        data() {
            return {
                eventLogs: [],
                totalRecords: 0,
                searchCriteria: SearchCriteria.getClearSearchCriteriaEventLogs(),
                page: SearchCriteria.getClearPage(),
                loading: false,
            };
        },

        emits: ["update:modelValue"],

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(`${this.$route.path}[eventLogs]`)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(`${this.$route.path}[eventLogs]`);
            }
        },

        mounted() {
            this.search();
        },

        methods: {
            search() {
                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    const criteria = SearchCriteria.prepareCriteria(
                        this.searchCriteria,
                        this.page,
                        this.filterDescription,
                    );
                    searchEventLogs({programEditionId: this.$route.params.id, body: criteria})
                        .then((response) => {
                            this.eventLogs = response.data;
                            return searchEventLogsCount({programEditionId: this.$route.params.id, body: criteria});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }, 100);
            },

            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },
        },
        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(`${this.$route.path}[eventLogs]`, value);
                },
                deep: true,
            },
        },
        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaEventLogsFilterDescription();
            },
        },
    };
</script>

<style lang="scss">
</style>
