<template>
    <div :id="mapId" :style="height ? `height: ${this.height}; position: relative;` : ''" class="map-single-transect" />
</template>

<script>
    /* eslint no-underscore-dangle: 0 */
    import _ from "lodash";
    import L from "leaflet";
    import {MapMixin} from "@/mixins/MapMixin";

    export default {
        name: "MapSingleTransect",

        mixins: [MapMixin],

        props: {
            mapId: {
                type: String,
                default: "map",
            },
            height: {
                type: String,
                default: null,
            },
            modelValue: {
                type: Object,
            },
            color: {
                type: String,
                default: "red",
            },
            field: {
                type: Array,
                default: () => [],
            },
            zoomControls: {
                type: Boolean,
                default: true,
            },
            isOn: {
                type: Boolean,
                default: true,
            },
            deleteCircle: null,
        },

        data() {
            return {
                markersCoordinates: [],
                redCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='red' /></svg>",
                greyCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='grey' /></svg>",
                redPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='red' /></svg>",
                greyPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='grey' /></svg>",
                fieldOnMap: [],
                polandBoundaries: [
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                ],
                currentPositionsOnMap: new Map(),
                timer: [],
                polyline: null,
                latlngs: [],
                markers: new Map(),
                fieldShape: "",
            };
        },

        emits: ["onClick", "onHover", "update:modelValue"],

        mounted() {
            this.initCustomIdMap(this.mapId);
            this.addZoomControls();
            if (this.field !== null && this.field.length > 0) {
                this.makeField();
            } else {
                this.zoomToPoland();
            }
            this.makeTransect();
        },

        methods: {
            addZoomControls() {
                if (this.zoomControls) {
                    const zoom = L.control.zoom({
                        position: "bottomright",
                    });
                    zoom.addTo(this.map);
                }
            },
            makeField() {
                for (let i = 0; i < this.field.length; i += 1) {
                    this.fieldOnMap.push([this.field[i].latitude, this.field[i].longitude]);
                }
                this.fieldShape = L.polygon(this.fieldOnMap);
                this.fieldShape.setStyle({fillColor: "transparent", color: "#33795B"});
                this.fieldShape.addTo(this.map);
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 6);
            },
            zoomToPoland() {
                for (let i = 0; i < this.polandBoundaries.length; i += 1) {
                    this.fieldOnMap.push([this.polandBoundaries[i].latitude, this.polandBoundaries[i].longitude]);
                }
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 1);
            },
            makeTransect() {
                this.makePolyline();
                this.makeCircles();
            },
            makeCircles() {
                this.transect.line.forEach((line, index) => {
                    this.makeCircle(line, index);
                });
            },
            makeCircle(line, index) {
                const markerConfig = this.getMarker(index, this.color);
                const marker = L.marker([line.latitude, line.longitude], markerConfig).addTo(this.map);
                this.markers.set(index, marker);
                marker.on("drag", (event) => {
                    this.latlngs[index] = [event.target._latlng.lat, event.target._latlng.lng];
                    this.polyline.setLatLngs(this.latlngs);
                });
                marker.on("dragend", () => {
                    this.transect.line[index] = {
                        latitude: this.latlngs[index][0],
                        longitude: this.latlngs[index][1],
                    };
                });
            },
            makePolyline() {
                this.latlngs = this.transect.line.map((line) => [line.latitude, line.longitude]);
                this.polyline = L.polyline(this.latlngs, {color: this.color}).addTo(this.map);
            },
            updateTransect() {
                this.updatePolyline();
                this.updateCircles();
                this.updateField();
            },
            updateField() {
                let fieldChanged = false;
                this.field.forEach((actual, index) => {
                    const prev = this.fieldOnMap[index];
                    if (actual.latitude !== prev[0]) fieldChanged = true;
                    if (actual.longitude !== prev[1]) fieldChanged = true;
                });
                if (fieldChanged) {
                    this.fieldOnMap = [];
                    this.fieldShape.removeFrom(this.map);
                    this.makeField();
                }
            },
            updateCircles() {
                this.transect.line.forEach((line, index) => {
                    if (line.longitude != null && line.latitude != null) {
                        if (this.markers.has(index)) {
                            const marker = this.markers.get(index);
                            marker.setLatLng([line.latitude, line.longitude]);
                        } else {
                            this.makeCircle(line, index);
                        }
                    }
                });
            },
            updatePolyline() {
                this.latlngs = this.transect.line.map((line) => {
                    if (line.latitude !== null && line.longitude !== null) {
                        return [line.latitude, line.longitude];
                    }
                    return [];
                });
                if (this.latlngs.length > 0) {
                    this.polyline.setLatLngs(this.latlngs);
                }
            },
            getMarker(id, color, zIndexOffset = 1) {
                return {
                    icon: this.getIcon(color),
                    bubblingMouseEvents: false,
                    zIndexOffset,
                    draggable: this.isOn,
                    id,
                    dontHidePopup: false,
                };
            },
            getIcon(color) {
                let icon = this.redCircle;
                if (color === "red") icon = this.redCircle;
                else if (color === "grey") icon = this.greyCircle;
                // eslint-disable-next-line
                return new L.divIcon({
                    html: icon,
                    iconSize: [12, 12],
                    iconAnchor: [5, 7.5],
                    popupAnchor: [1, -34],
                });
            },
            removeCircle(circleCoords, index) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (_.isEqual(circleCoords, layer.getLatLng())) {
                            layer.removeFrom(this.map);
                        }
                    }
                });
                this.markers.delete(index);
            },
        },

        computed: {
            transect: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            transect: {
                handler(value) {
                    this.updateTransect();
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
            deleteCircle(value) {
                this.removeCircle({lat: value[0].latitude, lng: value[0].longitude}, value[1]);
            },
        },
    };
</script>

<style lang="scss">
@import "../assets/scss/leaflet-map";
@import "../../assets/theme/mytheme/variables";
</style>
