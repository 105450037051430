<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions" :controls="controls">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition v-model="position" :positions="positions" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlMainData="slotProps">
            <ControlTypeAndDates v-model="slotProps.control" controlDateLabel="Data liczenia" />
        </template>
        <template v-slot:controlBirdsData="slotProps">
            <FormBirds v-model:birds="slotProps.control.birds" v-model:noBirds="slotProps.control.noBirds" />
            <CustomMultiSelect v-if="!slotProps.control.noBirds" label="Inne lęgowe ptaki w kolonii" name="otherBirds"
                               v-model="slotProps.control.otherBirds" class="mt-4 w-6" :items="dicts.programBirds"
                               :showErrors="showErrorMessages" :emptyValue="false"
                               itemLabel="name" :disabled="!editing" />
        </template>
        <template v-slot:controlHabitatData>
            <HabitatDataMSL :habitatData="position.habitatData" v-model:habitatDataToChange="position.habitatDataToChange"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import HabitatDataMSL from "./HabitatDataMSL.vue";

    export default {
        name: "FormMSLObservations",

        components: {
            FormObservationsTemplate,
            ObservationsSelectPosition,
            ControlTypeAndDates,
            FormBirds,
            CustomMultiSelect,
            HabitatDataMSL,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
            field: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                positions: [],
                activeIndex: 0,
                index: 0,
                position: _.cloneDeep(EmptyStructures.getEmptyMSLPosition()),
                control: _.cloneDeep(EmptyStructures.getEmptyMSLControl()),
                controls: [],
            };
        },

        emits: ["update:step", "update:form"],

        inject: ["showErrorMessages", "editing", "dicts"],

        beforeMount() {
            this.positions = this.internalForm.positions.filter((p) => (p.notAudited === null || !p.notAudited));
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            position: {
                handler(value) {
                    this.controls[0] = value.control1;
                    this.controls[1] = value.control2;
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
