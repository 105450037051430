<template>
    <Dialog v-if="positions.length > 0" class="archive-positions-dialog" header="Wybór stanowisk archiwalnych"
            :draggable="false" v-model:visible="visible">
        <div class="archive-positions-dialog-container flex flex-column my-5 px-4">
            <CustomInfoBox text="Zaznacz stanowiska, które chcesz dodać.
            Aby zobaczyć lokalizację stanowiska, najedź myszą na jego nazwę i kliknij ją." />
            <div class="archive-positions-dialog-content flex justify-content-between mt-5 mb-4">
                <div class="archive-positions-dialog-content__left">
                    <ul class="archive-positions-list list-none">
                        <li v-for="(position,index) in positions" :key="position.name"
                            class="archive-positions-list-element" @click="selectPosition(position,index)">
                            <CustomRadioButton name="selected-archive-position" :value="position.positionId"
                                               :inputId="position.positionId.toString()" :label="position.name"
                                               v-model="selectedPosition"/>
                        </li>

                    </ul>
                </div>
                <div class="archive-positions-dialog-content__right">
                    <ArchivePositionsMap :positions="positions" :field="field.shape"
                                         :selectedPosition="selectedPosition" />
                </div>
            </div>
            <CustomButton label="Dodaj wybrane stanowiska" icon="pi pi-plus" rowReverse bgColor="var(--secondary-color)"
                          :disabled="selectedPosition == null" color="var(--surface-a)" class="align-self-end"
                          @click="addArchivePosition" />
        </div>
    </Dialog>
    <Dialog v-else class="approve-info-dialog" header="Nie znaleziono stanowisk archiwalnych,
     których jesteś właścicielem na tej powierzchni."
            :draggable="false" v-model:visible="visible" modal>
        <div class="approve-info-dialog-buttons" style="width:100%">
            <CustomButton label="Zamknij" @click="visible = false" style="width:100%" />
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInfoBox from "@/components/form/CustomInfoBox.vue";
    import {MapMixin} from "@/mixins/MapMixin";
    import {getHistoricalPositions, assignPosition} from "@/swagger/vue-api-client";
    import CustomRadioButton from "@/components/form/CustomRadioButton.vue";
    import ArchivePositionsMap from "./ArchivePositionsMap.vue";

    export default {
        name: "ArchivePositionsDialog",

        components: {
            ArchivePositionsMap,
            CustomRadioButton,
            Dialog,
            CustomButton,
            CustomInfoBox,
        },

        mixins: [MapMixin],

        emits: ["update:modelValue"],

        props: {
            modelValue: {
                type: Boolean,
                default: false,
            },
            field: {
                type: Object,
                default: () => {},
            },
            programCode: null,
        },

        data() {
            return {
                positions: [],
                selectedPosition: null,
            };
        },

        beforeMount() {
            getHistoricalPositions({
                programCode: this.programCode,
                formId: this.$route.params.id,
                fieldName: this.field.name,
            })
                .then((response) => {
                    this.positions = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        methods: {
            addArchivePosition() {
                assignPosition({
                    programCode: this.programCode,
                    formId: this.$route.params.id,
                    positionId: this.selectedPosition.positionId,
                })
                    .then(() => {
                        this.visible = false;
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się dodać stanowiska, spróbuj ponownie.",
                            life: 3000,
                        });
                    });
            },
            highlightPosition(index) {
                const liElements = Array.from(document.getElementsByClassName("archive-positions-list-element"));
                for (let i = 0; i < liElements.length; i += 1) {
                    if (liElements[i].classList.contains("active-position")) {
                        liElements[i].classList.remove("active-position");
                    }
                }
                liElements[index].classList.add("active-position");
            },
            selectPosition(position, index) {
                this.selectedPosition = position.positionId;
                this.highlightPosition(index);
            },
        },

        computed: {
            visible: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../assets/theme/mytheme/variables";
    @import "../../../views/assets/scss/dialogs";

    .approve-info-dialog.p-dialog {
        .p-dialog-content {
            .approve-info-dialog-buttons {
                &::after {
                    content: none;
                }
            }
        }
    }
</style>
