<template>
    <div class="custom-icon-button-container flex justify-content-center align-items-center gap-2"
         :class="computedFlexDirection">
        <PrimeButton :class="small ? 'small-icon-button' : 'custom-icon-button'" :style="{backgroundColor: bgColor}">
            <i :class="icon" :style="{color: color}" class="icon-style"/>
        </PrimeButton>
        <span class="custom-icon-button-label">{{ label }}</span>
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";

    export default {
        name: "CustomIconButton",

        props: {
            icon: {
                type: String,
            },
            bgColor: {
                type: String,
                default: "var(--secondary-color)",
            },
            color: {
                type: String,
                default: "var(--surface-a)",
            },
            label: {
                type: String,
                default: "",
            },
            rowReverse: {
                type: Boolean,
                default: false,
            },
            small: {
                type: Boolean,
                default: false,
            },
        },

        components: {
            PrimeButton,
        },

        data() {
            return {

            };
        },

        computed: {
            computedFlexDirection() {
                return this.rowReverse ? "flex-row-reverse" : "flex-row";
            },
        },
    };
</script>

<style lang="scss" scoped>
    .custom-icon-button {
        width: 42px;
        height: 42px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-style {
            font-size: 12px;
        }
        &-label {
            font-size: 1rem;
            font-weight: 600;
        }
    }
    .small-icon-button {
        width: 25px;
        height: 25px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        .icon-style {
            font-size: 10px;
        }
    }
</style>
