<template>
    <div class="flex flex-column">
        <div class="flex gap-3">
            <CustomInputNumber label="Liczba dorosłych osobników" name="adults"
                               v-model="internalBirds.adults" class="w-6" required :disabled="!editing"/>
            <CustomSelectOne label="Kryterium lęgowości" name="nestingCriteria" v-model="internalBirds.nestingCriteria"
                             class="w-6" :emptyValue="false" required :items="dicts.nestingCriteria"
                             :disabled="!editing" :itemLabel="getDictLabelWithCodePrefix"
                             tooltip />
        </div>
        <div class="flex gap-3">
            <CustomInputNumber v-if="internalBirds.nestingCriteria?.code === 'PIS' || internalBirds.nestingCriteria?.code === 'MŁO'"
                               label="Liczba młodych" name="younglings" v-model="internalBirds.younglings"
                               class="w-6" :disabled="!editing" />
            <CustomSelectOne v-if="internalBirds.nestingCriteria?.code === 'PIS' || internalBirds.nestingCriteria?.code === 'MŁO'"
                             label="Wielkość młodych" name="younglingsSize" v-model="internalBirds.younglingsSize" class="w-6" :emptyValue="false" :items="dicts.younglingsSize"
                             :disabled="!editing" />
            <CustomSelectOne v-if="index === 1" label="Wynik lęgu" name="breedingResult" v-model="internalBirds.breedingResult" class="w-6" :emptyValue="false" :items="dicts.breedingResult" :disabled="!editing" />
            <div v-if="index === 1 && internalBirds.nestingCriteria?.code !== 'PIS' && internalBirds.nestingCriteria?.code !== 'MŁO'" class="w-6 dummy" />
        </div>
        <div class="flex gap-3">
            <CustomSelectOne label="Zachowania poligamiczne" name="poligamia" v-model="internalBirds.poligamia" class="w-6" :emptyValue="false" :items="dicts.poligamia" :disabled="!editing" />
            <CustomInputText v-if="internalBirds.poligamia?.value" label="Opis zachowań poligamicznych" name="poligamiaNotes"
                             v-model="internalBirds.poligamiaNotes" class="w-6" :disabled="!editing" />
            <div v-else class="w-6 dummy" />
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "FormBirdsMCY",

        components: {
            CustomInputNumber,
            CustomSelectOne,
            CustomInputText,
        },

        props: {
            modelValue: null,
            index: null,
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:modelValue"],

        data() {
            return {
            };
        },

        methods: {
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 80) {
                    return _.truncate(text, {
                        length: 80,
                        separator: " ",
                    });
                }
                return text;
            },
        },

        computed: {
            internalBirds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
