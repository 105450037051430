<template>
    <div class="flex justify-content-between">
        <div class="flex gap-2 w-full">
            <CustomInputText label="Kod" name="code" />
        </div>
        <CustomButton label="Wyczyść" />
    </div>
</template>

<script>
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "BirdsDictSearchCriteria",

        components: {
            CustomInputText,
            CustomButton,
        },
    };
</script>

<style lang="scss">
</style>
