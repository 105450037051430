<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <CustomInputText style="width: 4rem" label="Para" v-model="ordinal" disabled name="ordinal" />
            <div style="width: 20%">
                <CustomSelectOne label="Kontrola 1 - kryterium lęgowości" name="nestingCriteria1" v-model="newItem.nestingCriteria1"
                                 :items="nestingCriteria1" :emptyValue="false" filter required :showErrors="showErrors"/>
            </div>
            <div style="width: 20%">
                <CustomSelectOne label="Kontrola 2 - kryterium lęgowości" name="nestingCriteria2" v-model="newItem.nestingCriteria2"
                                 :items="nestingCriteria2" :emptyValue="false" filter :disabled="newItem.lossBetweenControls"
                                 required :showErrors="showErrors && newItem.lossBetweenControls !== true" />
            </div>
            <div class="w-2">
                <CustomCheckbox label="Strata między kontrolami" name="lossBetweenControls" v-model="newItem.lossBetweenControls"
                                :disabled="!criteriaC.includes(newItem.nestingCriteria1?.code)"/>
            </div>
            <div class="w-1">
                <CustomSelectOne label="Para mieszana" name="pairMixType" v-model="newItem.pairMixType" :items="dicts.pairMixType"
                                 :emptyValue="false" filter required :showErrors="showErrors"/>
            </div>
            <div class="w-2">
                <CustomInputText label="Dodatkowe informacje" name="additionalInfo" v-model="newItem.additionalInfo"/>
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem" v-on:keydown.tab="add"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";

    export default {
        name: "AddBirdsMMC",

        components: {
            CustomInputText,
            CustomCheckbox,
            CustomSelectOne,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
            nestingCriteria1: {
                type: Array,
                default: () => [],
            },
            nestingCriteria2: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyMMCBird()),
                showErrors: false,
                ordinal: 1,
                criteriaC: ["GNS", "WYS", "JAJ", "PIS", "MŁO"],
            };
        },

        methods: {
            add() {
                if (!ValidateUtils.validateNotEmpty(this.newItem, ["nestingCriteria1", "pairMixType"])
                    && (this.newItem.lossBetweenControls === true
                        || !ValidateUtils.validateNotEmpty(this.newItem, ["nestingCriteria2"]))) {
                    this.showErrors = true;
                } else {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyMMCBird());
                    this.showErrors = false;
                }
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) { this.ordinal = this.birds.length + 1; this.$emit("update:modelValue", value); },
                deep: true,
            },
            "newItem.lossBetweenControls"(value) {
                if (value === true) this.newItem.nestingCriteria2 = null;
            },
            "newItem.nestingCriteria1"(value) {
                if (!this.criteriaC.includes(value?.code)) this.newItem.lossBetweenControls = false;
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
.disabled-not-opacity {
    .p-disabled, .p-component:disabled {
        opacity: 1;
    }
}
</style>
