<template>
    <div :id="mapId" :style="height ? `height: ${this.height}; position: relative;` : ''" class="map-multi-transects" />
    <div ref="popup" class="marker-popup hidden"></div>
    <div v-if="popup">
        <div v-for="(transect,index) in transects" ref="popup" class="marker-popup" :key="transect.id"
             @mouseover="closePopupOnHover(transect.id)" @focus="closePopupOnHover(transect.id)">
            <!-- @click="clickPopup(transect.id,index)"
             @mouseover="mouseoverOrFocusPopup(transect.id,index)"
             @focus="mouseoverOrFocusPopup(transect.id,index)" -->
            <slot name="popup" :transect="transect" :index="index"></slot>
        </div>
    </div>
</template>

<script>
    /* eslint no-underscore-dangle: 0 */
    import _ from "lodash";
    import {toRaw} from "vue";
    import L from "leaflet";
    import {MapMixin} from "@/mixins/MapMixin";

    export default {
        name: "MapMultiTransectsFormZero",

        mixins: [MapMixin],

        props: {
            mapId: {
                type: String,
                default: "map",
            },
            height: {
                type: String,
                default: null,
            },
            popup: {
                type: Boolean,
                default: false,
            },
            popupFull: {
                type: Boolean,
                default: false,
            },
            transect: null,
            transects: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            openPopupByDefault: {
                type: Boolean,
                default: false,
            },
            openPopupOnHover: {
                type: Boolean,
                default: false,
            },
            openPopupOnClick: {
                type: Boolean,
                default: false,
            },
            changeColorOnHover: {
                type: Boolean,
                default: false,
            },
            color: {
                type: String,
                default: "red",
            },
            colorOnHover: {
                type: String,
                default: "red",
            },
            zoomControls: {
                type: Boolean,
                default: true,
            },
            isOn: {
                type: Boolean,
                default: true,
            },
            editable: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                markersCoordinates: [],
                redCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='red' /></svg>",
                greyCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='grey' /></svg>",
                lightGreyCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='lightgrey' /></svg>",
                redPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='red' /></svg>",
                greyPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='grey' /></svg>",
                lightGreyPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='lightgrey' /></svg>",
                fieldOnMap: [],
                polandBoundaries: [
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                ],
                currentPositionsOnMap: new Map(),
                timer: [],
                polylines: new Map(),
                markers: new Map(),
                polyline: null,
                latlngs: [],
                fieldShape: "",
            };
        },

        emits: ["onClick", "onHover", "update:transect", "update:transects"],

        mounted() {
            this.initCustomIdMap(this.mapId);
            L.Popup.prototype._animateZoom = function (e) {
                if (!this._map) {
                    return;
                }
                const pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center);
                const anchor = this._getAnchor();
                L.DomUtil.setPosition(this._container, pos.add(anchor));
            };
            this.addZoomControls();
            // this.map.on("click", () => { this.$emit("onClick", null); });
            if (this.field !== null && this.field.length > 0) {
                this.makeField();
            } else {
                this.zoomToPoland();
            }
            this.makeTransects();
            if (this.editable) {
                this.map.on("click", (e) => {
                    if (this.internalTransect) {
                        const coordinates = {
                            latitude: e.latlng.lat,
                            longitude: e.latlng.lng,
                        };
                        this.internalTransect.line.push({...coordinates});
                    }
                });
            }
            window.addEventListener("keydown", (e) => {
                if (this.internalTransect && e.key === "Escape") {
                    this.$emit("onClick", null);
                }
            });
            this.internalTransect = this.transect;
            this.polyline = this.polylines.get(this.internalTransect.id);
            this.updateSingleTransect();
        },

        methods: {
            closePopupOnHover(transectId) {
                if (this.markers.has(transectId)) {
                    const marker = this.markers.get(transectId);
                    marker.closePopup();
                    setTimeout(() => {
                        marker.openPopup();
                    }, 3000);
                }
            },
            // mouseoverOrFocusPopup(transectId, index) {
            //     if (this.popupFull) this.showFullPopup(transectId, index);
            // },
            // clickPopup(transectId, index, fullIsOpen = false) {
            //     if (this.popupFull) {
            //         this.showFullPopup(transectId, index, true, fullIsOpen);
            //         this.hideOtherFullPopups(transectId);
            //     }
            // },
            addZoomControls() {
                if (this.zoomControls) {
                    const zoom = L.control.zoom({
                        position: "bottomright",
                    });
                    zoom.addTo(this.map);
                }
            },
            makeField() {
                for (let i = 0; i < this.field.length; i += 1) {
                    this.fieldOnMap.push([this.field[i].latitude, this.field[i].longitude]);
                }
                const fieldShape = L.polygon(this.fieldOnMap);
                fieldShape.setStyle({fillColor: "transparent", color: "#33795B"});
                fieldShape.addTo(this.map);
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 6);
            },
            zoomToPoland() {
                for (let i = 0; i < this.polandBoundaries.length; i += 1) {
                    this.fieldOnMap.push([this.polandBoundaries[i].latitude, this.polandBoundaries[i].longitude]);
                }
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 1);
            },
            makeTransects() {
                this.transects.forEach((transect, index) => {
                    if (transect.line?.length > 0) {
                        this.makeTransect(transect, index);
                    }
                });
                // this.map.on("click", () => this.hideAllFullPopups());
            },
            makeTransect(transect, index) {
                const polyline = this.makePolyline(transect, index);
                // this.makePolyline(transect, index);
                transect.line.forEach((line, i) => {
                    if (i === 0 || i === transect.line.length - 1) {
                        this.makeSingleTransectCircle(line, index, i);
                    }
                });
                this.makePopup(transect, index, polyline);
            },
            makePopup(transect, index, polyline) {
                const centerMarker = this.getPointMarker(transect.id);
                const center = polyline.getCenter();
                const marker = L.marker([center.lat, center.lng], centerMarker).addTo(this.map);
                const popup = L.popup({autoPan: false}).setContent(this.$refs.popup[index]);
                marker.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                // if (this.openPopupByDefault || transect.openPopup) {
                //     marker.on("mouseover", () => {
                //         marker.closePopup();
                //     });
                // }
                this.markers.set(transect.id, marker);
            },
            makePolyline(transect, index) {
                const latlngs = transect.line.map((line) => [line.latitude, line.longitude]);
                const color = transect.color != null ? transect.color : "red";
                const p = L.polyline(latlngs, {color}).addTo(this.map);
                p.on("click", (e) => { L.DomEvent.stopPropagation(e); this.$emit("onClick", index); });
                p.on("mouseover", () => { this.$emit("onHover", index); });
                p.on("mouseout", () => { this.$emit("onHover", null); });
                this.polylines.set(transect.id, p);
                return p;
            },
            updateSingleTransect() {
                if (this.internalTransect.line.length > 1) {
                    this.updateSingleTransectPolyline();
                }
                if (this.internalTransect.line.length > 0) {
                    this.updateSingleTransectCircles();
                    this.markers = new Map([...this.markers.entries()].sort());
                }
                if (this.internalTransect.line.length < 1) {
                    let index;
                    this.transects.forEach((t, i) => {
                        if (t.id === this.internalTransect.id) {
                            index = i;
                        }
                    });
                    if (this.markers.has(`${index}.0`)) {
                        this.map.eachLayer((layer) => {
                            if (layer instanceof L.Marker) {
                                if (_.isEqual(this.markers.get(`${index}.0`).getLatLng(), layer.getLatLng())) {
                                    layer.removeFrom(this.map);
                                }
                            }
                        });
                        this.markers.delete(`${index}.0`);
                    }
                }
            },
            updateSingleTransectCircles() {
                let index;
                this.transects.forEach((t, i) => {
                    if (t.id === this.internalTransect.id) {
                        index = i;
                        this.internalTransect.line.forEach((l, j) => {
                            if (this.markers.has(`${i}.${j}`)) {
                                const marker = this.markers.get(`${i}.${j}`);
                                marker.setZIndexOffset(9999);
                            }
                        });
                    } else {
                        t.line.forEach((l, j) => {
                            if (this.markers.has(`${i}.${j}`)) {
                                const marker = this.markers.get(`${i}.${j}`);
                                marker.setZIndexOffset(99);
                                if (marker.dragging) {
                                    marker.dragging.disable();
                                }
                            }
                        });
                    }
                });
                if (this.internalTransect.line.length < 2) {
                    const centerMarker = this.markers.get(this.internalTransect.id);
                    if (centerMarker) {
                        // centerMarker.closePopup().unbindPopup();
                    }
                    if (this.polylines.has(this.internalTransect.id)) {
                        this.map.eachLayer((layer) => {
                            if (layer instanceof L.Polyline) {
                                if (_.isEqual(layer, this.polylines.get(this.internalTransect.id))) {
                                    layer.removeFrom(this.map);
                                    this.polylines.delete(this.internalTransect.id);
                                }
                            }
                        });
                    }
                }
                this.internalTransect.line.forEach((line, i) => {
                    if (this.markers.has(`${index}.${i + 1}`)) {
                        this.map.eachLayer((layer) => {
                            if (layer instanceof L.Marker) {
                                if (_.isEqual(this.markers.get(`${index}.${i + 1}`).getLatLng(), layer.getLatLng())) {
                                    layer.removeFrom(this.map);
                                }
                            }
                        });
                        this.markers.delete(`${index}.${i + 1}`);
                    }
                    if (line.longitude != null && line.latitude != null) {
                        if (this.markers.has(`${index}.${i}`)) {
                            const marker = this.markers.get(`${index}.${i}`);
                            if (marker.dragging) {
                                marker.dragging.enable();
                            } else {
                                this.makeSingleTransectCircle(line, index, i);
                            }
                            marker.setLatLng([line.latitude, line.longitude]);
                            // this.map.panTo([line.latitude, line.longitude]);
                        } else {
                            this.makeSingleTransectCircle(line, index, i);
                        }
                    }
                });
                if (this.internalTransect.line.length < 1 && this.markers.has(`${index}.0`)) {
                    this.map.eachLayer((layer) => {
                        if (layer instanceof L.Marker) {
                            if (_.isEqual(this.markers.get(`${index}.0`).getLatLng(), layer.getLatLng())) {
                                layer.removeFrom(this.map);
                            }
                        }
                    });
                    this.markers.delete(`${index}.${0}`);
                }
            },
            updateSingleTransectPolyline() {
                let polyline;
                if (this.polylines.has(this.internalTransect.id)) {
                    polyline = this.polylines.get(this.internalTransect.id);
                } else {
                    let index;
                    this.transects.forEach((t, i) => {
                        if (t.id === this.internalTransect.id) {
                            index = i;
                        }
                    });
                    polyline = this.makePolyline(this.internalTransect, index);
                }
                let color = this.internalTransect.color != null ? this.internalTransect.color : "red";
                polyline.setStyle({color});
                this.transects.forEach((t) => {
                    if (t.id !== this.internalTransect.id) {
                        const polylineOthers = this.polylines.get(t.id);
                        color = "grey";
                        if (polylineOthers) {
                            polylineOthers.setStyle({color});
                        }
                    }
                });
                this.latlngs = this.internalTransect.line.map((line) => {
                    if (line.latitude !== null && line.longitude !== null) {
                        return [line.latitude, line.longitude];
                    }
                    return [];
                });
                if (this.latlngs.length > 0) {
                    this.polyline.setLatLngs(this.latlngs);
                }
            },
            makeSingleTransectCircle(line, index, i) {
                const color = this.transects[index].color != null ? this.transects[index].color : "red";
                const markerConfig = this.getMarker(i, color);
                const marker = L.marker([line.latitude, line.longitude], markerConfig).addTo(this.map);
                // this.map.panTo([line.latitude, line.longitude]);
                this.markers.set(`${index}.${i}`, marker);
                marker.dragging.enable();
                marker.on("drag", (event) => {
                    this.latlngs[i] = [event.target._latlng.lat, event.target._latlng.lng];
                    if (this.polyline) {
                        this.polyline.setLatLngs(this.latlngs);
                    }
                });
                marker.on("dragend", () => {
                    this.internalTransect.line[i] = {
                        latitude: this.latlngs[i][0],
                        longitude: this.latlngs[i][1],
                    };
                });
                marker.setZIndexOffset(9999);
            },
            updateTransects(transects) {
                transects.forEach((transect, index) => {
                    if (transect.line.length > 0) {
                        this.updateTransect(transect, index);
                    }
                });
            },
            /* eslint-disable-next-line */
            updateTransect(transect, index) {
                if (transect.line.length > 0) {
                    this.updateCircles(transect, index);
                }
                if (transect.line.length >= 2) {
                    this.updatePolyline(transect);
                    this.updatePopup(transect, index);
                }
            },
            updatePopup(transect, index) {
                const centerMarker = this.markers.get(transect.id);
                let polyline;
                if (this.polylines.has(transect.id)) {
                    polyline = this.polylines.get(transect.id);
                }
                if (!centerMarker) {
                    this.makePopup(transect, index, polyline);
                } else {
                    const center = polyline.getCenter();
                    centerMarker.setLatLng([center.lat, center.lng]);
                    if (this.openPopupByDefault || transect.openPopup) {
                        if (!centerMarker.isPopupOpen()) {
                            // const popup = L.popup({autoPan: false}).setContent(this.$refs.popup[index]);
                            // centerMarker.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                        }
                    } else if (centerMarker.isPopupOpen()) {
                        // centerMarker.closePopup().unbindPopup();
                    }
                }
            },
            updatePolyline(transect) {
                let polyline;
                if (this.polylines.has(transect.id)) {
                    polyline = this.polylines.get(transect.id);
                } else {
                    let index;
                    this.transects.forEach((t, i) => {
                        if (t.id === transect.id) {
                            index = i;
                        }
                    });
                    polyline = this.makePolyline(transect, index);
                }
                const color = transect.color != null ? transect.color : "red";
                polyline.setStyle({color});
                const latlngs = transect.line.map((line) => [line.latitude, line.longitude]);
                polyline.setLatLngs(latlngs);
            },
            updateCircles(transect, index) {
                let internalTransectIndex;
                this.transects.forEach((t, i) => {
                    // const marker = this.markers.get(`${index}.${i}`);
                    // const icon = this.getIcon("grey");
                    // marker.setIcon(icon);
                    if (t.id === this.internalTransect?.id) {
                        internalTransectIndex = i;
                    }
                });
                transect.line.forEach((l, i) => {
                    if (i !== 0 && i !== transect.line.length - 1) {
                        if (this.markers.has(`${index}.${i}`) && internalTransectIndex !== index) {
                            const marker = this.markers.get(`${index}.${i}`);
                            if (this.map.hasLayer(toRaw(marker))) {
                                this.map.removeLayer(toRaw(marker));
                                this.markers.delete(`${index}.${i}`);
                            }
                        }
                    } else if (this.markers.has(`${index}.${i}`) && internalTransectIndex !== index) {
                        const marker = this.markers.get(`${index}.${i}`);
                        const color = transect.color != null ? transect.color : "red";
                        const icon = this.getIcon(color);
                        marker.setIcon(icon);
                    }
                });
            },
            getPointMarker(id) {
                // eslint-disable-next-line
                const icon = L.divIcon({
                    html: this.redPoint,
                    iconSize: [1, 1],
                    iconAnchor: [0.5, 0.5],
                    popupAnchor: [0, 0],
                });
                return {
                    icon,
                    bubblingMouseEvents: false,
                    draggable: this.isOn,
                    id,
                    dontHidePopup: false,
                };
            },
            getMarker(id, color, zIndexOffset = 1) {
                return {
                    icon: this.getIcon(color),
                    bubblingMouseEvents: false,
                    zIndexOffset,
                    draggable: this.isOn,
                    id,
                    dontHidePopup: false,
                };
            },
            getIcon(color) {
                let icon = this.redCircle;
                if (color === "red") icon = this.redCircle;
                else if (color === "grey") icon = this.greyCircle;
                else if (color === "lightGrey") icon = this.lightGreyCircle;
                // eslint-disable-next-line
                return new L.divIcon({
                    html: icon,
                    iconSize: [12, 12],
                    iconAnchor: [5, 7.5],
                    popupAnchor: [1, -34],
                });
            },
            clearTimer(i) {
                if (this.timer[i] !== null) {
                    clearTimeout(this.timer[i]);
                }
            },
            // showFullPopup(transectId, i, dontHidePopup = false, fullIsOpen = false) {
            //     const marker = this.markers.get(transectId + "-center");
            //     if (!fullIsOpen) {
            //         marker.closePopup();
            //         marker.setPopupContent(this.$refs.popupFull[i]);
            //         marker.openPopup();
            //     }
            //     marker.options.dontHidePopup = dontHidePopup;
            // },
            // hideFullPopup(transectId, i) {
            //     const marker = this.markers.get(transectId + "-center");
            //     this.timer[i] = setTimeout(() => {
            //         if (!marker.options.dontHidePopup) marker.setPopupContent(this.$refs.popup[i]);
            //     }, 200);
            // },
            // hideOtherFullPopups(transectId) {
            //     this.transects.forEach((t, i) => {
            //         if (t.id !== transectId) {
            //             const marker = this.markers.get(t.id + "-center");
            //             marker.setPopupContent(this.$refs.popup[i]);
            //             marker.options.dontHidePopup = false;
            //         }
            //     });
            // },
            // hideAllFullPopups() {
            //     this.transects.forEach((t, i) => {
            //         const marker = this.markers.get(t.id + "-center");
            //         marker.setPopupContent(this.$refs.popup[i]);
            //         marker.options.dontHidePopup = false;
            //     });
            // },
        },

        computed: {
            internalTransect: {
                get() { return this.transect; },
                set(value) { this.$emit("update:transect", value); },
            },
        },

        watch: {
            transects: {
                handler(value, oldValue) {
                    if (oldValue.length > value.length) {
                        this.map.eachLayer((layer) => {
                            if (layer instanceof L.Marker) {
                                layer.removeFrom(this.map);
                            }
                            oldValue.forEach((oV) => {
                                if (layer instanceof L.Polyline) {
                                    if (_.isEqual(layer, this.polylines.get(oV.id))) {
                                        layer.removeFrom(this.map);
                                        this.polylines.delete(oV.id);
                                    }
                                }
                            });
                        });
                        this.makeTransects();
                    } else {
                        this.updateTransects(value);
                    }
                },
                deep: true,
            },
            internalTransect: {
                handler(value) {
                    if (value) {
                        this.updateTransects(this.transects);
                        this.polyline = this.polylines.get(value.id);
                        this.updateSingleTransect();
                        this.$emit("update:transect", value);
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
@import "../assets/scss/leaflet-map";
@import "../../assets/theme/mytheme/variables";
.map-multi-transects .leaflet-popup .leaflet-popup-content {
    .marker-popup {
        &-header {
            padding: 5px;
        }
        &-content {
            padding: 5px;
        }
    }
    .marker-popup-full {
        &-header {
            padding: 15px;
        }
        &-content {
            padding: 15px;
        }
    }
}
</style>
