<template>
    <Dialog v-model:visible="visible" :draggable="false" modal :closable="false"
            header="Formularz zawiera błędy. Czy chcesz zapisać formularz z błędami?"
            class="save-info-dialog">
        <div class="save-info-dialog-buttons">
            <CustomButton label="Zapisz z błędami" @click="$emit('save')" />
            <CustomButton label="Wróć do formularza" @click="visible = false" />
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "SaveDespiteErrorsDialog",

        components: {
            CustomButton,
            Dialog,
        },

        props: {
            modelValue: null,
        },

        emits: ["save", "update:modelValue"],

        computed: {
            visible: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";
@import "../../views/assets/scss/dialogs";
</style>
