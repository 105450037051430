<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      :onlyStepOne="onlyStepOne" dontShowNoFormControlCheckbox>
        <template v-slot:formMap>
            <div class="mb-5 px-3">
                <FormControlsTemplate :controls="controls" checkboxNoBirds>
                    <template v-slot:singleControl="slotProps">
                        <ControlTypeAndDates v-model="slotProps.control"/>
                        <HeaderComponent headerText="Dodatkowe informacje" class="mt-3 mb-3" />
                        <AdditionalInformation v-model="slotProps.control" />
                    </template>
                </FormControlsTemplate>
            </div>
            <FormPositions v-model="form.positions" :field="form.field" @reloadFormData="getFormData"
                           dynamicPositions :onlyStepOne="onlyStepOne"/>
        </template>
        <template v-slot:formObservations>
            <FormMCHObservations :field="form.field.shape" :form="form"
                                 v-model:step="step" :allPositions="form.positions" ref="observationsView" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import _ from "lodash";
    import {computed} from "vue";
    import FormPositions from "@/components/formSharedComponents/FormPositions.vue";
    import FormMCHObservations from "./components/observations/FormMCHObservations.vue";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import {
        getList as getDict,
        getForm,
    } from "@/swagger/vue-api-client";
    import {DateUtils} from "@/utils/DateUtils";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import FormControlsTemplate from "@/views/form/FormControlsTemplate.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import AdditionalInformation from "@/components/formSharedComponents/AdditionalInformation.vue";

    export default {
        name: "FormMCHView",

        components: {
            AdditionalInformation,
            HeaderComponent,
            ControlTypeAndDates,
            FormControlsTemplate,
            FormViewTemplate,
            FormPositions,
            FormMCHObservations,
        },

        data() {
            return {
                programCode: "MCH",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {
                    controlType: [],
                },
                request: {},
                validationCorrect: false,
                controls: [],
                activeIndex: 0,
                onlyStepOne: false,
                species: [],
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                onlyStepOne: computed(() => this.onlyStepOne),
                species: computed(() => this.dicts.programBirds),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getFormData();
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        ValidateUtils.flattenData(this.form.positions, "position");
                        for (let i = 1; i < 4; i += 1) {
                            const control = this.form[`control${i}`];
                            if (control !== null) {
                                if (control.startTime !== null) {
                                    control.startTime = control.startTime.substring(0, 5);
                                }
                                if (control.endTime !== null) {
                                    control.endTime = control.endTime.substring(0, 5);
                                }
                            }
                        }
                        for (let i = 0; i < this.form.positions.length; i += 1) {
                            const position = this.form.positions[i];
                            if (position.birds === null) position.birds = [];
                        }
                        this.controls[0] = this.form.control1;
                        this.controls[1] = this.form.control2;
                        this.controls[2] = this.form.control3;
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loaded = false;
                    });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "positions");
                if (!this.form.noFormControl) {
                    for (let i = 1; i <= 3; i += 1) {
                        ValidateUtils.formatControlDateStartTimeAndEndTime(this.form[`control${i}`]);
                    }
                    ValidateUtils.copyFieldValues(this.form, request, ["positions", "control1", "control2", "control3"]);
                }
                return request;
            },
            validateControls() {
                let isCorrect = true;
                for (let i = 1; i < 4; i += 1) {
                    const control = this.form[`control${i}`];
                    let isSingleControlCorrect = true;
                    if (control.error) delete control.error; // remove previous checks
                    if (control.controlDate !== null && this.form.monitoringYear.toString() !== DateUtils.dateAsString(control.controlDate).slice(0, 4)) {
                        isSingleControlCorrect = false;
                    }
                    if (!control.noControl) {
                        isSingleControlCorrect = ValidateUtils.validateNotEmpty(control, ["controlDate", "startTime", "endTime"]) && isSingleControlCorrect;
                        isSingleControlCorrect = ValidateUtils.validateIsDateInRange(control.controlDate, this.form.control.controlStart, this.form.control.controlEnd) && isSingleControlCorrect;
                    }
                    if (control.noControl) {
                        isSingleControlCorrect = ValidateUtils.validateNotEmpty(control, ["noControlReason"]) && isSingleControlCorrect;
                    }
                    isSingleControlCorrect = ValidateUtils.validateIsDateInRange(control.controlDate, this.form.control.controlStart, this.form.control.controlEnd) && isSingleControlCorrect;
                    if (!isSingleControlCorrect) {
                        control.error = true;
                        isCorrect = false;
                    }
                }
                return isCorrect;
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = this.validateFirstStep() && isCorrect;
                    isCorrect = ValidateUtils.validateNotEmpty(this.form, ["control1", "control2", "control3"]) && isCorrect;
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        const position = this.form.positions[i];
                        if (position.error) delete position.error; // remove previous checks
                        if (position.notAudited === true) {
                            if (position.notAuditedReason === null || position.notAuditedReason === "") {
                                isCorrect = false;
                            }
                        } else if (position.notAudited === false || position.notAudited === null || position.notAudited === undefined) {
                            if (position.noBirds !== true && position.birds.length < 1) {
                                isCorrect = false;
                            }
                            if (position.birds.length > 0) {
                                const birds = position.birds;
                                const correctChecker = [];
                                birds.forEach((b, k) => {
                                    for (let j = 0; j < birds.length; j += 1) {
                                        if (k !== j) {
                                            correctChecker.push(_.isEqual(b.controlType, birds[j].controlType) && _.isEqual(b.species, birds[j].species));
                                        }
                                    }
                                });
                                if (correctChecker.includes(true)) {
                                    this.addErrorToast("W przypadku tej samej kontroli, wprowadzone gatunki obserwowanych ptaków muszą być różne");
                                }
                            }
                            if (position.habitatType === null) isCorrect = false;
                            if (position.plantsType === null) isCorrect = false;
                            isCorrect = this.validateControls() && isCorrect;
                        }
                    }
                }
                console.log(isCorrect);
                // for (let i = 1; i < 4; i += 1) {
                //     const control = this.form[`control${i}`];
                //     if (control.error) delete control.error; // remove previous checks
                // }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
            },
            hideControlOnList(value) {
                if (this.dicts.controlType.length > 0) {
                    this.dicts.controlType[value].disabled = true;
                }
            },
            showControlOnList(value) {
                if (this.dicts.controlType.length > 0) {
                    this.dicts.controlType[value].disabled = false;
                }
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.positions, "Stanowisko");
                for (let i = 1; i <= 3; i += 1) {
                    if (!this.form[`control${i}`].noControl) {
                        const requiredCompleted = ValidateUtils.validateNotEmpty(this.form[`control${i}`], ["controlDate", "startTime", "endTime"]);
                        if (!requiredCompleted) errors.push("Nie wszystkie wymagane pola w Kontroli " + i + " zostały uzupełnione");
                    }
                }
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
            addErrorToast(message) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: message,
                    life: 3000,
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
        },

        watch: {
            controls: {
                handler(value) {
                    this.onlyStepOne = (value[0].noControl || value[0].noBirds)
                        && (value[1].noControl || value[1].noBirds)
                        && (value[2].noControl || value[2].noBirds);
                    for (let i = 0; i < value.length; i += 1) {
                        if (value[i].noControl || value[i].noBirds) {
                            this.hideControlOnList(i);
                        } else {
                            this.showControlOnList(i);
                        }
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    @import "../../assets/theme/mytheme/variables";
</style>
