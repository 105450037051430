<template>
    <Dialog header="Czy na pewno chcesz usunąć ten wpis?" v-model:visible="isVisible" class="delete-position-dialog"
            :draggable="false" modal :closable="false">
        <div class="delete-position-dialog-container">
            <div class="delete-position-dialog-buttons">
                <CustomButton label="Tak" @click="emitDeleteEntry" />
                <CustomButton label="Nie" @click="isVisible = false" />
            </div>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "DeletePositionHistoryDialog",

        components: {
            Dialog,
            CustomButton,
        },

        props: {
            visible: {
                type: Boolean,
            },
        },

        data() {
            return {
            };
        },
        methods: {
            emitDeleteEntry() {
                this.$emit("deletePosition");
            },
        },

        emits: ["deletePosition"],

        computed: {
            isVisible: {
                get() { return this.visible; },
                set(value) { this.$emit("update:visible", value); },
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/_variables.scss";
    @import "../../assets/scss/dialogs.scss";
</style>
