<template>
    <div class="flex justify-content-between">
        <div class="flex gap-2 w-full">
            <CustomInputText label="Nazwa" name="name" v-model="internalValue.name" />
        </div>
        <CustomButton label="Wyczyść" />
    </div>
</template>

<script>
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";

    export default {
        name: "AreaDictSearchCriteria",

        components: {
            CustomInputText,
            CustomButton,
        },

        props: {
            modelValue: null,
        },

        data() {
            return {
                name: "",
                searchCriteria: SearchCriteria,
            };
        },

        emits: ["update:modelValue", "unselect"],

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.name = "";
                this.$emit("unselect");
                this.update();
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        watch: {
            internalValue: {
                handler(value) {
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },
    };
</script>
