<template>
    <h2 class="pt-4 pb-3">Szczegóły transektu</h2>
    <TransectMainInfo v-if="loaded" v-model="transect" :field="transect.transect.field.shape"
                      :fields="fields" @getTransect="getTransect()"/>
    <TransectHistoryTable v-model="transect" v-if="loaded" @getTransect="getTransect()"/>
</template>

<script>

    import TransectHistoryTable from "./TransectHistoryTable.vue";
    import TransectMainInfo from "./TransectMainInfo.vue";
    import {
        getById as getTransectById,
        // getTransectHistoriesById,
        listFieldsForProgram,
    } from "@/swagger/vue-api-client";

    export default {
        name: "TransectDetailsView",

        components: {
            TransectHistoryTable,
            TransectMainInfo,
        },

        data() {
            return {
                transect: {
                    histories: [],
                },
                totalRecords: 0,
                hObj: null,
                loaded: false,
                isEditTransectActive: false,
                tmpTransect: {},
                fields: [],
            };
        },

        beforeMount() {
            this.getTransect();
        },

        methods: {
            getTransect() {
                getTransectById({ id: this.$route.params.id })
                    .then((response) => {
                        this.transect = response.data;
                        this.loadFields(this.transect.transect?.field?.program);
                        this.totalRecords = this.transect.histories.length;

                        this.loaded = true;
                    });
            },
            loadFields(programCode) {
                listFieldsForProgram({ program: programCode })
                    .then((response) => {
                        this.fields = [...response.data];
                    })
                    .catch((error) => {
                        console.error(`Error loading fields for program ${programCode}:`, error);
                    });
            },
        },
    };
</script>

<style scoped lang="scss">

</style>
