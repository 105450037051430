import moment from "moment";

/* eslint-disable */
export const DateUtils = {
    defaultDateFormat: "YYYY-MM-DD",
    defaultDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    timestampFormat: "YYYY-MM-DD-HH-mm-ss",
    dateFormat: "YYYY-MM-DD",
    defaultTimeOnlyFormat: "HH:mm",
    defaultYearOnlyFormat: "YYYY",
    polishDateFormat: "DD MMMM YYYY",
    polishDateTimeFormat: "DD MMMM YYYY HH:mm:ss",

    displayDateTime(dateString) {
        return moment(dateString).format(this.defaultDateTimeFormat);
    },

    displayDate(dateString) {
        return moment(dateString).format(this.dateFormat);
    },

    isInThePast(dateString) {
        return moment().isSameOrAfter(dateString);
    },

    isInTheFuture(dateString) {
        return moment().isSameOrBefore(dateString);
    },

    dateAsString(date) {
        return moment(date).format(this.dateFormat);
    },

    getTimeOnly(date) {
        return moment(date, "HH:mm:ss").format(this.defaultTimeOnlyFormat);
    },

    getYearOnly(date) {
        return moment(date, "HH:mm:ss").format(this.defaultYearOnlyFormat);
    },

    displayDateOnlyInPolishFormat(date) {
        return moment(date).locale('pl').format(this.polishDateFormat)
    },

    displayDateTimeInPolishFormat(dateString) {
        return moment(dateString).locale('pl').format(this.polishDateTimeFormat);
    },
    isDateEarlier(date1, date2) {
        return this.displayDate(date1) < this.displayDate(date2);
    },
    isTimeEarlier(time1, time2) {
        return time1 != null && time2 != null && this.getTimeOnly(time1) < this.getTimeOnly(time2);
    },
    formatFormDate(date) {
        return (date instanceof Date) ? this.dateAsString(date) : date;
    },
    formatFormTime(date) {
        return (date instanceof Date) ? this.getTimeOnly(date) : date;
    },
};
