<template>
    <CustomCheckbox label="Brak ssaków" v-model="internalNoMammals" :disabled="!editing"
                    :name="`noMammals-${index}`" :error="showError()"/>
    <div v-if="!internalNoMammals">
        <MammalsTable :index="index" v-model="internalMammals" :error="showError()"/>
    </div>
</template>

<script>
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import MammalsTable from "@/components/formMammals/MammalsTable.vue";

    export default {
        name: "FormMammals",

        components: {
            MammalsTable,
            CustomCheckbox,
        },

        props: {
            noMammals: {
                required: true,
            },
            mammals: {
                type: Array,
                default: () => [],
            },
            index: null,
        },

        inject: ["editing", "showErrorMessages"],

        emits: ["update:noMammals", "update:mammals"],

        methods: {
            showError() {
                return this.showErrorMessages && ((this.internalMammals == null
                    || this.internalMammals.length === 0) && !(this.noMammals === true));
            },
        },

        computed: {
            internalNoMammals: {
                get() { return this.noMammals; },
                set(value) { this.$emit("update:noMammals", value); },
            },
            internalMammals: {
                get() { return this.mammals; },
                set(value) { this.$emit("update:mammals", value); },
            },
        },

        watch: {
            internalMammals: {
                handler(value) { this.$emit("update:mammals", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
