<template>
    <div class="single-tile">
        <div class="single-tile-counter">{{ count }}</div>
        <div class="single-tile-text">{{ text }}</div>
    </div>
</template>

<script>
    export default {
        name: "SingleTile",

        props: {
            text: {
                type: String,
            },
            count: {
                type: Number,
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";

    .single-tile {
        display: flex;
        flex-direction: column;
        width: 19.5%;
        // height: 120px;
        background-color: var(--surface-0);
        position: relative;
        padding: 1rem 2rem;
        border-radius: 4px;
        &-counter {
            margin-bottom: 1rem;
            font-size: 3.5rem;
            line-height: 3.5rem;
            font-weight: bold;
            color: var(--color-black);
        }
        &-text {
            color: var(--color-black);
        }
    }
</style>
