<template>
    <div class="position-table">
        <DataTable :value="positions" editMode="row" dataKey="id" lazy selectionMode="single"
                   :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria" @page="onPage"
                   @sort="onPage" removableSort paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink CurrentPageReport RowsPerPageDropdown" currentPageReportTemplate="Emaili na stronie"
                   paginator :rows="10" :rowsPerPageOptions="[5,10,20,50]" v-model:rows="searchParams.page.limit"
                   v-model:first="searchParams.page.offset">
            <Column header="Temat" field="subject"></Column>
            <Column header="Odbiorcy" field="subject"></Column>
            <Column header="Działania">
                <template #body="slotProps">
                    <div class="position-dic-table-buttons flex flex-wrap gap-2">
                        <CustomButton label="Wyświetl szczegóły" @click="navigateToPosition(slotProps.data.id)" />
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchPositions,
        searchPositionsCount,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton";

    export default {
        name: "PositionDictTable",

        components: {
            CustomButton,
            DataTable,
            Column,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                positions: null,
                allPositions: null,
                fields: [],
                programs: [],
                statuses: [],
                loading: false,
                totalRecords: 0,
                confirmationPositionDialogVisible: false,
                currentEntry: {},
                previousEntry: {},
                markerIndexClicked: null,
            };
        },

        beforeMount() {
            searchPositions({body: {}})
                .then((response) => {
                    this.allPositions = response.data;
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    }
                });
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchPositions({body: this.searchCriteria})
                    .then((response) => {
                        this.positions = response.data;
                        this.loading = false;
                    });
            },

            updateTotalRecords() {
                searchPositionsCount({body: this.searchCriteria})
                    .then((response) => {
                        this.totalRecords = response.data;
                    });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            navigateToPosition(id) {
                this.$router.push(`/position/${id}`);
            },

            computedStatus(status) {
                if (status === "ACTIVE") return "Aktywne";
                if (status === "REPORTED_REMOVE") return "Zgłoszone do usunięcia";
                if (status === "REPORTED_RESTORE") return "Zgłoszone do przywrócenia";
                if (status === "ARCHIVED") return "Zarchiwizowane";
                if (status === "CREATED") return "Utworzone";
                return "";
            },

            searchClickedMarker(value) {
                this.markerIndexClicked = value.id;
                this.searchParams.programs = [value.field.program];
                this.searchParams.fieldNames = [value.field.name];
                this.searchParams.statuses = [value.status];
                this.searchParams.name = value.name;
                this.search();
            },
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },
    };
</script>
