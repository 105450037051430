<template>
    <Dialog header="Dane skumulowane i zagregowane" :draggable="false" v-model:visible="visible" style="width: 98%;">
        <HeaderComponent headerText="Dane skumulowane" class="mt-6 mb-3" />
        <SemiRawDataTable v-model="semiRawSearchCriteria" :data="semiRawData" useGivenData
                          :sortable="false" editable class=""/>
        <HeaderComponent headerText="Dane zagregowane" class="mt-6 mb-3" />
        <DataAggregatesTable v-model="aggregatesSearchCriteria" monitoringProgram :data="aggregates"
                             useGivenData :sortable="false" editable class="mb-4"/>
        <div class="approve-form-dialog-buttons w-100 h-100">
            <CustomButton label="Zatwierdź i zamknij" bgColor="transparent" @click="approve"/>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import SemiRawDataTable from "@/views/semiRawData/components/SemiRawDataTable.vue";
    import DataAggregatesTable from "@/views/dataAggregates/components/DataAggregatesTable.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomButton from "@/components/form/CustomButton.vue";
    import HeaderComponent from "@/components/HeaderComponent.vue";

    export default {
        name: "SemiRawAndAggregatesDialog",

        components: {
            CustomButton,
            DataAggregatesTable,
            SemiRawDataTable,
            Dialog,
            HeaderComponent,
        },

        props: {
            modelValue: {
                type: Boolean,
                default: false,
            },
            semiRawData: {
                type: Array,
                default: () => [],
            },
            aggregates: {
                type: Array,
                default: () => [],
            },
            programEditionId: null,
        },

        data() {
            return {
                semiRawSearchCriteria: this.getClearSearchCriteriaSemiRawData(),
                aggregatesSearchCriteria: this.getClearSearchCriteriaDataAggregates(),
            };
        },

        methods: {
            approve() {
                this.$router.push(`/monitoring-program/${this.programEditionId}`);
            },
            getClearSearchCriteriaDataAggregates() {
                return SearchCriteria.getClearSearchCriteriaDataAggregates();
            },
            clear() {
                this.searchCriteria = SearchCriteria.getClearSearchCriteriaDataAggregates();
            },
            getClearSearchCriteriaSemiRawData() {
                return SearchCriteria.getClearSearchCriteriaSemiRawData("MPS", this.programEditionYear);
            },
        },

        computed: {
            visible: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";
.approve-form-dialog-buttons {
    display: flex;
    width: 100%;
    padding: 0;
    border-top: 1px solid var(--primary-darker-color);
    justify-content: center;

    .custom-button {
        height: 56px;
        display: flex;
        justify-content: center;
        border-radius: 0;
        border: none;
    }
}
</style>
