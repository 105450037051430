<template>
    <HabitatDataTemplate :habitatData="habitatData" v-model:habitatDataToChange="localHabitatDataToChange"
                         :emptyHabitatData="EmptyStructures.getEmptyMCYHabitatData()">
        <template v-slot:data="slotProps">
            <div class="flex flex-column">
                <div class="flex gap-3">
                    <CustomSelectOne label="Typ zbiornika" name="habitatType"
                                     v-model="slotProps.habitatData.habitatType" :emptyValue="false"
                                     :items="dicts.habitatType" :disabled="!editing" class="w-6"
                                     required />
                    <CustomInputText v-if="slotProps.habitatData?.habitatType?.code === 'IN'"
                                     label="Podaj inny typ" name="otherHabitatType"
                                     v-model="slotProps.habitatData.otherHabitatType" class="w-6"
                                     required />
                    <CustomMultiSelect label="Otoczenie zbiornika" name="habitatEnvironment"
                                       v-model="slotProps.habitatData.habitatEnvironment" :emptyValue="false"
                                       :items="dicts.habitatEnvironment" :disabled="!editing" class="w-6"
                                       required />
                </div>
                <div class="flex gap-3">
                    <CustomMultiSelect label="Roślinność wynurzona (wybierz dominujący typ roślinności)" name="emergentVegetation"
                                       v-model="slotProps.habitatData.emergentVegetation" :emptyValue="false"
                                       :items="dicts.emergentVegetation" :disabled="!editing" class="w-6"
                                       required />
                    <CustomSelectOne label="Poziom wody" name="waterLevel"
                                     v-model="slotProps.habitatData.waterLevel" :emptyValue="false"
                                     :items="dicts.waterLevel" :disabled="!editing" class="w-6"
                                     required />
                </div>
            </div>
        </template>
    </HabitatDataTemplate>
</template>

<script>
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import HabitatDataTemplate from "@/views/form/HabitatDataTemplate.vue";

    export default {
        name: "HabitatDataMCY",

        components: {
            CustomSelectOne,
            CustomInputText,
            CustomMultiSelect,
            HabitatDataTemplate,
        },

        props: {
            habitatData: {
                type: Object,
            },
            habitatDataToChange: {
                type: Object,
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:habitatDataToChange"],

        computed: {
            EmptyStructures() {
                return EmptyStructures;
            },
            localHabitatDataToChange: {
                get() { return this.habitatDataToChange; },
                set(value) { this.$emit("update:habitatDataToChange", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
