<template>
    <Dialog v-model:visible="visible" :draggable="false" modal :closable="false"
            header="Zostaną cofnięte wszystkie niezaakceptowane zmiany stanowisk
            (w tym ich utworzenie, przeniesienie i edycja)"
            class="approve-info-dialog">
        <div class="approve-info-dialog-buttons">
            <CustomButton label="Akceptuj" @click="approve()" />
            <CustomButton label="Anuluj" @click="visible = false" />
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton";

    export default {
        name: "RejectChangesDialog",

        components: {
            CustomButton,
            Dialog,
        },

        props: {
            modelValue: null,
            programEditionId: {
                type: Number,
            },
        },

        emits: ["approve", "update:modelValue"],

        methods: {
            approve() {
                this.$emit("approve");
                this.visible = false;
            },
        },

        computed: {
            visible: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
