<template>
    <div>
        <h2 v-if="createPoint" class="pt-4 pb-3">Stwórz nowy {{ mianownik }}</h2>
        <h2 v-else class="pt-4 pb-3">Szczegóły {{ dopelniacz }}</h2>
        <div class="border-1 surface-border">
            <HeaderComponent headerText="Informacje" class="m-0" :buttons="buttonsItem"
                             @clickButton="clickButtonItem"/>
            <div class="flex gap-8 p-5">
                <div style="width: 60%">
                    <slot name="map"/>
                </div>
                <div v-if="loaded" class="flex flex-1 flex-column gap-5">
                    <CustomInputText name="name" label="Nazwa" v-model="internalItem.name"
                                     required :showErrors="showErrors" class="m-0 w-8"/>
                    <CustomInputNumber name="order" label="Numer kolejności" v-model="internalItem.order"
                                       required :showErrors="showErrors" class="m-0 w-8"/>
                    <CustomSelectOne id="program" v-model="internalItem.program" :items="programs" :emptyValue="false"
                                     name="program" label="Program" itemLabel="" :showErrors="showErrors"
                                     :showClear="false" class="m-0 w-8"/>
                    <div class="flex gap-3">
                        <CustomSelectOne id="field" v-model="internalItem.fieldId" :items="fields"
                                         :disabled="internalItem.program == null" :showClear="false"
                                         :required="internalItem.program != null" :showErrors="showErrors"
                                         name="field" label="Powierzchnia" itemValue="id" itemLabel="name"
                                         class="m-0 w-8"/>
                        <CustomButton label="Zobacz" class="justify-content-center"
                                      @click="navigateToField(internalItem.fieldId)"
                                      :disabled="internalItem.fieldId == null" />
                    </div>
                    <div v-if="programHasSubfields" class="flex gap-3">
                        <CustomSelectOne id="subfield" v-model="internalItem.subfieldId"
                                         :items="subfields" :showErrors="showErrors" :required="programHasSubfields"
                                         name="subfield" label="Podpowierzchnia" itemValue="id" itemLabel="name"
                                         :showClear="false" class="m-0 w-8"/>
                        <CustomButton label="Zobacz" class="justify-content-center"
                                      @click="navigateToField(internalItem.subfieldId)"
                                      :disabled="internalItem.subfieldId == null"/>
                    </div>
                </div>
            </div>
        </div>
        <Dialog v-model:visible="acceptItemHistoryDialogVisible" :draggable="false" modal :closable="false"
                :header="'Czy chcesz zaakceptować historię ' + dopelniacz + '?'"
                class="save-info-dialog">
            <div class="save-info-dialog-buttons">
                <CustomButton label="Akceptuj" @click="confirmAcceptItemHistory" />
                <CustomButton label="Anuluj" @click="acceptItemHistoryDialogVisible = false" />
            </div>
        </Dialog>
        <Dialog v-model:visible="deleteItemHistoryDialogVisible" class="delete-transect-dialog"
                header="Czy na pewno chcesz usunąć ten wpis?" :draggable="false" modal :closable="false">
            <div class="delete-transect-dialog-container">
                <div class="delete-transect-dialog-buttons">
                    <CustomButton label="Tak" @click="confirmDeleteItemHistory" />
                    <CustomButton label="Nie" @click="() => deleteItemHistoryDialogVisible = false" />
                </div>
            </div>
        </Dialog>
        <HabitatDataDialog v-if="hasHabitatData && habitatDataDialogVisible" v-model:visible="habitatDataDialogVisible"
                           :gHistoryId="itemHistoryToShowHabitatData.id" :programCode="item.program" />
        <div class="transect-history-table">
            <div class="transect-details border-1 surface-border mt-7">
                <HeaderComponent headerText="Historia lokalizacji" :buttons="buttonsItemHistory"
                                 @clickButton="clickButtonItemHistory" class="mt-0 mb-3"/>
                <div class="p-3">
                    <DataTable :value="internalItemHistory" paginator :rows="10"
                               :totalRecords="internalItemHistory.length">
                        <Column v-if="showCoordinatesInHistoryTable" header="Współrzędne" field="coordinates">
                            <template #body="slotProps">
                                {{ slotProps.data.coordinates !== null ? slotProps.data.coordinates.longitude : "" }},
                                {{ slotProps.data.coordinates !== null ? slotProps.data.coordinates.latitude : "" }}
                            </template>
                        </Column>
                        <Column header="Zaakceptowany" field="accepted">
                            <template #body="slotProps">
                                <div :class="slotProps.data.accepted ? 'true-style' : 'false-style'">
                                    <span>{{ slotProps.data.accepted ? "Tak" : "Nie" }}</span>
                                </div>
                            </template>
                        </Column>
                        <Column header="Rok" field="year">
                            <template #body="slotProps">{{ getYear(slotProps.data.year) }}</template>
                        </Column>
                        <Column header="Działania">
                            <template #body="slotProps">
                                <div class="flex gap-3">
                                    <CustomButton label="Edycja" icon="pi pi-pencil"
                                                  @click="editItemHistory(slotProps.data)" />
                                    <CustomButton v-if="!slotProps.data.accepted" label="Akceptuj"
                                                  class="p-button-success"
                                                  @click="acceptItemHistory(slotProps.data)" />
                                    <CustomButton v-if="hasHabitatData"
                                                  label="Zobacz dane siedliskowe" class="p-button-success"
                                                  @click="showHabitatData(slotProps.data)" />
                                    <CustomButton label="Usuń" class="p-button-danger"
                                                  @click="deleteItemHistory(slotProps.data)" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Dialog from "primevue/dialog";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import {
        getListOfPrograms,
        listFieldsForProgram,
        listSubfieldsForField,
    } from "@/swagger/vue-api-client";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import HabitatDataDialog from "@/views/transectTable/HabitatDataDialog.vue";

    export default {
        name: "DictDetailsTemplate",

        components: {
            HabitatDataDialog,
            Dialog,
            DataTable,
            Column,
            CustomButton,
            CustomSelectOne,
            CustomInputText,
            CustomInputNumber,
            HeaderComponent,
        },

        props: {
            item: {
                type: Object,
                required: true,
            },
            itemHistory: {
                type: Array,
                required: true,
            },
            mianownik: {
                type: String,
            },
            dopelniacz: {
                type: String,
            },
            field: {
                type: Array,
                default: () => [],
            },
            showCoordinatesInHistoryTable: {
                type: Boolean,
                default: false,
            },
            hasHabitatData: {
                type: Boolean,
                default: false,
            },
            createPoint: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                programs: [],
                fields: [],
                subfields: [],
                loaded: false,
                programHasHabitatData: false, // TODO
                showErrors: false,
                itemHistoryToDelete: null,
                deleteItemHistoryDialogVisible: false,
                itemHistoryToAccept: null,
                acceptItemHistoryDialogVisible: false,
                itemHistoryToShowHabitatData: null,
                habitatDataDialogVisible: false,
                somethingChanged: false,
                savedNew: false,
            };
        },

        emits: ["update:item", "update:itemHistory", "deleteItemHistory", "acceptItemHistory",
                "saveItem", "reloadItemData", "editItemHistory", "createItemHistory", "exit"],

        beforeMount() {
            getListOfPrograms()
                .then((responsePrograms) => {
                    this.programs = responsePrograms.data;
                    if (this.internalItem.program != null) {
                        listFieldsForProgram({program: this.internalItem.program})
                            .then((responseFields) => {
                                this.fields = responseFields.data;
                                if (this.internalItem.field != null) {
                                    listSubfieldsForField({fieldId: this.internalItem.field.id})
                                        .then((responseSubfields) => {
                                            this.subfields = responseSubfields.data;
                                            this.loaded = true;
                                        });
                                } else {
                                    this.loaded = true;
                                }
                            });
                    } else {
                        this.loaded = true;
                    }
                });
        },

        methods: {
            clickButtonItem(value) {
                if (this.buttonsItem[value].onClick) {
                    this.buttonsItem[value].onClick();
                }
            },
            clickButtonItemHistory(value) {
                if (this.buttonsItemHistory[value].onClick) {
                    this.buttonsItemHistory[value].onClick();
                }
            },
            editItemHistory(entry) {
                this.$emit("editItemHistory", entry);
            },
            createItemHistory() {
                this.$emit("createItemHistory");
            },
            deleteItemHistory(entry) {
                this.itemHistoryToDelete = entry;
                this.deleteItemHistoryDialogVisible = true;
            },
            confirmDeleteItemHistory() {
                this.$emit("deleteItemHistory", this.itemHistoryToDelete.id);
                this.deleteItemHistoryDialogVisible = false;
                this.itemHistoryToDelete = null;
            },
            acceptItemHistory(entry) {
                this.itemHistoryToAccept = entry;
                this.acceptItemHistoryDialogVisible = true;
            },
            confirmAcceptItemHistory() {
                this.$emit("acceptItemHistory", this.itemHistoryToAccept.id);
                this.acceptItemHistoryDialogVisible = false;
                this.itemHistoryToAccept = null;
            },
            showHabitatData(entry) {
                this.itemHistoryToShowHabitatData = entry;
                this.habitatDataDialogVisible = true;
            },
            saveItem(exit) {
                if (this.validateItem()) {
                    this.$emit("saveItem", exit);
                    this.savedNew = true;
                    this.showErrors = false;
                } else {
                    this.showErrors = true;
                }
            },
            validateItem() {
                return this.internalItem.name != null && this.internalItem.name !== ""
                    && this.internalItem.order != null && this.internalItem.order !== ""
                    && this.internalItem.program != null && this.internalItem.fieldId != null
                    && (!this.programHasSubfields || this.internalItem.subfieldId != null);
            },
            updateFieldsForProgram(program) {
                listFieldsForProgram({program})
                    .then((response) => { this.fields = response.data; });
            },
            updateSubfieldsForField(fieldId) {
                listSubfieldsForField({fieldId})
                    .then((response) => { this.subfields = response.data; });
            },
            getYear(date) {
                if (date instanceof Date) return DateUtils.getYearOnly(date);
                return date;
            },
            navigateToField(fieldId) {
                const route = this.$router.resolve(`/field/${fieldId}`);
                window.open(route.href, "_blank");
                // this.$router.push(`/field/${fieldId}`);
            },
        },

        computed: {
            programHasSubfields() {
                return this.subfields.length > 0;
            },
            internalItem: {
                get() { return this.item; },
                set(value) { this.$emit("update:item", value); },
            },
            internalItemHistory: {
                get() { return this.itemHistory; },
                set(value) { this.$emit("update:itemHistory", value); },
            },
            buttonsItem() {
                const buttons = [
                    {
                        label: "Zapisz",
                        show: true,
                        onClick: () => { this.saveItem(false); },
                    },
                    {
                        label: "Zapisz i wyjdź",
                        show: true,
                        onClick: () => { this.saveItem(true); },
                    },
                ];
                if (!this.createPoint) {
                    buttons.push({
                        label: "Wycofaj zmiany",
                        bgColor: "var(--surface-a)",
                        labelColor: "var(--text-color)",
                        borderColor: "var(--custom-button-border-color)",
                        show: true,
                        onClick: () => { this.$emit("reloadItemData"); },
                        disabled: !this.somethingChanged,
                    });
                }
                // zależy nam, żeby ten przycisk był ostatni, już po ewentualnym "wycofaj zmiany"
                buttons.push({
                    label: "Powrót",
                    bgColor: "var(--surface-a)",
                    labelColor: "var(--text-color)",
                    borderColor: "var(--custom-button-border-color)",
                    show: true,
                    onClick: () => { this.$emit("exit"); },
                });
                return buttons;
            },
            buttonsItemHistory() {
                return [
                    {
                        label: "Utwórz nową historię " + this.dopelniacz,
                        show: true,
                        onClick: () => { this.createItemHistory(); },
                        disabled: this.createPoint && !this.savedNew,
                    },
                ];
            },
        },

        watch: {
            internalItem: {
                handler(value) {
                    this.somethingChanged = true;
                    this.$emit("update:item", value);
                },
                deep: true,
            },
            internalItemHistory: {
                handler(value) { this.$emit("update:itemHistory", value); },
                deep: true,
            },
            "internalItem.program"(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.internalItem.field = null;
                    this.fields = [];
                    if (newValue != null) this.updateFieldsForProgram(newValue);
                }
            },
            "internalItem.fieldId"(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.internalItem.subfield = null;
                    this.subfields = [];
                    if (newValue != null) this.updateSubfieldsForField(newValue);
                }
            },
        },
    };
</script>

<style scoped lang="scss">

</style>
