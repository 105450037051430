<template>
    <Dialog v-if="isFlock" class="delete-control-dialog"
            v-model:visible="confirmDelete" header="Czy na pewno chcesz usunąć stado?"
            :closable="false" :draggable="false" modal>
        <div class="delete-control-dialog-container">
            <div class="delete-control-dialog-buttons">
                <CustomButton label="Usuń" color="#EA3030" bgColor="transparent"
                              @click="deleteFlockDialog" :disabled="false" />
                <CustomButton label="Anuluj" @click="cancelDeleteFlock" bgColor="transparent" :disabled="false" />
            </div>
        </div>
    </Dialog>
    <CustomCheckbox v-if="showNoBirdsCheckbox" :label="label" v-model="internalNoBirds" :disabled="!editing"
                    name="noBirds" :error="showError()"/>
    <BirdsTable v-if="!internalNoBirds" v-model="internalBirds" :error="showError()" :columns="tableColumns" :lp="lp" :otherBirds="otherBirds"/>
    <div v-if="isFlock" class="flex justify-content-center">
        <CustomButton v-if="numberOfFlocks > 1 && editing" icon="pi pi-trash" label="Usuń stado"
                      color="var(--red)" bgColor="var(--surface-a)" @click="deleteFlock" />
    </div>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import BirdsTable from "@/components/formBirds/BirdsTable.vue";

    export default {
        name: "FormBirds",

        components: {
            BirdsTable,
            CustomCheckbox,
            Dialog,
            CustomButton,
        },

        props: {
            noBirds: {
                type: Boolean,
            },
            birds: {
                type: Array,
                default: () => [],
            },
            showNoBirdsCheckbox: {
                type: Boolean,
                default: true,
            },
            lp: {
                type: Boolean,
                default: true,
            },
            numberOfFlocks: null,
            isFlock: {
                type: Boolean,
                default: false,
            },
            countSex: {
                type: Boolean,
                default: false,
            },
            otherBirds: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: "Brak ptaków",
            },
        },

        inject: ["editing", "showErrorMessages", "programCode"],

        emits: ["update:noBirds", "update:birds", "deleteThisFlock"],

        data() {
            return {
                confirmDelete: false,
                tableColumns: [],
                columnSpecies: null,
                columnSpeciesCode: null,
                columnSpeciesName: null,
                columnControlType: null,
                columnCountingMethod: null,
                columnNests: null,
                columnAdults: null,
                columnPairs: null,
                columnNestingCriteria: null,
                columnDictNestingCriteria: null,
                columnMales: null,
                columnFemales: null,
                columnOtherAdults: null,
                columnNestlings: null,
                columnYoung: null,
                columnAdditionalInfo: null,
                columnCount: null,
                columnCountShortage: null,
                columnSex: null,
                columnDistance: null,
                columnInFlight: null,
                columnTransectZone: null,
                columnTransectSection: null,
                columnControlMethod: null,
                columnBirds: null,
                columnReaction: null,
                columnDistricts: null,
                columnLossStage: null,
                columnNestsWithLoss: null,
                columnLossReason: null,
                columnAge: null,
                columnNotCounted: null,
                columnTree: null,
                columnTreeCode: null,
                columnTreeName: null,
                columnNestsOnTrees: null,
                columnDistanceNum: null,
                columnAzimuth: null,
                columnNotes: null,
                columnHabitatType: null,
                columnObservationType: null,
            };
        },

        beforeMount() {
            this.assignColumnProps();
            /* eslint-disable */
            if (this.programCode === "MCH") {
                this.tableColumns = [this.columnSpecies, this.columnControlType, this.columnCountingMethod, this.columnNests, this.columnAdults, this.columnPairs, this.columnNestingCriteria];
            } else if (this.programCode === "MPS") {
                this.tableColumns = [this.columnCountingMethod, this.columnDictNestingCriteria, this.columnMales, this.columnFemales, this.columnOtherAdults, this.columnNestlings, this.columnAdditionalInfo];
            } else if (this.programCode === "MZPM") {
                this.tableColumns = [this.columnTransectSection, this.columnSpeciesCode, this.columnSpeciesName, this.columnCount, this.columnInFlight, this.columnTransectZone];
            } else if (this.programCode === "MWOT") {
                this.tableColumns = [this.columnSingingMales, this.columnDistance];
            } else if (this.programCode === "MPPL") {
                if (this.countSex) {
                    this.tableColumns = [this.columnSpeciesCode, this.columnSpeciesName, this.columnDistance, this.columnCount, this.columnSex];
                } else {
                    this.tableColumns = [this.columnSpeciesCode, this.columnSpeciesName, this.columnDistance, this.columnCount];
                }
            } else if (this.programCode === "MPM") {
                this.tableColumns = [this.columnSpeciesCode, this.columnSpeciesName, this.columnDistance, this.columnCount, this.columnSex];
            } else if (this.programCode === "MNG") {
                this.tableColumns = [this.columnSpecies, this.columnControlMethod, this.columnCountingMethod, this.columnBirds];
            } else if (this.programCode === "MKR") {
                this.tableColumns = [this.columnAdults, this.columnYoung, this.columnNestlings, this.columnDictNestingCriteria];
            } else if (this.programCode === "MRC") {
                this.tableColumns = [this.columnLossStage, this.columnNestsWithLoss, this.columnLossReason, this.columnAdditionalInfo];
            } else if (this.programCode === "MZPW") {
                // TODO docelowo tu nie ma columnSpeciesCode i columnSpeciesName, czyli autocompletów ale jest columnSpecies czyli selectOne
                this.tableColumns = [this.columnSpeciesCode, this.columnSpeciesName, this.columnCount, this.columnInFlight, this.columnSex, this.columnAge];
            } else if (this.programCode === "MCZ") {
                this.tableColumns = [this.columnSpeciesCode, this.columnSpeciesName, this.columnNotCounted, this.columnTreeCode, this.columnTreeName, this.columnNests, this.columnNestsOnTrees]
            } else if (this.programCode === "MPPM") {
                this.tableColumns = [this.columnSpeciesCode, this.columnSpeciesName, this.columnSex, this.columnCount];
            } else if (this.programCode === "MPD") {
                this.tableColumns = [this.columnSpecies, this.columnDistricts, this.columnCount];
            } else if (this.programCode === "MLSL") {
                this.tableColumns = [this.columnSpecies, this.columnCount, this.columnSex, this.columnAge, this.columnDistanceNum, this.columnAzimuth, this.columnReaction, this.columnNotes];
            } else if (this.programCode === "MNZ") {
                this.tableColumns = [this.columnCount];
            } else if (this.programCode === "MPG") {
                this.tableColumns = [this.columnSpeciesCode, this.columnSpeciesName, this.columnCount, this.columnSex, this.columnDistance];
            } else if (this.programCode === "MSL") {
                this.tableColumns = [this.columnPairs, this.columnDictNestingCriteria];
            } else if (this.programCode === "MFGP") {
                if (this.$route.query.birdCode === 'POG') {
                    this.tableColumns = [this.columnPairs, this.columnDictNestingCriteria, this.columnHabitatType, this.columnAdditionalInfo];
                }
                if (this.$route.query.birdCode === 'COF') {
                    this.tableColumns = [this.columnNests, this.columnTree, this.columnAdditionalInfo];
                }
            } else if (this.programCode === "MRD") {
                this.tableColumns = [this.columnSpecies, this.columnCount, this.columnSex, this.columnObservationType];
            } else {
                this.tableColumns = [];
            }
            /* eslint-enable */
        },

        methods: {
            assignColumnProps() {
                /* eslint-disable */
                this.columnSpecies = {header: "Gatunek", field: "species", bodyContent: (data) => {return data.species?.namePl ? data.species?.namePl : data.species?.name}, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "programBirds", editorItemLabel: "name", columnWidth: 2};
                this.columnSpeciesCode = {header: "Kod", field: "species", bodyContent: (data) => data.species?.code, bodyClass: () => "", editorType: "autocompleteBirdSpecies", editorEmptyValue: false, columnWidth: 1, searchParam: "code"};
                this.columnSpeciesName = {header: "Gatunek", field: "species", bodyContent: (data) => data.species?.name, bodyClass: () => "", editorType: "autocompleteBirdSpecies", editorEmptyValue: false, columnWidth: 1, searchParam: "name"};
                this.columnControlType = {header: "Kontrola", field: "controlType", bodyContent: (data) => data.controlType?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "controlType", editorItemLabel: "label", columnWidth: 1};
                this.columnCountingMethod = {header: "Metoda liczenia", field: "countingMethod", bodyContent: (data) => data.countingMethod?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "countingMethod", editorItemLabel: "label", columnWidth: 2};
                this.columnNests = this.getNumberColumn("L. gniazd", "nests", 1);
                this.columnAdults = this.getNumberColumn("L. dorosłych os.", "adults", 1);
                this.columnPairs = this.getNumberColumn("L. par", "pairs", 1);
                this.columnSingingMales = this.getNumberColumn("L. śpiewających samców", "count", 2);
                this.columnNestingCriteria = {header: "Kryteria lęgowości", field: "nestingCriteria", bodyContent: (data) => data.nestingCriteria, bodyClass: () => "", editorType: "inputText", columnWidth: 2};
                this.columnDictNestingCriteria = this.getTooltipColumn("Kryterium lęgowości", "nestingCriteria", "selectOne", 2, false, "nestingCriteria", "code");
                // this.columnDictNestingCriteriaWithCode = {header: "Kryteria lęgowości", field: "nestingCriteria", bodyContent: (data) => data.nestingCriteria?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "nestingCriteria", editorItemLabel: this.getDictLabelWithCodePrefix, columnWidth: 2};
                this.columnMales = this.getNumberColumn("L. samców", "males", 1);
                this.columnFemales = this.getNumberColumn("L. samic", "females", 1);
                this.columnOtherAdults = this.getNumberColumn("L. nieozn.", "otherAdults", 1);
                this.columnNestlings = this.getNumberColumn("L. piskląt", "nestlings", 1);
                this.columnYoung = this.getNumberColumn("L. młod.", "young", 2);
                this.columnAdditionalInfo = {header: "Dodatkowe info", field: "additionalInfo", bodyContent: (data) => data.additionalInfo, bodyClass: () => "", editorType: "inputText", columnWidth: 2};
                this.columnTransectSection = this.getNumberColumn("Odcinek transektu", "transectSection", 2);
                this.columnCount = this.getNumberColumn("L. osobników", "count", 1);
                this.columnBirds = this.getNumberColumn("L. osobników", "birds", 1);
                this.columnInFlight = this.getBooleanColumn("W locie", "inFlight", 1);
                this.columnTransectZone = {header: "Strefa transektu", field: "transectZone", bodyContent: (data) => data.transectZone?.code, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "transectZone", editorItemLabel: "code", columnWidth: 2};
                this.columnSex = {header: "Płeć", field: "sex", bodyContent: (data) => data.sex?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "sex", editorItemLabel: "label", columnWidth: 1};
                this.columnDistance = {header: "Kategoria odległości", field: "distance", bodyContent: (data) => data.distance?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "distance", editorItemLabel: "label", columnWidth: 2};
                this.columnControlMethod = {header: "Metoda kontroli", field: "controlMethod", bodyContent: (data) => data.controlMethod?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "controlMethod", editorItemLabel: "label", columnWidth: 2};
                this.columnReaction = {header: "Czas reakcji", field: "reaction", bodyContent: (data) => data.reaction?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "reaction", editorItemLabel: "label", columnWidth: 2};
                this.columnDistricts = this.getNumberColumn("L. rewirów", "districts", 1);
                this.columnLossStage = {header: "Etap strat", field: "lossesStage", bodyContent: (data) => data.lossesStage?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "lossesStage", editorItemLabel: "label", columnWidth: 2};
                this.columnNestsWithLoss = this.getNumberColumn("Liczba gniazd (lub dorosłych ptaków)", "count", 3);
                this.columnLossReason = {header: "Przyczyna strat", field: "lossesCircumstances", bodyContent: (data) => data.lossesCircumstances?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "lossesCircumstances", editorItemLabel: "label", columnWidth: 2};
                this.columnAge = {header: "Wiek", field: "age", bodyContent: (data) => data.age?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "age", editorItemLabel: "label", columnWidth: 2};
                this.columnNotCounted = this.getBooleanColumn("Nie liczono", "notCounted", 1);
                this.columnTree = {header: "Rodzaj drzewa/ typ szuwaru", field: "treeType", bodyContent: (data) => data.treeType?.name, bodyClass: () => "", editorType: "autocompleteTreeSpecies", editorEmptyValue: false, columnWidth: 3, searchParam: "name"};
                this.columnNestsOnTrees = {header: "Liczby gniazd na poszczególnych drzewach", field: "nestsOnTrees", bodyContent: (data) => data.nestsOnTrees, bodyClass: () => "", editorType: "inputText", columnWidth: 3};
                this.columnDistanceNum = this.getNumberColumn("Odległość [m]", "distance", 1);
                this.columnAzimuth = this.getNumberColumn("Kierunek [°]", "azimuth", 1);
                this.columnNotes = {header: "Uwagi", field: "notes", bodyContent: (data) => data.notes, bodyClass: () => "", editorType: "inputText", columnWidth: 2};
                this.columnHabitatType = {header: "Siedlisko", field: "habitatType", bodyContent: (data) => data.habitatType?.label, bodyClass: () => "", editorType: "selectOne", editorEmptyValue: false, editorItems: "habitatType", editorItemLabel: "label", columnWidth: 2};
                this.columnObservationType = this.getTooltipColumn("Rodzaj obserwacji", "observationType", "selectOne", 2, false, "observationType", "code");

                if (this.programCode === "MPS") {
                    this.columnCountingMethod.required = true;
                    this.columnMales.required = true;
                    this.columnFemales.required = true;
                }
                if (this.programCode === "MCH") {
                    this.columnSpecies.required = true;
                    this.columnControlType.required = true;
                    this.columnNestingCriteria.required = true;
                }
                if (this.programCode === "MZPM") {
                    this.columnTransectSection.required = true;
                    this.columnSpeciesCode.required = true;
                    this.columnSpeciesName.required = true;
                    this.columnCount.required = true;
                }
                if (this.programCode === "MWOT") {
                    this.columnSingingMales.required = true;
                    this.columnDistance.required = true;
                }
                if (this.programCode === "MPPL") {
                    this.columnSpeciesCode.required = true;
                    this.columnSpeciesName.required = true;
                    this.columnDistance.required = true;
                    this.columnCount.required = true;
                    this.columnSex.required = this.countSex;
                    this.columnSpeciesName.columnWidth = 2;
                }
                if (this.programCode === "MPM") {
                    this.columnSpeciesCode.required = true;
                    this.columnSpeciesName.required = true;
                    this.columnDistance.required = true;
                    this.columnCount.required = true;
                    this.columnCount.notZero = true;
                    this.columnSex.required = true;
                }
                if (this.programCode === "MNG") {
                    this.columnSpecies.required = true;
                    this.columnControlMethod.required = true;
                    this.columnControlMethod.editorEmptyValue = true;
                    this.columnCountingMethod.required = true;
                    this.columnCountingMethod.editorEmptyValue = true;
                    this.columnBirds.required = true;
                }
                if (this.programCode === "MWOT") {
                    this.columnSingingMales.required = true;
                    this.columnDistance.required = true;
                }
                if (this.programCode === "MKR") {
                    this.columnAdults.required = true;
                    this.columnAdults.columnWidth = 2;
                    this.columnDictNestingCriteria.required = true;
                }
                if (this.programCode === "MRC") {
                    this.columnLossStage.required = true;
                    this.columnNestsWithLoss.required = true;
                    this.columnAdditionalInfo.columnWidth = 3;
                }
                if (this.programCode === "MZPW") {
                    // docelowo tu nie ma columnSpeciesCode/Name, ale tylko samo columnSpecies
                    this.columnSex.columnWidth = 2;
                    this.columnSpeciesCode.required = true;
                    this.columnSpeciesName.required = true;
                    // this.columnSpecies.required = true;
                    this.columnCount.required = true;
                    this.columnSex.required = true;
                    this.columnAge.required = true;
                }
                if (this.programCode === "MPPM") {
                    this.columnSpeciesCode.required = true;
                    this.columnSpeciesName.required = true;
                    this.columnSex.required = true;
                    this.columnCount.required = true;
                    this.columnSpeciesCode.columnWidth = 2;
                    this.columnSpeciesName.columnWidth = 2;
                    this.columnSex.columnWidth = 2;
                    this.columnCount.columnWidth = 2;
                }
                if (this.programCode === "MCZ") {
                    this.columnSpeciesCode.required = true;
                    this.columnSpeciesName.required = true;
                    this.columnTree.required = true;
                    this.columnNests.required = true;
                }
                if (this.programCode === "MPD") {
                    this.columnSpeciesCode.required = true;
                    this.columnSpeciesName.required = true;
                    this.columnDistricts.required = true;
                    this.columnCount.required = true;
                    this.columnSpeciesCode.columnWidth = 2;
                    this.columnSpeciesName.columnWidth = 2;
                    this.columnDistricts.columnWidth = 2;
                    this.columnCount.columnWidth = 2;
                }
                if (this.programCode === "MLSL") {
                    this.columnSpecies.required = true;
                    this.columnCount.required = true;
                    this.columnSex.required = true;
                    this.columnAge.required = true;
                    this.columnDistanceNum.required = true;
                    this.columnAzimuth.required = true;
                    this.columnReaction.required = true;
                    this.columnAge.columnWidth = 1;
                    this.columnNotes.columnWidth = 1;
                }
                if (this.programCode === "MNZ") {
                    this.columnCount.required = true;
                    this.columnCount.columnWidth = 3;
                }
                if (this.programCode === "MPG") {
                    this.columnSpeciesCode.required = true;
                    this.columnSpeciesName.required = true;
                    this.columnCount.required = true;
                    this.columnSex.required = true;
                    this.columnDistance.required = true;
                    this.columnSpeciesName.columnWidth = 2;
                }
                if (this.programCode === "MSL") {
                    this.columnDictNestingCriteria.columnWidth = 6;
                    this.columnDictNestingCriteria.offTableTooltip = true;
                    this.columnDictNestingCriteria.bodyContent = (data) => data.nestingCriteria?.code + " - " + data.nestingCriteria?.label;
                }
                if (this.programCode === "MFGP") {
                    this.columnPairs.required = true;
                    this.columnDictNestingCriteria.required = true;
                    this.columnHabitatType.required = true;
                    this.columnNests.required = true;
                    this.columnTree.required = true;
                    this.columnNests.header = "L. zajętych gniazd";
                    this.columnTree.header = "Gatunek drzewa";
                    this.columnTree.editorType = "selectOne";
                    this.columnTree.editorItems = "treeType";
                    this.columnTree.bodyContent = (data) => data.treeType?.label;
                    this.columnNests.columnWidth = 2;
                    this.columnAdditionalInfo.header = "Dodatkowe informacje";
                }
                if (this.programCode === "MRD") {
                    this.columnSpecies.required = true;
                    if (this.otherBirds) {
                        this.columnSpecies.editorItems = "otherBirds";
                    }
                    this.columnCount.required = true;
                    this.columnSex.required = true;
                    this.columnSex.columnWidth = 2;
                    this.columnObservationType.required = true;
                    this.columnObservationType.columnWidth = 4;
                    this.columnObservationType.offTableTooltip = true;
                    this.columnObservationType.bodyContent = (data) => data.observationType?.code + " - " + data.observationType?.label;
                }
            },
            getNumberColumn(header, field, width) {
                return {header: header, field: field,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data[field],
                    editorType: "inputNumber", columnWidth: width};
            },
            getBooleanColumn(header, field, width) {
                return {header: header, field: field, bodyClass: (data) => data[field] ? "true-style" : "false-style",
                    bodyTooltip: false, bodyContent: (data) => this.computedBoolean(data[field]), columnWidth: width, editorType: "checkbox"};
            },
            getTooltipColumn(header, field, editor, width, emptyValue, items, label) {
                return {header: header, field: field, bodyClass: () => "", bodyTooltip: true, bodyTooltipContent: (data) => data[field]?.label, bodyContent: (data) => data[field]?.code, editorType: editor, columnWidth: width, editorEmptyValue: emptyValue, editorItems: items, editorItemLabel: label};
            },
            /* eslint-enable */
            computedBoolean(value) {
                if (value) return "Tak";
                if (value === false) return "Nie";
                return null;
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            deleteFlock() {
                if (this.numberOfFlocks > 1) {
                    this.confirmDelete = true;
                }
            },
            deleteFlockDialog() {
                this.$emit("deleteThisFlock", this.birdsFlock);
                this.confirmDelete = false;
            },
            cancelDeleteFlock() {
                this.confirmDelete = false;
            },
            getDictLabelWithCodePrefix(item) {
                return item.code + " - " + item.label;
            },
        },

        computed: {
            internalNoBirds: {
                get() { return this.noBirds; },
                set(value) { this.$emit("update:noBirds", value); },
            },
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:birds", value); },
                deep: true,
            },
            /* eslint-disable */
            countSex(value) {
                if (this.programCode === "MPPL") {
                    if (value) {
                        this.tableColumns = [this.columnSpeciesCode, this.columnSpeciesName, this.columnDistance, this.columnCount, this.columnSex];
                        this.columnSex.required = true;
                    } else {
                        this.tableColumns = [this.columnSpeciesCode, this.columnSpeciesName, this.columnDistance, this.columnCount];
                        this.columnSex.required = false;
                    }
                }
            },
            /* eslint-enable */
        },
    };
</script>

<style lang="scss">
</style>
