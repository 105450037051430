<template>
    <div class="create-position-view">
        <Toast/>
        <h1>Tworzenie stanowiska</h1>
        <div class="w-3">
            <div class="field">
                <CustomInputText id="name" v-model="position.name" name="name" label="Nazwa"/>
            </div>
            <div class="field">
                <CustomSelectOne itemValue="key" id="status" v-model="position.status" :items="statuses"
                                 name="status" label="Status" :emptyValue="false" />
            </div>
            <div class="field">
                <CustomSelectOne v-if="programs !== null" id="program" v-model="position.program" :items="programs"
                                 name="program" label="Program" :emptyValue="false" itemLabel="" />
            </div>
            <div class="field">
                <CustomSelectOne id="field" v-model="position.field" :items="fields" name="field" label="Powierzchnia"
                                 :emptyValue="false" :disabled="position.program === null" itemLabel="name" />
            </div>
            <div class="flex justify-content-end">
                <CustomButton label="Zapisz" @click="submitForm" type="submit" />
            </div>
        </div>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import {
        createPosition,
        listFieldsForProgram,
        getListOfPrograms,
    } from "@/swagger/vue-api-client";

    export default {
        components: {
            CustomInputText,
            CustomSelectOne,
            CustomButton,
            Toast,
        },

        data() {
            return {
                position: {
                    name: "",
                    status: {},
                    coordinates: {
                        longitude: null,
                        latitude: null,
                    },
                    program: null,
                    field: null,
                },
                fields: [],
                statuses: [
                    {
                        label: "Aktywne",
                        key: "ACTIVE",
                    },
                    {
                        label: "Zarchiwizowane",
                        key: "ARCHIVED",
                    },
                ],
                programs: null,
            };
        },

        beforeMount() {
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                });
        },

        watch: {
            "position.program"(newVal, oldVal) {
                if (newVal !== oldVal) {
                    listFieldsForProgram({program: this.position.program})
                        .then((response) => {
                            this.fields = response.data;
                        });
                }
            },
        },

        methods: {
            submitForm() {
                const positionRequest = {
                    status: this.position.status,
                    name: this.position.name,
                    fieldId: this.position.field.id,
                };
                createPosition({body: positionRequest})
                    .then((response) => {
                        if (response.status === 200) {
                            this.$router.push({ name: "positionDictView" });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        const detail = error.response.data ? error.response.data : "Nie udało się dodać stanowiska.";
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail,
                            life: 5000,
                        });
                    });
            },
        },
    };
</script>

<style lang="scss">
</style>
