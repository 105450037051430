<template>
    <div class="flex align-items-center mb-3 custom-checkbox" :class="error ? 'error' : ''">
        <Checkbox :inputId="name" v-model="internalValue" :class="computedClasses"
                  @change="change" @blur="handleBlur" :binary="true" :disabled="disabled"/>
        <label :for="name" :class="computedClasses" class="ml-2 checkbox-label" :disabled="disabled">
            {{ label }}<span class="star" v-if="rules">*</span>
        </label>
    </div>
</template>

<script>
    import Checkbox from "primevue/checkbox";
    import {useField} from "vee-validate";

    export default {
        name: "CustomCheckbox",

        components: {Checkbox},

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            error: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
            modelValue: null,
        },

        emits: ["update:modelValue", "change"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                    this.$emit("change", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        methods: {
            change() {
                this.handleChange(!this.internalValue);
            },
        },
    };
</script>

<style scoped lang="scss">
    .custom-checkbox {
        .checkbox-label {
            font-size: 1.07rem;
            line-height: 19px;
        }
    }
    .star {
        font-size: 1.14rem;
        color: var(--red-500);
        font-weight: 700;
    }
    .error {
        color: var(--red-500);
    }
</style>
