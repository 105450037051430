<template>
    <div class="flex justify-content-between flex-column">
        <div class="flex gap-4 justify-content-between mb-4">
            <p v-if="localHabitatData?.controlDate">
                Ostatnia zmiana danych siedliskowych: {{ localHabitatData?.controlDate }}
            </p>
            <CustomButton v-if="!editHabitatData" label="Edytuj dane siedliskowe"
                          @click="enableEditHabitatData" :disabled="!editing" />
            <CustomButton v-if="editHabitatData && habitatData != null" label="Cofnij zmiany danych siedliskowych"
                          @click="undoHabitatChanges" :disabled="!editing" />
        </div>

        <slot name="data" :habitatData="localHabitatData" :disabled="!editHabitatData || !editing"/>

    </div>
</template>

<script>
    import _ from "lodash";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "HabitatDataTemplate",

        components: {
            CustomButton,
        },

        props: {
            habitatData: {
                required: true,
            },
            habitatDataToChange: {
                required: true,
            },
            emptyHabitatData: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                localHabitatData: _.cloneDeep(this.emptyHabitatData),
                editHabitatData: true,
            };
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        emits: ["update:habitatDataToChange"],

        beforeMount() {
            this.updateLocalHabitatData();
        },

        methods: {
            updateLocalHabitatData() {
                if (this.habitatDataToChange != null) {
                    // czyli są jakieś niezatwierdzone dane siedliskowe --> można je edytować
                    this.editHabitatData = true;
                    this.localHabitatData = this.habitatDataToChange;
                } else if (this.habitatData != null) {
                    // czyli są zatwierdzone dane siedliskowe, ale nie ma żadnych niezatwierdzonych --> wyświetlamy je ale nie można ich edytować
                    this.editHabitatData = false;
                    this.localHabitatData = this.habitatData;
                } else {
                    // czyli nie istnieją ani zatwierdzone, ani niezatwierdzone dane siedliskowe --> wyświetlamy puste i możliwe do edycji
                    this.editHabitatData = true;
                    this.localHabitatData = _.cloneDeep(this.emptyHabitatData);
                }
            },
            undoHabitatChanges() {
                if (this.habitatData != null && !(_.isEqual(this.habitatData, this.emptyHabitatData))) {
                    this.editHabitatData = false;
                    this.localHabitatData = this.habitatData;
                } else {
                    this.editHabitatData = true;
                    this.localHabitatData = _.cloneDeep(this.emptyHabitatData);
                }
                this.$emit("update:habitatDataToChange", null);
            },
            enableEditHabitatData() {
                this.editHabitatData = true;
                if (this.habitatData != null) {
                    this.localHabitatData = _.cloneDeep(this.habitatData);
                } else {
                    this.localHabitatData = _.cloneDeep(this.emptyHabitatData);
                }
            },
        },

        watch: {
            habitatData: {
                handler() { this.updateLocalHabitatData(); },
                deep: true,
            },
            habitatDataToChange: {
                handler() { this.updateLocalHabitatData(); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
