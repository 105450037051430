<template>
    <div class="mt-5 mb-8">
        <h4 class="uppercase">Odcinki {{computedTransectNumber(transectNumber)}}</h4>
        <div class="flex flex-1 align-items-center mr-8">
            <div v-for="(transect,index) in transects" v-bind:key="index" class="line-with-dot">
                <div v-if="transect.notAudited" class="line-with-dot">
                    <div class="line-with-label disabled-line"></div>
                    <div class="dot disabled-dot">{{transect.name}}</div>
                    <div class="line-with-label disabled-line"></div>
                </div>
                <div v-else class="line-with-dot"
                     @click="$emit('onClicked',index)"
                     @mouseover="$emit('onHover',index)" @focus="$emit('onHover',index)"
                     @mouseout="$emit('onHover',null)" @blur="$emit('onHover',null)">
                    <div class="line-with-label" :class="computedLineColor(index)"></div>
                    <div class="dot" :class="computedDotColor(index) + computedError(transect.error)">
                        {{transect.name}}
                    </div>
                    <div class="line-with-label" :class="computedLineColor(index)"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "SelectTransectLine",

        props: {
            transects: {
                type: Array,
                default: () => [],
            },
            transectNumber: {
                type: Number,
                default: 1,
            },
        },

        emits: ["onHover", "onClicked"],

        methods: {
            computedDotColor(i) {
                if (this.transects[i].clicked || this.transects[i].hover) return "selected-dot";
                return "non-selected-dot";
            },
            computedLineColor(i) {
                if (this.transects[i].clicked || this.transects[i].hover) return "selected-line";
                return "non-selected-line";
            },
            computedError(error) {
                if (error) return " colorBgRed";
                return "";
            },
            computedTransectNumber(transectNumber) {
                if (transectNumber === 1) return "1-5";
                if (transectNumber === 2) return "6-10";
                return transectNumber;
            },
        },
    };
</script>

<style lang="scss">
@import "../../../assets/theme/mytheme/variables";
.dot {
    border-radius: 17px;
    background-color: $primaryDarkerColor;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    color: $primaryColor;
    font-weight: bold;
    font-size: 1.2rem;
}
.line-with-label {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-bottom: 4px solid $primaryDarkerColor;
}
.line-with-dot {
    display: flex;
    flex: 1;
    height: 60px;
    align-items: center;
}
.non-selected-dot {
    background-color: $primaryDarkerColor;
}
.selected-dot {
    background-color: $secondaryColor;
}
.disabled-dot {
    background-color: $primaryDarkColor;
}
.non-selected-line {
    border-bottom-color: $primaryDarkerColor;
}
.selected-line {
    border-bottom-color: $secondaryColor;
}
.disabled-line {
    border-bottom-color: $primaryDarkColor;
}
.colorBgRed {
    background-color: var(--red) !important;
}
</style>
