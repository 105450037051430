/* eslint-disable */
export const ProgramEditionState = {
    CAST_NOT_ENTERED: "Oczekiwanie na wprowadzenie obsady",
    CAST_WAITING_FOR_NATIONAL_APPROVAL: "Oczekiwanie na zatwierdzenie obsady na poziomie krajowym",
    CAST_WAITING_FOR_MAIN_APPROVAL: "Oczekiwanie na zatwierdzenie obsady przez koordynatora głównego",
    ENTERING_DATA: "Wprowadzanie danych o obserwacjach",
    RESULTS_WAITING_FOR_NATIONAL_APPROVAL: "Oczekiwanie na zatwierdzenie danych na poziomie krajowym",
    RESULTS_WAITING_FOR_MAIN_APPROVAL: "Oczekiwanie na zatwierdzenie danych przez koordynatora głównego",
    PROGRAM_FINISHED: "Program zakończony",

    getStateLabel(state) {
        if (state === "CAST_NOT_ENTERED") return this.CAST_NOT_ENTERED;
        else if (state === "CAST_WAITING_FOR_NATIONAL_APPROVAL") return this.CAST_WAITING_FOR_NATIONAL_APPROVAL;
        else if (state === "CAST_WAITING_FOR_MAIN_APPROVAL") return this.CAST_WAITING_FOR_MAIN_APPROVAL;
        else if (state === "ENTERING_DATA") return this.ENTERING_DATA;
        else if (state === "RESULTS_WAITING_FOR_NATIONAL_APPROVAL") return this.RESULTS_WAITING_FOR_NATIONAL_APPROVAL;
        else if (state === "RESULTS_WAITING_FOR_MAIN_APPROVAL") return this.RESULTS_WAITING_FOR_MAIN_APPROVAL;
        else if (state === "PROGRAM_FINISHED") return this.PROGRAM_FINISHED;
        return "";
    },

    isEditingByDefault(state) {
        return !["SENT_TO_VERIFICATION", "APPROVED", "RETURNED_SENT_TO_VERIFICATION"].includes(state);
    },
};
