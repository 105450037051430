<template>
    <Dialog header="Podaj powód odrzucenia obsady" v-model:visible="visible" :closable="false"
            modal class="reject-cast-dialog">
        <div>
            <CustomInputTextArea :rows="4" v-model="rejectionCause" name="rejectionCause"
                                 required :showErrors="showErrorMessages"/>
        </div>
        <div class="flex justify-content-around">
            <CustomButton label="Odrzuć obsadę" bgColor="var(--red)" color="var(--surface-a)"
                          @click="declineCast" />
            <CustomButton label="Anuluj" class="flex justify-content-center" @click="visible = false" />
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import {rejectCast} from "@/swagger/vue-api-client";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "RejectCastDialog",

        components: {
            CustomButton,
            CustomInputTextArea,
            Dialog,
        },

        props: {
            modelValue: null,
        },

        data() {
            return {
                rejectionCause: "",
                showErrorMessages: false,
            };
        },

        methods: {
            declineCast() {
                if (this.validateRejectionCause()) {
                    rejectCast({programEditionId: this.$route.params.id, rejectionCause: this.rejectionCause})
                        .then(() => {
                            this.visible = false;
                            this.$emit("reloadProgramState");
                        });
                }
            },

            validateRejectionCause() {
                if (this.rejectionCause !== "" && this.rejectionCause !== null) {
                    this.showErrorMessages = false;
                    return true;
                }
                this.showErrorMessages = true;
                return false;
            },
        },

        computed: {
            visible: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
.reject-cast-dialog.p-dialog {
    width: 440px;
    .p-dialog-content {
        padding: 1.5rem;
    }
}
</style>
