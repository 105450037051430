<template>
    <div class="custom-warning-box">
        <div class="custom-warning-box-icon"><i class="pi pi-exclamation-triangle" /></div>
        <div class="custom-warning-box-text">{{ text }}</div>
    </div>
</template>

<script>
    export default {
        name: "CustomWarningBox",

        props: {
            text: {
                type: String,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/custom-warning-box.scss"
</style>
