<template>
    <div v-if="!isAdmin" class="flex flex-1 justify-content-between flex-wrap add-in-table-header__mammals" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap">
            <CustomAutocompleteMammalSpecies label="Kod" v-model="mammalCode" searchParam="code"
                                             name="mammalCode" :disabled="!editing"
                                             required :showErrors="showErrorMessagesMammals"
                                             :emptyValue="false" style="display: flex; flex: 2" width="100%"
                                             :customErrorMessage="getIncorrectSpeciesMessage()" />
            <CustomAutocompleteMammalSpecies label="Gatunek" v-model="mammalName" searchParam="name"
                                             name="mammalName" :disabled="!editing"
                                             required :showErrors="showErrorMessagesMammals"
                                             :emptyValue="false" style="display: flex; flex: 2" width="100%"
                                             :customErrorMessage="getIncorrectSpeciesMessage()" />
            <CustomSelectOne label="Kategoria odległości" v-model="newItem.distance" itemLabel="code"
                             :items="dicts.distance" name="distance" :disabled="!editing" required
                             :showErrors="showErrorMessagesMammals" :emptyValue="false"
                             style="display: flex; flex: 2" width="100%" />
            <CustomInputNumber label="L. osobników" v-model="newItem.count" name="count" required
                               :showErrors="showErrorMessagesMammals" :disabled="!editing"
                               style="display: flex; flex: 2" width="100%" />
            <div style="display: flex; flex: 2"/>
        </div>
        <div class="w-2 flex justify-content-end">
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="addMammalsControl" :error="error"
                          v-on:keydown.tab="addMammalsControl" />
        </div>

    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomAutocompleteMammalSpecies
        from "@/components/form/CustomAutocompleteMammalSpecies.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "AddMammals",

        components: {
            CustomAutocompleteMammalSpecies,
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            index: null,
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyMammals()),
                showErrorMessagesMammals: false,
                mammalName: "",
                mammalCode: "",
            };
        },

        methods: {
            addMammalsControl() {
                // TODO add all required fields below
                if (this.newItem.species === null || this.newItem.distance === null
                    || !ValidateUtils.validateCorrectSpecies(this.newItem, ["mammal"])) {
                    this.showErrorMessagesMammals = true;
                } else {
                    this.mammals.push({
                        ...this.newItem,
                        ordinal: this.mammals.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyMammals());
                    this.mammalCode = "";
                    this.mammalName = "";
                    this.showErrorMessagesMammals = false;
                }
            },
            getIncorrectSpeciesMessage() {
                if (!ValidateUtils.validateCorrectSpecies(this.newItem, ["mammal"])) return "Niepoprawny gatunek";
                return "";
            },
        },

        computed: {
            mammals: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            mammals: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            mammalName(value) {
                if (value.name != null) {
                    this.newItem.mammal = value;
                    this.mammalCode = value;
                } else {
                    this.newItem.mammal = null;
                    this.mammalCode = "";
                }
            },
            mammalCode(value) {
                if (value.code != null) {
                    this.newItem.mammal = value;
                    this.mammalName = value;
                } else {
                    this.newItem.mammal = null;
                    this.mammalName = "";
                }
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header__mammals {
    margin-bottom: -1rem,
}
</style>
