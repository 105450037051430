<template>
    <div class="data-aggregates-view">
        <DataAggregatesSearchCriteria v-model="searchCriteria" />
        <DataAggregatesTable v-model="searchCriteria" />
    </div>
</template>

<script>
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import DataAggregatesSearchCriteria from "./components/DataAggregatesSearchCriteria";
    import DataAggregatesTable from "./components/DataAggregatesTable";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "DataAggregatesView",

        components: {
            DataAggregatesSearchCriteria,
            DataAggregatesTable,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteriaDataAggregates(),
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        methods: {
            getClearSearchCriteriaDataAggregates() {
                return SearchCriteria.getClearSearchCriteriaDataAggregates();
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
