const TableArrowsNavigation = {
    arrowsNavigateTable(ctx) {
        const table = ctx.currentTarget.closest(".navigate-table");
        const cellsList = table.querySelectorAll("td.p-cell-editing input:not([disabled]), td.p-cell-editing .p-dropdown .p-inputtext");
        const focusedEl = document.activeElement;
        let indexOfFocused = [...cellsList].indexOf(focusedEl);
        if (indexOfFocused > -1) {
            table.addEventListener("keydown", (e) => {
                if (e.keyCode === 37) {
                    if (indexOfFocused > 0) {
                        indexOfFocused -= 1;
                    }
                    cellsList[indexOfFocused].focus();
                }
                if (e.keyCode === 39) {
                    if (indexOfFocused < cellsList.length - 1) {
                        indexOfFocused += 1;
                    }
                    cellsList[indexOfFocused].focus();
                }
            });
        }
    },
};

export default TableArrowsNavigation;
