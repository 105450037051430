<template>
    <div class="file-upload-container">
        <div class="file-upload-dropzone"
             @drop.prevent="fileUpload"
             @dragover.prevent
             :class="{'active-dropzone': true}">
            <label class="file-upload-label" for="importFile">
                <div class="files-list">
                    <div v-for="(file,index) in files" :key="index" class="file-preview-single">
                        <p>{{ file.name }}</p>
                        <PrimeButton @click.stop="remove(index)" icon="pi pi-times" class="file-upload-button"
                                     :disabled="disabled" />
                        <PrimeButton v-if="!file.isNew" @click.stop="downloadFile(file.id)" icon="pi pi-download"
                                     class="file-upload-button" :disabled="disabled" />
                    </div>
                </div>
                <input v-if="!disabled" v-show="false" type="file" ref="file"
                       @change="handleFile" id="importFile" name="file" :accept="accept" />
                <div v-show="files.length === 0" class="file-label-text">
                    Aby dodać pliki <u>kliknij tutaj</u> lub przeciągnij i upuść
                </div>
            </label>
        </div>
    </div>
</template>

<script>
    import {ref} from "vue";
    import PrimeButton from "primevue/button";
    import {
        deleteFile,
        getFilesByForm,
        uploadFiles,
        getFileDTO,
    } from "@/swagger/vue-api-client";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "CustomFileUpload",

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            accept: {
                type: String,
                default: "",
            },
        },

        emits: ["importFile"],

        components: {
            PrimeButton,
        },

        setup() {
            const active = ref(false);

            return { active };
        },

        data() {
            return {
                files: [],
                filesToDelete: [],
            };
        },

        async mounted() {
            const formId = this.$route.params.id;
            let existingFiles = [];
            await getFilesByForm({formId})
                .then((response) => {
                    existingFiles = response.data;
                })
                .catch((error) => { console.log(error); });
            this.files = existingFiles.map((file) => ({
                id: file.id,
                name: file.name,
                size: file.size,
                isNew: false,
            }));
        },

        methods: {
            fileUpload(e) {
                const {files} = e.dataTransfer;
                if (files.length > 0) {
                    this.$refs.file.files = files;
                    this.handleFile();
                }
            },

            handleFile() {
                if (!this.$refs.file.files.length) {
                    return;
                }

                let totalSize = this.totalSizeOfFiles();
                const maxTotalSize = 10 * 1024 * 1024;

                Array.from(this.$refs.file.files).forEach((file) => {
                    if (!this.isValidFileType(file) || this.files.length >= 10
                        || totalSize + file.size > maxTotalSize) {
                        return;
                    }
                    this.files.push({
                        file,
                        name: file.name,
                        isNew: true,
                    });
                    totalSize += file.size;
                });
            },

            isValidFileType(file) {
                const notAllowedExtensions = ["exe", "bat", "cmd", "sh", "js", "vbs", "ps1", "sh", "docm", "xlsm",
                                              "pptm", "msi", "msp", "dll", "sys", "scr", "html", "htm", "php",
                                              "asp", "jsp", "ini", "cnf", "reg", "iso", "vhd", "dmg", "lnk", "url"];
                const extension = file.name.split(".").pop().toLowerCase();
                return !notAllowedExtensions.includes(extension);
            },

            async uploadFiles() {
                const formData = new FormData();
                this.files.forEach((file) => {
                    if (file.isNew) {
                        formData.append("files", file.file);
                    }
                });
                const formId = this.$route.params.id;
                try {
                    await uploadFiles({ body: formData, formId });
                } catch (error) {
                    console.error("Error uploading files", error);
                }
            },

            remove(index) {
                const fileToRemove = this.files[index];
                if (fileToRemove.isNew) {
                    this.files.splice(index, 1);
                } else {
                    this.filesToDelete.push(fileToRemove.id);
                    this.files.splice(index, 1);
                }
            },

            totalSizeOfFiles() {
                return this.files.reduce((acc, file) => acc + (file.file ? file.file.size : file.size || 0), 0);
            },

            async downloadFile(fileId) {
                const response = await getFileDTO({id: fileId});
                FileUtils.downloadFileUsingGET(response.data.name, "/files/" + fileId);
            },

            async deleteFiles() {
                const deletePromises = this.filesToDelete.map((file) => deleteFile(
                    { id: file },
                ).catch((error) => {
                    console.error("error deleting file with ID", file.id, ":", error);
                }),
                );

                await Promise.all(deletePromises);
                this.filesToDelete = [];
            },

            async saveChanges() {
                await this.uploadFiles();
                await this.deleteFiles();
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";
.active-dropzone {
    background-color: var(--primary-darker-color);
}
.file-upload-container {
    .file-upload-dropzone {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100px;
        .file-upload-label {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .files-list {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;
                width: 100%;
                justify-content: center;
                align-items: center;
            }
            .file-preview-single {
                display: flex;
                align-items: center;
                gap: 1rem;
                .file-upload-button {
                    background-color: transparent;
                    border: none;
                    &.p-button-icon-only {
                        width: fit-content;
                        padding: 0;
                    }
                }
            }
            .file-label-text {
                text-align: center;
                width: 100%;
            }
        }
    }
    .file-label {
        text-align: center;
    }
    // .file-upload-dropzone {
    //     width: 200px;
    //     height: 100px;
    //     background-color: lightblue;
    // }
}
</style>
