<template>
    <FormObservationsTemplate ref="observationsTemplate" :controls="controls">
        <template v-slot:controlMainData="slotProps">
            <ControlTypeAndDates v-model="slotProps.control" />
            <CustomCheckbox class="w-4 darker-font" label="Brak wody na stanowisku" v-model="slotProps.control.noWater" name="noWater"/>
        </template>
        <template v-slot:controlBirdsData="slotProps">
            <FormBirdsMPO v-model:birds="slotProps.control.birds" v-model:noBirds="slotProps.control.noBirds" :disabled="!slotProps.control.noWater" />
        </template>
        <template v-slot:habitatData>
            <HabitatDataMPO :habitatData="internalForm.habitatData" v-model:habitatDataToChange="internalForm.habitatDataToChange"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import FormBirdsMPO from "@/views/formMPO/components/FormBirdsMPO.vue";
    import HabitatDataMPO from "@/views/formMPO/components/HabitatDataMPO.vue";

    export default {
        name: "FormMPOObservations",

        components: {
            HabitatDataMPO,
            FormBirdsMPO,
            ControlTypeAndDates,
            FormObservationsTemplate,
            CustomCheckbox,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
        },

        inject: ["editing", "showErrorMessages"],

        emits: ["update:form"],

        data() {
            return {
                controls: [],
            };
        },

        beforeMount() {
            this.controls[0] = this.form.control1;
            this.controls[1] = this.form.control2;
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) {
                    this.$emit("update:form", value);
                    this.controls[0] = value.control1;
                    this.controls[1] = value.control2;
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";

    .darker-font {
        color: var(--text-color-secondary);
    }
</style>
