<template>
    <div>
        <TransectHabitatDataTable v-model="internalForm.habitatData" :form="form"/>
        <div class="flex justify-content-start align-items-center gap-5">
            <CustomCheckbox v-if="canReportChange" label="Zgłoś zmianę" v-model="changeHabitatData" :disabled="!editing"
                            name="changeHabitatData" class="mt-5"/>
            <div v-if="ordinalError" class="error-message mt-1">
                Można zgłaszać zmiany jedynie do zatwierdzonych i niemożliwych do edycji danych.
            </div>
        </div>
        <TransectHabitatDataTable v-if="changeHabitatData" v-model="internalForm.habitatDataToChange" canAddRow
                                  alwaysEditable :form="form"/>
    </div>
</template>

<script>
    import TransectHabitatDataTable from "@/components/transectForm/habitatData/TransectHabitatDataTable.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";

    export default {
        name: "TransectHabitatData",

        components: {
            CustomCheckbox,
            TransectHabitatDataTable,
        },

        props: {
            form: {
                type: Object,
            },
        },

        inject: ["editing"],

        data() {
            return {
                changeHabitatData: false,
                ordinalError: false,
                canReportChange: false,
            };
        },

        beforeMount() {
            this.changeHabitatData = this.internalForm.habitatDataToChange.length > 0;
            this.updateOrdinalError(this.internalForm);
            this.updateNotAudited(this.internalForm);
            this.internalForm.habitatData.forEach((h) => {
                if (!h.editable) {
                    this.canReportChange = true;
                }
            });
        },

        methods: {
            updateOrdinalError(s) {
                this.ordinalError = false;
                s.habitatDataToChange.forEach((hd) => {
                    if (hd.ordinal != null && hd.ordinal !== "" && hd.ordinal > 0 && hd.ordinal <= s.habitatData.length
                        && EmptyStructures.isHabitatDataEmpty(s.habitatData[hd.ordinal - 1])) {
                        this.ordinalError = true;
                    }
                });
            },
            updateNotAudited(s) {
                s.habitatData.forEach((hd, i) => {
                    hd.notAudited = s.formTransects[i].notAudited;
                });
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) {
                    this.$emit("update:form", value);
                    this.updateOrdinalError(value);
                    this.updateNotAudited(value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
.error-message {
    color: var(--red-500);
}
</style>
