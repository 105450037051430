<template>
    <div class="px-3">
        <RejectChangesDialog v-model="showRejectChangesDialog" @approve="() => revertChanges()" />
        <div class="flex gap-2">
            <ChangeCoordinatesInfo class="flex flex-1" objectBeingChanged="odcinek"/>
            <CustomWarningBox v-if="showAlert && isCoordinator" :text="text" class="w-6" />
        </div>
        <HeaderComponent headerText="Mapa powierzchni" :uppercase="false" class="mt-5"/>
        <TransectsMap v-model="internalForm.formTransects" :field="internalForm.field.shape"
                      :disabled="!editing" :key="key"/>
        <div v-if="editing" class="flex flex-column">
            <div class="flex justify-content-end mb-2">
                <CustomButton label="Cofnij zmiany na mapie" icon="pi pi-undo" rowReverse
                              bgColor="#33795B" color="white" @click="showRejectChangesDialog = true" />
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import TransectsMap from "@/components/transectForm/TransectsMap.vue";
    import ChangeCoordinatesInfo from "@/components/formSharedComponents/ChangeCoordinatesInfo.vue";
    import RejectChangesDialog from "@/components/formSharedComponents/dialogs/RejectChangesDialog.vue";
    import CustomWarningBox from "@/components/form/CustomWarningBox.vue";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "TransectFormMap",

        components: {
            RejectChangesDialog,
            ChangeCoordinatesInfo,
            TransectsMap,
            CustomButton,
            HeaderComponent,
            CustomWarningBox,
        },

        props: {
            form: {
                type: Object,
            },
        },

        inject: ["editing"],

        data() {
            return {
                showEditDialog: false,
                showRejectChangesDialog: false,
                key: 1,
                showAlert: false,
            };
        },

        methods: {
            revertChanges() {
                // revertAllPositions({
                //     programCode: "MPPL",
                //     formId: this.$route.params.id,
                // }).then(() => {
                //     this.$emit("reloadFormData");
                // });
            },
            checkIfNotAcceptedPosition(transects) {
                transects.forEach((transect) => {
                    if (!transect.accepted) {
                        this.showAlert = true;
                        return;
                    }
                    this.showAlert = false;
                });
            },
        },

        computed: {
            internalForm: {
                get() {
                    this.checkIfNotAcceptedPosition(this.form.formTransects);
                    return this.form;
                },
                set(value) { this.$emit("update:form", value); },
            },
            text() {
                return "Nastąpiła zmiana lokalizacji odcinków";
            },
            isCoordinator() {
                return SystemRole.isMainCoordinator() || SystemRole.isRegionalCoordinator()
                    || SystemRole.isNationalCoordinator();
            },
        },

        watch: {
            internalForm: {
                handler(value) {
                    this.checkIfNotAcceptedPosition(value.formTransects);
                    this.$emit("update:form", value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
