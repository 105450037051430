<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              v-model:additionalInformation="position" additionalInformationInControl
                              :controls="controls">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition v-model="position" :positions="positions" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <div v-if="$route.query.birdCode !== 'CCC'" class="flex gap-3">
                <CustomCalendar class="w-4" label="Data liczenia" v-model="position.controlDate" name="controlDate" required />
                <CustomSelectOne label="Siedlisko" name="habitatType"
                                 v-model="position.habitatType" class="w-4" :items="dicts.habitatType"
                                 required :showErrors="showErrorMessages" :emptyValue="false" />
            </div>
            <div v-if="$route.query.birdCode === 'CCC'" class="flex gap-3">
                <CustomSelectOne label="Położenie gniazda" name="nestPosition"
                                 v-model="position.nestPosition" class="w-4" :items="dicts.nestPosition"
                                 required :showErrors="showErrorMessages" :emptyValue="false" />
            </div>
        </template>
        <template v-if="$route.query.birdCode === 'CCC'" v-slot:controlMainData="slotProps">
            <div>
                <CustomCalendar class="w-4" label="Data kontroli" v-model="slotProps.control.controlDate" name="controlDate" required />
            </div>
        </template>
        <template v-if="$route.query.birdCode === 'CCC'" v-slot:controlBirdsData="slotProps">
            <div class="flex flex-column">
                <div class="flex gap-3">
                    <CustomSelectOne label="Kryterium lęgowości" name="nestingCriteria"
                                     v-model="slotProps.control.nestingCriteria" class="w-6" :items="computedItems(dicts.nestingCriteria,slotProps.control.id)"
                                     required :showErrors="showErrorMessages" :emptyValue="false" />
                    <CustomSelectOne label="Metoda liczenia" name="countingMethod"
                                     v-model="slotProps.control.countingMethod" class="w-6" :items="dicts.countingMethod"
                                     required :showErrors="showErrorMessages" :emptyValue="false" />
                </div>
                <div class="flex gap-3">
                    <CustomSelectOne v-if="slotProps.control.id === 2" label="Wynik lęgu" name="breedingResult"
                                     v-model="slotProps.control.breedingResult" class="w-6 pr-2" :items="breedingResults"
                                     :showErrors="showErrorMessages" :emptyValue="false"
                                     disabled />
                </div>
            </div>
        </template>
        <template v-if="$route.query.birdCode !== 'CCC'" v-slot:birdsData>
            <FormBirds v-if="$route.query.birdCode === 'POG'
                || $route.query.birdCode === 'COF'" v-model:birds="position.birds" v-model:noBirds="position.noBirds" />
            <FormBirdsMFGP v-else v-model="position" />
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import FormBirdsMFGP from "./FormBirdsMFGP.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "FormMFGPObservations",

        components: {
            FormObservationsTemplate,
            ObservationsSelectPosition,
            CustomCalendar,
            FormBirds,
            FormBirdsMFGP,
            CustomSelectOne,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
            field: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                positions: [],
                activeIndex: 0,
                index: 0,
                position: _.cloneDeep(EmptyStructures.getEmptyMFGPPosition()),
                control: _.cloneDeep(EmptyStructures.getEmptyMFGPControl()),
                controls: [],
                breedingResults: [
                    {code: "success", label: "sukces"},
                    {code: "loss", label: "strata"},
                    {code: "unknown", label: "nieznany / nie dotyczy"},
                ],
                cloneControls: null,
            };
        },

        emits: ["update:step", "update:form"],

        inject: ["showErrorMessages", "editing", "dicts"],

        beforeMount() {
            this.positions = this.internalForm.positions.filter((p) => (p.notAudited === null || !p.notAudited));
            if (this.$route.query.birdCode === "CCC") {
                this.updatePositionAndControls(0);
                const nCCodes = ["HPM1", "HPM2", "HPM3", "HPM4", "HPM5", "HPM6", "HPMX"];
                if (nCCodes.includes(this.controls[1].nestingCriteria.code)) {
                    this.controls[1].breedingResult = {code: "success", label: "sukces"};
                } else if ((this.controls[0].nestingCriteria.code === "WYS" && this.controls[1].nestingCriteria.code === "HPA")
                    || this.controls[1].nestingCriteria.code === "HP0") {
                    this.controls[1].breedingResult = {code: "loss", label: "strata"};
                } else {
                    this.controls[1].breedingResult = {code: "unknown", label: "nieznany / nie dotyczy"};
                }
            }
        },

        methods: {
            updatePositionAndControls(newActiveIndex) {
                this.position = this.positions[newActiveIndex];
                if (this.positions[newActiveIndex].control1 != null) {
                    this.controls[0] = {...this.positions[newActiveIndex].control1, id: 1};
                } else {
                    this.controls[0] = {..._.cloneDeep(EmptyStructures.getEmptyMFGPControl()), id: 1};
                }
                if (this.positions[newActiveIndex].control2 != null) {
                    this.controls[1] = {...this.positions[newActiveIndex].control2, id: 2};
                } else {
                    this.controls[1] = {..._.cloneDeep(EmptyStructures.getEmptyMFGPControl()), id: 2};
                }
            },
            computedItems(dict, id) {
                if (dict && id) {
                    return dict.filter((nc) => nc.control === id);
                }
                return this.dict;
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            // position: {
            //     handler(value) {
            //         if (this.$route.query.birdCode === "CCC") {
            //             this.controls[0] = value.control1;
            //             this.controls[1] = value.control2;
            //         }
            //     },
            //     deep: true,
            // },
            activeIndex(value) {
                if (this.$route.query.birdCode === "CCC") {
                    this.updatePositionAndControls(value);
                }
            },
            controls: {
                handler(value) {
                    if (this.cloneControls && (this.cloneControls[0].nestingCriteria.code !== value[0].nestingCriteria.code
                        || this.cloneControls[1].nestingCriteria.code !== value[1].nestingCriteria.code)) {
                        const nCCodes = ["HPM1", "HPM2", "HPM3", "HPM4", "HPM5", "HPM6", "HPMX"];
                        if (nCCodes.includes(value[1].nestingCriteria.code)) {
                            value[1].breedingResult = {code: "success", label: "sukces"};
                        } else if ((value[0].nestingCriteria.code === "WYS" && value[1].nestingCriteria.code === "HPA")
                            || value[1].nestingCriteria.code === "HP0") {
                            value[1].breedingResult = {code: "loss", label: "strata"};
                        } else {
                            value[1].breedingResult = {code: "unknown", label: "nieznany / nie dotyczy"};
                        }
                    }
                    this.cloneControls = _.cloneDeep(value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
