<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              :controls="position.controls" canDeleteControl @deleteControl="deleteControl">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition v-model="position" :positions="positions" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlNestData>
            <NestDataMPS v-model="position" :positions="positions" :field="field" />
        </template>
        <template v-slot:controlMainData="slotProps">
            <ControlTypeAndDates v-model="slotProps.control" showTypeInput />
            <ControlWeather v-model="slotProps.control" hideAudibility />
        </template>
        <template v-slot:controlBirdsData="slotProps">
            <FormBirds v-model:birds="slotProps.control.birds" v-model:noBirds="slotProps.control.noBirds" />
        </template>
        <template v-slot:controlsSummary>
            <CustomButton v-if="editing" class="add-control-button align-self-center mb-4"
                          label="Dodaj kontrolę" icon="pi pi-plus" @click="addControl" />
        </template>
    </FormObservationsTemplate>
    <Dialog class="delete-control-dialog" v-model:visible="confirmDeleteDialog" header="Czy na pewno chcesz usunąć kontrolę?"
            :closable="false" :draggable="false" modal>
        <div class="delete-control-dialog-container">
            <div class="delete-control-dialog-buttons">
                <CustomButton label="Usuń" color="#EA3030" bgColor="transparent" @click="confirmDeleteControl" :disabled="!editing" />
                <CustomButton label="Anuluj" @click="confirmDeleteDialog = false" bgColor="transparent" :disabled="!editing" />
            </div>
        </div>
    </Dialog>
</template>

<script>
    import _ from "lodash";
    import Dialog from "primevue/dialog";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import NestDataMPS from "@/views/formMPS/components/observations/NestDataMPS.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomButton from "@/components/form/CustomButton.vue";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import ControlWeather from "@/components/formSharedComponents/ControlWeather.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";

    export default {
        name: "FormMPSObservations",

        components: {
            Dialog,
            ControlTypeAndDates,
            FormObservationsTemplate,
            ControlWeather,
            FormBirds,
            CustomButton,
            NestDataMPS,
            ObservationsSelectPosition,
        },

        props: {
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["editing"],

        data() {
            return {
                positions: [],
                position: _.cloneDeep(EmptyStructures.getEmptyMPSPosition()),
                activeIndex: 0,
                confirmDeleteDialog: false,
                deletingControlIndex: null,
            };
        },

        emits: ["update:step"],

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
            for (let i = 0; i < this.positions.length; i += 1) {
                if (this.positions[i].controls == null) {
                    this.positions[i].controls = [];
                }
                if (this.positions[i].controls.length === 0) {
                    this.positions[i].controls.push(_.cloneDeep(EmptyStructures.getEmptyMPSControl()));
                    this.activeIndex = this.positions[i].controls.length - 1;
                }
            }
        },

        methods: {
            addControl() {
                if (this.position.controls == null) {
                    this.position.controls = [];
                }
                this.position.controls.push(_.cloneDeep(EmptyStructures.getEmptyMPSControl()));
                this.activeIndex = this.position.controls.length - 1;
            },
            confirmDeleteControl() {
                if (this.position.controls.length > 1) {
                    this.position.controls.splice(this.deletingControlIndex, 1);
                    this.activeIndex = this.position.controls.length - 1;
                    this.confirmDeleteDialog = false;
                }
            },
            deleteControl(index) {
                if (this.position.controls.length > 1) {
                    this.deletingControlIndex = index;
                    if (_.isEqual(this.position.controls[index], EmptyStructures.getEmptyMPSControl())) {
                        this.confirmDeleteControl();
                    } else {
                        this.confirmDeleteDialog = true;
                    }
                }
            },
        },

        computed: {
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../../assets/theme/mytheme/variables";

    .colorRedBG {
        .p-accordion-header-link.p-accordion-header-action {
            background-color: $color-red !important;
            color: var(--surface-a) !important;
        }
    }
</style>
