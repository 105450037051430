<template>
    <div class="monitoring-program-summary w-6">
        <div class="monitoring-program-summary-box">
            <div v-for="(progress,index) in controlStatistics" :key="index"
                 class="single-summary-progress mb-2">
                <span class="font-bold text-left w-4">{{ progress.name }}</span>
                <!-- <ProgressCircle :progressNumber="progress.number" :progressAll="progress.all"
                                :progressPercentage="progress.percentOfAll" /> -->
                <ProgressBar :progressPercentage="progress.percentOfAll" class="w-6 progress-bar-container"
                             :progressNumber="progress.number" :progressAll="progress.all" showNumbers />
            </div>
        </div>
    </div>
</template>

<script>
    // import ProgressCircle from "@/components/ProgressCircle";
    import ProgressBar from "../../../components/ProgressBar.vue";

    export default {
        name: "MonitoringProgramSummary",

        components: {
            // ProgressCircle,
            ProgressBar,
        },

        props: {
            controlStatistics: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            getProgressAll(progress) {
                if (progress.all && progress.all !== "") {
                    return "/" + progress.all;
                }
                return "";
            },
            getProgressPercentage(progress) {
                if (progress.percentOfAll && progress.percentOfAll !== "") {
                    return " (" + progress.percentOfAll + "%)";
                }
                return "";
            },
        },
    };
</script>

<style lang="scss">
.monitoring-program-summary {
    border: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.monitoring-program-summary-box {
    width: 100%;
    padding: 12px;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}

.single-summary-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 960px) {
        flex-wrap: wrap;
    }
    @media screen and (max-width: 800px) {
        flex-direction: column;
        .progress-bar-container {
            width: 100% !important;
        }
    }
}
</style>
