<template>
    <div>
        <EnumDictSearchCriteria v-model="sc" @update:modelValue="search" />
        <EnumDictTable :searchCriteria="sc" ref="table" />
    </div>
</template>

<script>
    import EnumDictTable from "./EnumDictTable.vue";
    import EnumDictSearchCriteria from "./EnumDictSearchCriteria.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";

    export default {
        name: "EnumDictView",

        data() {
            return {
                sc: SearchCriteria.getClearSearchCriteriaEnumDicts(),
            };
        },

        methods: {
            search() {
                this.$refs.table.search();
            },
        },

        components: {
            EnumDictTable,
            EnumDictSearchCriteria,
        },
    };
</script>

<style lang="scss">
</style>
