<template>
    <div v-if="!loading">
        <h2 class="pt-4 pb-3">Szczegóły stanowiska</h2>
        <PositionMainInfo :position="position" :program="program"/>
        <PositionHistoryTable v-model="position" :total-records="totalRecords"
                              @positionHistoryEdited="fetchPosition(this.$route.params.id)"/>
    </div>
</template>

<script>
    import PositionMainInfo from "./components/PositionMainInfo";
    import PositionHistoryTable from "./components/PositionHistoryTable";
    import {
        getPositionById,
        // getPositionHistoryById,
    } from "@/swagger/vue-api-client";

    export default {
        name: "PositionDetailsView",

        components: {
            PositionMainInfo,
            PositionHistoryTable,
        },

        data() {
            return {
                isErrorDialogVisible: false,
                errorMessage: "",
                confirmationPositionDialogVisible: false,
                currentEntry: {},
                previousEntry: {},
                totalRecords: 0,
                position: {
                    histories: [],
                },
                loading: true,
                editDialogVisible: false,
                editingEntry: {
                    coordinates: "",
                    year: null,
                    accepted: false,
                },
                editPositionDialogVisible: false,
                editingPosition: {
                    id: "",
                    name: "",
                    status: "",
                },
                acceptedOptions: [
                    { label: "YES", value: true },
                    { label: "NO", value: false },
                ],
                deleteConfirmationVisible: false,
                entryToDelete: null,
                mapCoordinates: [],
                program: "",
                onlyStepOne: false,
            };
        },
        provide() {
            return {
                onlyStepOne: this.onlyStepOne,
            };
        },
        methods: {
            setLatLng(editingEntry) {
                this.editingEntry.latitude = editingEntry.lat;
                this.editingEntry.longitude = editingEntry.lng;
            },
            async fetchPosition(positionId) {
                this.loading = true;
                await getPositionById({id: positionId})
                    .then((response) => {
                        this.position = response.data;
                        this.program = this.position.field.program;
                        this.totalRecords = this.position.histories.length;
                        this.addPropertyNameId(this.position.histories);
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error("Failed to fetch position history:", error);
                    });
            },
            addPropertyNameId(array) {
                array.forEach((position) => {
                    position.positionId = position.id;
                });
            },
        },
        async created() {
            const positionId = this.$route.params.id;
            await this.fetchPosition(positionId);
        },
    };
</script>

<style scoped lang="scss">

</style>
