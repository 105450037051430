<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <CustomButton icon="pi pi-copy" style="margin-bottom: 1rem"
                          title="Skopiuj ostatnio dodany gatunek"
                          :disabled="!editing" @click="copyLastSpecies"
                          v-on:keydown.enter="copyLastSpecies" />
            <div class="w-1">
                <CustomAutocompleteBirdSpecies label="Kod" name="species" v-model="newItem.species"
                                               searchParam="code" required
                                               :showErrors="showErrors" :disabled="!editing" :emptyValue="false"
                                               :customErrorMessage="getIncorrectSpeciesMessage()" />
            </div>
            <div class="w-1">
                <CustomAutocompleteBirdSpecies label="Gatunek" name="species" v-model="newItem.species"
                                               searchParam="name" required
                                               :showErrors="showErrors" :disabled="!editing"
                                               :customErrorMessage="getIncorrectSpeciesMessage()" />
            </div>
            <div class="w-1">
                <CustomInputNumber label="L. osobników" name="count" v-model="newItem.count"
                                   required :showErrors="showErrors" :disabled="!editing" />
            </div>
            <div class="w-1">
                <CustomCheckbox label="W locie" name="inFlight" v-model="newItem.inFlight"
                                :disabled="!editing" />
            </div>
            <div class="w-2">
                <CustomSelectOne label="Płeć" name="sex" v-model="newItem.sex" :items="dicts.sex" :required="noteSex"
                                 :showErrors="showErrors && noteSex"
                                 :disabled="!editing || !noteSex" :emptyValue="false" />
            </div>
            <div class="w-2">
                <CustomSelectOne label="Wiek" name="age" v-model="newItem.age" :items="dicts.age" :required="noteAge"
                                 :showErrors="showErrors && noteAge"
                                 :disabled="!editing || !noteAge" :emptyValue="false" />
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem" v-on:keydown.tab="add"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomAutocompleteBirdSpecies from "@/components/form/CustomAutocompleteBirdSpecies.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "AddBirdsMZPW",

        components: {
            CustomAutocompleteBirdSpecies,
            CustomCheckbox,
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyBirds()),
                showErrors: false,
                speciesName: "",
                speciesCode: "",
                noteSex: true,
                noteAge: true,
                sexNotNotedValue: null,
                ageNotNotedValue: null,
            };
        },

        mounted() {
            const nCodeSex = this.dicts.sex.filter((e) => e.code === "N");
            this.sexNotNotedValue = nCodeSex.length > 0 ? nCodeSex[0] : null;
            const nCodeAge = this.dicts.age.filter((e) => e.code === "N");
            this.ageNotNotedValue = nCodeAge.length > 0 ? nCodeAge[0] : null;
        },

        methods: {
            add() {
                if (this.newItem.species == null || this.newItem.species === ""
                    || this.newItem.count == null || this.newItem.count === ""
                    || (this.noteSex && (this.newItem.sex == null || this.newItem.sex === ""))
                    || (this.noteAge && (this.newItem.age == null || this.newItem.age === ""))
                    || !ValidateUtils.validateCorrectSpecies(this.newItem, ["species"])) {
                    this.showErrors = true;
                } else {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length + 1,
                        // id: (this.birds.length + 1) + "-" + this.newItem.species.name,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyBirds());
                    this.showErrors = false;
                }
            },
            copyLastSpecies() {
                if (this.birds?.length > 0) {
                    this.newItem.species = this.birds[this.birds.length - 1].species;
                }
            },
            getIncorrectSpeciesMessage() {
                if (!ValidateUtils.validateCorrectSpecies(this.newItem, ["species"])) return "Niepoprawny gatunek";
                return "";
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            "newItem.species"(value) {
                this.noteSex = value == null || value.sex == null ? true : value.sex;
                this.noteAge = value == null || value.age == null ? true : value.age;
            },
            noteSex(value) {
                if (!value) this.newItem.sex = this.sexNotNotedValue;
            },
            noteAge(value) {
                if (!value) this.newItem.age = this.ageNotNotedValue;
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
</style>
