<template>
    <Dialog v-model:visible="visible" :draggable="false" modal :closable="false"
            header="Podaj przyczynę odrzucenia formularza">
        <div>
            <CustomInputTextArea :rows="7" v-model="rejectionCause" name="rejectionCause" />
        </div>
        <div class="reject-reason-dialog-buttons">
            <CustomButton label="Anuluj" @click="visible = false" />
            <CustomButton label="Odrzuć" @click="rejectThisForm" color="#EA3030" />
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import {rejectForm} from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea.vue";

    export default {
        name: "RejectFormDialog",

        components: {
            CustomButton,
            CustomInputTextArea,
            Dialog,
        },

        props: {
            modelValue: null,
        },

        data() {
            return {
                rejectionCause: "",
            };
        },

        emits: ["reject", "update:modelValue"],

        methods: {
            rejectThisForm() {
                rejectForm({
                    formId: this.$route.params.id,
                    body: this.rejectionCause,
                })
                    .then((response) => {
                        console.log(response);
                        this.$emit("reject");
                    });
            },
        },

        computed: {
            visible: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";
.reject-reason-dialog.p-dialog {
    .p-dialog-header {
        padding-left: 3rem;
        padding-right: 3rem;
        border: none;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: $color-white;
        color: var(--text-color);
    }
    .p-dialog-content {
        padding: 0;
        background-color: $color-white;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        height: fit-content;
    }
    .reject-reason-dialog-buttons {
        display: flex;
        width: 100%;
        padding: 0;
        position: relative;
        &:first-child {
            border-radius: 50%;
        }
        &::after {
            content: "";
            border: 1px solid var(--surface-0);
            height: 60%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%);
        }
        .custom-button {
            width: 50%;
            height: 56px;
            display: flex;
            justify-content: center;
            border-radius: 0;
            border: none;
        }
    }
}
</style>
