<template>
    <div class="data-aggregates-search-criteria flex justify-content-between flex-column"
         :class="monitoringProgram ? 'mt-3' : ''">
        <div class="flex gap-3 flex-wrap row-gap-0">
            <div class="flex gap-3 flex-wrap" :class="monitoringProgram ? 'w-2' : 'w-8'">
                <CustomCalendar v-if="!monitoringProgram" name="year" label="Rok" view="year" dateFormat="yy"
                                class="flex-1 w-0" v-model="searchCriteria.year" />
                <CustomMultiSelect v-if="!monitoringProgram && loadPrograms" name="program" label="Program"
                                   :items="programs" itemLabel="" v-model="searchCriteria.programs"
                                   class="flex-1 w-0" :emptyValue="false" />
                <CustomMultiSelect v-if="loadFields" name="field" label="Powierzchnia" :items="fields"
                                   itemLabel="name" itemValue="id" v-model="searchCriteria.fieldIds"
                                   class="flex-1 w-0" :emptyValue="false"
                                   :disabled="searchCriteria.programs === null
                                       || searchCriteria.programs.length === 0" />
                <CustomMultiSelect v-if="!monitoringProgram && loadObservers" name="observer" label="Obserwator"
                                   :items="observers" itemLabel="fullName" v-model="searchCriteria.observer"
                                   class="flex-1 w-0" :emptyValue="false" />
                <CustomMultiSelect v-if="!monitoringProgram" name="species" label="Gatunek" :items="species"
                                   itemLabel="" v-model="searchCriteria.speciesIds"
                                   class="flex-1 w-0" :emptyValue="false" />
            </div>
            <GeographicalFilters v-model="searchCriteria" v-model:matchMode="searchCriteria.matchMode"
                                 :filterBy="matchModeOptions" />
        </div>
        <div class="flex align-self-end justify-content-end field">
            <CustomButton label="Wyczyść" @click="clear"/>
        </div>
    </div>
</template>

<script>
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect";
    import CustomCalendar from "@/components/form/CustomCalendar";
    import CustomButton from "@/components/form/CustomButton";
    import {
        getAllObserversAsCoordinator,
        listFieldsForProgram,
        getListOfPrograms,
    } from "@/swagger/vue-api-client";
    import GeographicalFilters from "@/components/filters/GeographicalFilters.vue";

    export default {
        name: "DataAggregatesSearchCriteria",

        components: {
            CustomCalendar,
            CustomMultiSelect,
            CustomButton,
            GeographicalFilters,
        },

        props: {
            modelValue: null,
            monitoringProgram: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                observers: [],
                fields: [],
                species: [],
                programs: [],
                loadFields: false,
                loadObservers: false,
                loadSpecies: false,
                loadPrograms: false,
                matchModeOptions: [
                    {value: "FIELD", label: "Powierzchni"},
                ],
            };
        },

        emits: ["update:modelValue"],

        beforeMount() {
            getAllObserversAsCoordinator()
                .then((response) => {
                    this.observers = response.data;
                    this.loadObservers = true;
                });
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                    this.loadPrograms = true;
                });
        },

        methods: {
            clear() {
                this.searchCriteria = SearchCriteria.getClearSearchCriteriaDataAggregates();
            },

            getFields() {
                if (!this.searchCriteria.programs || this.searchCriteria.programs.length === 0) {
                    this.fields = [];
                    return;
                }

                const promises = this.searchCriteria.programs
                    .map((program) => listFieldsForProgram({ program }));

                Promise.all(promises)
                    .then((responses) => {
                        this.fields = responses.reduce((acc, response) => acc.concat(response.data), []);
                    })
                    .catch((error) => {
                        console.error("An error occurred:", error);
                    });
            },

            // computedMatchModeOptions(programType) {
            //     if (programType === "POINT") return {value: "POINT", label: "Punktach"};
            //     if (programType === "TRANSECT") return {value: "TRANSECT", label: "Transektach"};
            //     if (programType === "POSITION") return {value: "POSITION", label: "Stanowiskach"};
            //     return null;
            // },
        },

        computed: {
            searchCriteria: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    this.$emit("update:modelValue", value);
                    if (value.programs.length > 0) {
                        this.getFields();
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
