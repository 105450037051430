<template>
    <div class="flex justify-content-between flex-column">
        <div class="grid-container">
            <div class="grid-item">
                <CustomSelectRadio label="Użytkowanie" :options="dicts.utilization"
                                   v-model="internalHabitatData.utilization" :showErrors="showErrorMessages"
                                   :disabled="!editing || !editable" vertical :required="required"/>
                <CustomInputText v-if="internalHabitatData.utilization?.code === '5'" class="mt-4"
                                 v-model="internalHabitatData.differentUtilization"
                                 :showErrors="showErrorMessages" required />
            </div>
            <div class="grid-item">
                <CustomSelectRadio label="Kępy" :options="dicts.clumps" v-model="internalHabitatData.clumps"
                                   :showErrors="showErrorMessages"
                                   :disabled="!editing || !editable" vertical :required="required"/>
            </div>
            <div class="grid-item">
                <CustomSelectRadio label="Gatunek" :options="dicts.treeType" v-model="internalHabitatData.treeType"
                                   :showErrors="showErrorMessages"
                                   :disabled="!editing || !editable" vertical :required="required"/>
            </div>
            <div class="grid-item">
                <CustomSelectRadio label="Woda" :options="dicts.water" v-model="internalHabitatData.water"
                                   :showErrors="showErrorMessages"
                                   :disabled="!editing || !editable" vertical :required="required"/>
            </div>

            <div class="grid-item">
                <CustomSelectRadio label="Kiedy" :options="dicts.whenEnum" v-model="internalHabitatData.when"
                                   :showErrors="showErrorMessages"
                                   :disabled="!editing || !editable" vertical :required="required"/>
            </div>
            <div class="grid-item">
                <CustomSelectRadio label="Wysokość roślinności" :options="dicts.plantHeight"
                                   v-model="internalHabitatData.plantHeight" :disabled="!editing || !editable"
                                   :showErrors="showErrorMessages" vertical :required="required"/>
            </div>
            <div class="grid-item">
                <CustomInputNumber label="Wysokość zakrzewień" v-model="internalHabitatData.bushHeight"
                                   :disabled="!editing || !editable" name="bushesHeight"
                                   :showErrors="showErrorMessages" class="mb-0 pt-4" :required="required"/>
            </div>
            <div class="grid-item">
                <CustomSelectRadio label="Ściółka" :options="dicts.plantLitter"
                                   v-model="internalHabitatData.plantLitter" :showErrors="showErrorMessages"
                                   :disabled="!editing || !editable" vertical :required="required"/>
            </div>

            <div class="grid-item">
                <CustomSelectRadio label="Koleiny" :options="dicts.rut" v-model="internalHabitatData.rut"
                                   :showErrors="showErrorMessages"
                                   :disabled="!editing || !editable" vertical :required="required"/>
            </div>
            <div class="grid-item">
                <CustomSelectRadio label="Drzewa i krzewy" :options="dicts.treeBushesArea"
                                   v-model="internalHabitatData.treeBushesArea" :disabled="!editing || !editable"
                                   :showErrors="showErrorMessages" vertical :required="required"/>
            </div>
            <div class="grid-item">
                <CustomSelectRadio label="Trzcina" :options="dicts.cane" v-model="internalHabitatData.cane"
                                   :showErrors="showErrorMessages"
                                   :disabled="!editing || !editable" vertical :required="required"/>
            </div>
            <div class="grid-item">
                <CustomSelectRadio label="Miejsca na gniazdo" :options="dicts.nestPlace"
                                   v-model="internalHabitatData.nestPlace" :disabled="!editing || !editable"
                                   :showErrors="showErrorMessages" vertical :required="required"/>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import CustomSelectRadio from "@/components/form/CustomSelectRadio.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import { EmptyStructures } from "@/utils/EmptyStructures";

    export default {
        name: "HabitatDataMWOT",

        components: {
            CustomSelectRadio,
            CustomInputNumber,
            CustomInputText,
        },

        props: {
            habitatData: {
                type: Object,
            },
            required: {
                type: Boolean,
                default: false,
            },
            editable: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["dicts", "editing", "showErrorMessages"],

        emits: ["update:habitatData"],

        data() {
            return {
                emptyHabitatData: _.cloneDeep(EmptyStructures.getEmptyMWOTHabitatData()),
            };
        },

        computed: {
            internalHabitatData: {
                get() { return this.habitatData != null ? this.habitatData : this.emptyHabitatData; },
                set(value) { this.$emit("update:habitatData", value); },
            },
        },

        watch: {
            internalHabitatData: {
                handler(value) { this.$emit("update:habitatData", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
@import "../../../assets/theme/mytheme/variables";
.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    background: rgba(0, 0, 0, 0.08);
    grid-gap: 2px;
    margin: 1rem;
}
.grid-item {
    padding: 1rem;
    background: var(--surface-a);
}
</style>
