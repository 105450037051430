<template>
    <Dialog :draggable="false" v-model:visible="visible"
            class="generate-aggregates-control-dialog" modal :closable="false">
        <template #header>
            <div class="flex flex-column align-items-center">
                <span>Uwaga!</span>
                <span>Wszystkie dane zagregowane zatwierdzone przez koordynatorów zostaną usunięte
                    i ich poprawki nie będą możliwe do odzyskania.</span>
                <span>Czy chcesz kontynuować?</span>
            </div>
        </template>
        <div class="generate-aggregates-control-dialog-container">
            <div class="generate-aggregates-control-dialog-buttons">
                <CustomButton label="Wygeneruj dane" bgColor="transparent"
                              @click="generateAggregatesDialog" />
                <CustomButton label="Anuluj" @click="visible = false" bgColor="transparent" />
            </div>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "../../../components/form/CustomButton";
    import {createAggregates} from "@/swagger/vue-api-client";

    export default {
        name: "GenerateAggregatesDataDialog",

        components: {
            CustomButton,
            Dialog,
        },

        props: {
            modelValue: null,
            mzpwp: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            generateAggregatesDialog() {
                createAggregates({programEditionId: this.$route.params.id, mzpwp: this.internalMZPWP})
                    .then((response) => {
                        if (response.status === 200) {
                            this.internalMZPWP = false;
                            this.visible = false;
                            this.$toast.add({
                                severity: "success",
                                summary: "Wygenerowano pomyślnie",
                                life: 3000,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.internalMZPWP = false;
                        this.visible = false;
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            life: 3000,
                        });
                    });
            },
        },

        computed: {
            visible: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            internalMZPWP: {
                get() {
                    return this.mzpwp;
                },
                set(value) {
                    this.$emit("update:mzpwp", value);
                },
            },
        },

        watch: {
            internalMZPWP(value) {
                this.$emit("update:mzpwp", value);
            },
        },
    };
</script>

<style lang="scss">
@import "../../../assets/theme/mytheme/variables";
@import "../../assets/scss/dialogs.scss";
</style>
