<template>
    <ImportFieldDialog v-model="showImportDialog"/>
    <div class="flex justify-content-between flex-column">
        <div class="flex gap-3 row-gap-0">
            <div class="flex gap-3 flex-wrap row-gap-0">
                <CustomMultiSelect v-if="programs !== null" id="programFilter"
                                   v-model="internalValue.programs" name="programs"
                                   :items="programs" label="Program" class="w-10rem"
                                   @update:modelValue="update" :emptyValue="false"
                                   itemLabel="" />
                <CustomInputText name="name" label="Nazwa" id="name" v-model="internalValue.name" class="w-12rem"
                                 @update:modelValue="update" />
                <GeographicalFilters v-model="internalValue" v-model:matchMode="internalValue.matchMode"
                                     ref="geographicalFilters" :filterBy="matchModeOptions" />
            </div>
            <div class="flex align-self-end justify-content-end field">
                <CustomButton label="Wyczyść" @click="clear"/>
            </div>
        </div>
    </div>
    <div class="flex gap-3 justify-content-end mb-3">
        <CustomButton @click="navigateToCreateField" label="Utwórz powierzchnie"/>
        <CustomButton @click="exportData" label="Eksportuj"/>
        <CustomButton @click="showImportDialog = true" label="Importuj"/>
    </div>
</template>

<script>
    import ImportFieldDialog from "@/views/fieldTable/ImportFieldDialog.vue";
    import CustomButton from "@/components/form/CustomButton";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomInputText from "@/components/form/CustomInputText";
    import {
        getListOfPrograms,
    } from "@/swagger/vue-api-client";
    import {FileUtils} from "@/utils/FileUtils";
    import GeographicalFilters from "@/components/filters/GeographicalFilters.vue";

    export default {
        name: "PositionDictSearchCrtiera",

        components: {
            CustomButton,
            CustomMultiSelect,
            CustomInputText,
            ImportFieldDialog,
            GeographicalFilters,
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        data() {
            return {
                programs: null,
                showImportDialog: false,
                matchModeOptions: [
                    {value: "FIELD", label: "Powierzchni"},
                ],
            };
        },

        beforeMount() {
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                });
        },

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.programs = [];
                this.internalValue.name = "";
                this.$refs.geographicalFilters.clear();
                this.update();
            },

            navigateToCreateField() {
                this.$router.push("/create-field");
            },
            exportData() {
                FileUtils.downloadFile(this.modelValue, "powierzchnie.xlsx", "/field/export");
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
</style>
