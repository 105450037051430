<template>
    <DataTable :value="mammals" v-model:editingRows="editingRows" editMode="row" dataKey="code" lazy
               :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
               @page="onPage" @sort="onPage" @row-edit-save="onRowEditSave"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
               LastPageLink CurrentPageReport RowsPerPageDropdown" removableSort
               currentPageReportTemplate="Pozycji na stronie" paginator
               :rows="10" :rowsPerPageOptions="[5,10,20,50]" size="small"
               v-model:rows="page.limit" v-model:first="page.offset">
        <Column header="Kod" field="code">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="code" />
            </template>
        </Column>
        <Column header="Nazwa polska" field="namePl">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="namePl" />
            </template>
        </Column>
        <Column header="Nazwa angielska" field="nameEn">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="nameEn" />
            </template>
        </Column>
        <Column header="Nazwa łacińska" field="nameLat">
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="nameLat" />
            </template>
        </Column>
        <Column :rowEditor="true" style="text-align: center;" class="w-1"/>
        <Column style="width: 4.1666%; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteMammalDict(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    // import {} from "@/swagger/vue-api-client";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "MammalsDictTable",

        components: {
            DataTable,
            Column,
            CustomInputText,
            CustomButton,
        },

        data() {
            return {
                mammals: [],
                editingRows: ref([]),
                loading: false,
                totalRecords: 0,
                page: SearchCriteria.getClearPage(),
                searchCriteria: SearchCriteria.getClearSearchCriteriaEnumDicts(),
            };
        },

        mounted() {
            this.search();
        },

        methods: {
            async search() {
                this.loading = true;
                // const criteria = SearchCriteria.prepareCriteria(
                //     this.searchCriteria,
                //     this.page,
                // );
                this.mammals = [
                    {
                        namePl: "test", nameEn: "test", nameLat: "test", code: "test",
                    },
                ];
                this.loading = false;
            },

            onPage(event) {
                this.searchCriteria.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
            },

            onRowEditSave(event) {
                const {newData, index} = event;
                this.mammals[index] = newData;
            },

            deleteMammalDict(item) {
                const index = this.birds.indexOf(item);
                this.mammals.splice(index, 1);
            },
        },
    };
</script>

<style lang="scss">
</style>
