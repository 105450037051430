/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    
 ==========================================================*/
/**
 * 
 * request: createAggregates
 * url: createAggregatesURL
 * method: createAggregates_TYPE
 * raw_url: createAggregates_RAW_URL
 * @param programEditionId - 
 * @param mzpwp - 
 */
export const createAggregates = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/aggregates/create/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['mzpwp'] !== undefined) {
    queryParameters['mzpwp'] = parameters['mzpwp']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const createAggregates_RAW_URL = function() {
  return '/aggregates/create/{programEditionId}'
}
export const createAggregates_TYPE = function() {
  return 'get'
}
export const createAggregatesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/aggregates/create/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['mzpwp'] !== undefined) {
    queryParameters['mzpwp'] = parameters['mzpwp']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getAggregatesById
 * url: getAggregatesByIdURL
 * method: getAggregatesById_TYPE
 * raw_url: getAggregatesById_RAW_URL
 * @param id - 
 */
export const getAggregatesById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/aggregates/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAggregatesById_RAW_URL = function() {
  return '/aggregates/get/{id}'
}
export const getAggregatesById_TYPE = function() {
  return 'get'
}
export const getAggregatesByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/aggregates/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchAggregates
 * url: searchAggregatesURL
 * method: searchAggregates_TYPE
 * raw_url: searchAggregates_RAW_URL
 * @param body - 
 */
export const searchAggregates = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/aggregates/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchAggregates_RAW_URL = function() {
  return '/aggregates/search'
}
export const searchAggregates_TYPE = function() {
  return 'post'
}
export const searchAggregatesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/aggregates/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchAggregatesCount
 * url: searchAggregatesCountURL
 * method: searchAggregatesCount_TYPE
 * raw_url: searchAggregatesCount_RAW_URL
 * @param body - 
 */
export const searchAggregatesCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/aggregates/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchAggregatesCount_RAW_URL = function() {
  return '/aggregates/search/count'
}
export const searchAggregatesCount_TYPE = function() {
  return 'post'
}
export const searchAggregatesCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/aggregates/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateAggregates
 * url: updateAggregatesURL
 * method: updateAggregates_TYPE
 * raw_url: updateAggregates_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateAggregates = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/aggregates/update/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateAggregates_RAW_URL = function() {
  return '/aggregates/update/{id}'
}
export const updateAggregates_TYPE = function() {
  return 'post'
}
export const updateAggregatesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/aggregates/update/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportToExcelFormSearch
 * url: exportToExcelFormSearchURL
 * method: exportToExcelFormSearch_TYPE
 * raw_url: exportToExcelFormSearch_RAW_URL
 * @param body - 
 */
export const exportToExcelFormSearch = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/data-raw/export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportToExcelFormSearch_RAW_URL = function() {
  return '/data-raw/export'
}
export const exportToExcelFormSearch_TYPE = function() {
  return 'post'
}
export const exportToExcelFormSearchURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/data-raw/export'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportToExcelFormInProgramEdition
 * url: exportToExcelFormInProgramEditionURL
 * method: exportToExcelFormInProgramEdition_TYPE
 * raw_url: exportToExcelFormInProgramEdition_RAW_URL
 * @param programEditionId - 
 */
export const exportToExcelFormInProgramEdition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/data-raw/export/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const exportToExcelFormInProgramEdition_RAW_URL = function() {
  return '/data-raw/export/{programEditionId}'
}
export const exportToExcelFormInProgramEdition_TYPE = function() {
  return 'get'
}
export const exportToExcelFormInProgramEditionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/data-raw/export/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchForm
 * url: searchFormURL
 * method: searchForm_TYPE
 * raw_url: searchForm_RAW_URL
 * @param body - 
 */
export const searchForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/data-raw/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchForm_RAW_URL = function() {
  return '/data-raw/search'
}
export const searchForm_TYPE = function() {
  return 'post'
}
export const searchFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/data-raw/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: countForm
 * url: countFormURL
 * method: countForm_TYPE
 * raw_url: countForm_RAW_URL
 * @param body - 
 */
export const countForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/data-raw/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const countForm_RAW_URL = function() {
  return '/data-raw/search/count'
}
export const countForm_TYPE = function() {
  return 'post'
}
export const countFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/data-raw/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getRawDataFilters
 * url: getRawDataFiltersURL
 * method: getRawDataFilters_TYPE
 * raw_url: getRawDataFilters_RAW_URL
 * @param program - 
 * @param qualifier - 
 */
export const getRawDataFilters = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/data-raw/{program}/search/filters'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  if (parameters['qualifier'] !== undefined) {
    queryParameters['qualifier'] = parameters['qualifier']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRawDataFilters_RAW_URL = function() {
  return '/data-raw/{program}/search/filters'
}
export const getRawDataFilters_TYPE = function() {
  return 'get'
}
export const getRawDataFiltersURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/data-raw/{program}/search/filters'
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['qualifier'] !== undefined) {
    queryParameters['qualifier'] = parameters['qualifier']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: addDataIndicator
 * url: addDataIndicatorURL
 * method: addDataIndicator_TYPE
 * raw_url: addDataIndicator_RAW_URL
 * @param body - 
 */
export const addDataIndicator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dataIndicator/add'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addDataIndicator_RAW_URL = function() {
  return '/dataIndicator/add'
}
export const addDataIndicator_TYPE = function() {
  return 'post'
}
export const addDataIndicatorURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dataIndicator/add'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteDataIndicator
 * url: deleteDataIndicatorURL
 * method: deleteDataIndicator_TYPE
 * raw_url: deleteDataIndicator_RAW_URL
 * @param id - 
 */
export const deleteDataIndicator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dataIndicator/delete/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteDataIndicator_RAW_URL = function() {
  return '/dataIndicator/delete/{id}'
}
export const deleteDataIndicator_TYPE = function() {
  return 'delete'
}
export const deleteDataIndicatorURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dataIndicator/delete/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getDataIndicatorbyId
 * url: getDataIndicatorbyIdURL
 * method: getDataIndicatorbyId_TYPE
 * raw_url: getDataIndicatorbyId_RAW_URL
 * @param id - 
 */
export const getDataIndicatorbyId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dataIndicator/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDataIndicatorbyId_RAW_URL = function() {
  return '/dataIndicator/get/{id}'
}
export const getDataIndicatorbyId_TYPE = function() {
  return 'get'
}
export const getDataIndicatorbyIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dataIndicator/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importDataIndicator
 * url: importDataIndicatorURL
 * method: importDataIndicator_TYPE
 * raw_url: importDataIndicator_RAW_URL
 * @param body - 
 */
export const importDataIndicator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dataIndicator/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importDataIndicator_RAW_URL = function() {
  return '/dataIndicator/import'
}
export const importDataIndicator_TYPE = function() {
  return 'post'
}
export const importDataIndicatorURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dataIndicator/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchDataIndicator
 * url: searchDataIndicatorURL
 * method: searchDataIndicator_TYPE
 * raw_url: searchDataIndicator_RAW_URL
 * @param body - 
 */
export const searchDataIndicator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dataIndicator/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchDataIndicator_RAW_URL = function() {
  return '/dataIndicator/search'
}
export const searchDataIndicator_TYPE = function() {
  return 'post'
}
export const searchDataIndicatorURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dataIndicator/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchDataIndicatorCount
 * url: searchDataIndicatorCountURL
 * method: searchDataIndicatorCount_TYPE
 * raw_url: searchDataIndicatorCount_RAW_URL
 * @param body - 
 */
export const searchDataIndicatorCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dataIndicator/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchDataIndicatorCount_RAW_URL = function() {
  return '/dataIndicator/search/count'
}
export const searchDataIndicatorCount_TYPE = function() {
  return 'post'
}
export const searchDataIndicatorCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dataIndicator/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportDataIndicator
 * url: exportDataIndicatorURL
 * method: exportDataIndicator_TYPE
 * raw_url: exportDataIndicator_RAW_URL
 * @param body - 
 */
export const exportDataIndicator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dataIndicator/search/export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportDataIndicator_RAW_URL = function() {
  return '/dataIndicator/search/export'
}
export const exportDataIndicator_TYPE = function() {
  return 'post'
}
export const exportDataIndicatorURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dataIndicator/search/export'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateDataIndicator
 * url: updateDataIndicatorURL
 * method: updateDataIndicator_TYPE
 * raw_url: updateDataIndicator_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateDataIndicator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dataIndicator/update/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateDataIndicator_RAW_URL = function() {
  return '/dataIndicator/update/{id}'
}
export const updateDataIndicator_TYPE = function() {
  return 'post'
}
export const updateDataIndicatorURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dataIndicator/update/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateDict
 * url: updateDictURL
 * method: updateDict_TYPE
 * raw_url: updateDict_RAW_URL
 * @param body - 
 */
export const updateDict = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dict/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateDict_RAW_URL = function() {
  return '/dict/edit'
}
export const updateDict_TYPE = function() {
  return 'post'
}
export const updateDictURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dict/edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getListOfFormStates
 * url: getListOfFormStatesURL
 * method: getListOfFormStates_TYPE
 * raw_url: getListOfFormStates_RAW_URL
 */
export const getListOfFormStates = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dict/list/formStates'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getListOfFormStates_RAW_URL = function() {
  return '/dict/list/formStates'
}
export const getListOfFormStates_TYPE = function() {
  return 'get'
}
export const getListOfFormStatesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dict/list/formStates'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getListOfPrograms
 * url: getListOfProgramsURL
 * method: getListOfPrograms_TYPE
 * raw_url: getListOfPrograms_RAW_URL
 */
export const getListOfPrograms = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dict/list/programs'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getListOfPrograms_RAW_URL = function() {
  return '/dict/list/programs'
}
export const getListOfPrograms_TYPE = function() {
  return 'get'
}
export const getListOfProgramsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dict/list/programs'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getList
 * url: getListURL
 * method: getList_TYPE
 * raw_url: getList_RAW_URL
 * @param programCode - 
 * @param formId - 
 */
export const getList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dict/list/{programCode}/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getList_RAW_URL = function() {
  return '/dict/list/{programCode}/{formId}'
}
export const getList_TYPE = function() {
  return 'get'
}
export const getListURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dict/list/{programCode}/{formId}'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchList
 * url: searchListURL
 * method: searchList_TYPE
 * raw_url: searchList_RAW_URL
 * @param dictType - 
 * @param body - 
 */
export const searchList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dict/search/{dictType}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dictType}', `${parameters['dictType']}`)
  if (parameters['dictType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dictType'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchList_RAW_URL = function() {
  return '/dict/search/{dictType}'
}
export const searchList_TYPE = function() {
  return 'post'
}
export const searchListURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dict/search/{dictType}'
  path = path.replace('{dictType}', `${parameters['dictType']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: sendEmail_1
 * url: sendEmail_1URL
 * method: sendEmail_1_TYPE
 * raw_url: sendEmail_1_RAW_URL
 * @param emailHistoryId - 
 */
export const sendEmail_1 = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/email/get/history/{emailHistoryId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{emailHistoryId}', `${parameters['emailHistoryId']}`)
  if (parameters['emailHistoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: emailHistoryId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const sendEmail_1_RAW_URL = function() {
  return '/email/get/history/{emailHistoryId}'
}
export const sendEmail_1_TYPE = function() {
  return 'get'
}
export const sendEmail_1URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/email/get/history/{emailHistoryId}'
  path = path.replace('{emailHistoryId}', `${parameters['emailHistoryId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchEmailHistory
 * url: searchEmailHistoryURL
 * method: searchEmailHistory_TYPE
 * raw_url: searchEmailHistory_RAW_URL
 * @param body - 
 */
export const searchEmailHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/email/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEmailHistory_RAW_URL = function() {
  return '/email/search'
}
export const searchEmailHistory_TYPE = function() {
  return 'post'
}
export const searchEmailHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/email/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: countEmailHistory
 * url: countEmailHistoryURL
 * method: countEmailHistory_TYPE
 * raw_url: countEmailHistory_RAW_URL
 * @param body - 
 */
export const countEmailHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/email/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const countEmailHistory_RAW_URL = function() {
  return '/email/search/count'
}
export const countEmailHistory_TYPE = function() {
  return 'post'
}
export const countEmailHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/email/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchRecipientsByProgram
 * url: searchRecipientsByProgramURL
 * method: searchRecipientsByProgram_TYPE
 * raw_url: searchRecipientsByProgram_RAW_URL
 * @param body - 
 */
export const searchRecipientsByProgram = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/email/search/users/program'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchRecipientsByProgram_RAW_URL = function() {
  return '/email/search/users/program'
}
export const searchRecipientsByProgram_TYPE = function() {
  return 'post'
}
export const searchRecipientsByProgramURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/email/search/users/program'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchRecipientsByRole
 * url: searchRecipientsByRoleURL
 * method: searchRecipientsByRole_TYPE
 * raw_url: searchRecipientsByRole_RAW_URL
 * @param body - 
 */
export const searchRecipientsByRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/email/search/users/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchRecipientsByRole_RAW_URL = function() {
  return '/email/search/users/role'
}
export const searchRecipientsByRole_TYPE = function() {
  return 'post'
}
export const searchRecipientsByRoleURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/email/search/users/role'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: sendEmail
 * url: sendEmailURL
 * method: sendEmail_TYPE
 * raw_url: sendEmail_RAW_URL
 * @param body - 
 */
export const sendEmail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/email/send'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const sendEmail_RAW_URL = function() {
  return '/email/send'
}
export const sendEmail_TYPE = function() {
  return 'post'
}
export const sendEmailURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/email/send'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getEmailTags
 * url: getEmailTagsURL
 * method: getEmailTags_TYPE
 * raw_url: getEmailTags_RAW_URL
 */
export const getEmailTags = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/email/tags'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEmailTags_RAW_URL = function() {
  return '/email/tags'
}
export const getEmailTags_TYPE = function() {
  return 'get'
}
export const getEmailTagsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/email/tags'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchEventLogsCount
 * url: searchEventLogsCountURL
 * method: searchEventLogsCount_TYPE
 * raw_url: searchEventLogsCount_RAW_URL
 * @param programEditionId - 
 * @param body - 
 */
export const searchEventLogsCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-logs/search-count/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEventLogsCount_RAW_URL = function() {
  return '/event-logs/search-count/{programEditionId}'
}
export const searchEventLogsCount_TYPE = function() {
  return 'post'
}
export const searchEventLogsCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-logs/search-count/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchEventLogs
 * url: searchEventLogsURL
 * method: searchEventLogs_TYPE
 * raw_url: searchEventLogs_RAW_URL
 * @param programEditionId - 
 * @param body - 
 */
export const searchEventLogs = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-logs/search/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEventLogs_RAW_URL = function() {
  return '/event-logs/search/{programEditionId}'
}
export const searchEventLogs_TYPE = function() {
  return 'post'
}
export const searchEventLogsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-logs/search/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createField
 * url: createFieldURL
 * method: createField_TYPE
 * raw_url: createField_RAW_URL
 * @param body - 
 */
export const createField = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createField_RAW_URL = function() {
  return '/field/create'
}
export const createField_TYPE = function() {
  return 'post'
}
export const createFieldURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportFields
 * url: exportFieldsURL
 * method: exportFields_TYPE
 * raw_url: exportFields_RAW_URL
 * @param body - 
 */
export const exportFields = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportFields_RAW_URL = function() {
  return '/field/export'
}
export const exportFields_TYPE = function() {
  return 'post'
}
export const exportFieldsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/export'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getFieldById
 * url: getFieldByIdURL
 * method: getFieldById_TYPE
 * raw_url: getFieldById_RAW_URL
 * @param id - 
 */
export const getFieldById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFieldById_RAW_URL = function() {
  return '/field/get/{id}'
}
export const getFieldById_TYPE = function() {
  return 'get'
}
export const getFieldByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importFields
 * url: importFieldsURL
 * method: importFields_TYPE
 * raw_url: importFields_RAW_URL
 * @param body - 
 */
export const importFields = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importFields_RAW_URL = function() {
  return '/field/import'
}
export const importFields_TYPE = function() {
  return 'post'
}
export const importFieldsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: listFieldsForProgram
 * url: listFieldsForProgramURL
 * method: listFieldsForProgram_TYPE
 * raw_url: listFieldsForProgram_RAW_URL
 * @param program - 
 */
export const listFieldsForProgram = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/list/program/{program}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const listFieldsForProgram_RAW_URL = function() {
  return '/field/list/program/{program}'
}
export const listFieldsForProgram_TYPE = function() {
  return 'get'
}
export const listFieldsForProgramURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/list/program/{program}'
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: listSubfieldsForField
 * url: listSubfieldsForFieldURL
 * method: listSubfieldsForField_TYPE
 * raw_url: listSubfieldsForField_RAW_URL
 * @param fieldId - 
 */
export const listSubfieldsForField = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/list/subfields/field/{fieldId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{fieldId}', `${parameters['fieldId']}`)
  if (parameters['fieldId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fieldId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const listSubfieldsForField_RAW_URL = function() {
  return '/field/list/subfields/field/{fieldId}'
}
export const listSubfieldsForField_TYPE = function() {
  return 'get'
}
export const listSubfieldsForFieldURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/list/subfields/field/{fieldId}'
  path = path.replace('{fieldId}', `${parameters['fieldId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: listSubfields
 * url: listSubfieldsURL
 * method: listSubfields_TYPE
 * raw_url: listSubfields_RAW_URL
 * @param programEditionId - 
 */
export const listSubfields = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/list/subfields/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const listSubfields_RAW_URL = function() {
  return '/field/list/subfields/{programEditionId}'
}
export const listSubfields_TYPE = function() {
  return 'get'
}
export const listSubfieldsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/list/subfields/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: listFields
 * url: listFieldsURL
 * method: listFields_TYPE
 * raw_url: listFields_RAW_URL
 * @param programEditionId - 
 */
export const listFields = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/list/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const listFields_RAW_URL = function() {
  return '/field/list/{programEditionId}'
}
export const listFields_TYPE = function() {
  return 'get'
}
export const listFieldsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/list/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchFields
 * url: searchFieldsURL
 * method: searchFields_TYPE
 * raw_url: searchFields_RAW_URL
 * @param body - 
 */
export const searchFields = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchFields_RAW_URL = function() {
  return '/field/search'
}
export const searchFields_TYPE = function() {
  return 'post'
}
export const searchFieldsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchFieldsCount
 * url: searchFieldsCountURL
 * method: searchFieldsCount_TYPE
 * raw_url: searchFieldsCount_RAW_URL
 * @param body - 
 */
export const searchFieldsCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchFieldsCount_RAW_URL = function() {
  return '/field/search/count'
}
export const searchFieldsCount_TYPE = function() {
  return 'post'
}
export const searchFieldsCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateField
 * url: updateFieldURL
 * method: updateField_TYPE
 * raw_url: updateField_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateField = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/field/update/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateField_RAW_URL = function() {
  return '/field/update/{id}'
}
export const updateField_TYPE = function() {
  return 'post'
}
export const updateFieldURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/field/update/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getFilesByForm
 * url: getFilesByFormURL
 * method: getFilesByForm_TYPE
 * raw_url: getFilesByForm_RAW_URL
 * @param formId - 
 */
export const getFilesByForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/files'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['formId'] !== undefined) {
    queryParameters['formId'] = parameters['formId']
  }
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFilesByForm_RAW_URL = function() {
  return '/files'
}
export const getFilesByForm_TYPE = function() {
  return 'get'
}
export const getFilesByFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/files'
  if (parameters['formId'] !== undefined) {
    queryParameters['formId'] = parameters['formId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: uploadFiles
 * url: uploadFilesURL
 * method: uploadFiles_TYPE
 * raw_url: uploadFiles_RAW_URL
 * @param formId - 
 * @param body - 
 */
export const uploadFiles = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/files'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['formId'] !== undefined) {
    queryParameters['formId'] = parameters['formId']
  }
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const uploadFiles_RAW_URL = function() {
  return '/files'
}
export const uploadFiles_TYPE = function() {
  return 'post'
}
export const uploadFilesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/files'
  if (parameters['formId'] !== undefined) {
    queryParameters['formId'] = parameters['formId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteFile
 * url: deleteFileURL
 * method: deleteFile_TYPE
 * raw_url: deleteFile_RAW_URL
 * @param id - 
 */
export const deleteFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/files/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteFile_RAW_URL = function() {
  return '/files/{id}'
}
export const deleteFile_TYPE = function() {
  return 'delete'
}
export const deleteFileURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/files/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getFile
 * url: getFileURL
 * method: getFile_TYPE
 * raw_url: getFile_RAW_URL
 * @param id - 
 */
export const getFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/files/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFile_RAW_URL = function() {
  return '/files/{id}'
}
export const getFile_TYPE = function() {
  return 'get'
}
export const getFileURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/files/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getFileDTO
 * url: getFileDTOURL
 * method: getFileDTO_TYPE
 * raw_url: getFileDTO_RAW_URL
 * @param id - 
 */
export const getFileDTO = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/files/{id}/dto'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFileDTO_RAW_URL = function() {
  return '/files/{id}/dto'
}
export const getFileDTO_TYPE = function() {
  return 'get'
}
export const getFileDTOURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/files/{id}/dto'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getAllGeographies
 * url: getAllGeographiesURL
 * method: getAllGeographies_TYPE
 * raw_url: getAllGeographies_RAW_URL
 */
export const getAllGeographies = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/filter/geographies'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getAllGeographies_RAW_URL = function() {
  return '/filter/geographies'
}
export const getAllGeographies_TYPE = function() {
  return 'post'
}
export const getAllGeographiesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/filter/geographies'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getSelectedGeographies
 * url: getSelectedGeographiesURL
 * method: getSelectedGeographies_TYPE
 * raw_url: getSelectedGeographies_RAW_URL
 * @param type - 
 */
export const getSelectedGeographies = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/filter/geographies/{type}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{type}', `${parameters['type']}`)
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSelectedGeographies_RAW_URL = function() {
  return '/filter/geographies/{type}'
}
export const getSelectedGeographies_TYPE = function() {
  return 'get'
}
export const getSelectedGeographiesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/filter/geographies/{type}'
  path = path.replace('{type}', `${parameters['type']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: approveForm
 * url: approveFormURL
 * method: approveForm_TYPE
 * raw_url: approveForm_RAW_URL
 * @param formId - 
 * @param validate - 
 */
export const approveForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/approve/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['validate'] !== undefined) {
    queryParameters['validate'] = parameters['validate']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const approveForm_RAW_URL = function() {
  return '/form/approve/{formId}'
}
export const approveForm_TYPE = function() {
  return 'post'
}
export const approveFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/approve/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['validate'] !== undefined) {
    queryParameters['validate'] = parameters['validate']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: addPosition
 * url: addPositionURL
 * method: addPosition_TYPE
 * raw_url: addPosition_RAW_URL
 * @param programCode - 
 * @param formId - 
 * @param force - 
 * @param body - 
 */
export const addPosition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/dynamicPosition/{programCode}/{formId}/add'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addPosition_RAW_URL = function() {
  return '/form/dynamicPosition/{programCode}/{formId}/add'
}
export const addPosition_TYPE = function() {
  return 'post'
}
export const addPositionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/dynamicPosition/{programCode}/{formId}/add'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: assignPosition
 * url: assignPositionURL
 * method: assignPosition_TYPE
 * raw_url: assignPosition_RAW_URL
 * @param programCode - 
 * @param formId - 
 * @param positionId - 
 */
export const assignPosition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/dynamicPosition/{programCode}/{formId}/assign/{positionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  path = path.replace('{positionId}', `${parameters['positionId']}`)
  if (parameters['positionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: positionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const assignPosition_RAW_URL = function() {
  return '/form/dynamicPosition/{programCode}/{formId}/assign/{positionId}'
}
export const assignPosition_TYPE = function() {
  return 'post'
}
export const assignPositionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/dynamicPosition/{programCode}/{formId}/assign/{positionId}'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  path = path.replace('{positionId}', `${parameters['positionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: editPositionHistory
 * url: editPositionHistoryURL
 * method: editPositionHistory_TYPE
 * raw_url: editPositionHistory_RAW_URL
 * @param programCode - 
 * @param formId - 
 * @param body - 
 */
export const editPositionHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/dynamicPosition/{programCode}/{formId}/edit'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editPositionHistory_RAW_URL = function() {
  return '/form/dynamicPosition/{programCode}/{formId}/edit'
}
export const editPositionHistory_TYPE = function() {
  return 'post'
}
export const editPositionHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/dynamicPosition/{programCode}/{formId}/edit'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getHistoricalPositions
 * url: getHistoricalPositionsURL
 * method: getHistoricalPositions_TYPE
 * raw_url: getHistoricalPositions_RAW_URL
 * @param programCode - 
 * @param formId - 
 * @param fieldName - 
 */
export const getHistoricalPositions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/dynamicPosition/{programCode}/{formId}/historical'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['fieldName'] !== undefined) {
    queryParameters['fieldName'] = parameters['fieldName']
  }
  if (parameters['fieldName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fieldName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getHistoricalPositions_RAW_URL = function() {
  return '/form/dynamicPosition/{programCode}/{formId}/historical'
}
export const getHistoricalPositions_TYPE = function() {
  return 'get'
}
export const getHistoricalPositionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/dynamicPosition/{programCode}/{formId}/historical'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['fieldName'] !== undefined) {
    queryParameters['fieldName'] = parameters['fieldName']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deletePosition
 * url: deletePositionURL
 * method: deletePosition_TYPE
 * raw_url: deletePosition_RAW_URL
 * @param programCode - 
 * @param formId - 
 * @param positionId - 
 */
export const deletePosition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/dynamicPosition/{programCode}/{formId}/position/{positionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  path = path.replace('{positionId}', `${parameters['positionId']}`)
  if (parameters['positionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: positionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePosition_RAW_URL = function() {
  return '/form/dynamicPosition/{programCode}/{formId}/position/{positionId}'
}
export const deletePosition_TYPE = function() {
  return 'delete'
}
export const deletePositionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/dynamicPosition/{programCode}/{formId}/position/{positionId}'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  path = path.replace('{positionId}', `${parameters['positionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: revertPosition
 * url: revertPositionURL
 * method: revertPosition_TYPE
 * raw_url: revertPosition_RAW_URL
 * @param programCode - 
 * @param formId - 
 * @param positionId - 
 */
export const revertPosition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/dynamicPosition/{programCode}/{formId}/position/{positionId}/revert'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  path = path.replace('{positionId}', `${parameters['positionId']}`)
  if (parameters['positionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: positionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const revertPosition_RAW_URL = function() {
  return '/form/dynamicPosition/{programCode}/{formId}/position/{positionId}/revert'
}
export const revertPosition_TYPE = function() {
  return 'post'
}
export const revertPositionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/dynamicPosition/{programCode}/{formId}/position/{positionId}/revert'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  path = path.replace('{positionId}', `${parameters['positionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: revertAllPositions
 * url: revertAllPositionsURL
 * method: revertAllPositions_TYPE
 * raw_url: revertAllPositions_RAW_URL
 * @param programCode - 
 * @param formId - 
 */
export const revertAllPositions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/dynamicPosition/{programCode}/{formId}/positions/revert'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const revertAllPositions_RAW_URL = function() {
  return '/form/dynamicPosition/{programCode}/{formId}/positions/revert'
}
export const revertAllPositions_TYPE = function() {
  return 'post'
}
export const revertAllPositionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/dynamicPosition/{programCode}/{formId}/positions/revert'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getRemovedPositions
 * url: getRemovedPositionsURL
 * method: getRemovedPositions_TYPE
 * raw_url: getRemovedPositions_RAW_URL
 * @param programCode - 
 * @param formId - 
 * @param fieldName - 
 */
export const getRemovedPositions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/dynamicPosition/{programCode}/{formId}/removed'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['fieldName'] !== undefined) {
    queryParameters['fieldName'] = parameters['fieldName']
  }
  if (parameters['fieldName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fieldName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRemovedPositions_RAW_URL = function() {
  return '/form/dynamicPosition/{programCode}/{formId}/removed'
}
export const getRemovedPositions_TYPE = function() {
  return 'get'
}
export const getRemovedPositionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/dynamicPosition/{programCode}/{formId}/removed'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['fieldName'] !== undefined) {
    queryParameters['fieldName'] = parameters['fieldName']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: revertAllGeographicalHistoryInForm
 * url: revertAllGeographicalHistoryInFormURL
 * method: revertAllGeographicalHistoryInForm_TYPE
 * raw_url: revertAllGeographicalHistoryInForm_RAW_URL
 * @param programCode - 
 * @param formId - 
 */
export const revertAllGeographicalHistoryInForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/geographical/{programCode}/{formId}/all/revert'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const revertAllGeographicalHistoryInForm_RAW_URL = function() {
  return '/form/geographical/{programCode}/{formId}/all/revert'
}
export const revertAllGeographicalHistoryInForm_TYPE = function() {
  return 'post'
}
export const revertAllGeographicalHistoryInFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/geographical/{programCode}/{formId}/all/revert'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: editGeographicalHistoryInForm
 * url: editGeographicalHistoryInFormURL
 * method: editGeographicalHistoryInForm_TYPE
 * raw_url: editGeographicalHistoryInForm_RAW_URL
 * @param programCode - 
 * @param formId - 
 * @param body - 
 */
export const editGeographicalHistoryInForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/geographical/{programCode}/{formId}/edit'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editGeographicalHistoryInForm_RAW_URL = function() {
  return '/form/geographical/{programCode}/{formId}/edit'
}
export const editGeographicalHistoryInForm_TYPE = function() {
  return 'post'
}
export const editGeographicalHistoryInFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/geographical/{programCode}/{formId}/edit'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: revertGeographicalHistoryInForm
 * url: revertGeographicalHistoryInFormURL
 * method: revertGeographicalHistoryInForm_TYPE
 * raw_url: revertGeographicalHistoryInForm_RAW_URL
 * @param programCode - 
 * @param formId - 
 * @param geographicalObjectId - 
 */
export const revertGeographicalHistoryInForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/geographical/{programCode}/{formId}/{geographicalObjectId}/revert'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  path = path.replace('{geographicalObjectId}', `${parameters['geographicalObjectId']}`)
  if (parameters['geographicalObjectId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: geographicalObjectId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const revertGeographicalHistoryInForm_RAW_URL = function() {
  return '/form/geographical/{programCode}/{formId}/{geographicalObjectId}/revert'
}
export const revertGeographicalHistoryInForm_TYPE = function() {
  return 'post'
}
export const revertGeographicalHistoryInFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/geographical/{programCode}/{formId}/{geographicalObjectId}/revert'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  path = path.replace('{geographicalObjectId}', `${parameters['geographicalObjectId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: approveFormPointZero
 * url: approveFormPointZeroURL
 * method: approveFormPointZero_TYPE
 * raw_url: approveFormPointZero_RAW_URL
 * @param formId - 
 */
export const approveFormPointZero = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/zero/point/approve/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const approveFormPointZero_RAW_URL = function() {
  return '/form/zero/point/approve/{formId}'
}
export const approveFormPointZero_TYPE = function() {
  return 'post'
}
export const approveFormPointZeroURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/zero/point/approve/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: editPointZero
 * url: editPointZeroURL
 * method: editPointZero_TYPE
 * raw_url: editPointZero_RAW_URL
 * @param formId - 
 * @param body - 
 */
export const editPointZero = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/zero/point/edit/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editPointZero_RAW_URL = function() {
  return '/form/zero/point/edit/{formId}'
}
export const editPointZero_TYPE = function() {
  return 'post'
}
export const editPointZeroURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/zero/point/edit/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getPointZero
 * url: getPointZeroURL
 * method: getPointZero_TYPE
 * raw_url: getPointZero_RAW_URL
 * @param formId - 
 */
export const getPointZero = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/zero/point/get/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPointZero_RAW_URL = function() {
  return '/form/zero/point/get/{formId}'
}
export const getPointZero_TYPE = function() {
  return 'get'
}
export const getPointZeroURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/zero/point/get/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: addPointZero
 * url: addPointZeroURL
 * method: addPointZero_TYPE
 * raw_url: addPointZero_RAW_URL
 * @param programObserverId - 
 * @param fieldName - 
 */
export const addPointZero = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/zero/point/{programObserverId}/{fieldName}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programObserverId}', `${parameters['programObserverId']}`)
  if (parameters['programObserverId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programObserverId'))
  }
  path = path.replace('{fieldName}', `${parameters['fieldName']}`)
  if (parameters['fieldName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fieldName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addPointZero_RAW_URL = function() {
  return '/form/zero/point/{programObserverId}/{fieldName}'
}
export const addPointZero_TYPE = function() {
  return 'post'
}
export const addPointZeroURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/zero/point/{programObserverId}/{fieldName}'
  path = path.replace('{programObserverId}', `${parameters['programObserverId']}`)
  path = path.replace('{fieldName}', `${parameters['fieldName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: approveFormTransectZero
 * url: approveFormTransectZeroURL
 * method: approveFormTransectZero_TYPE
 * raw_url: approveFormTransectZero_RAW_URL
 * @param formId - 
 */
export const approveFormTransectZero = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/zero/transect/approve/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const approveFormTransectZero_RAW_URL = function() {
  return '/form/zero/transect/approve/{formId}'
}
export const approveFormTransectZero_TYPE = function() {
  return 'post'
}
export const approveFormTransectZeroURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/zero/transect/approve/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: editTransectZero
 * url: editTransectZeroURL
 * method: editTransectZero_TYPE
 * raw_url: editTransectZero_RAW_URL
 * @param formId - 
 * @param body - 
 */
export const editTransectZero = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/zero/transect/edit/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editTransectZero_RAW_URL = function() {
  return '/form/zero/transect/edit/{formId}'
}
export const editTransectZero_TYPE = function() {
  return 'post'
}
export const editTransectZeroURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/zero/transect/edit/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTransectZero
 * url: getTransectZeroURL
 * method: getTransectZero_TYPE
 * raw_url: getTransectZero_RAW_URL
 * @param formId - 
 */
export const getTransectZero = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/zero/transect/get/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTransectZero_RAW_URL = function() {
  return '/form/zero/transect/get/{formId}'
}
export const getTransectZero_TYPE = function() {
  return 'get'
}
export const getTransectZeroURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/zero/transect/get/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: addTransectZero
 * url: addTransectZeroURL
 * method: addTransectZero_TYPE
 * raw_url: addTransectZero_RAW_URL
 * @param programObserverId - 
 * @param fieldId - 
 */
export const addTransectZero = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/zero/transect/{programObserverId}/{fieldId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programObserverId}', `${parameters['programObserverId']}`)
  if (parameters['programObserverId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programObserverId'))
  }
  path = path.replace('{fieldId}', `${parameters['fieldId']}`)
  if (parameters['fieldId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fieldId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addTransectZero_RAW_URL = function() {
  return '/form/zero/transect/{programObserverId}/{fieldId}'
}
export const addTransectZero_TYPE = function() {
  return 'post'
}
export const addTransectZeroURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/zero/transect/{programObserverId}/{fieldId}'
  path = path.replace('{programObserverId}', `${parameters['programObserverId']}`)
  path = path.replace('{fieldId}', `${parameters['fieldId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateForm
 * url: updateFormURL
 * method: updateForm_TYPE
 * raw_url: updateForm_RAW_URL
 * @param formId - 
 * @param validate - 
 * @param body - 
 */
export const updateForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['validate'] !== undefined) {
    queryParameters['validate'] = parameters['validate']
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateForm_RAW_URL = function() {
  return '/form/{formId}'
}
export const updateForm_TYPE = function() {
  return 'put'
}
export const updateFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['validate'] !== undefined) {
    queryParameters['validate'] = parameters['validate']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: rejectForm
 * url: rejectFormURL
 * method: rejectForm_TYPE
 * raw_url: rejectForm_RAW_URL
 * @param formId - 
 * @param body - 
 */
export const rejectForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/{formId}/reject'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const rejectForm_RAW_URL = function() {
  return '/form/{formId}/reject'
}
export const rejectForm_TYPE = function() {
  return 'post'
}
export const rejectFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/{formId}/reject'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: sendFormToVerification
 * url: sendFormToVerificationURL
 * method: sendFormToVerification_TYPE
 * raw_url: sendFormToVerification_RAW_URL
 * @param formId - 
 */
export const sendFormToVerification = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/{formId}/sendToVerification'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const sendFormToVerification_RAW_URL = function() {
  return '/form/{formId}/sendToVerification'
}
export const sendFormToVerification_TYPE = function() {
  return 'post'
}
export const sendFormToVerificationURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/{formId}/sendToVerification'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getForm
 * url: getFormURL
 * method: getForm_TYPE
 * raw_url: getForm_RAW_URL
 * @param program - 
 * @param formId - 
 * @param controlCode - 
 */
export const getForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/{program}/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['controlCode'] !== undefined) {
    queryParameters['controlCode'] = parameters['controlCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getForm_RAW_URL = function() {
  return '/form/{program}/{formId}'
}
export const getForm_TYPE = function() {
  return 'get'
}
export const getFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/{program}/{formId}'
  path = path.replace('{program}', `${parameters['program']}`)
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['controlCode'] !== undefined) {
    queryParameters['controlCode'] = parameters['controlCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: addForm
 * url: addFormURL
 * method: addForm_TYPE
 * raw_url: addForm_RAW_URL
 * @param program - 
 * @param programObserverId - 
 * @param fieldId - 
 * @param controlCode - 
 */
export const addForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/form/{program}/{programObserverId}/{fieldId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  path = path.replace('{programObserverId}', `${parameters['programObserverId']}`)
  if (parameters['programObserverId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programObserverId'))
  }
  path = path.replace('{fieldId}', `${parameters['fieldId']}`)
  if (parameters['fieldId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fieldId'))
  }
  if (parameters['controlCode'] !== undefined) {
    queryParameters['controlCode'] = parameters['controlCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addForm_RAW_URL = function() {
  return '/form/{program}/{programObserverId}/{fieldId}'
}
export const addForm_TYPE = function() {
  return 'post'
}
export const addFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/form/{program}/{programObserverId}/{fieldId}'
  path = path.replace('{program}', `${parameters['program']}`)
  path = path.replace('{programObserverId}', `${parameters['programObserverId']}`)
  path = path.replace('{fieldId}', `${parameters['fieldId']}`)
  if (parameters['controlCode'] !== undefined) {
    queryParameters['controlCode'] = parameters['controlCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: checkImportAvailability
 * url: checkImportAvailabilityURL
 * method: checkImportAvailability_TYPE
 * raw_url: checkImportAvailability_RAW_URL
 */
export const checkImportAvailability = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/geography/checkAvailability'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const checkImportAvailability_RAW_URL = function() {
  return '/geography/checkAvailability'
}
export const checkImportAvailability_TYPE = function() {
  return 'get'
}
export const checkImportAvailabilityURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/geography/checkAvailability'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: countGeography
 * url: countGeographyURL
 * method: countGeography_TYPE
 * raw_url: countGeography_RAW_URL
 * @param body - 
 */
export const countGeography = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/geography/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const countGeography_RAW_URL = function() {
  return '/geography/count'
}
export const countGeography_TYPE = function() {
  return 'post'
}
export const countGeographyURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/geography/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importExternalGeography
 * url: importExternalGeographyURL
 * method: importExternalGeography_TYPE
 * raw_url: importExternalGeography_RAW_URL
 * @param type - 
 */
export const importExternalGeography = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/geography/load/{type}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{type}', `${parameters['type']}`)
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const importExternalGeography_RAW_URL = function() {
  return '/geography/load/{type}'
}
export const importExternalGeography_TYPE = function() {
  return 'get'
}
export const importExternalGeographyURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/geography/load/{type}'
  path = path.replace('{type}', `${parameters['type']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchGeography
 * url: searchGeographyURL
 * method: searchGeography_TYPE
 * raw_url: searchGeography_RAW_URL
 * @param body - 
 */
export const searchGeography = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/geography/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchGeography_RAW_URL = function() {
  return '/geography/search'
}
export const searchGeography_TYPE = function() {
  return 'post'
}
export const searchGeographyURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/geography/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getGeographyById
 * url: getGeographyByIdURL
 * method: getGeographyById_TYPE
 * raw_url: getGeographyById_RAW_URL
 * @param type - 
 * @param id - 
 */
export const getGeographyById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/geography/{type}/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{type}', `${parameters['type']}`)
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getGeographyById_RAW_URL = function() {
  return '/geography/{type}/{id}'
}
export const getGeographyById_TYPE = function() {
  return 'get'
}
export const getGeographyByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/geography/{type}/{id}'
  path = path.replace('{type}', `${parameters['type']}`)
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: acceptHabitatById
 * url: acceptHabitatByIdURL
 * method: acceptHabitatById_TYPE
 * raw_url: acceptHabitatById_RAW_URL
 * @param program - 
 * @param id - 
 */
export const acceptHabitatById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/habitat/accept/{program}/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptHabitatById_RAW_URL = function() {
  return '/habitat/accept/{program}/{id}'
}
export const acceptHabitatById_TYPE = function() {
  return 'post'
}
export const acceptHabitatByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/habitat/accept/{program}/{id}'
  path = path.replace('{program}', `${parameters['program']}`)
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getHabitatByGeographicalHistoryId
 * url: getHabitatByGeographicalHistoryIdURL
 * method: getHabitatByGeographicalHistoryId_TYPE
 * raw_url: getHabitatByGeographicalHistoryId_RAW_URL
 * @param type - 
 * @param gHistoryId - 
 */
export const getHabitatByGeographicalHistoryId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/habitat/geographical/{type}/{gHistoryId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{type}', `${parameters['type']}`)
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  path = path.replace('{gHistoryId}', `${parameters['gHistoryId']}`)
  if (parameters['gHistoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gHistoryId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getHabitatByGeographicalHistoryId_RAW_URL = function() {
  return '/habitat/geographical/{type}/{gHistoryId}'
}
export const getHabitatByGeographicalHistoryId_TYPE = function() {
  return 'get'
}
export const getHabitatByGeographicalHistoryIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/habitat/geographical/{type}/{gHistoryId}'
  path = path.replace('{type}', `${parameters['type']}`)
  path = path.replace('{gHistoryId}', `${parameters['gHistoryId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: addHabitat
 * url: addHabitatURL
 * method: addHabitat_TYPE
 * raw_url: addHabitat_RAW_URL
 * @param gObjectId - 
 * @param body - 
 */
export const addHabitat = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/habitat/{gObjectId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{gObjectId}', `${parameters['gObjectId']}`)
  if (parameters['gObjectId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gObjectId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addHabitat_RAW_URL = function() {
  return '/habitat/{gObjectId}'
}
export const addHabitat_TYPE = function() {
  return 'post'
}
export const addHabitatURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/habitat/{gObjectId}'
  path = path.replace('{gObjectId}', `${parameters['gObjectId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateHabitat
 * url: updateHabitatURL
 * method: updateHabitat_TYPE
 * raw_url: updateHabitat_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateHabitat = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/habitat/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateHabitat_RAW_URL = function() {
  return '/habitat/{id}'
}
export const updateHabitat_TYPE = function() {
  return 'put'
}
export const updateHabitatURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/habitat/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchHabitats
 * url: searchHabitatsURL
 * method: searchHabitats_TYPE
 * raw_url: searchHabitats_RAW_URL
 * @param program - 
 * @param body - 
 */
export const searchHabitats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/habitat/{program}/search'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchHabitats_RAW_URL = function() {
  return '/habitat/{program}/search'
}
export const searchHabitats_TYPE = function() {
  return 'post'
}
export const searchHabitatsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/habitat/{program}/search'
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchHabitatsCount
 * url: searchHabitatsCountURL
 * method: searchHabitatsCount_TYPE
 * raw_url: searchHabitatsCount_RAW_URL
 * @param program - 
 * @param body - 
 */
export const searchHabitatsCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/habitat/{program}/search/count'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchHabitatsCount_RAW_URL = function() {
  return '/habitat/{program}/search/count'
}
export const searchHabitatsCount_TYPE = function() {
  return 'post'
}
export const searchHabitatsCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/habitat/{program}/search/count'
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getHabitatFilters
 * url: getHabitatFiltersURL
 * method: getHabitatFilters_TYPE
 * raw_url: getHabitatFilters_RAW_URL
 * @param program - 
 */
export const getHabitatFilters = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/habitat/{program}/search/filters'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getHabitatFilters_RAW_URL = function() {
  return '/habitat/{program}/search/filters'
}
export const getHabitatFilters_TYPE = function() {
  return 'get'
}
export const getHabitatFiltersURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/habitat/{program}/search/filters'
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteHabitatById
 * url: deleteHabitatByIdURL
 * method: deleteHabitatById_TYPE
 * raw_url: deleteHabitatById_RAW_URL
 * @param program - 
 * @param id - 
 */
export const deleteHabitatById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/habitat/{program}/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteHabitatById_RAW_URL = function() {
  return '/habitat/{program}/{id}'
}
export const deleteHabitatById_TYPE = function() {
  return 'delete'
}
export const deleteHabitatByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/habitat/{program}/{id}'
  path = path.replace('{program}', `${parameters['program']}`)
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getHabitatById
 * url: getHabitatByIdURL
 * method: getHabitatById_TYPE
 * raw_url: getHabitatById_RAW_URL
 * @param program - 
 * @param id - 
 */
export const getHabitatById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/habitat/{program}/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getHabitatById_RAW_URL = function() {
  return '/habitat/{program}/{id}'
}
export const getHabitatById_TYPE = function() {
  return 'get'
}
export const getHabitatByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/habitat/{program}/{id}'
  path = path.replace('{program}', `${parameters['program']}`)
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importFormsFromExcel
 * url: importFormsFromExcelURL
 * method: importFormsFromExcel_TYPE
 * raw_url: importFormsFromExcel_RAW_URL
 * @param programEditionId - 
 * @param body - 
 */
export const importFormsFromExcel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/import-form/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importFormsFromExcel_RAW_URL = function() {
  return '/import-form/{programEditionId}'
}
export const importFormsFromExcel_TYPE = function() {
  return 'post'
}
export const importFormsFromExcelURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/import-form/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: isTokenValid
 * url: isTokenValidURL
 * method: isTokenValid_TYPE
 * raw_url: isTokenValid_RAW_URL
 */
export const isTokenValid = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/is-token-valid'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const isTokenValid_RAW_URL = function() {
  return '/is-token-valid'
}
export const isTokenValid_TYPE = function() {
  return 'get'
}
export const isTokenValidURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/is-token-valid'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getFiltersInProgramEdition
 * url: getFiltersInProgramEditionURL
 * method: getFiltersInProgramEdition_TYPE
 * raw_url: getFiltersInProgramEdition_RAW_URL
 * @param programEditionId - 
 * @param requestType - 
 */
export const getFiltersInProgramEdition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/lists/programEdition/filter/{programEditionId}/{requestType}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  path = path.replace('{requestType}', `${parameters['requestType']}`)
  if (parameters['requestType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: requestType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFiltersInProgramEdition_RAW_URL = function() {
  return '/lists/programEdition/filter/{programEditionId}/{requestType}'
}
export const getFiltersInProgramEdition_TYPE = function() {
  return 'get'
}
export const getFiltersInProgramEditionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/lists/programEdition/filter/{programEditionId}/{requestType}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  path = path.replace('{requestType}', `${parameters['requestType']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getFreeRegionalCoordinators
 * url: getFreeRegionalCoordinatorsURL
 * method: getFreeRegionalCoordinators_TYPE
 * raw_url: getFreeRegionalCoordinators_RAW_URL
 * @param programEditionId - 
 * @param observerId - 
 */
export const getFreeRegionalCoordinators = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/lists/programEdition/free/{programEditionId}/coordinatorForObserver/{observerId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  path = path.replace('{observerId}', `${parameters['observerId']}`)
  if (parameters['observerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: observerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFreeRegionalCoordinators_RAW_URL = function() {
  return '/lists/programEdition/free/{programEditionId}/coordinatorForObserver/{observerId}'
}
export const getFreeRegionalCoordinators_TYPE = function() {
  return 'get'
}
export const getFreeRegionalCoordinatorsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/lists/programEdition/free/{programEditionId}/coordinatorForObserver/{observerId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  path = path.replace('{observerId}', `${parameters['observerId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getFreeFieldsForCoordinators
 * url: getFreeFieldsForCoordinatorsURL
 * method: getFreeFieldsForCoordinators_TYPE
 * raw_url: getFreeFieldsForCoordinators_RAW_URL
 * @param programEditionId - 
 */
export const getFreeFieldsForCoordinators = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/lists/programEdition/free/{programEditionId}/fieldForCoordinator'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFreeFieldsForCoordinators_RAW_URL = function() {
  return '/lists/programEdition/free/{programEditionId}/fieldForCoordinator'
}
export const getFreeFieldsForCoordinators_TYPE = function() {
  return 'get'
}
export const getFreeFieldsForCoordinatorsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/lists/programEdition/free/{programEditionId}/fieldForCoordinator'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getFreeObservationsInProgramEdition
 * url: getFreeObservationsInProgramEditionURL
 * method: getFreeObservationsInProgramEdition_TYPE
 * raw_url: getFreeObservationsInProgramEdition_RAW_URL
 * @param programEditionId - 
 * @param requestType - 
 */
export const getFreeObservationsInProgramEdition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/lists/programEdition/free/{programEditionId}/{requestType}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  path = path.replace('{requestType}', `${parameters['requestType']}`)
  if (parameters['requestType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: requestType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFreeObservationsInProgramEdition_RAW_URL = function() {
  return '/lists/programEdition/free/{programEditionId}/{requestType}'
}
export const getFreeObservationsInProgramEdition_TYPE = function() {
  return 'get'
}
export const getFreeObservationsInProgramEditionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/lists/programEdition/free/{programEditionId}/{requestType}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  path = path.replace('{requestType}', `${parameters['requestType']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: login
 * url: loginURL
 * method: login_TYPE
 * raw_url: login_RAW_URL
 * @param body - 
 */
export const login = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/oldLogin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const login_RAW_URL = function() {
  return '/oldLogin'
}
export const login_TYPE = function() {
  return 'post'
}
export const loginURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/oldLogin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: acceptPoint
 * url: acceptPointURL
 * method: acceptPoint_TYPE
 * raw_url: acceptPoint_RAW_URL
 * @param id - 
 */
export const acceptPoint = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/accept/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptPoint_RAW_URL = function() {
  return '/point/accept/{id}'
}
export const acceptPoint_TYPE = function() {
  return 'post'
}
export const acceptPointURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/accept/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createPoint
 * url: createPointURL
 * method: createPoint_TYPE
 * raw_url: createPoint_RAW_URL
 * @param body - 
 */
export const createPoint = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPoint_RAW_URL = function() {
  return '/point/create'
}
export const createPoint_TYPE = function() {
  return 'post'
}
export const createPointURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportPoints
 * url: exportPointsURL
 * method: exportPoints_TYPE
 * raw_url: exportPoints_RAW_URL
 * @param body - 
 */
export const exportPoints = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportPoints_RAW_URL = function() {
  return '/point/export'
}
export const exportPoints_TYPE = function() {
  return 'post'
}
export const exportPointsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/export'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getPointById
 * url: getPointByIdURL
 * method: getPointById_TYPE
 * raw_url: getPointById_RAW_URL
 * @param id - 
 */
export const getPointById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPointById_RAW_URL = function() {
  return '/point/get/{id}'
}
export const getPointById_TYPE = function() {
  return 'get'
}
export const getPointByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createPointHistory
 * url: createPointHistoryURL
 * method: createPointHistory_TYPE
 * raw_url: createPointHistory_RAW_URL
 * @param body - 
 */
export const createPointHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/history'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPointHistory_RAW_URL = function() {
  return '/point/history'
}
export const createPointHistory_TYPE = function() {
  return 'post'
}
export const createPointHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/history'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: acceptPointHistory
 * url: acceptPointHistoryURL
 * method: acceptPointHistory_TYPE
 * raw_url: acceptPointHistory_RAW_URL
 * @param id - 
 */
export const acceptPointHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/history/accept/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptPointHistory_RAW_URL = function() {
  return '/point/history/accept/{id}'
}
export const acceptPointHistory_TYPE = function() {
  return 'post'
}
export const acceptPointHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/history/accept/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deletePointHistoryById
 * url: deletePointHistoryByIdURL
 * method: deletePointHistoryById_TYPE
 * raw_url: deletePointHistoryById_RAW_URL
 * @param id - 
 */
export const deletePointHistoryById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/history/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePointHistoryById_RAW_URL = function() {
  return '/point/history/{id}'
}
export const deletePointHistoryById_TYPE = function() {
  return 'delete'
}
export const deletePointHistoryByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/history/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getAvailablePointControlVersions
 * url: getAvailablePointControlVersionsURL
 * method: getAvailablePointControlVersions_TYPE
 * raw_url: getAvailablePointControlVersions_RAW_URL
 * @param id - 
 * @param year - 
 */
export const getAvailablePointControlVersions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/history/{id}/controlVersions'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['year'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: year'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAvailablePointControlVersions_RAW_URL = function() {
  return '/point/history/{id}/controlVersions'
}
export const getAvailablePointControlVersions_TYPE = function() {
  return 'get'
}
export const getAvailablePointControlVersionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/history/{id}/controlVersions'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importPoints
 * url: importPointsURL
 * method: importPoints_TYPE
 * raw_url: importPoints_RAW_URL
 * @param body - 
 */
export const importPoints = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importPoints_RAW_URL = function() {
  return '/point/import'
}
export const importPoints_TYPE = function() {
  return 'post'
}
export const importPointsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchPoints
 * url: searchPointsURL
 * method: searchPoints_TYPE
 * raw_url: searchPoints_RAW_URL
 * @param body - 
 */
export const searchPoints = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPoints_RAW_URL = function() {
  return '/point/search'
}
export const searchPoints_TYPE = function() {
  return 'post'
}
export const searchPointsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchPointsCount
 * url: searchPointsCountURL
 * method: searchPointsCount_TYPE
 * raw_url: searchPointsCount_RAW_URL
 * @param body - 
 */
export const searchPointsCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPointsCount_RAW_URL = function() {
  return '/point/search/count'
}
export const searchPointsCount_TYPE = function() {
  return 'post'
}
export const searchPointsCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePoint
 * url: updatePointURL
 * method: updatePoint_TYPE
 * raw_url: updatePoint_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updatePoint = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/update/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updatePoint_RAW_URL = function() {
  return '/point/update/{id}'
}
export const updatePoint_TYPE = function() {
  return 'post'
}
export const updatePointURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/update/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePointHistory
 * url: updatePointHistoryURL
 * method: updatePointHistory_TYPE
 * raw_url: updatePointHistory_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updatePointHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/point/{id}/update'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updatePointHistory_RAW_URL = function() {
  return '/point/{id}/update'
}
export const updatePointHistory_TYPE = function() {
  return 'post'
}
export const updatePointHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/point/{id}/update'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createPosition
 * url: createPositionURL
 * method: createPosition_TYPE
 * raw_url: createPosition_RAW_URL
 * @param body - 
 */
export const createPosition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPosition_RAW_URL = function() {
  return '/position'
}
export const createPosition_TYPE = function() {
  return 'post'
}
export const createPositionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: acceptPosition
 * url: acceptPositionURL
 * method: acceptPosition_TYPE
 * raw_url: acceptPosition_RAW_URL
 * @param id - 
 */
export const acceptPosition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/accept/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptPosition_RAW_URL = function() {
  return '/position/accept/{id}'
}
export const acceptPosition_TYPE = function() {
  return 'post'
}
export const acceptPositionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/accept/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportPositions
 * url: exportPositionsURL
 * method: exportPositions_TYPE
 * raw_url: exportPositions_RAW_URL
 * @param body - 
 */
export const exportPositions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportPositions_RAW_URL = function() {
  return '/position/export'
}
export const exportPositions_TYPE = function() {
  return 'post'
}
export const exportPositionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/export'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getPositionById
 * url: getPositionByIdURL
 * method: getPositionById_TYPE
 * raw_url: getPositionById_RAW_URL
 * @param id - 
 */
export const getPositionById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPositionById_RAW_URL = function() {
  return '/position/get/{id}'
}
export const getPositionById_TYPE = function() {
  return 'get'
}
export const getPositionByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: acceptPositionHistory
 * url: acceptPositionHistoryURL
 * method: acceptPositionHistory_TYPE
 * raw_url: acceptPositionHistory_RAW_URL
 * @param id - 
 */
export const acceptPositionHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/history/accept/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptPositionHistory_RAW_URL = function() {
  return '/position/history/accept/{id}'
}
export const acceptPositionHistory_TYPE = function() {
  return 'post'
}
export const acceptPositionHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/history/accept/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deletePositionHistory
 * url: deletePositionHistoryURL
 * method: deletePositionHistory_TYPE
 * raw_url: deletePositionHistory_RAW_URL
 * @param id - 
 */
export const deletePositionHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/history/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePositionHistory_RAW_URL = function() {
  return '/position/history/{id}'
}
export const deletePositionHistory_TYPE = function() {
  return 'delete'
}
export const deletePositionHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/history/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getAvailablePositionControlVersions
 * url: getAvailablePositionControlVersionsURL
 * method: getAvailablePositionControlVersions_TYPE
 * raw_url: getAvailablePositionControlVersions_RAW_URL
 * @param id - 
 * @param year - 
 */
export const getAvailablePositionControlVersions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/history/{id}/controlVersions'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['year'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: year'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAvailablePositionControlVersions_RAW_URL = function() {
  return '/position/history/{id}/controlVersions'
}
export const getAvailablePositionControlVersions_TYPE = function() {
  return 'get'
}
export const getAvailablePositionControlVersionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/history/{id}/controlVersions'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importPositions
 * url: importPositionsURL
 * method: importPositions_TYPE
 * raw_url: importPositions_RAW_URL
 * @param body - 
 */
export const importPositions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importPositions_RAW_URL = function() {
  return '/position/import'
}
export const importPositions_TYPE = function() {
  return 'post'
}
export const importPositionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: listPositions
 * url: listPositionsURL
 * method: listPositions_TYPE
 * raw_url: listPositions_RAW_URL
 * @param programCode - 
 */
export const listPositions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/list/{programCode}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters['programCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const listPositions_RAW_URL = function() {
  return '/position/list/{programCode}'
}
export const listPositions_TYPE = function() {
  return 'get'
}
export const listPositionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/list/{programCode}'
  path = path.replace('{programCode}', `${parameters['programCode']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchPositions
 * url: searchPositionsURL
 * method: searchPositions_TYPE
 * raw_url: searchPositions_RAW_URL
 * @param body - 
 */
export const searchPositions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPositions_RAW_URL = function() {
  return '/position/search'
}
export const searchPositions_TYPE = function() {
  return 'post'
}
export const searchPositionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchPositionsCount
 * url: searchPositionsCountURL
 * method: searchPositionsCount_TYPE
 * raw_url: searchPositionsCount_RAW_URL
 * @param body - 
 */
export const searchPositionsCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPositionsCount_RAW_URL = function() {
  return '/position/search/count'
}
export const searchPositionsCount_TYPE = function() {
  return 'post'
}
export const searchPositionsCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getListOfStatuses
 * url: getListOfStatusesURL
 * method: getListOfStatuses_TYPE
 * raw_url: getListOfStatuses_RAW_URL
 */
export const getListOfStatuses = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/statuses'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getListOfStatuses_RAW_URL = function() {
  return '/position/statuses'
}
export const getListOfStatuses_TYPE = function() {
  return 'get'
}
export const getListOfStatusesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/statuses'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePosition
 * url: updatePositionURL
 * method: updatePosition_TYPE
 * raw_url: updatePosition_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updatePosition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/update/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updatePosition_RAW_URL = function() {
  return '/position/update/{id}'
}
export const updatePosition_TYPE = function() {
  return 'post'
}
export const updatePositionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/update/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePositionHistory
 * url: updatePositionHistoryURL
 * method: updatePositionHistory_TYPE
 * raw_url: updatePositionHistory_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updatePositionHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/updateHistory/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updatePositionHistory_RAW_URL = function() {
  return '/position/updateHistory/{id}'
}
export const updatePositionHistory_TYPE = function() {
  return 'post'
}
export const updatePositionHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/updateHistory/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createPositionHistory
 * url: createPositionHistoryURL
 * method: createPositionHistory_TYPE
 * raw_url: createPositionHistory_RAW_URL
 * @param id - 
 * @param body - 
 */
export const createPositionHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/position/{id}/history'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPositionHistory_RAW_URL = function() {
  return '/position/{id}/history'
}
export const createPositionHistory_TYPE = function() {
  return 'post'
}
export const createPositionHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/position/{id}/history'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportPrograms
 * url: exportProgramsURL
 * method: exportPrograms_TYPE
 * raw_url: exportPrograms_RAW_URL
 */
export const exportPrograms = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/program/export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportPrograms_RAW_URL = function() {
  return '/program/export'
}
export const exportPrograms_TYPE = function() {
  return 'post'
}
export const exportProgramsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/program/export'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProgramById
 * url: getProgramByIdURL
 * method: getProgramById_TYPE
 * raw_url: getProgramById_RAW_URL
 * @param id - 
 */
export const getProgramById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/program/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProgramById_RAW_URL = function() {
  return '/program/get/{id}'
}
export const getProgramById_TYPE = function() {
  return 'get'
}
export const getProgramByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/program/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getAllPrograms
 * url: getAllProgramsURL
 * method: getAllPrograms_TYPE
 * raw_url: getAllPrograms_RAW_URL
 */
export const getAllPrograms = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/program/getAll'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllPrograms_RAW_URL = function() {
  return '/program/getAll'
}
export const getAllPrograms_TYPE = function() {
  return 'get'
}
export const getAllProgramsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/program/getAll'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importProgram
 * url: importProgramURL
 * method: importProgram_TYPE
 * raw_url: importProgram_RAW_URL
 * @param body - 
 */
export const importProgram = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/program/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importProgram_RAW_URL = function() {
  return '/program/import'
}
export const importProgram_TYPE = function() {
  return 'post'
}
export const importProgramURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/program/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateProgram
 * url: updateProgramURL
 * method: updateProgram_TYPE
 * raw_url: updateProgram_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateProgram = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/program/update/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateProgram_RAW_URL = function() {
  return '/program/update/{id}'
}
export const updateProgram_TYPE = function() {
  return 'post'
}
export const updateProgramURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/program/update/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createProgram
 * url: createProgramURL
 * method: createProgram_TYPE
 * raw_url: createProgram_RAW_URL
 * @param program - 
 * @param year - 
 */
export const createProgram = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/add/{program}/{year}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  path = path.replace('{year}', `${parameters['year']}`)
  if (parameters['year'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: year'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createProgram_RAW_URL = function() {
  return '/programEdition/add/{program}/{year}'
}
export const createProgram_TYPE = function() {
  return 'post'
}
export const createProgramURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/add/{program}/{year}'
  path = path.replace('{program}', `${parameters['program']}`)
  path = path.replace('{year}', `${parameters['year']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: approveCast
 * url: approveCastURL
 * method: approveCast_TYPE
 * raw_url: approveCast_RAW_URL
 * @param programEditionId - 
 */
export const approveCast = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/approve/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const approveCast_RAW_URL = function() {
  return '/programEdition/cast/approve/{programEditionId}'
}
export const approveCast_TYPE = function() {
  return 'post'
}
export const approveCastURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/approve/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: duplicateCast
 * url: duplicateCastURL
 * method: duplicateCast_TYPE
 * raw_url: duplicateCast_RAW_URL
 * @param programEditionId - 
 */
export const duplicateCast = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/duplicate/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const duplicateCast_RAW_URL = function() {
  return '/programEdition/cast/duplicate/{programEditionId}'
}
export const duplicateCast_TYPE = function() {
  return 'post'
}
export const duplicateCastURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/duplicate/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: addNationalCoordinatorToCast
 * url: addNationalCoordinatorToCastURL
 * method: addNationalCoordinatorToCast_TYPE
 * raw_url: addNationalCoordinatorToCast_RAW_URL
 * @param programEditionId - 
 * @param body - 
 */
export const addNationalCoordinatorToCast = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/edit/add/nationalCoordinator/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addNationalCoordinatorToCast_RAW_URL = function() {
  return '/programEdition/cast/edit/add/nationalCoordinator/{programEditionId}'
}
export const addNationalCoordinatorToCast_TYPE = function() {
  return 'post'
}
export const addNationalCoordinatorToCastURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/edit/add/nationalCoordinator/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: addProgramObserverToCast
 * url: addProgramObserverToCastURL
 * method: addProgramObserverToCast_TYPE
 * raw_url: addProgramObserverToCast_RAW_URL
 * @param programEditionId - 
 * @param body - 
 */
export const addProgramObserverToCast = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/edit/add/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addProgramObserverToCast_RAW_URL = function() {
  return '/programEdition/cast/edit/add/{programEditionId}'
}
export const addProgramObserverToCast_TYPE = function() {
  return 'post'
}
export const addProgramObserverToCastURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/edit/add/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: editProgramObserversCast
 * url: editProgramObserversCastURL
 * method: editProgramObserversCast_TYPE
 * raw_url: editProgramObserversCast_RAW_URL
 * @param programEditionId - 
 * @param body - 
 */
export const editProgramObserversCast = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/edit/change/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editProgramObserversCast_RAW_URL = function() {
  return '/programEdition/cast/edit/change/{programEditionId}'
}
export const editProgramObserversCast_TYPE = function() {
  return 'post'
}
export const editProgramObserversCastURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/edit/change/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: editRegionalCoordinatorCast
 * url: editRegionalCoordinatorCastURL
 * method: editRegionalCoordinatorCast_TYPE
 * raw_url: editRegionalCoordinatorCast_RAW_URL
 * @param programEditionId - 
 * @param body - 
 */
export const editRegionalCoordinatorCast = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/edit/regionalCoordinator/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editRegionalCoordinatorCast_RAW_URL = function() {
  return '/programEdition/cast/edit/regionalCoordinator/{programEditionId}'
}
export const editRegionalCoordinatorCast_TYPE = function() {
  return 'post'
}
export const editRegionalCoordinatorCastURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/edit/regionalCoordinator/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteProgramRegionalCoordinatorFromCast
 * url: deleteProgramRegionalCoordinatorFromCastURL
 * method: deleteProgramRegionalCoordinatorFromCast_TYPE
 * raw_url: deleteProgramRegionalCoordinatorFromCast_RAW_URL
 * @param programEditionId - 
 * @param regionalCoordinatorId - 
 */
export const deleteProgramRegionalCoordinatorFromCast = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/edit/regionalCoordinator/{programEditionId}/{regionalCoordinatorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  path = path.replace('{regionalCoordinatorId}', `${parameters['regionalCoordinatorId']}`)
  if (parameters['regionalCoordinatorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionalCoordinatorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteProgramRegionalCoordinatorFromCast_RAW_URL = function() {
  return '/programEdition/cast/edit/regionalCoordinator/{programEditionId}/{regionalCoordinatorId}'
}
export const deleteProgramRegionalCoordinatorFromCast_TYPE = function() {
  return 'delete'
}
export const deleteProgramRegionalCoordinatorFromCastURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/edit/regionalCoordinator/{programEditionId}/{regionalCoordinatorId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  path = path.replace('{regionalCoordinatorId}', `${parameters['regionalCoordinatorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteProgramObserverFromCast
 * url: deleteProgramObserverFromCastURL
 * method: deleteProgramObserverFromCast_TYPE
 * raw_url: deleteProgramObserverFromCast_RAW_URL
 * @param programEditionId - 
 * @param observerId - 
 * @param regionalId - 
 */
export const deleteProgramObserverFromCast = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/edit/{programEditionId}/{observerId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  path = path.replace('{observerId}', `${parameters['observerId']}`)
  if (parameters['observerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: observerId'))
  }
  if (parameters['regionalId'] !== undefined) {
    queryParameters['regionalId'] = parameters['regionalId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteProgramObserverFromCast_RAW_URL = function() {
  return '/programEdition/cast/edit/{programEditionId}/{observerId}'
}
export const deleteProgramObserverFromCast_TYPE = function() {
  return 'delete'
}
export const deleteProgramObserverFromCastURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/edit/{programEditionId}/{observerId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  path = path.replace('{observerId}', `${parameters['observerId']}`)
  if (parameters['regionalId'] !== undefined) {
    queryParameters['regionalId'] = parameters['regionalId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportCastToExcel
 * url: exportCastToExcelURL
 * method: exportCastToExcel_TYPE
 * raw_url: exportCastToExcel_RAW_URL
 * @param programEditionId - 
 */
export const exportCastToExcel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/export/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportCastToExcel_RAW_URL = function() {
  return '/programEdition/cast/export/{programEditionId}'
}
export const exportCastToExcel_TYPE = function() {
  return 'post'
}
export const exportCastToExcelURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/export/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importCastFromExcel
 * url: importCastFromExcelURL
 * method: importCastFromExcel_TYPE
 * raw_url: importCastFromExcel_RAW_URL
 * @param programEditionId - 
 * @param body - 
 */
export const importCastFromExcel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/import/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importCastFromExcel_RAW_URL = function() {
  return '/programEdition/cast/import/{programEditionId}'
}
export const importCastFromExcel_TYPE = function() {
  return 'post'
}
export const importCastFromExcelURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/import/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: rejectCast
 * url: rejectCastURL
 * method: rejectCast_TYPE
 * raw_url: rejectCast_RAW_URL
 * @param programEditionId - 
 * @param rejectionCause - 
 */
export const rejectCast = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/cast/reject/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['rejectionCause'] !== undefined) {
    queryParameters['rejectionCause'] = parameters['rejectionCause']
  }
  if (parameters['rejectionCause'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rejectionCause'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const rejectCast_RAW_URL = function() {
  return '/programEdition/cast/reject/{programEditionId}'
}
export const rejectCast_TYPE = function() {
  return 'post'
}
export const rejectCastURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/cast/reject/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['rejectionCause'] !== undefined) {
    queryParameters['rejectionCause'] = parameters['rejectionCause']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProgramEdition
 * url: getProgramEditionURL
 * method: getProgramEdition_TYPE
 * raw_url: getProgramEdition_RAW_URL
 * @param id - 
 */
export const getProgramEdition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProgramEdition_RAW_URL = function() {
  return '/programEdition/get/{id}'
}
export const getProgramEdition_TYPE = function() {
  return 'get'
}
export const getProgramEditionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportRegionalCastToExcel
 * url: exportRegionalCastToExcelURL
 * method: exportRegionalCastToExcel_TYPE
 * raw_url: exportRegionalCastToExcel_RAW_URL
 * @param programEditionId - 
 */
export const exportRegionalCastToExcel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/regional-cast/export/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportRegionalCastToExcel_RAW_URL = function() {
  return '/programEdition/regional-cast/export/{programEditionId}'
}
export const exportRegionalCastToExcel_TYPE = function() {
  return 'post'
}
export const exportRegionalCastToExcelURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/regional-cast/export/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importRegionalCastFromExcel
 * url: importRegionalCastFromExcelURL
 * method: importRegionalCastFromExcel_TYPE
 * raw_url: importRegionalCastFromExcel_RAW_URL
 * @param programEditionId - 
 * @param body - 
 */
export const importRegionalCastFromExcel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/regional-cast/import/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importRegionalCastFromExcel_RAW_URL = function() {
  return '/programEdition/regional-cast/import/{programEditionId}'
}
export const importRegionalCastFromExcel_TYPE = function() {
  return 'post'
}
export const importRegionalCastFromExcelURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/regional-cast/import/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: approveResults
 * url: approveResultsURL
 * method: approveResults_TYPE
 * raw_url: approveResults_RAW_URL
 * @param programEditionId - 
 */
export const approveResults = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/results/approve/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const approveResults_RAW_URL = function() {
  return '/programEdition/results/approve/{programEditionId}'
}
export const approveResults_TYPE = function() {
  return 'post'
}
export const approveResultsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/results/approve/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchProgramEditions
 * url: searchProgramEditionsURL
 * method: searchProgramEditions_TYPE
 * raw_url: searchProgramEditions_RAW_URL
 * @param body - 
 */
export const searchProgramEditions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchProgramEditions_RAW_URL = function() {
  return '/programEdition/search'
}
export const searchProgramEditions_TYPE = function() {
  return 'post'
}
export const searchProgramEditionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchProgramControlsCount
 * url: searchProgramControlsCountURL
 * method: searchProgramControlsCount_TYPE
 * raw_url: searchProgramControlsCount_RAW_URL
 * @param id - 
 * @param body - 
 */
export const searchProgramControlsCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/search/controls/count/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchProgramControlsCount_RAW_URL = function() {
  return '/programEdition/search/controls/count/{id}'
}
export const searchProgramControlsCount_TYPE = function() {
  return 'post'
}
export const searchProgramControlsCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/search/controls/count/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchProgramControls
 * url: searchProgramControlsURL
 * method: searchProgramControls_TYPE
 * raw_url: searchProgramControls_RAW_URL
 * @param id - 
 * @param body - 
 */
export const searchProgramControls = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/search/controls/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchProgramControls_RAW_URL = function() {
  return '/programEdition/search/controls/{id}'
}
export const searchProgramControls_TYPE = function() {
  return 'post'
}
export const searchProgramControlsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/search/controls/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchProgramEditionsCount
 * url: searchProgramEditionsCountURL
 * method: searchProgramEditionsCount_TYPE
 * raw_url: searchProgramEditionsCount_RAW_URL
 * @param body - 
 */
export const searchProgramEditionsCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchProgramEditionsCount_RAW_URL = function() {
  return '/programEdition/search/count'
}
export const searchProgramEditionsCount_TYPE = function() {
  return 'post'
}
export const searchProgramEditionsCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchProgramEditionsFilters
 * url: searchProgramEditionsFiltersURL
 * method: searchProgramEditionsFilters_TYPE
 * raw_url: searchProgramEditionsFilters_RAW_URL
 */
export const searchProgramEditionsFilters = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/search/filters'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchProgramEditionsFilters_RAW_URL = function() {
  return '/programEdition/search/filters'
}
export const searchProgramEditionsFilters_TYPE = function() {
  return 'post'
}
export const searchProgramEditionsFiltersURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/search/filters'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchProgramObserverCount
 * url: searchProgramObserverCountURL
 * method: searchProgramObserverCount_TYPE
 * raw_url: searchProgramObserverCount_RAW_URL
 * @param id - 
 * @param body - 
 */
export const searchProgramObserverCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/search/programObserver/count/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchProgramObserverCount_RAW_URL = function() {
  return '/programEdition/search/programObserver/count/{id}'
}
export const searchProgramObserverCount_TYPE = function() {
  return 'post'
}
export const searchProgramObserverCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/search/programObserver/count/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchProgramObserver
 * url: searchProgramObserverURL
 * method: searchProgramObserver_TYPE
 * raw_url: searchProgramObserver_RAW_URL
 * @param id - 
 * @param body - 
 */
export const searchProgramObserver = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/programEdition/search/programObserver/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchProgramObserver_RAW_URL = function() {
  return '/programEdition/search/programObserver/{id}'
}
export const searchProgramObserver_TYPE = function() {
  return 'post'
}
export const searchProgramObserverURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/programEdition/search/programObserver/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: changeRole
 * url: changeRoleURL
 * method: changeRole_TYPE
 * raw_url: changeRole_RAW_URL
 * @param role - 
 */
export const changeRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/role/{role}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changeRole_RAW_URL = function() {
  return '/role/{role}'
}
export const changeRole_TYPE = function() {
  return 'post'
}
export const changeRoleURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/role/{role}'
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchUser
 * url: searchUserURL
 * method: searchUser_TYPE
 * raw_url: searchUser_RAW_URL
 * @param body - 
 */
export const searchUser = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUser_RAW_URL = function() {
  return '/search/user'
}
export const searchUser_TYPE = function() {
  return 'post'
}
export const searchUserURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchUserCount
 * url: searchUserCountURL
 * method: searchUserCount_TYPE
 * raw_url: searchUserCount_RAW_URL
 * @param body - 
 */
export const searchUserCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserCount_RAW_URL = function() {
  return '/search/user/count'
}
export const searchUserCount_TYPE = function() {
  return 'post'
}
export const searchUserCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createSemiRawData
 * url: createSemiRawDataURL
 * method: createSemiRawData_TYPE
 * raw_url: createSemiRawData_RAW_URL
 * @param programEditionId - 
 * @param mzpwp - 
 */
export const createSemiRawData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/semiRawData/create/{programEditionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['programEditionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: programEditionId'))
  }
  if (parameters['mzpwp'] !== undefined) {
    queryParameters['mzpwp'] = parameters['mzpwp']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const createSemiRawData_RAW_URL = function() {
  return '/semiRawData/create/{programEditionId}'
}
export const createSemiRawData_TYPE = function() {
  return 'get'
}
export const createSemiRawDataURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/semiRawData/create/{programEditionId}'
  path = path.replace('{programEditionId}', `${parameters['programEditionId']}`)
  if (parameters['mzpwp'] !== undefined) {
    queryParameters['mzpwp'] = parameters['mzpwp']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getSemiRawDataById
 * url: getSemiRawDataByIdURL
 * method: getSemiRawDataById_TYPE
 * raw_url: getSemiRawDataById_RAW_URL
 * @param id - 
 */
export const getSemiRawDataById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/semiRawData/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSemiRawDataById_RAW_URL = function() {
  return '/semiRawData/get/{id}'
}
export const getSemiRawDataById_TYPE = function() {
  return 'get'
}
export const getSemiRawDataByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/semiRawData/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importSemiRawData
 * url: importSemiRawDataURL
 * method: importSemiRawData_TYPE
 * raw_url: importSemiRawData_RAW_URL
 * @param body - 
 */
export const importSemiRawData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/semiRawData/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importSemiRawData_RAW_URL = function() {
  return '/semiRawData/import'
}
export const importSemiRawData_TYPE = function() {
  return 'post'
}
export const importSemiRawDataURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/semiRawData/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchSemiRawData
 * url: searchSemiRawDataURL
 * method: searchSemiRawData_TYPE
 * raw_url: searchSemiRawData_RAW_URL
 * @param body - 
 */
export const searchSemiRawData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/semiRawData/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchSemiRawData_RAW_URL = function() {
  return '/semiRawData/search'
}
export const searchSemiRawData_TYPE = function() {
  return 'post'
}
export const searchSemiRawDataURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/semiRawData/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchSemiRawDataCount
 * url: searchSemiRawDataCountURL
 * method: searchSemiRawDataCount_TYPE
 * raw_url: searchSemiRawDataCount_RAW_URL
 * @param body - 
 */
export const searchSemiRawDataCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/semiRawData/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchSemiRawDataCount_RAW_URL = function() {
  return '/semiRawData/search/count'
}
export const searchSemiRawDataCount_TYPE = function() {
  return 'post'
}
export const searchSemiRawDataCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/semiRawData/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportSemiRawData
 * url: exportSemiRawDataURL
 * method: exportSemiRawData_TYPE
 * raw_url: exportSemiRawData_RAW_URL
 * @param body - 
 */
export const exportSemiRawData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/semiRawData/search/export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportSemiRawData_RAW_URL = function() {
  return '/semiRawData/search/export'
}
export const exportSemiRawData_TYPE = function() {
  return 'post'
}
export const exportSemiRawDataURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/semiRawData/search/export'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateSemiRawData
 * url: updateSemiRawDataURL
 * method: updateSemiRawData_TYPE
 * raw_url: updateSemiRawData_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateSemiRawData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/semiRawData/update/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateSemiRawData_RAW_URL = function() {
  return '/semiRawData/update/{id}'
}
export const updateSemiRawData_TYPE = function() {
  return 'post'
}
export const updateSemiRawDataURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/semiRawData/update/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createBird
 * url: createBirdURL
 * method: createBird_TYPE
 * raw_url: createBird_RAW_URL
 * @param body - 
 */
export const createBird = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/species/bird'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createBird_RAW_URL = function() {
  return '/species/bird'
}
export const createBird_TYPE = function() {
  return 'post'
}
export const createBirdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/species/bird'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getBird
 * url: getBirdURL
 * method: getBird_TYPE
 * raw_url: getBird_RAW_URL
 * @param id - 
 */
export const getBird = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/species/bird/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getBird_RAW_URL = function() {
  return '/species/bird/{id}'
}
export const getBird_TYPE = function() {
  return 'get'
}
export const getBirdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/species/bird/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateBird
 * url: updateBirdURL
 * method: updateBird_TYPE
 * raw_url: updateBird_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateBird = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/species/bird/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateBird_RAW_URL = function() {
  return '/species/bird/{id}'
}
export const updateBird_TYPE = function() {
  return 'put'
}
export const updateBirdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/species/bird/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: countBirds
 * url: countBirdsURL
 * method: countBirds_TYPE
 * raw_url: countBirds_RAW_URL
 * @param body - 
 */
export const countBirds = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/species/birds/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const countBirds_RAW_URL = function() {
  return '/species/birds/count'
}
export const countBirds_TYPE = function() {
  return 'post'
}
export const countBirdsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/species/birds/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchBirds
 * url: searchBirdsURL
 * method: searchBirds_TYPE
 * raw_url: searchBirds_RAW_URL
 * @param body - 
 */
export const searchBirds = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/species/birds/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchBirds_RAW_URL = function() {
  return '/species/birds/search'
}
export const searchBirds_TYPE = function() {
  return 'post'
}
export const searchBirdsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/species/birds/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getSpeciesForForm
 * url: getSpeciesForFormURL
 * method: getSpeciesForForm_TYPE
 * raw_url: getSpeciesForForm_RAW_URL
 * @param formId - 
 * @param name - 
 * @param code - 
 */
export const getSpeciesForForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/species/birds/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSpeciesForForm_RAW_URL = function() {
  return '/species/birds/{formId}'
}
export const getSpeciesForForm_TYPE = function() {
  return 'get'
}
export const getSpeciesForFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/species/birds/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getMammalsForForm
 * url: getMammalsForFormURL
 * method: getMammalsForForm_TYPE
 * raw_url: getMammalsForForm_RAW_URL
 * @param formId - 
 * @param name - 
 * @param code - 
 */
export const getMammalsForForm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/species/mammals/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMammalsForForm_RAW_URL = function() {
  return '/species/mammals/{formId}'
}
export const getMammalsForForm_TYPE = function() {
  return 'get'
}
export const getMammalsForFormURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/species/mammals/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: acceptTransect
 * url: acceptTransectURL
 * method: acceptTransect_TYPE
 * raw_url: acceptTransect_RAW_URL
 * @param id - 
 */
export const acceptTransect = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/accept/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptTransect_RAW_URL = function() {
  return '/transect/accept/{id}'
}
export const acceptTransect_TYPE = function() {
  return 'post'
}
export const acceptTransectURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/accept/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createTransect
 * url: createTransectURL
 * method: createTransect_TYPE
 * raw_url: createTransect_RAW_URL
 * @param body - 
 */
export const createTransect = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTransect_RAW_URL = function() {
  return '/transect/create'
}
export const createTransect_TYPE = function() {
  return 'post'
}
export const createTransectURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportTransects
 * url: exportTransectsURL
 * method: exportTransects_TYPE
 * raw_url: exportTransects_RAW_URL
 * @param body - 
 */
export const exportTransects = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportTransects_RAW_URL = function() {
  return '/transect/export'
}
export const exportTransects_TYPE = function() {
  return 'post'
}
export const exportTransectsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/export'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getById
 * url: getByIdURL
 * method: getById_TYPE
 * raw_url: getById_RAW_URL
 * @param id - 
 */
export const getById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getById_RAW_URL = function() {
  return '/transect/get/{id}'
}
export const getById_TYPE = function() {
  return 'get'
}
export const getByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createTransectHistory
 * url: createTransectHistoryURL
 * method: createTransectHistory_TYPE
 * raw_url: createTransectHistory_RAW_URL
 * @param body - 
 */
export const createTransectHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/history'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTransectHistory_RAW_URL = function() {
  return '/transect/history'
}
export const createTransectHistory_TYPE = function() {
  return 'post'
}
export const createTransectHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/history'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: acceptTransectHistory
 * url: acceptTransectHistoryURL
 * method: acceptTransectHistory_TYPE
 * raw_url: acceptTransectHistory_RAW_URL
 * @param id - 
 */
export const acceptTransectHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/history/accept/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptTransectHistory_RAW_URL = function() {
  return '/transect/history/accept/{id}'
}
export const acceptTransectHistory_TYPE = function() {
  return 'post'
}
export const acceptTransectHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/history/accept/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteTransectHistoryById
 * url: deleteTransectHistoryByIdURL
 * method: deleteTransectHistoryById_TYPE
 * raw_url: deleteTransectHistoryById_RAW_URL
 * @param id - 
 */
export const deleteTransectHistoryById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/history/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteTransectHistoryById_RAW_URL = function() {
  return '/transect/history/{id}'
}
export const deleteTransectHistoryById_TYPE = function() {
  return 'delete'
}
export const deleteTransectHistoryByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/history/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTransectHistoryById
 * url: getTransectHistoryByIdURL
 * method: getTransectHistoryById_TYPE
 * raw_url: getTransectHistoryById_RAW_URL
 * @param id - 
 */
export const getTransectHistoryById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/history/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTransectHistoryById_RAW_URL = function() {
  return '/transect/history/{id}'
}
export const getTransectHistoryById_TYPE = function() {
  return 'get'
}
export const getTransectHistoryByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/history/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getAvailableControlVersions
 * url: getAvailableControlVersionsURL
 * method: getAvailableControlVersions_TYPE
 * raw_url: getAvailableControlVersions_RAW_URL
 * @param id - 
 * @param year - 
 */
export const getAvailableControlVersions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/history/{id}/controlVersions'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['year'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: year'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAvailableControlVersions_RAW_URL = function() {
  return '/transect/history/{id}/controlVersions'
}
export const getAvailableControlVersions_TYPE = function() {
  return 'get'
}
export const getAvailableControlVersionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/history/{id}/controlVersions'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importTransects
 * url: importTransectsURL
 * method: importTransects_TYPE
 * raw_url: importTransects_RAW_URL
 * @param body - 
 */
export const importTransects = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importTransects_RAW_URL = function() {
  return '/transect/import'
}
export const importTransects_TYPE = function() {
  return 'post'
}
export const importTransectsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchTransects
 * url: searchTransectsURL
 * method: searchTransects_TYPE
 * raw_url: searchTransects_RAW_URL
 * @param body - 
 */
export const searchTransects = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchTransects_RAW_URL = function() {
  return '/transect/search'
}
export const searchTransects_TYPE = function() {
  return 'post'
}
export const searchTransectsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchTransectsCount
 * url: searchTransectsCountURL
 * method: searchTransectsCount_TYPE
 * raw_url: searchTransectsCount_RAW_URL
 * @param body - 
 */
export const searchTransectsCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchTransectsCount_RAW_URL = function() {
  return '/transect/search/count'
}
export const searchTransectsCount_TYPE = function() {
  return 'post'
}
export const searchTransectsCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTransect
 * url: updateTransectURL
 * method: updateTransect_TYPE
 * raw_url: updateTransect_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTransect = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/update/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateTransect_RAW_URL = function() {
  return '/transect/update/{id}'
}
export const updateTransect_TYPE = function() {
  return 'post'
}
export const updateTransectURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/update/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTransectHistory
 * url: updateTransectHistoryURL
 * method: updateTransectHistory_TYPE
 * raw_url: updateTransectHistory_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTransectHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/transect/updateHistory/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateTransectHistory_RAW_URL = function() {
  return '/transect/updateHistory/{id}'
}
export const updateTransectHistory_TYPE = function() {
  return 'post'
}
export const updateTransectHistoryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/transect/updateHistory/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: addTrendData
 * url: addTrendDataURL
 * method: addTrendData_TYPE
 * raw_url: addTrendData_RAW_URL
 * @param body - 
 */
export const addTrendData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trendData/add'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addTrendData_RAW_URL = function() {
  return '/trendData/add'
}
export const addTrendData_TYPE = function() {
  return 'post'
}
export const addTrendDataURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trendData/add'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteTrendById
 * url: deleteTrendByIdURL
 * method: deleteTrendById_TYPE
 * raw_url: deleteTrendById_RAW_URL
 * @param id - 
 */
export const deleteTrendById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trendData/delete/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteTrendById_RAW_URL = function() {
  return '/trendData/delete/{id}'
}
export const deleteTrendById_TYPE = function() {
  return 'delete'
}
export const deleteTrendByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trendData/delete/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTrendById
 * url: getTrendByIdURL
 * method: getTrendById_TYPE
 * raw_url: getTrendById_RAW_URL
 * @param id - 
 */
export const getTrendById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trendData/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTrendById_RAW_URL = function() {
  return '/trendData/get/{id}'
}
export const getTrendById_TYPE = function() {
  return 'get'
}
export const getTrendByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trendData/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importTrendData
 * url: importTrendDataURL
 * method: importTrendData_TYPE
 * raw_url: importTrendData_RAW_URL
 * @param body - 
 */
export const importTrendData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trendData/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importTrendData_RAW_URL = function() {
  return '/trendData/import'
}
export const importTrendData_TYPE = function() {
  return 'post'
}
export const importTrendDataURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trendData/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchTrendData
 * url: searchTrendDataURL
 * method: searchTrendData_TYPE
 * raw_url: searchTrendData_RAW_URL
 * @param body - 
 */
export const searchTrendData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trendData/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchTrendData_RAW_URL = function() {
  return '/trendData/search'
}
export const searchTrendData_TYPE = function() {
  return 'post'
}
export const searchTrendDataURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trendData/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: searchTrendDataCount
 * url: searchTrendDataCountURL
 * method: searchTrendDataCount_TYPE
 * raw_url: searchTrendDataCount_RAW_URL
 * @param body - 
 */
export const searchTrendDataCount = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trendData/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchTrendDataCount_RAW_URL = function() {
  return '/trendData/search/count'
}
export const searchTrendDataCount_TYPE = function() {
  return 'post'
}
export const searchTrendDataCountURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trendData/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportTrendData
 * url: exportTrendDataURL
 * method: exportTrendData_TYPE
 * raw_url: exportTrendData_RAW_URL
 * @param body - 
 */
export const exportTrendData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trendData/search/export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const exportTrendData_RAW_URL = function() {
  return '/trendData/search/export'
}
export const exportTrendData_TYPE = function() {
  return 'post'
}
export const exportTrendDataURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trendData/search/export'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTrendData
 * url: updateTrendDataURL
 * method: updateTrendData_TYPE
 * raw_url: updateTrendData_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTrendData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trendData/update/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateTrendData_RAW_URL = function() {
  return '/trendData/update/{id}'
}
export const updateTrendData_TYPE = function() {
  return 'post'
}
export const updateTrendDataURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trendData/update/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createOrUpdateUser
 * url: createOrUpdateUserURL
 * method: createOrUpdateUser_TYPE
 * raw_url: createOrUpdateUser_RAW_URL
 * @param body - 
 */
export const createOrUpdateUser = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateUser_RAW_URL = function() {
  return '/user'
}
export const createOrUpdateUser_TYPE = function() {
  return 'post'
}
export const createOrUpdateUserURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: listUsers
 * url: listUsersURL
 * method: listUsers_TYPE
 * raw_url: listUsers_RAW_URL
 */
export const listUsers = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const listUsers_RAW_URL = function() {
  return '/user/list'
}
export const listUsers_TYPE = function() {
  return 'get'
}
export const listUsersURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getAllNationalCoordinators
 * url: getAllNationalCoordinatorsURL
 * method: getAllNationalCoordinators_TYPE
 * raw_url: getAllNationalCoordinators_RAW_URL
 * @param program - 
 */
export const getAllNationalCoordinators = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/nationalCoordinators/{program}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllNationalCoordinators_RAW_URL = function() {
  return '/user/nationalCoordinators/{program}'
}
export const getAllNationalCoordinators_TYPE = function() {
  return 'get'
}
export const getAllNationalCoordinatorsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/nationalCoordinators/{program}'
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getAllObserversAsCoordinator
 * url: getAllObserversAsCoordinatorURL
 * method: getAllObserversAsCoordinator_TYPE
 * raw_url: getAllObserversAsCoordinator_RAW_URL
 */
export const getAllObserversAsCoordinator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/observers'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllObserversAsCoordinator_RAW_URL = function() {
  return '/user/observers'
}
export const getAllObserversAsCoordinator_TYPE = function() {
  return 'get'
}
export const getAllObserversAsCoordinatorURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/observers'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getObservers
 * url: getObserversURL
 * method: getObservers_TYPE
 * raw_url: getObservers_RAW_URL
 * @param formId - 
 * @param query - 
 */
export const getObservers = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/observers/{formId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['formId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formId'))
  }
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getObservers_RAW_URL = function() {
  return '/user/observers/{formId}'
}
export const getObservers_TYPE = function() {
  return 'get'
}
export const getObserversURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/observers/{formId}'
  path = path.replace('{formId}', `${parameters['formId']}`)
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: changePassword
 * url: changePasswordURL
 * method: changePassword_TYPE
 * raw_url: changePassword_RAW_URL
 * @param body - 
 */
export const changePassword = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changePassword_RAW_URL = function() {
  return '/user/password/change'
}
export const changePassword_TYPE = function() {
  return 'post'
}
export const changePasswordURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: remindPassword
 * url: remindPasswordURL
 * method: remindPassword_TYPE
 * raw_url: remindPassword_RAW_URL
 * @param body - 
 */
export const remindPassword = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/remind'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const remindPassword_RAW_URL = function() {
  return '/user/password/remind'
}
export const remindPassword_TYPE = function() {
  return 'post'
}
export const remindPasswordURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/remind'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getAllRegionalCoordinators
 * url: getAllRegionalCoordinatorsURL
 * method: getAllRegionalCoordinators_TYPE
 * raw_url: getAllRegionalCoordinators_RAW_URL
 * @param program - 
 */
export const getAllRegionalCoordinators = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/regionalCoordinators/{program}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters['program'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: program'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllRegionalCoordinators_RAW_URL = function() {
  return '/user/regionalCoordinators/{program}'
}
export const getAllRegionalCoordinators_TYPE = function() {
  return 'get'
}
export const getAllRegionalCoordinatorsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/regionalCoordinators/{program}'
  path = path.replace('{program}', `${parameters['program']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getUserRoles
 * url: getUserRolesURL
 * method: getUserRoles_TYPE
 * raw_url: getUserRoles_RAW_URL
 */
export const getUserRoles = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/roles'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserRoles_RAW_URL = function() {
  return '/user/roles'
}
export const getUserRoles_TYPE = function() {
  return 'get'
}
export const getUserRolesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/roles'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getUser
 * url: getUserURL
 * method: getUser_TYPE
 * raw_url: getUser_RAW_URL
 * @param id - 
 */
export const getUser = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUser_RAW_URL = function() {
  return '/user/{id}'
}
export const getUser_TYPE = function() {
  return 'get'
}
export const getUserURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getUsers
 * url: getUsersURL
 * method: getUsers_TYPE
 * raw_url: getUsers_RAW_URL
 * @param query - 
 */
export const getUsers = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/users'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUsers_RAW_URL = function() {
  return '/users'
}
export const getUsers_TYPE = function() {
  return 'get'
}
export const getUsersURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/users'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}