<template>
    <div class="acc-font-size flex align-items-center">
        <div class="smaller clickable flex px-1 py-2" tabindex="0"
             @keyup.enter="decreaseFontSize" @click="decreaseFontSize">
            <span class="visually-hidden">Zmniejsz rozmiar czcionki</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="9.256" height="4.508" viewBox="0 0 9.256 4.508">
                <path class="arrow-path" d="M4013.354,1922.581l3.923,3.3,3.923-3.3"
                      transform="translate(-4012.649 -1921.877)"/>
            </svg>
        </div>
        <span class="acc-font-aa line-height-2">Aa</span>
        <div class="greater clickable flex px-1 py-2" tabindex="0"
             @keyup.enter="increaseFontSize" @click="increaseFontSize">
            <span class="visually-hidden">Zwiększ rozmiar czcionki</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="9.256" height="4.508" viewBox="0 0 9.256 4.508">
                <path class="arrow-path" d="M4013.354,1922.581l3.923,3.3,3.923-3.3"
                      transform="translate(4021.905 1926.384) rotate(180)"/>
            </svg>
        </div>
    </div>
</template>

<script>
    import {ThemeSettingsHelper} from "@/mixins/themeSettings";

    export default {
        name: "AccToolsFontSize",

        components: { },

        methods: {
            increaseFontSize() {
                ThemeSettingsHelper.increaseFontSize();
            },
            decreaseFontSize() {
                ThemeSettingsHelper.decreaseFontSize();
            },
        },

        data() {
            return {
            };
        },
    };
</script>

<style lang="scss">
    .arrow-path {
        fill:none;
        stroke:var(--text-color);
        stroke-linecap:round;
        stroke-linejoin:round;
    }
</style>
