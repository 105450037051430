<template>
    <CustomCheckbox name="noBirds" label="Brak ptaków" v-model="noBirds" />
    <div v-if="!noBirds" class="observation-birds flex flex-column">
        <Accordion :activeIndex="[activeIndex]" :multiple="true" class="accordion-rounded">
            <AccordionTab v-for="(flock,index) in flocks" :key="index" :header="`Stado ${index + 1}`"
                          headerClass="accordion-header-grey">
                <FormBirds v-model:birds="flock.birds" :showNoBirdsCheckbox="false" :numberOfFlocks="flocks.length"
                           @deleteThisFlock="deleteFlock" isFlock />
            </AccordionTab>
        </Accordion>
        <CustomButton class="add-control-button mt-4 align-self-center"
                      label="Dodaj stado" icon="pi pi-plus" @click="addFlock"
                      :disabled="!editing" />
    </div>
</template>

<script>
    import _ from "lodash";
    import {computed} from "vue";
    import Accordion from "primevue/accordion";
    import AccordionTab from "primevue/accordiontab";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import {getSpeciesForForm} from "@/swagger/vue-api-client";

    export default {
        name: "ObservationBirdsMNG",

        components: {
            Accordion,
            AccordionTab,
            CustomButton,
            CustomCheckbox,
            FormBirds,
        },

        props: {
            modelValue: null,
            posNoBirds: null,
        },

        emits: ["update:modelValue", "update:posNoBirds"],

        inject: ["editing"],

        provide() {
            return {
                species: computed(() => this.species),
            };
        },

        data() {
            return {
                activeIndex: 0,
                species: [],
            };
        },

        beforeMount() {
            getSpeciesForForm({formId: this.$route.params.id})
                .then((response) => {
                    this.species = response.data;
                });
        },

        methods: {
            addFlock() {
                if (this.flocks === null || this.flocks === undefined) {
                    this.flocks = [];
                }
                let ordinal;
                if (this.flocks.length === 0) {
                    ordinal = 1;
                } else {
                    ordinal = this.flocks.length + 1;
                }
                this.flocks.push(_.cloneDeep(EmptyStructures.getEmptyMNGFlock(ordinal)));
                this.activeIndex = this.flocks.length - 1;
            },
            deleteFlock(value) {
                if (this.flocks.length > 1) {
                    let index;
                    for (let i = 0; i < this.flocks.length; i += 1) {
                        if (this.flocks[i].birds.indexOf(value) >= 0) {
                            index = i;
                        }
                    }
                    this.flocks.splice(index, 1);
                    this.activeIndex = this.flocks.length - 1;
                }
            },
        },

        computed: {
            flocks: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            noBirds: {
                get() {
                    return this.posNoBirds;
                },
                set(value) {
                    this.$emit("update:posNoBirds", value);
                },
            },
        },
    };
</script>

<style lang="scss">
</style>
