<template>
    <div class="flex justify-content-between">
        <div class="flex gap-2">
            <CustomSelectOne label="Program" name="program" :items="programs"
                             class="w-1" v-model="newTrend.program" :emptyValue="false" />
            <CustomSelectOne label="Gatunek" name="species" :items="mockData.species"
                             itemLabel="name" itemValue="" class="w-1"
                             v-model="newTrend.species" :emptyValue="false" />
            <CustomCalendar label="Rok od" name="yearStart" class="w-1" dateFormat="yy" view="year"
                            v-model="newTrend.yearStart" />
            <CustomCalendar label="Rok do" name="yearEnd" class="w-1" dateFormat="yy" view="year"
                            v-model="newTrend.yearEnd" />
            <CustomInputNumber label="Trend" name="trendValue" class="w-1"
                               v-model="newTrend.trendValue" :maxDigits="99" />
            <CustomInputNumber label="Błąd standardowy trendu" name="standardError" class="w-2"
                               v-model="newTrend.standardError" :maxDigits="99" />
            <CustomSelectOne label="Trend (nazwa)" name="trendName" :items="mockData.trend"
                             class="w-2" v-model="newTrend.trend" :emptyValue="false" />
            <CustomSelectOne label="Typ trendu" name="trendType" :items="mockData.trendType"
                             class="w-1" v-model="newTrend.trendType" :emptyValue="false" />
            <CustomSelectOne label="Obszar" name="trendArea" :items="mockData.trendArea"
                             class="w-1" v-model="newTrend.trendArea" :emptyValue="false" />
        </div>
        <CustomButton label="Dodaj" name="addTrend" @click="addTrend" />
    </div>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {
        addTrendData,
    } from "@/swagger/vue-api-client";

    export default {
        name: "AddTrend",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            CustomCalendar,
            CustomButton,
        },

        props: {
            programs: {
                type: Array,
                default: () => [],
            },
            mockData: {
                type: Object,
                default: () => {},
            },
        },

        emits: ["refreshTable"],

        data() {
            return {
                newTrend: {
                    program: null,
                    species: null,
                    yearStart: null,
                    yearEnd: null,
                    trendValue: null,
                    standardError: null,
                    trend: null,
                    trendType: null,
                    trendArea: null,
                },
            };
        },

        methods: {
            addTrend() {
                const trendRequest = {
                    program: this.newTrend.program,
                    speciesId: this.newTrend.species.id,
                    yearStart: this.newTrend.yearStart.getFullYear(),
                    yearEnd: this.newTrend.yearEnd.getFullYear(),
                    trendValue: this.newTrend.trendValue,
                    standardError: this.newTrend.standardError,
                    trend: this.newTrend.trend,
                    trendType: this.newTrend.trendType,
                    trendArea: this.newTrend.trendArea,
                };
                addTrendData({body: [trendRequest]})
                    .then(() => {
                        this.$emit("refreshTable");
                        this.newTrend = {
                            program: null,
                            species: null,
                            yearStart: null,
                            yearEnd: null,
                            trendValue: null,
                            standardError: null,
                            trend: null,
                            trendType: null,
                            trendArea: null,
                        };
                    });
            },
        },
    };
</script>

<style lang="scss">
</style>
