<template>
    <div class="transects-map" style="width: 500px;">
        <MapMultiTransects mapId="transectsMap" height="480px" :isOn="false" :field="field" popup
                           :transects="coloredTransects" @onClick="onClick" @onHover="onHover">
            <template v-slot:popup="slotProps">
                <div class="marker-popup-header">
                    {{ slotProps.transect.name }}
                </div>
            </template>
        </MapMultiTransects>
    </div>
</template>

<script>
    import _ from "lodash";
    import MapMultiTransects from "@/components/map/MapMultiTransects.vue";

    export default {
        name: "SelectTransectMap",

        components: {
            MapMultiTransects,
        },

        props: {
            transects: {
                type: Array,
            },
            field: null,
            hoverIndex: {
                type: Number,
                default: null,
            },
            clickedIndex: {
                type: Number,
                default: null,
            },
        },

        data() {
            return {
                previousHover: null,
                previousClicked: null,
                color: "grey",
                colorDisabled: "lightGrey",
                colorClickedOrHover: "red",
                coloredTransects: [],
            };
        },

        emits: ["update:hoverIndex", "update:clickedIndex"],

        beforeMount() {
            this.coloredTransects = _.cloneDeep(this.transects);
            this.coloredTransects.forEach((t, i) => {
                if (t.notAudited === true) {
                    t.color = this.colorDisabled;
                    t.openPopup = false;
                    t.zIndexOffset = -1;
                } else if (i === this.clickedIndex || i === this.hoverIndex) {
                    t.color = this.colorClickedOrHover;
                    t.openPopup = true;
                    t.zIndexOffset = 1000;
                } else {
                    t.color = this.color;
                    t.openPopup = false;
                    t.zIndexOffset = 1;
                }
            });
            this.previousHover = this.hoverIndex;
            this.previousClicked = this.clickedIndex;
        },

        methods: {
            onClick(index) {
                if (index == null || !(this.transects[index].notAudited === true)) {
                    this.$emit("update:clickedIndex", index);
                }
            },
            onHover(index) {
                if (index == null || !(this.transects[index].notAudited === true)) {
                    this.$emit("update:hoverIndex", index);
                }
            },
            markAsHoverOrClicked(index) {
                this.coloredTransects[index].color = this.colorClickedOrHover;
                this.coloredTransects[index].openPopup = true;
                this.coloredTransects[index].zIndexOffset = 1000;
            },
            markAsNormal(index) {
                this.coloredTransects[index].color = this.color;
                this.coloredTransects[index].openPopup = false;
                this.coloredTransects[index].zIndexOffset = 1;
            },
        },

        watch: {
            hoverIndex(value) {
                if (this.previousHover !== null && this.previousHover !== this.clickedIndex) {
                    this.markAsNormal(this.previousHover);
                }
                if (value !== null) {
                    this.markAsHoverOrClicked(value);
                    this.previousHover = value;
                }
            },
            clickedIndex(value) {
                if (this.previousClicked !== null && this.previousClicked !== this.hoverIndex) {
                    this.markAsNormal(this.previousClicked);
                }
                if (value !== null) {
                    this.markAsHoverOrClicked(value);
                    this.previousClicked = value;
                }
            },
        },
    };
</script>

<style lang="scss">
</style>
