<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      showControlType>
        <template v-slot:formMap>
            <FormPositions v-model="form.positions" :field="form.field" @reloadFormData="getFormData"/>
        </template>
        <template v-slot:formObservations>
            <FormMSLObservations v-model:form="form" v-model:step="step" ref="observationsView" :field="form.field.shape" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    import FormMSLObservations from "./components/FormMSLObservations.vue";
    // import {getForm, getList as getDict} from "@/swagger/vue-api-client";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    // import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "FormMSLView",

        components: {
            FormMSLObservations,
            FormViewTemplate,
            FormPositions,
        },

        data() {
            return {
                programCode: "MSL",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
                ValidateUtils,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            // this.getFormData();
            this.dicts = {
                nestingCriteria: [
                    {code: "O", label: "stwierdzenie pojedynczych ptaków dorosłych lub młodocianych podczas jednej kontroli"},
                    {code: "NP", label: "zaniepokojone ptaki latające nad obserwatorem"},
                    {code: "BU", label: "dorosłe ptaki z patykami w dziobie"},
                    {code: "GNS", label: "stwierdzenie gniazd użytkowanych w danym sezonie"},
                    {code: "WYS", label: "gniazdo wysiadywane"},
                    {code: "JAJ", label: "gniazdo z jajami"},
                    {code: "PIS", label: "gniazdo z pisklętami, nielotne młode wspinające się po gałęziach krzewów i  drzew"},
                    {code: "MŁO", label: "młode podloty z puchem na głowie"},
                ],
                programBirds: [
                    {code: "EGA", name: "czapla biała"},
                    {code: "EGZ", name: "czapla nadobna"},
                    {code: "AR", name: "czapla siwa"},
                    {code: "PCA", name: "kormoran"},
                    {code: "???", name: "kormoran mały"},
                    {code: "LAN", name: "mewa białogłowa"},
                    {code: "LAU", name: "mewa czarnogłowa"},
                    {code: "LAH", name: "mewa romańska"},
                    {code: "LAC", name: "mewa siwa"},
                    {code: "LAR", name: "śmieszka"},
                ],
                habitatType: [
                    {code: "WZ", label: "Wyspa w żwirowni"},
                    {code: "WH", label: "Wyspa na stawie hodowlanym"},
                    {code: "LO", label: "Łozowisko w dolinie rzeki"},
                    {code: "INN", label: "Inne"},
                ],
                controlNotRealizedReason: [
                    {
                        key: "AREA_NA_HUMAN",
                        code: "AREA_NA_HUMAN",
                        label: "Obszar kontroli niedostępny z powodu działań człowieka (np. prace leśne, zamknięcie z powodu ptasiej grypy, niekorzystne uprawy, teren prywatny itd.)",
                    },
                    {
                        key: "AREA_NA_NATURAL",
                        code: "AREA_NA_NATURAL",
                        label: "Obszar kontroli niedostępny z powodów naturalnych (wysoki stan wody, powodzie itd.)",
                    },
                    {
                        key: "OBSERVER_ABANDON",
                        code: "OBSERVER_ABANDON",
                        label: "Zaniechanie obserwatora",
                    },
                    {
                        key: "METHODICAL_REASON",
                        code: "METHODICAL_REASON",
                        label: "Powody metodyczne (dotyczy to programów, w których liczba kontroli nie jest stała)",
                    },
                    {
                        key: "RESULT_NOT_ACCEPTED",
                        code: "RESULT_NOT_ACCEPTED",
                        label: "Brak akceptacji wyników ze strony koordynatora",
                    },
                ],
            };
            this.form = {
                programEditionId: 123,
                programName: "MSL",
                programFullName: "Monitoring Ślepowrona",
                monitoringYear: 2024,
                editable: true,
                state: "DRAFT",
                observer: "Jan Kowalski",
                id: 1,
                field: {
                    id: 1,
                    program: "MSL",
                    name: "MSL01",
                    fieldType: "FIELD",
                    superField: null,
                    shape: [
                        {longitude: 23.02674900176643, latitude: 51.39735704253448},
                        {longitude: 23.170159002152793, latitude: 51.39233304244274},
                        {longitude: 23.178350002151205, latitude: 51.4820400423824},
                        {longitude: 23.03466000176621, latitude: 51.48707904247509},
                        {longitude: 23.02674900176643, latitude: 51.39735704253448},
                    ],
                },
                control: {
                    label: "Kontrola 1",
                    controlStart: "2024-04-10",
                    controlEnd: "2024-07-20",
                },
                noFormControl: false,
                noFormControlReason: null,
                noFormControlNotes: null,
                positions: [
                    {
                        position: {
                            accepted: false,
                            coordinates: {
                                longitude: 23.04107666015625,
                                latitude: 51.433892209165236,
                            },
                            name: "MSL1",
                            positionHistoryId: 1,
                            positionId: 1,
                            status: "CREATED",
                        },
                        notAudited: false,
                        notAuditedReason: null,
                        notAuditedNotes: "",
                        control1: {
                            controlDate: "2024-04-10",
                            startTime: "20:00",
                            endTime: "22:00",
                            notes: "uwagi testowe",
                            additionalObservers: "Jan Nowak",
                            birds: [
                                {
                                    pairs: 1,
                                    nestingCriteria: {code: "JAJ", label: "gniazdo z jajami"},
                                },
                                {
                                    pairs: 3,
                                    nestingCriteria: {code: "PIS", label: "gniazdo z pisklętami, nielotne młode wspinające się po gałęziach krzewów i  drzew"},
                                },
                            ],
                            noBirds: false,
                            otherBirds: [
                                {code: "LAN", name: "mewa białogłowa"},
                                {code: "LAU", name: "mewa czarnogłowa"},
                                {code: "LAH", name: "mewa romańska"},
                            ],
                        },
                        control2: {
                            controlDate: "2024-04-10",
                            startTime: "20:00",
                            endTime: "22:00",
                            notes: "uwagi testowe",
                            additionalObservers: "Jan Nowak",
                            birds: [
                                {
                                    pairs: 5,
                                    nestingCriteria: {code: "O", label: "stwierdzenie pojedynczych ptaków dorosłych lub młodocianych podczas jednej kontroli"},
                                },
                            ],
                            noBirds: false,
                            otherBirds: [
                                {code: "LAU", name: "mewa czarnogłowa"},
                                {code: "LAH", name: "mewa romańska"},
                            ],
                        },
                        habitatData: null,
                        habitatDataToChange: {
                            habitatType: [
                                {code: "INN", label: "Inne"},
                            ],
                            habitatNotes: "Lorem ipsum",
                        },
                    },
                    {
                        position: {
                            accepted: false,
                            coordinates: {
                                longitude: 23.145446777343754,
                                latitude: 51.44373668041616,
                            },
                            name: "MSL2",
                            positionHistoryId: 2,
                            positionId: 2,
                            status: "CREATED",
                        },
                        notAudited: false,
                        notAuditedReason: null,
                        notAuditedNotes: "",
                        control1: {},
                        control2: {},
                        habitatData: null,
                        habitatDataToChange: null,
                    },
                ],
            };
            ValidateUtils.flattenData(this.form.positions, "position");
            console.log(this.form);
            console.log(this.dicts);
            this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
            this.loaded = true;
        },

        methods: {
            getFormData() {
                // getForm({program: this.programCode, formId: this.$route.params.id})
                //     .then((response) => {
                //         this.form = response.data;
                //         ValidateUtils.flattenData(this.form.positions, "position");
                //         console.log(this.form);
                //         this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                //         return getDict({programCode: this.programCode, formId: this.$route.params.id});
                //     })
                //     .then((response) => {
                //         this.dicts = response.data;
                //         console.log(this.dicts);
                //         this.loaded = true;
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //         this.loaded = false;
                //     });
            },
            getFormRequest() {
            },
            validateData() {
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.points, "Punkt");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
