import store from "../store";
/**
 * Uwaga zmienne, metody, watcher'y itp z tego pliku zostaną dodane do wszystkich komponentów w aplikacji
 */
/* eslint-disable */
export const ThemeSettings = {

    computed: {
    }
}

/**
 * Metody pomocnicze, które nie mają być widoczne w komponentach
 */
export const ThemeSettingsHelper = {
    fontSize: "font-size",
    fontSizeValue: "normal",
    blackContrast: "black",
    normalContrast: "normal",
    contrast: "contrast",
    setBlackContrastForAllApp() {
        localStorage.setItem(ThemeSettingsHelper.contrast, ThemeSettingsHelper.blackContrast);
        document.getElementById("bodyapp").classList.add("contrast-black");
        store.commit('setBlackContrast')
    },
    setNormalContrastForAllApp() {
        localStorage.setItem(ThemeSettingsHelper.contrast, ThemeSettingsHelper.normalContrast);
        document.getElementById("bodyapp").classList.remove("contrast-black");
        store.commit('setNormalContrast')
    },
    resetSettingsForAllApp() {
        localStorage.setItem(ThemeSettingsHelper.contrast, ThemeSettingsHelper.normalContrast);
        document.getElementById("bodyapp").classList.remove("contrast-black");
        store.commit('setNormalContrast');
        ThemeSettingsHelper.setNormalFontSize();
    },
    increaseFontSize() {
        let currentFontSize = parseInt(document.getElementsByTagName("html")[0]
            .getAttribute("data-font-size"), 10);
        if (currentFontSize < 2) {
            currentFontSize += 1;
            if (currentFontSize == 1) {
                ThemeSettingsHelper.setBigFontSize();
            }
            if (currentFontSize == 2) {
                ThemeSettingsHelper.setBiggerFontSize();
            }
        }
    },
    decreaseFontSize() {
        let currentFontSize = parseInt(document.getElementsByTagName("html")[0]
            .getAttribute("data-font-size"), 10);
        if (currentFontSize > 0) {
            currentFontSize -= 1;
            if (currentFontSize == 1) {
                ThemeSettingsHelper.setBigFontSize();
            }
            if (currentFontSize == 0) {
                ThemeSettingsHelper.setNormalFontSize();
            }
        }
    },
    setBigFontSize() {
        ThemeSettingsHelper.fontSizeValue = "big";
        localStorage.setItem(ThemeSettingsHelper.fontSize, ThemeSettingsHelper.fontSizeValue);
        document.getElementsByTagName("html")[0].setAttribute("data-font-size", "1");
        store.commit('setBigFontSize');
    },
    setBiggerFontSize() {
        ThemeSettingsHelper.fontSizeValue = "bigger";
        localStorage.setItem(ThemeSettingsHelper.fontSize, ThemeSettingsHelper.fontSizeValue);
        document.getElementsByTagName("html")[0].setAttribute("data-font-size", "2");
        store.commit('setBiggerFontSize');
    },
    setNormalFontSize() {
        ThemeSettingsHelper.fontSizeValue = "normal";
        localStorage.setItem(ThemeSettingsHelper.fontSize, ThemeSettingsHelper.fontSizeValue);
        document.getElementsByTagName("html")[0].setAttribute("data-font-size", "0");
        store.commit('setNormalFontSize');
    },
    checkLocalStorage() {
        const contrast = localStorage.getItem(ThemeSettingsHelper.contrast);
        const fontSize = localStorage.getItem(ThemeSettingsHelper.fontSize);
        if (contrast === ThemeSettingsHelper.blackContrast) {
            ThemeSettingsHelper.setBlackContrastForAllApp();
        }
        if (fontSize === "big") {
            ThemeSettingsHelper.setBigFontSize();
        }
        if (fontSize === "bigger") {
            ThemeSettingsHelper.setBiggerFontSize();
        }
        if (fontSize === "normal") {
            ThemeSettingsHelper.setNormalFontSize();
        }
    }

}
