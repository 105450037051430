<template>
    <div class="p-3">
        <div class="mx-3 flex" v-if="isMainCoordinator">
            <div class="my-2">
                Dodaj nowy program:
            </div>
            <div class="w-20rem mx-3">
                <CustomSelectOne v-model="program" :items="programs" :show-clear="false" name="program" itemLabel=""
                                 :emptyValue="false" />
            </div>
            <div class="w-20rem mx-3">
                <CustomCalendar v-model="year" name="year" label="Rok" dateFormat="yy" view="year" placeholder="YYYY"/>
            </div>
            <div class="w-20rem my-1 mx-3">
                <Button label="Dodaj" @click="addProgram"/>
            </div>
        </div>
        <ProgramsTable ref="table"/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import ProgramsTable from "./components/ProgramsTable";
    import {
        createProgram, getListOfPrograms,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";

    export default {
        name: "ProgramsView",

        components: {
            CustomCalendar,
            CustomSelectOne,
            ProgramsTable,
            Button,
        },

        computed: {
            isMainCoordinator() {
                return SystemRole.isMainCoordinator();
            },
        },

        data() {
            return {
                program: null,
                programs: [],
                year: null,
            };
        },

        mounted() {
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                });
        },

        methods: {
            addProgram() {
                createProgram({ program: this.program, year: this.year.getFullYear() })
                    .then(() => {
                        this.$refs.table.search();
                    });
            },
        },
    };
</script>

<style lang="scss">
</style>
