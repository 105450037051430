<template>
    <Slider v-model="internalValue" :min="rangeNotNull.min" :max="rangeNotNull.max" range />
    <div class="flex align-items-center justify-content-between px-2 pb-0">
        <CustomInputNumber show-buttons name="min" :min="rangeNotNull.min" :max="rangeNotNull.max"
                           v-model="inputMin" @input="update" :maxDigits="maxDigits" />
        <CustomInputNumber show-buttons name="max" :min="rangeNotNull.min" :max="rangeNotNull.max"
                           v-model="inputMax" @input="update" :maxDigits="maxDigits" />
    </div>
</template>
<script>
    import Slider from "primevue/slider";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";

    export default {
        name: "CustomIntervalFilter",
        components: {CustomInputNumber, Slider},
        props: {
            range: {
                type: Object,
                required: true,
            },
            modelValue: {
                required: true,
            },
            maxDigits: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                inputMin: this.modelValue ? this.modelValue[0] : (this.range.min || 0),
                inputMax: this.modelValue ? this.modelValue[1] : (this.range.max || 0),
            };
        },
        methods: {
            update() {
                this.internalValue = [this.inputMin, this.inputMax];
            },
        },
        computed: {
            internalValue: {
                get() {
                    if (this.modelValue == null) return [this.range.min, this.range.max];
                    return this.modelValue;
                },
                set(value) {
                    [this.inputMin, this.inputMax] = value;
                    if (value[0] > value[1]) value = [value[1], value[0]];
                    this.$emit("update:modelValue", [value[0], value[1]]);
                },
            },
            rangeNotNull() {
                return {
                    min: this.range.min || 0,
                    max: this.range.max || 0,
                };
            },
        },
        emits: ["update:modelValue"],
    };
</script>

<style lang="scss">
.min {
    width: 4rem;
    margin: 1rem 1.5rem 0 0;
}
.max {
    width: 4rem;
    margin: 1rem 0 0 1.5rem;
}
</style>
