<template>
    <div class="custom-autocomplete field relative">
        <span class="p-float-label" :style="computedWidth()">
            <AutoComplete :id="name" @complete="innerCompleteFunction" :suggestions="items"
                          :field="field" v-model="internalValue" :dropdown="dropdown" :multiple="multiple"
                          :placeholder="internalValue ? '' : label" :disabled="disabled" :class="computedClasses"/>
            <CustomLabel :name="name" :label="label" :required="required" :disabled="disabled"
                         :class="{'label-filled': internalValue != null && internalValue !== ''}" />
        </span>
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
    </div>
</template>

<script>
    import AutoComplete from "primevue/autocomplete";
    import CustomValidationMessage from "@/components/form/inner/CustomValidationMessage.vue";
    import CustomLabel from "@/components/form/inner/CustomLabel.vue";

    export default {
        name: "CustomAutocomplete",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            completeFunction: {
                type: Function,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            dropdown: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
            field: {
                type: String,
                default: "label",
            },
            itemFromQuery: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            width: {
                type: String,
                default: null,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue === "");
            },
            computedClasses() {
                return (this.customErrorMessage !== "" || this.showRequiredError) ? "p-invalid" : "";
            },
        },

        data() {
            return {
                items: [],
            };
        },

        components: {CustomLabel, CustomValidationMessage, AutoComplete},

        methods: {
            innerCompleteFunction(event) {
                this.completeFunction({query: event.query})
                    .then((response) => {
                        this.items = response.data;
                        if (this.itemFromQuery) {
                            const queryItem = {value: null};
                            queryItem[this.field] = document.querySelector(".custom-autocomplete input").value;
                            this.items.push(queryItem);
                        }
                        if (this.emptyValue) {
                            this.items = [null, ...this.items];
                        }
                    });
            },
            computedWidth() {
                if (this.width === null) return "";
                return "width: " + this.width;
            },
        },
    };
</script>

<style lang="less" scoped>
.custom-autocomplete {
    .p-autocomplete {
        width: 100%;
    }
}
</style>
