<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              :controls="position.controls">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition v-model="position" :positions="positions" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlMainData="slotProps">
            <div>
                <ControlTypeAndDates v-model="slotProps.control" />
                <div class="margins flex mb-4 gap-2">
                    <CustomCheckbox label="Brak ptaków" name="noBirds" v-model="slotProps.control.noBirds" class="w-4" />
                    <CustomSelectOne label="Metoda obserwacji" name="controlMethod" v-model="slotProps.control.controlMethod"
                                     class="w-4" :items="dicts.controlMethod" required :emptyValue="false"
                                     :showErrors="showErrorMessages" :disabled="!editing"/>
                    <CustomInputNumber v-if="!slotProps.control.noBirds" label="Liczba zajętych gniazd" name="occupiedNests"
                                       v-model="slotProps.control.occupiedNests" class="w-4" required :disabled="!editing"
                                       :showErrors="!slotProps.control.noBirds && showErrorMessages" />
                    <div v-if="slotProps.control.noBirds" class="w-4 dummy"></div>
                </div>
            </div>
        </template>
        <template v-slot:broodEffect>
            <div class="flex flex-column gap-6">
                <div>
                    <HeaderComponent headerText="Straty" uppercase :hierarchy="2" class="mb-4" />
                    <FormBirds :showNoBirdsCheckbox="false" v-model:birds="position.losses"/>
                </div>
                <div>
                    <HeaderComponent headerText="Udatność" uppercase :hierarchy="2" class="mb-4" />
                    <div class="flex gap-2">
                        <CustomInputNumber label="Liczba piskląt, które uzyskały lotność" class="w-4"
                                           v-model="position.nestlings" name="nestlingsSuccess" />
                        <CustomInputText label="Dodatkowe uwagi" class="w-8" v-model="position.additionalInfo"
                                         name="additionalInfoSuccess" />
                    </div>
                </div>
            </div>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        name: "FormMRCObservations",

        components: {
            CustomCheckbox,
            CustomSelectOne,
            FormObservationsTemplate,
            ControlTypeAndDates,
            ObservationsSelectPosition,
            HeaderComponent,
            FormBirds,
            CustomInputNumber,
            CustomInputText,
        },

        props: {
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["showErrorMessages", "dicts", "editing"],

        data() {
            return {
                positions: [],
                position: _.cloneDeep(EmptyStructures.getEmptyMRCPosition()),
                activeIndex: 0,
                index: 0,
            };
        },

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
            for (let i = 0; i < this.positions.length; i += 1) {
                if (this.positions[i].controls.length === 0) {
                    for (let j = 0; j < 6; j += 1) {
                        this.positions[i].controls.push(EmptyStructures.getEmptyMRCControl());
                    }
                }
            }
            console.log(this.positions);
        },

        emits: ["update:step"],

        computed: {
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            "position.controls": {
                handler(value) {
                    value.forEach((control, index) => {
                        let earlier = false;
                        for (let i = index - 1; i >= 0; i -= 1) {
                            const otherControl = this.position.controls[i];
                            if (otherControl.controlDate != null && control.controlDate < otherControl.controlDate) {
                                earlier = true;
                            }
                        }
                        control.showErrorControlDate = earlier;
                    });
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
