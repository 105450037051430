<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing"
                      v-model:step="step" v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      :noPositions="form.noPositions">
        <template v-slot:formMap>
            <div class="px-3">
                <CustomAlertBox class="flex justify-content-start w-full mb-3" :disabled="!editing"
                                text="Brak stanowisk" v-model="form.noPositions"/>
                <!-- <CustomCheckbox class="w-4" label="Brak stanowisk" name="noPositions" v-model="form.noPositions" /> -->
                <CustomCalendar v-if="form.noPositions" class="w-4" label="Data liczenia"
                                v-model="form.controlDate" name="controlDate" />
            </div>
            <FormPositions v-if="!form.noPositions" v-model="form.positions" :field="form.field" @reloadFormData="getFormData"
                           dynamicPositions />
        </template>
        <template v-slot:formObservations>
            <FormMFGPObservations :field="form.field.shape" :allPositions="form.positions"
                                  ref="observationsView" v-model:step="step" v-model:form="form" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    import FormMFGPObservations from "./components/FormMFGPObservations.vue";
    // import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import CustomAlertBox from "@/components/form/CustomAlertBox.vue";

    export default {
        name: "FormMFGPView",

        components: {
            FormViewTemplate,
            FormPositions,
            FormMFGPObservations,
            // CustomCheckbox,
            CustomCalendar,
            CustomAlertBox,
        },

        data() {
            return {
                programCode: "MFGP",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            // this.getFormData();
            this.dicts = {
                /* eslint-disable */
                nestingCriteria: [
                    {code: "O", label: "O - pojedyncze ptaki obserwowane w siedlisku lęgowym", species: ["POG"]},
                    {code: "PR", label: "PR - para w siedlisku lęgowym", species: ["POG"]},
                    {code: "KT", label: "KT - kopulacja, toki", species: ["POG"]},
                    {code: "NP", label: "NP - zachowanie jednego ptaka lub pary wskazujące na obecność lęgu", species: ["POG"]},
                    {code: "BU", label: "BU - budowa gniazda", species: ["POG"]},
                    {code: "GNS", label: "GNS - gniazdo nowe lub skorupy jaj z danego roku", species: ["POG"]},
                    {code: "JAJ", label: "JAJ - gniazdo z jajami", species: ["POG"]},
                    {code: "PIS", label: "PIS - pisklęta puchowe", species: ["POG"]},
                    {code: "MŁO", label: "MŁO - młode nielotne lub słabo lotne", species: ["POG"]},
                    {code: "WYS", label: "WYS - ptak w pozycji wysiadywania", species: ["POG"]},

                    {code: "PR", label: "PR - para w siedlisku lęgowym", species: ["CIA"]},
                    {code: "KT1", label: "KT1 - tokujący samiec", species: ["CIA"]},
                    {code: "KT2", label: "KT2 - tokująca lub kopulująca para", species: ["CIA"]},
                    {code: "NP", label: "NP - zaniepokojenie jednego ptaka lub pary wskazujące na obecność lęgu", species: ["CIA"]},
                    {code: "BU", label: "BU - budowa gniazda", species: ["CIA"]},
                    {code: "MPF", label: "MPF - samiec z pokarmem dla samicy", species: ["CIA"]},
                    {code: "GNS", label: "GNS - gniazdo nowe lub skorupy jaj z danego roku", species: ["CIA"]},
                    {code: "WYS", label: "WYS - ptak w pozycji wysiadywania", species: ["CIA"]},
                    {code: "JAJ", label: "JAJ - gniazdo z jajami", species: ["CIA"]},
                    {code: "PIS", label: "PIS - pisklęta puchowe", species: ["CIA"]},
                    {code: "MŁO", label: "MŁO - młode nielotne lub słabo lotne", species: ["CIA"]},
                    
                    {code: "PG", label: "PG - para odzywająca się w duecie w siedlisku dogodnym do gniazdowania", species: ["GR"]},
                    {code: "KT", label: "KT - kopulacja, toki", species: ["GR"]},
                    {code: "NP", label: "NP - zaniepokojenie jednego ptaka lub pary wskazujące na obecność lęgu", species: ["GR"]},
                    {code: "BU", label: "BU - budowa gniazda", species: ["GR"]},
                    {code: "GNS", label: "GNS - gniazdo nowe lub skorupy jaj z danego roku", species: ["GR"]},
                    {code: "WYS", label: "WYS - ptak w pozycji wysiadywania", species: ["GR"]},
                    {code: "UDA", label: "UDA - odwodzenie od gniazda lub młodych", species: ["GR"]},
                    {code: "JAJ", label: "JAJ - gniazdo z jajami", species: ["GR"]},
                    {code: "PIS", label: "PIS - pisklęta puchowe", species: ["GR"]},
                    {code: "MŁO", label: "MŁO - młode nielotne lub słabo lotne", species: ["GR"]},

                    {code: "H0", label: "H0 - gniazdo niezajęte", species: ["CCC"], control: 1},
                    {code: "H1", label: "H1 - jeden ptak na gnieździe", species: ["CCC"], control: 1},
                    {code: "HP", label: "HP - para na gnieździe", species: ["CCC"], control: 1},
                    {code: "BU", label: "BU - gniazdo dobudowane", species: ["CCC"], control: 1},
                    {code: "KT", label: "KT - kopulacja, toki", species: ["CCC"], control: 1},
                    {code: "WYS", label: "WYS - ptak w pozycji wysiadywania", species: ["CCC"], control: 1},
                    {code: "BRAKG", label: "BRAKG - brak gniazda", species: ["CCC"], control: 1},

                    {code: "H0", label: "H0 - gniazdo niezajęte", species: ["CCC"], control: 2},
                    {code: "HE", label: "HE - gniazdo zajęte przez jednego ptaka dłużej niż jeden miesiąc w okresie od 14.04 do 15.06", species: ["CCC"], control: 2},
                    {code: "HB", label: "HB - gniazdo zajęte przez jednego lub dwa ptaki krócej niż jeden miesiąc lub zajmowane z przerwamii", species: ["CCC"], control: 2},
                    {code: "HPA", label: "HPA - gniazdo zajęte przez parę dłużej niż jeden miesiąc w okresie od 14.04 do 15.06", species: ["CCC"], control: 2},
                    {code: "HPX", label: "HPX - gniazdo typu HPA; nie ustalono czy zostały wprowadzone pisklęta", species: ["CCC"], control: 2},
                    {code: "HP0", label: "HP0 - gniazdo typu HPA, z którego nie zostały wyprowadzone pisklęta", species: ["CCC"], control: 2},
                    {code: "HPMX", label: "HPMX - gniazdo typu HPM o nieustalonej liczbie wyprowadzonych młodych", species: ["CCC"], control: 2},
                    {code: "HPM1", label: "HPM1 - gniazdo z młodymi, z którego został wyprowadzony 1 młody", species: ["CCC"], control: 2},
                    {code: "HPM2", label: "HPM2 - gniazdo z młodymi, z którego zostały wyprowadzone 2 młode", species: ["CCC"], control: 2},
                    {code: "HPM3", label: "HPM3 - gniazdo z młodymi, z którego zostały wyprowadzone 3 młode", species: ["CCC"], control: 2},
                    {code: "HPM4", label: "HPM4 - gniazdo z młodymi, z którego zostały wyprowadzone 4 młode", species: ["CCC"], control: 2},
                    {code: "HPM5", label: "HPM5 - gniazdo z młodymi, z którego zostało wyprowadzonych 5 młodych", species: ["CCC"], control: 2},
                    {code: "HPM6", label: "HPM6 - gniazdo z młodymi, z którego zostało wyprowadzonych 6 młodych", species: ["CCC"], control: 2},
                    {code: "BRAKG", label: "BRAKG - brak gniazda", species: ["CCC"], control: 2},
                    
                    {code: "O", label: "O - pojedyncze ptaki obserwowane w siedlisku lęgowym", species: ["BS"]},
                    {code: "S", label: "S - samiec buczący", species: ["BS"]},
                ],
                countingMethod: [
                    {code: "1", label: "nasłuch", species: ["GR"]},
                    {code: "2", label: "obserwacja wizualna", species: ["GR"]},

                    {code: "1", label: "obserwacja wizualna", species: ["CCC"]},
                    {code: "2", label: "kontrola dronem", species: ["CCC"]},
                ],
                habitatType: [
                    {code: "JZ", label: "JZ – jezioro", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "OP", label: "OP – oczko wodne śródpolne", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "OŚ", label: "OŚ – oczko wodne śródleśne", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "OO", label: "OO – okresowe oczko wodne", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "RZ", label: "RZ – rzeka", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "SE", label: "SE – starorzecze", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "ZZ", label: "ZZ – zbiornik retencyjny / zaporowy", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "ST", label: "ST – staw", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "GT", label: "GT – glinianka, torfianka", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "RO", label: "RÓ – rów", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "KA", label: "KA – kanał", species: ["BS", "GR", "CIA", "POG"]},
                    {code: "IN", label: "IN – inne (opisać w dodatkowych informacjach)", species: ["BS", "GR", "CIA", "POG"]},

                    {code: "ZP", label: "ZP – zadrzewienie w parku", species: ["COF"]},
                    {code: "CM", label: "CM – drzewa na cmentarzu", species: ["COF"]},
                    {code: "OG", label: "OG – drzewa w ogrodzie", species: ["COF"]},
                    {code: "DR", label: "DR – drzewa rozporszone", species: ["COF"]},
                    {code: "AP", label: "AP – aleja przydrożna", species: ["COF"]},
                    {code: "SL", label: "SL – skraj lasu", species: ["COF"]},
                    {code: "LE", label: "LE – lampa", species: ["COF"]},
                    {code: "IN", label: "IN – inne (opisać w dodatkowych informacjach)", species: ["COF"]},
                ],
                treeType: [
                    {code: "BRZ", label: "BRZ - brzoza", species: ["COF"]},
                    {code: "DAB", label: "DAB - dąb", species: ["COF"]},
                    {code: "GRA", label: "GRA - grab", species: ["COF"]},
                    {code: "GRU", label: "GRU - grusza", species: ["COF"]},
                    {code: "JAB", label: "JAB - jabłoń", species: ["COF"]},
                    {code: "JAW", label: "JAW - jawor", species: ["COF"]},
                    {code: "JES", label: "JES - jesion", species: ["COF"]},
                    {code: "KAS", label: "KAS - kasztanowiec", species: ["COF"]},
                    {code: "KLO", label: "KLO - klon", species: ["COF"]},
                    {code: "LIP", label: "LIP - lipa", species: ["COF"]},
                    {code: "MOD", label: "MOD - modrzew", species: ["COF"]},
                    {code: "OLC", label: "OLC - olcha", species: ["COF"]},
                    {code: "ROB", label: "ROB - robinia", species: ["COF"]},
                    {code: "SOS", label: "SOS - sosna", species: ["COF"]},
                    {code: "SWI", label: "SWI - świerk", species: ["COF"]},
                    {code: "TOP", label: "TOP - topola", species: ["COF"]},
                    {code: "WIA", label: "WIA - wiąz", species: ["COF"]},
                    {code: "WIE", label: "WIE - wierzba", species: ["COF"]},
                    {code: "IN", label: "IN - inne (opisać w dodatkowych informacjach)", species: ["COF"]},
                ],
                nestPosition: [
                    {code: "SE", label: "SE - słup energetyczny", species: ["CCC"]},
                    {code: "SEP", label: "SEP - słup energetyczny z platformą", species: ["CCC"]},
                    {code: "SSP", label: "SSP - słup specjalny z platformą", species: ["CCC"]},
                    {code: "BUD", label: "BUD - dach budynku", species: ["CCC"]},
                    {code: "KB", label: "KB - komin budynku", species: ["CCC"]},
                    {code: "KP", label: "KP - komin przemysłowy", species: ["CCC"]},
                    {code: "DRZ", label: "DRZ - drzewo (podać w dodatkowych informacjach)", species: ["CCC"]},
                    {code: "IN", label: "IN - inne (podać w dodatkowych informacjach)", species: ["CCC"]},
                ],
                /* eslint-enable */
            };
            this.dictsFilterBySpecies();
            this.form = {
                programEditionId: 123,
                programName: "MFGP",
                programFullName: "Monitoring Flagowych Gatunków Ptaków",
                monitoringYear: 2024,
                editable: true,
                state: "DRAFT",
                observer: "Jan Kowalski",
                id: 1,
                field: {
                    id: 1,
                    program: "MFGP",
                    name: "MFGP01",
                    fieldType: "FIELD",
                    superField: null,
                    shape: [
                        {longitude: 23.02674900176643, latitude: 51.39735704253448},
                        {longitude: 23.170159002152793, latitude: 51.39233304244274},
                        {longitude: 23.178350002151205, latitude: 51.4820400423824},
                        {longitude: 23.03466000176621, latitude: 51.48707904247509},
                        {longitude: 23.02674900176643, latitude: 51.39735704253448},
                    ],
                },
                control: {
                    label: "Kontrola 1",
                    controlStart: "2024-04-10",
                    controlEnd: "2024-07-20",
                },
                noFormControl: false,
                noFormControlReason: null,
                noFormControlNotes: null,
                noPositions: false,
                controlDate: null,
                positions: [
                    {
                        position: {
                            accepted: false,
                            coordinates: {
                                longitude: 23.04107666015625,
                                latitude: 51.433892209165236,
                            },
                            name: "Zagłębocze",
                            positionHistoryId: 1,
                            positionId: 1,
                            status: "CREATED",
                        },
                        notAudited: false,
                        notAuditedReason: null,
                        notAuditedNotes: "",
                        controlDate: "2024-05-11",
                        additionalObservers: "Jan Kowalski",
                        nestPosition: {code: "BUD", label: "BUD - dach budynku", species: ["CCC"]},
                        habitatType: {code: "OO", label: "OO – okresowe oczko wodne", species: ["BS", "GR", "CIA", "POG"]},
                        noBirds: false,
                        birds: [
                            {
                                pairs: 2,
                                nestingCriteria: {code: "PIS", label: "PIS - pisklęta puchowe", species: ["POG"]},
                                additionalInfo: "Lorem ipsum",
                            },
                        ],
                        control1: {
                            controlDate: "2024-06-21",
                            nestingCriteria: {
                                code: "HP", label: "HP - para na gnieździe", species: ["CCC"], control: 1,
                            },
                            countingMethod: {code: "1", label: "obserwacja wizualna", species: ["CCC"]},
                            breedingResult: null,
                            additionalInfo: "Lorem ipsum",
                            noControl: false,
                            noControlReason: null,
                            noControlNotes: null,
                            additionalObservers: "Jan Kowalski",
                        },
                        control2: {
                            controlDate: "2024-07-21",
                            nestingCriteria: {
                                code: "HPX", label: "HPX - gniazdo typu HPA; nie ustalono czy zostały wprowadzone pisklęta", species: ["CCC"], control: 2,
                            },
                            countingMethod: {code: "1", label: "obserwacja wizualna", species: ["CCC"]},
                            breedingResult: null,
                            additionalInfo: "Lorem ipsum",
                            noControl: false,
                            noControlReason: null,
                            noControlNotes: null,
                            additionalObservers: "Jan Kowalski",
                        },
                    },
                ],
            };
            if (this.$route.query.birdCode === "COF") {
                this.form.positions[0].habitatType = {code: "DR", label: "DR – drzewa rozporszone", species: ["COF"]};
                this.form.positions[0].birds[0] = {
                    nests: 4,
                    countingMethod: {code: "1", label: "obserwacja wizualna", species: ["CCC"]},
                    treeType: {code: "KLO", label: "KLO - klon", species: ["COF"]},
                    additionalInfo: "Lorem ipsum",
                };
            }
            if (this.$route.query.birdCode === "CIA" || this.$route.query.birdCode === "GR" || this.$route.query.birdCode === "BS") {
                this.form.positions[0].habitatType = {code: "SE", label: "SE – starorzecze", species: ["BS", "GR", "CIA", "POG"]};
                this.form.positions[0].birds = {
                    noBirds: false,
                    countingMethod: {code: "1", label: "nasłuch", species: ["GR"]},
                    nestingCriteria: this.$route.query.birdCode === "BS" ? {code: "O", label: "O - pojedyncze ptaki obserwowane w siedlisku lęgowym", species: ["BS"]} : {code: "PR", label: "PR - para w siedlisku lęgowym", species: ["CIA", "GR"]},
                    additionalInfo: "Lorem ipsum",
                };
            }
            if (this.$route.query.birdCode === "GR") {
                this.form.positions[0].position.name = "2024_01";
                this.form.positions[0].birds.nestingCriteria = {code: "PG", label: "PG - para odzywająca się w duecie w siedlisku dogodnym do gniazdowania", species: ["GR"]};
            }
            if (this.$route.query.birdCode === "CCC" || this.$route.query.birdCode === "COF") {
                this.form.positions[0].position.name = "Lejno";
            }
            if (this.$route.query.birdCode === "POG") {
                this.form.subprogramName = "Liczenie perkoza rdzawoszyjego";
            }
            if (this.$route.query.birdCode === "CIA") {
                this.form.subprogramName = "Liczenie błotniaka stawowego";
            }
            if (this.$route.query.birdCode === "GR") {
                this.form.subprogramName = "Liczenie żurawia";
            }
            if (this.$route.query.birdCode === "CCC") {
                this.form.subprogramName = "Liczenie bociana białego";
            }
            if (this.$route.query.birdCode === "BS") {
                this.form.subprogramName = "Liczenie bąka";
            }
            if (this.$route.query.birdCode === "COF") {
                this.form.subprogramName = "Liczenie gawrona";
            }
            ValidateUtils.flattenData(this.form.positions, "position");
            console.log(this.form);
            console.log(this.dicts);
            this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
            this.loaded = true;
        },

        methods: {
            getFormData() {
                // getForm({program: this.programCode, formId: this.$route.params.id})
                //     .then((response) => {
                //         this.form = response.data;
                //         ValidateUtils.flattenData(this.form.positions, "position");
                //         console.log(this.form);
                //         this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                //         return getDict({programCode: this.programCode, formId: this.$route.params.id});
                //     })
                //     .then((response) => {
                //         this.dicts = response.data;
                //         console.log(this.dicts);
                //         this.loaded = true;
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //         this.loaded = false;
                //     });
            },
            getFormRequest() {
            },
            validateData() {
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.positions, "Stanowisko");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
            dictsFilterBySpecies() {
                if (this.$route.query.birdCode) {
                    Object.keys(this.dicts).forEach((key) => {
                        this.dicts[key] = this.dicts[key].filter((nc) => nc.species.includes(this.$route.query.birdCode));
                    });
                }
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
