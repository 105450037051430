<template>
    <Accordion :multiple="true" :activeIndex="[...Array(controls.length).keys()]" class="accordion-rounded">
        <AccordionTab v-for="(control,index) in controls" :key="control.id ? control.id : index"
                      :headerClass="`accordion-header-midgreen ${index !== 0 && spaceBetweenControls ? 'mt-5' : ''} ${spaceBetweenControls ? 'accordion-rounded-all' : ''}`">
            <template #header>
                <span v-if="customControlHeader" class="p-accordion-header-text">{{ controlHeader(control) }}</span>
                <span v-else class="p-accordion-header-text">KONTROLA {{ index + 1 }}</span>
            </template>
            <div>
                <div class="flex justify-content-between gap-8 mb-4">
                    <CustomAlertBox v-if="checkboxNoBirds" v-model="control.noBirds" class="w-12"
                                    text="Brak gatunków głównych" :disabled="!editing" />
                    <CustomAlertBox :disabled="!editing" text="Kontrola nie została wykonana"
                                    v-model="control.noControl" class="w-12"/>
                    <CustomButton v-if="canDeleteControl" icon="pi pi-trash" label="Usuń kontrolę" style="width: 200px !important;"
                                  color="var(--surface-a)" bgColor="#EA3030" @click="$emit('deleteControl',index)" />
                </div>
                <div v-if="control.noControl">
                    <CustomSelectOne label="Przyczyna braku kontroli" name="noControlReason"
                                     v-model="control.noControlReason" :disabled="!editing"
                                     :items="dicts.controlNotRealizedReason" :emptyValue="false"
                                     :required="control.noControl" :filter="false"
                                     :showErrors="showErrorMessages && control.noControl"/>
                    <CustomInputTextArea label="Uwagi" name="noControlNotes"
                                         v-model="control.noControlNotes" :disabled="!editing" />
                </div>
                <div v-else>
                    <slot name="singleControl" :control="control" :index="index"/>
                </div>
            </div>
        </AccordionTab>
    </Accordion>
    <slot name="controlsSummary" :position="position"/>
</template>

<script>
    import Accordion from "primevue/accordion";
    import AccordionTab from "primevue/accordiontab";
    import {DateUtils} from "@/utils/DateUtils";
    import CustomAlertBox from "@/components/form/CustomAlertBox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "FormControlsTemplate",

        components: {
            CustomButton,
            CustomInputTextArea,
            CustomSelectOne,
            CustomAlertBox,
            Accordion,
            AccordionTab,
        },

        props: {
            controls: {
                type: Array,
                default: () => [],
            },
            position: {
                type: Object,
            },
            customControlHeader: {
                type: Boolean,
                default: false,
            },
            checkboxNoBirds: {
                type: Boolean,
                default: false,
            },
            canDeleteControl: {
                type: Boolean,
                default: false,
            },
            spaceBetweenControls: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["dicts", "editing", "showErrorMessages"],

        emits: ["deleteControl"],

        methods: {
            controlHeader(control) {
                let header = "";
                if (control.control !== null) header += control.control.label;
                header += " - " + this.controlDateHeader(control.controlDate) + " / ";
                header += this.controlTimeHeader(control.startTime) + " - " + this.controlTimeHeader(control.endTime);
                return header;
            },
            controlDateHeader(value) {
                if (value === "" || value === null) return "";
                return DateUtils.dateAsString(value).split("-").reverse().join(".");
            },
            controlTimeHeader(value) {
                if (value === "" || value === null) return "";
                if (value && value.length === 8) return value.slice(0, 5);
                return DateUtils.getTimeOnly(value);
            },
            computedActiveIndex() {
                const aIds = [];
                this.controls.forEach((c, i) => {
                    aIds.push(i);
                });
                return aIds;
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";
</style>
