<template>
    <div class="custom-alert-box">
        <Checkbox class="custom-alert-box-checkbox" v-model="internalValue" :binary="true" :disabled="disabled"/>
        <div class="custom-alert-box-text">{{ text }}</div>
    </div>
</template>

<script>
    import Checkbox from "primevue/checkbox";

    export default {
        name: "CustomAlertBox",

        components: {
            Checkbox,
        },

        props: {
            text: {
                type: String,
            },
            modelValue: null,
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
    @import "../assets/scss/custom-alert-box.scss";

    .custom-alert-box {
        &-checkbox {
            .p-checkbox-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                &::before {
                    font-size: .7rem;
                    font-weight: bold;
                }
            }
        }
    }
</style>
