<template>
    <div :id="mapId" :style="height ? `height: ${this.height}; position: relative;` : ''" />
    <div v-if="markerPopup">
        <div v-for="position in positions" ref="markerPopup" class="marker-popup" :key="position.positionId">
            <div class="marker-popup-header">
                {{ position.name }}
            </div>
        </div>
    </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
    import L from "leaflet";
    import {MapMixin} from "@/mixins/MapMixin";

    export default {
        name: "MapMultiMarkersSimple",

        mixins: [MapMixin],

        props: {
            mapId: {
                type: String,
                default: "map",
            },
            height: {
                type: String,
                default: null,
            },
            positions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            isOn: {
                type: Boolean,
                default: true,
            },
            zoomControls: {
                type: Boolean,
                default: true,
            },
            markerType: {
                type: String,
            },
            hideNamePopups: {
                type: Boolean,
                default: false,
            },
            markerPopup: {
                type: Boolean,
                default: true,
            },
            hoverPosition: {
                type: Number,
            },
            clickedPosition: {
                type: Number,
            },
        },

        emits: ["clickedMarker", "update:hoverPosition", "update:clickedPosition"],

        data() {
            return {
                markersCoordinates: [],
                markers: new Map(),
                /* eslint-disable */
                iconRed: '<svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.05C0 5.85 5.85 0 13.05 0L13.44 0.00599992C20.46 0.213 26.1 5.9805 26.1 13.05C26.1 20.1 19.65 30.3 14.1 37.05C13.8 37.35 13.5 37.65 13.05 37.65C12.6 37.65 12.3 37.35 12 37.05C6.3 30.15 0 20.1 0 13.05ZM9.05005 12.8252C9.05005 15.0474 10.8278 16.8252 13.05 16.8252C15.2723 16.8252 17.05 15.0474 17.05 12.8252C17.05 10.603 15.2723 8.8252 13.05 8.8252C10.8278 8.8252 9.05005 10.603 9.05005 12.8252Z" fill="#EA3030"/></svg>',
                iconRedSmall: '<svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.46434C0 2.89781 2.89781 0 6.46435 0L6.65753 0.00297207C10.1349 0.10551 12.9287 2.96245 12.9287 6.46434C12.9287 9.95657 9.73367 15.0092 6.98447 18.3528C6.83586 18.5014 6.68725 18.65 6.46435 18.65C6.24144 18.65 6.09283 18.5014 5.94423 18.3528C3.12072 14.9349 0 9.95657 0 6.46434ZM4.48286 6.35289C4.48286 7.45367 5.36349 8.3343 6.46427 8.3343C7.56505 8.3343 8.44568 7.45367 8.44568 6.35289C8.44568 5.25211 7.56505 4.37148 6.46427 4.37148C5.36349 4.37148 4.48286 5.25211 4.48286 6.35289Z" fill="#EA3030"/></svg>',
                iconLightGrey: '<svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.05C0 5.85 5.85 0 13.05 0L13.44 0.00599992C20.46 0.213 26.1 5.9805 26.1 13.05C26.1 20.1 19.65 30.3 14.1 37.05C13.8 37.35 13.5 37.65 13.05 37.65C12.6 37.65 12.3 37.35 12 37.05C6.3 30.15 0 20.1 0 13.05ZM9.05005 12.8252C9.05005 15.0474 10.8278 16.8252 13.05 16.8252C15.2723 16.8252 17.05 15.0474 17.05 12.8252C17.05 10.603 15.2723 8.8252 13.05 8.8252C10.8278 8.8252 9.05005 10.603 9.05005 12.8252Z" fill="#73808799"/></svg>',
                iconLightGreySmall: '<svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.46434C0 2.89781 2.89781 0 6.46435 0L6.65753 0.00297207C10.1349 0.10551 12.9287 2.96245 12.9287 6.46434C12.9287 9.95657 9.73367 15.0092 6.98447 18.3528C6.83586 18.5014 6.68725 18.65 6.46435 18.65C6.24144 18.65 6.09283 18.5014 5.94423 18.3528C3.12072 14.9349 0 9.95657 0 6.46434ZM4.48286 6.35289C4.48286 7.45367 5.36349 8.3343 6.46427 8.3343C7.56505 8.3343 8.44568 7.45367 8.44568 6.35289C8.44568 5.25211 7.56505 4.37148 6.46427 4.37148C5.36349 4.37148 4.48286 5.25211 4.48286 6.35289Z" fill="#73808799"/></svg>',
                iconGreen: '<svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.05C0 5.85 5.85 0 13.05 0L13.44 0.00599992C20.46 0.213 26.1 5.9805 26.1 13.05C26.1 20.1 19.65 30.3 14.1 37.05C13.8 37.35 13.5 37.65 13.05 37.65C12.6 37.65 12.3 37.35 12 37.05C6.3 30.15 0 20.1 0 13.05ZM9.05005 12.8252C9.05005 15.0474 10.8278 16.8252 13.05 16.8252C15.2723 16.8252 17.05 15.0474 17.05 12.8252C17.05 10.603 15.2723 8.8252 13.05 8.8252C10.8278 8.8252 9.05005 10.603 9.05005 12.8252Z" fill="#33795B"/></svg>',
                iconGreySmall: '<svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.46434C0 2.89781 2.89781 0 6.46435 0L6.65753 0.00297207C10.1349 0.10551 12.9287 2.96245 12.9287 6.46434C12.9287 9.95657 9.73367 15.0092 6.98447 18.3528C6.83586 18.5014 6.68725 18.65 6.46435 18.65C6.24144 18.65 6.09283 18.5014 5.94423 18.3528C3.12072 14.9349 0 9.95657 0 6.46434ZM4.48286 6.35289C4.48286 7.45367 5.36349 8.3343 6.46427 8.3343C7.56505 8.3343 8.44568 7.45367 8.44568 6.35289C8.44568 5.25211 7.56505 4.37148 6.46427 4.37148C5.36349 4.37148 4.48286 5.25211 4.48286 6.35289Z" fill="#738087"/></svg>',
                /* eslint-enable */
                fieldOnMap: [],
                polandBoundries: [
                    {longitude: 54.8333333, latitude: 14.116666666666667},
                    {longitude: 54.8333333, latitude: 24.15},
                    {longitude: 49, latitude: 24.15},
                    {longitude: 49, latitude: 14.116666666666667},
                    {longitude: 54.8333333, latitude: 14.116666666666667},
                ],
            };
        },

        watch: {
            clickedPosition(value) {
                if (value === null) {
                    this.zoomToPoland();
                }
                if (this.mapId === "positionDictMap") {
                    this.map.eachLayer((layer) => {
                        if (layer instanceof L.Marker) {
                            if (layer.options.id === value) {
                                this.map.setZoom(14);
                                this.map.panTo(layer.getLatLng());
                                layer.options.dontHidePopup = true;
                                const icon = layer.options.id === 1 ? this.getIcon(this.iconRed)
                                    : this.getIcon(this.iconLightGrey);
                                layer.setIcon(icon);
                            } else {
                                layer.options.dontHidePopup = false;
                                const icon = layer.options.id === 1 ? this.getIcon(this.iconRedSmall, true)
                                    : this.getIcon(this.iconLightGreySmall, true);
                                layer.setIcon(icon);
                            }
                        }
                    });
                } else {
                    let popupContent;
                    for (let i = 0; i < this.positions.length; i += 1) {
                        if (this.positions[i].positionId === value) {
                            popupContent = this.$refs.markerPopup[i];
                        }
                    }
                    const popup = L.popup().setContent(popupContent);
                    this.map.eachLayer((layer) => {
                        if (layer instanceof L.Marker) {
                            if (layer.options.id === value) {
                                layer.options.dontHidePopup = true;
                                this.map.closePopup();
                                layer.setIcon(this.getIcon(this.iconRed));
                                layer.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                            } else {
                                layer.options.dontHidePopup = false;
                                layer.setIcon(this.getIcon(this.iconLightGreySmall, true));
                                layer.closePopup().unbindPopup(popup);
                            }
                        }
                    });
                }
            },
            hoverPosition(value) {
                let popupContent;
                for (let i = 0; i < this.positions.length; i += 1) {
                    if (this.positions[i].positionId === value) {
                        popupContent = this.$refs.markerPopup[i];
                    }
                }
                const popup = L.popup().setContent(popupContent);
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (!layer.options.dontHidePopup) {
                            if (layer.options.id === value) {
                                layer.setIcon(this.getIcon(this.iconRed));
                                layer.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                            } else {
                                layer.setIcon(this.getIcon(this.iconLightGreySmall, true));
                                layer.closePopup().unbindPopup(popup);
                            }
                        }
                    }
                });
            },
        },

        mounted() {
            this.initCustomIdMap(this.mapId);
            this.addZoomControls();
            if (this.field !== null && this.field.length > 0) {
                this.makeField();
            } else {
                this.zoomToPoland();
            }
            this.makeMarkers();
            this.placeMarkers();
        },

        methods: {
            makeField() {
                for (let i = 0; i < this.field.length; i += 1) {
                    this.fieldOnMap.push([this.field[i].latitude, this.field[i].longitude]);
                }
                const fieldShape = L.polygon(this.fieldOnMap);
                fieldShape.setStyle({fillColor: "transparent", color: "#33795B"});
                fieldShape.addTo(this.map);
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 6);
            },
            zoomToPoland() {
                for (let i = 0; i < this.polandBoundries.length; i += 1) {
                    this.fieldOnMap.push([this.polandBoundries[i].longitude, this.polandBoundries[i].latitude]);
                }
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 1);
            },
            setMarkerObject(id, icon) {
                this.markers.set(id, {
                    icon,
                    bubblingMouseEvents: false,
                    draggable: this.isOn,
                    id,
                    dontHidePopup: false,
                });
            },
            makeMarker(position, i) {
                if (this.mapId === "positionsMap") {
                    this.setMarkerObject(position.positionId, this.getIcon(this.iconLightGreySmall, true));
                }
                if (this.mapId === "similarPositionMap") {
                    if (position.status === "ADDED") {
                        this.setMarkerObject(position.positionId, this.getIcon(this.iconRed));
                    }
                    if (position.status === "SIMILAR") {
                        this.setMarkerObject(position.positionId, this.getIcon(this.iconGreen));
                    }
                }
                if (this.mapId === "positionDictMap") {
                    if (i === 0) {
                        this.setMarkerObject(position.id, this.getIcon(this.iconRedSmall, true));
                    }
                    if (i > 0) {
                        this.setMarkerObject(position.id, this.getIcon(this.iconLightGreySmall, true));
                    }
                }
            },
            makeMarkers() {
                for (let i = 0; i < this.positions.length; i += 1) {
                    this.makeMarker(this.positions[i], i);
                }
            },
            placeMarker(position, i) {
                const markersArr = [];
                // eslint-disable-next-line
                const coordinates = position.coordinates;
                if (coordinates !== null && coordinates?.latitude !== null && coordinates?.longitude !== null) {
                    if (this.mapId === "positionsMap") {
                        const marker = L.marker([coordinates.latitude, coordinates.longitude],
                                                this.markers.get(position.positionId));
                        marker.on("mouseover", () => this.$emit("update:hoverPosition", position.positionId))
                            .on("mouseout", () => this.$emit("update:hoverPosition", null))
                            .on("click", () => this.$emit("update:clickedPosition", position.positionId));
                        marker.addTo(this.map);
                    }
                    if (this.mapId === "similarPositionMap") {
                        const popupContent = this.$refs.markerPopup[i];
                        const popup = L.popup().setContent(popupContent);
                        const marker = L.marker([coordinates.latitude, coordinates.longitude],
                                                this.markers.get(position.positionId));
                        marker.bindPopup(popup, {autoClose: false, closeOnClick: false})
                            .addTo(this.map)
                            .openPopup();
                        markersArr.push(marker);
                    }
                    this.markersCoordinates.push([coordinates.latitude, coordinates.longitude]);
                }
                if (this.mapId === "positionDictMap") {
                    if (coordinates) {
                        const marker = L.marker([coordinates.latitude, coordinates.longitude],
                                                this.markers.get(position.id));
                        marker.on("click", () => this.$emit("clickedMarker", position))
                            .addTo(this.map);
                        this.markersCoordinates.push([coordinates.latitude, coordinates.longitude]);
                    }
                }
                if (this.mapId === "similarPositionMap") {
                    // eslint-disable-next-line
                    const group = new L.featureGroup(markersArr);
                    const bounds = group.getBounds();
                    this.map.fitBounds(bounds, {padding: [20, 20]});
                }
            },
            placeMarkers() {
                for (let i = 0; i < this.positions.length; i += 1) {
                    this.placeMarker(this.positions[i], i);
                }
            },
            addZoomControls() {
                if (this.zoomControls) {
                    const zoom = L.control.zoom({
                        position: "bottomright",
                    });
                    zoom.addTo(this.map);
                }
            },
            getIcon(graphic, small = false) {
                if (small) {
                    // eslint-disable-next-line
                    return new L.divIcon({
                        html: graphic,
                        iconSize: [13, 19],
                        iconAnchor: [7.5, 19],
                        popupAnchor: [1, -34],
                    });
                }
                // eslint-disable-next-line
                return new L.divIcon({
                    html: graphic,
                    iconSize: [27, 38],
                    iconAnchor: [13.5, 38],
                    popupAnchor: [1, -34],
                });
            },
        },

    };
</script>

<style lang="scss">
    @import "../assets/scss/leaflet-map";
    @import "../../assets/theme/mytheme/variables";
</style>
