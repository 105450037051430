<template>
    <div class="p-fluid field relative">
        <span class="p-float-label">
            <Textarea :id="name" :rows="rows" cols="30" v-model="internalValue"
                      :class="computedClasses" @input="handleChange" @blur="handleBlur"
                      :autoResize="autoResize" :disabled="disabled" />
            <CustomLabel :name="name" :label="label" :required="required" :disabled="disabled" />
            <label class="hidden" :for="name">{{ label }}</label>
        </span>
        <CustomValidationMessage :errorMessage="errorMessage" />
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
    </div>
</template>

<script>
    import Textarea from "primevue/textarea";
    import {useField} from "vee-validate";
    import CustomValidationMessage from "./inner/CustomValidationMessage";
    import CustomLabel from "./inner/CustomLabel";

    export default {
        name: "CustomInputTextArea",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            width: {
                type: String,
            },
            rows: {
                type: Number,
                default: 5,
            },
            autoResize: {
                type: Boolean,
                default: true,
            },
            rules: undefined,
            modelValue: null,
            disabled: {
                type: Boolean,
                default: false,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
        },
        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            computedClasses() {
                return (this.customErrorMessage !== "" || this.showRequiredError) ? "p-invalid" : "";
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue === "");
            },
        },

        methods: {
            inputListener(event) {
                this.$emit("update:modelValue", event.target.value);
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Textarea, CustomValidationMessage, CustomLabel},
    };
</script>

<style scoped lang="scss">
</style>
