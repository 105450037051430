<template>
    <div class="observation-map" style="width: 500px;">
        <MapMultiMarkers mapId="positionsMap" :height="customHeight" :isOn="false" :field="field" markerPopup
                         :positions="positions" :markerTypes="markerTypes" openPopupOnHover openPopupOnClick
                         changeIconOnHover :iconOnHover="iconOnHover"
                         @mouseoverMarker="(id) => $emit('update:hoverPosition',id)"
                         @mouseoutMarker="$emit('update:hoverPosition',null)"
                         @clickMarker="(id) => $emit('update:clickedPosition',id)">
            <template v-slot:markerPopup="slotProps">
                <div class="marker-popup-header">
                    {{ slotProps.position.name }}
                </div>
            </template>
        </MapMultiMarkers>
    </div>
</template>

<script>
    import MapMultiMarkers from "@/components/map/MapMultiMarkers.vue";

    export default {
        name: "SelectPositionMap",

        components: {
            MapMultiMarkers,
        },

        props: {
            positions: {
                type: Array,
            },
            field: null,
            customHeight: {
                type: String,
                default: "600px",
            },
            hoverPosition: {
                type: Number,
                default: null,
            },
            clickedPosition: {
                type: Number,
                default: null,
            },
        },

        data() {
            return {
                markerTypes: new Map(),
                iconOnHover: {
                    color: "red",
                    size: "regular",
                    popup: true,
                    dontHidePopup: false,
                },
            };
        },

        emits: ["update:hoverPosition", "update:clickedPosition"],

        beforeMount() {
            this.setMarkerTypes(this.hoverPosition, this.clickedPosition);
        },

        methods: {
            setMarkerTypes(hover, clicked) {
                // creates actual map which keeps for every positionId an array with icon color and icon size
                this.positions.forEach((position, index) => {
                    if (position.positionId !== null
                        && (position.positionId === hover || position.positionId === clicked)) {
                        this.markerTypes.set(this.positions[index].positionId, {
                            color: "red",
                            size: "regular",
                            popup: true,
                            dontHidePopup: position.positionId === clicked,
                        });
                    } else {
                        this.markerTypes.set(this.positions[index].positionId, {
                            color: "lightGrey",
                            size: "small",
                            popup: false,
                            dontHidePopup: false,
                        });
                    }
                });
            },
        },

        watch: {
            hoverPosition(value) {
                this.setMarkerTypes(value, this.clickedPosition);
            },
            clickedPosition(value) {
                this.setMarkerTypes(this.hoverPosition, value);
            },
        },
    };
</script>

<style lang="scss">
</style>
