/* eslint-disable */
export const ControlState = {
    APPROVED: "Zatwierdzone",
    COORDINATOR_DRAFT: "Wersja robocza koordynatora",
    CORRECTED_DRAFT: "Poprawiona wersja robocza",
    DELETED: "Usunięty",
    DRAFT: "Wersja robocza",
    RETURNED: "Zwrócony",
    RETURNED_DRAFT: "Zwrócony – Wersja robocza",
    RETURNED_SENT_TO_VERIFICATION: "Ponownie przesłany do weryfikacji",
    SENT_TO_VERIFICATION: "Przesłany do weryfikacji",
    NOT_CREATED: "Wypełnij formularz",

    getControlStates() {
        return [
            {value: "APPROVED", label: this.APPROVED},
            {value: "COORDINATOR_DRAFT", label: this.COORDINATOR_DRAFT},
            {value: "CORRECTED_DRAFT", label: this.CORRECTED_DRAFT},
            {value: "DELETED", label: this.DELETED},
            {value: "DRAFT", label: this.DRAFT},
            {value: "RETURNED", label: this.RETURNED},
            {value: "RETURNED_DRAFT", label: this.RETURNED_DRAFT},
            {value: "RETURNED_SENT_TO_VERIFICATION", label: this.RETURNED_SENT_TO_VERIFICATION},
            {value: "SENT_TO_VERIFICATION", label: this.SENT_TO_VERIFICATION},
        ];
    },

    getStateLabel(state) {
        if (state === "APPROVED") return this.APPROVED;
        if (state === "COORDINATOR_DRAFT") return this.COORDINATOR_DRAFT;
        if (state === "CORRECTED_DRAFT") return this.CORRECTED_DRAFT;
        if (state === "DELETED") return this.DELETED;
        if (state === "DRAFT") return this.DRAFT;
        if (state === "RETURNED") return this.RETURNED;
        if (state === "RETURNED_DRAFT") return this.RETURNED_DRAFT;
        if (state === "RETURNED_SENT_TO_VERIFICATION") return this.RETURNED_SENT_TO_VERIFICATION;
        if (state === "SENT_TO_VERIFICATION") return this.SENT_TO_VERIFICATION;
        if (state === null || state === "NOT_CREATED") return this.NOT_CREATED
        return "";
    }
};
