<template>
    <div class="transect-habitat-data-table">
        <DataTable :value="habitatData" v-model:editingRows="editingRows" editMode="row" dataKey="transectId"
                   size="small" class="navigate-table" @click="arrowsNavigateTable">
            <template v-if="canAddRow" #header>
                <div class="flex justify-content-between align-items-center">
                    <div>Zmiany danych siedliskowych</div>
                    <CustomButton icon="pi pi-plus" label="Dodaj zmianę" @click="addRow" :disabled="!editing" />
                </div>
            </template>
            <ColumnGroup type="header">
                <Row>
                    <Column header="Odcinek transektu" :rowspan="2" class="w-1"/>
                    <Column header="Pierwsze siedlisko" :colspan="5" />
                    <Column header="Drugie siedlisko" :colspan="5" />
                    <Column header="Data kontroli" :rowspan="2" :colspan="1" class="w-1"/>
                    <Column v-if="canAddRow" :rowspan="2" :colspan="1" class="w-1"/>
                </Row>
                <Row>
                    <Column header="Poziom 1" :colspan="1" class="w-1"/>
                    <Column header="Poziom 2" :colspan="1" class="w-1"/>
                    <Column header="Poziom 3" :colspan="3" class="w-3"/>
                    <Column header="Poziom 1" :colspan="1" class="w-1"/>
                    <Column header="Poziom 2" :colspan="1" class="w-1"/>
                    <Column header="Poziom 3" :colspan="3" class="w-3"/>
                </Row>
            </ColumnGroup>
            <Column v-if="canAddRow" field="ordinal">
                <template #editor="{data,field,index}">
                    <CustomInputNumber v-model="data[field]" name="ordinal" class="table-input-number"
                                       @update:modelValue="(value) => habitatData[index][field] = value"
                                       :customErrorWithoutMessage="getOrdinalError(data[field])" required
                                       :disabled="!editing" />
                </template>
            </Column>
            <Column v-else field="ordinal"/>
            <Column field="hab1Lev1">
                <template #editor="{data,field,index}">
                    <CustomInputText v-model="data[field]" name="hab1Lev1" class="table-input-text"
                                     @update:modelValue="(value) => habitatData[index][field] = value"
                                     :customErrorWithoutMessage="getRequired(data[field],data.notAudited,true)" required
                                     :disabled="!editing" upperCaseOnly activeInputLock />
                </template>
            </Column>
            <Column v-for="field in ['hab1Lev2','hab1Lev3_1','hab1Lev3_2','hab1Lev3_3']" v-bind:key="field"
                    :field="field">
                <template #editor="{data,field,index}">
                    <CustomInputNumber v-model="data[field]" :name="field" class="table-input-number"
                                       @update:modelValue="(value) => habitatData[index][field] = value"
                                       :customErrorWithoutMessage="getRequired(
                                           data[field],
                                           data.notAudited,
                                           field === 'hab1Lev2' && habitatData[index]['hab1Lev1'] !== 'F',
                                       )"
                                       :required="field === 'hab1Lev2'"
                                       :disabled="!editing || data.hab1Lev1 === 'F'"
                                       :min="1" :max="habitatData[index].max ? habitatData[index].max[field] : null" />
                </template>
            </Column>
            <Column field="hab2Lev1">
                <template #editor="{data,field,index}">
                    <CustomInputText v-model="data[field]" name="hab2Lev1" class="table-input-text"
                                     @update:modelValue="(value) => habitatData[index][field] = value"
                                     :disabled="!editing" upperCaseOnly activeInputLock />
                </template>
            </Column>
            <Column v-for="field in ['hab2Lev2','hab2Lev3_1','hab2Lev3_2','hab2Lev3_3']" v-bind:key="field"
                    :field="field">
                <template #editor="{data,field,index}">
                    <CustomInputNumber v-model="data[field]" :name="field" class="table-input-number"
                                       @update:modelValue="(value) => habitatData[index][field] = value"
                                       :disabled="!editing || data.hab2Lev1 === 'F'"
                                       :min="1" :max="habitatData[index].max ? habitatData[index].max[field] : null" />
                </template>
            </Column>
            <Column field="controlDate" />
            <Column v-if="canAddRow" style="width: 60px; text-align: center;" class="w-1">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteHabitatData(slotProps.index)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import _ from "lodash";
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import ColumnGroup from "primevue/columngroup";
    import Row from "primevue/row";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "TransectHabitatDataTable",

        components: {
            CustomButton,
            CustomInputText,
            CustomInputNumber,
            DataTable,
            Column,
            ColumnGroup,
            Row,
        },

        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
            canAddRow: {
                type: Boolean,
                default: false,
            },
            alwaysEditable: {
                type: Boolean,
                default: false,
            },
            form: {
                type: Object,
            },
        },

        inject: ["editing", "showErrorMessages", "programCode"],

        data() {
            return {
                editingRows: ref([]),
                hab1Lev1Clone: [],
                hab2Lev1Clone: [],
            };
        },

        emits: ["update:modelValue"],

        mounted() {
            this.editingRows = this.habitatData.filter((hd) => this.alwaysEditable || hd.editable);
        },

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            addRow() {
                const newRow = _.cloneDeep(EmptyStructures.getEmptyTransectHabitatData());
                newRow.transectId = "index-" + this.habitatData.length;
                newRow.editable = true;
                this.habitatData = [...this.habitatData, newRow];
                this.editingRows = [...this.editingRows, newRow];
            },
            deleteHabitatData(index) {
                this.habitatData.splice(index, 1);
            },
            getOrdinalError(value) {
                if (this.canAddRow && this.showErrorMessages && (value == null || value === "")) return true;
                return this.canAddRow && value != null && value !== ""
                    && value > 0 && value <= this.form.habitatData.length
                    && EmptyStructures.isHabitatDataEmpty(this.form.habitatData[value - 1]);
            },
            getRequired(value, notAudited, required) {
                return this.showErrorMessages && !(notAudited === true) && (value == null || value === "") && required;
            },
            habLvlComputedMaxValues(el, hab) {
                el.max = {};
                if (this.programCode === "MPPL") {
                    if (el[`${hab}Lev1`] === "A") {
                        el.max[`${hab}Lev2`] = 4;
                        el.max[`${hab}Lev3_1`] = 9;
                        el.max[`${hab}Lev3_2`] = 9;
                        el.max[`${hab}Lev3_3`] = 9;
                    } else if (el[`${hab}Lev1`] === "B") {
                        el.max[`${hab}Lev2`] = 4;
                        el.max[`${hab}Lev3_1`] = 10;
                        el.max[`${hab}Lev3_2`] = 10;
                        el.max[`${hab}Lev3_3`] = 10;
                    } else if (el[`${hab}Lev1`] === "C") {
                        el.max[`${hab}Lev2`] = 8;
                        el.max[`${hab}Lev3_1`] = 8;
                        el.max[`${hab}Lev3_2`] = 8;
                        el.max[`${hab}Lev3_3`] = 8;
                    } else if (el[`${hab}Lev1`] === "D") {
                        el.max[`${hab}Lev2`] = 8;
                        el.max[`${hab}Lev3_1`] = 4;
                        el.max[`${hab}Lev3_2`] = 4;
                        el.max[`${hab}Lev3_3`] = 4;
                    } else if (el[`${hab}Lev1`] === "E") {
                        el.max[`${hab}Lev2`] = 7;
                        el.max[`${hab}Lev3_1`] = 8;
                        el.max[`${hab}Lev3_2`] = 8;
                        el.max[`${hab}Lev3_3`] = 8;
                    }
                } else if (this.programCode === "MPM") {
                    el.max[`${hab}Lev2`] = 8;
                    el.max[`${hab}Lev3_1`] = 10;
                    el.max[`${hab}Lev3_2`] = 10;
                    el.max[`${hab}Lev3_3`] = 10;
                } else {
                    el.max[`${hab}Lev2`] = null;
                    el.max[`${hab}Lev3_1`] = null;
                    el.max[`${hab}Lev3_2`] = null;
                    el.max[`${hab}Lev3_3`] = null;
                }
            },
        },

        computed: {
            habitatData: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            habitatData: {
                handler(value) {
                    value.forEach((el, index) => {
                        if (el.hab1Lev1 && el.hab1Lev1 !== this.hab1Lev1Clone[index]) {
                            this.habLvlComputedMaxValues(el, "hab1");
                            this.hab1Lev1Clone[index] = _.cloneDeep(el.hab1Lev1);
                        }
                        if (el.hab2Lev1 && el.hab2Lev1 !== this.hab2Lev1Clone[index]) {
                            this.habLvlComputedMaxValues(el, "hab2");
                            this.hab2Lev1Clone[index] = _.cloneDeep(el.hab2Lev1);
                        }
                    });
                    if (this.alwaysEditable) this.editingRows = [...value];
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    .transect-habitat-data-table {
        .p-datatable .p-datatable-tbody > tr > td {
            padding: 0.25rem 0.25rem;
        }
    }
    .transect-habitat-data-table {
        .table-input-number, .table-input-text {
            input {
                height: 32px !important;
            }
        }
    }
</style>
