/* eslint-disable */
export const messagesPL = {
    login: {
        title: "Logowanie",
        login: "Login",
        password: "Hasło",
        loginError: "Błędne dane logowania",
        action: {
            login: "Zaloguj się",
            remindPassword: "Przypomnij hasło",
        },
    },
    register: {
        title: "Rejestracja",
        firstName: "Imię",
        lastName: "Nazwisko",
        email: "Adres e-mail",
        password: "Hasło",
        confirmPassword: "Potwierdź hasło",
        organisationLevel: "Poziom organizacji",
        organisationName: "Nazwa organizacji",
        action: {
            register: "Zarejestruj się",
            backToLogin: "Wróć do logowania",
            dontHaveAnAccountYet: "Nie posiadasz jeszcze konta?",
        },
    },
    menu: {
        selectLists: "Listy wyboru",
        dataManagement: "Zarządzanie danymi",
        dictionaries: "Słowniki",
        email: "E-mail",
        sendEmail: "Wyślij e-mail",
        emailTemplate: "Szablony e-mail",
        emailsHistory: "Historia e-maili",
        fileArchive: "Archiwum plików",
        help: "Pomoc",
        monitoring: "Monitoring",
        processes: "Procesy",
        raports: "Raporty",
        settings: "Ustawienia",
        statute: "Regulamin",
        programs: "Programy",
        users: "Użytkownicy",
        usersList: "Lista użytkowników",
        addUser: "Dodaj użytkownika",
        dataAggregates: "Agregaty danych",
        habitatData: "Dane siedliskowe",
        semiRawData: "Dane skumulowane",
        rawData: "Dane surowe",
        programMCH: "MCH",
        programMCZ: "MCZ",
        programMDU: "MDU",
        programMKO: "MKO",
        programMKR: "MKR",
        programMLSL: "MLSL",
        programMNG: "MNG",
        programMOG: "MOG",
        programMOP: "MOP",
        programMPB: "MPB",
        programMPD: "MPD",
        programMPG: "MPG",
        programMPG: "MPG",
        programMPM: "MPM",
        programMPPL: "MPPL",
        programMPS: "MPS",
        programMPWR: "MPWR",
        programMRC: "MRC",
        programMRY: "MRY",
        programMSKR: "MSKR",
        programMWOT: "MWOT",
        programMZPM: "MZPM",
        programMZPW: "MZPW",
        programMZPWP: "MZPWP",
        programMPPM: "MPPM",
        programMGZ: "MGZ",
        programMNZ: "MNZ",
        programMWOP: "MWOP",
        programMZO: "MZO",
        programMLS: "MLS",
        programMLSBirds: "Ptaki",
        programMLSMammals: "Ssaki",
        programMPPLBirds: "Dane główne",
        programMPPLMammals: "Ssaki",
        programMPPLNests: "Gniazda",
        programMRCMain: "Dane główne",
        programMRCLosses: "Straty",
        trends: "Trendy",
        programPointers: "Wskaźniki programów",
        dataBase: "Baza danych",
        userManual: "Instrukcja użytkownika",
        elearning: "E-learning",
        filmsAndManuals: "Filmy i instrukcje",
        positions: "Stanowiska",
        fields: "Powierzchnie",
        transects: "Transekty",
        points: "Punkty",
        rain: "Deszcz",
        wind: "Wiatr",
        clouds: "Zachmurzenie",
        visibility: "Widoczność",
        breedingResult: "Metoda kontroli liczby młodych",
        cane: "Trzcina",
        whenEnum: "Kiedy",
        water: "Woda",
        utilization: "Użytkowanie",
        treeType: "Rodzaj drzew",
        treeBushesArea: "Drzewa i krzewy",
        transectZone: "Strefa transektu",
        sex: "Płeć",
        seaState: "Stan morza",
        rut: "Koleiny",
        plantsType: "Typ roślinności",
        plantLitter: "Ściółka",
        plantHeight: "Wysokość roślinności",
        nestType: "Rodzaj gniazda",
        nestPlace: "Miejsce na gniazdo",
        nestingCriteria: "Kryteria lęgowości",
        nestingCategory: "Kategoria gniazdowania",
        looseCircumstances: "Okoliczności strat",
        looseStage: "Stadium strat",
        humanActivity: "Aktywność ludzka",
        habitatType: "Siedlisko",
        clumps: "Kępy",
        distance: "Kategoria odległości",
        countingMethod: "Metoda liczenia",
        controlMethod: "Metoda kontroli",
        controlType: "Rodzaj Kontroli",
        temperature: "Temperatura",
        dominantVegetation: "Dominująca roślinność",
        audibility: "Słyszalność",
        reaction: "Czas reakcji",
        activity: "Aktywność",
        age: "Wiek",
        control: "Kontrola",
        controlNotRealizedReason: "Przyczyna braku kontroli",
        spatial: "Uwarunkowanie przestrzenne",
        succession: "Sukcesja",
        thermalModernization: "Termomodernizacja",
        mammals: "Gatunki ssaków",
        birds: "Gatunki ptaków",
        gminy: "Gminy",
        powiaty: "Powiaty",
        wojewodztwa: "Województwa",
        lesnictwa: "Leśnictwa",
        nadlesnictwa: "Nadleśnictwa",
        ochk: "Obszary chronionego krajobrazu",
        oso: "Obszary OSO",
        parkiKrajobrazowe: "Parki krajobrazowe",
        parkiNarodowe: "Parki narodowe",
        rezerwaty: "Rezerwaty",
        soo: "Obszary SOO",
        ramsar: "RAMSAR",
        rdlp: "RDLP",
        geographies: "Obszary geograficzne"
    },
    other: {
        emptyTable: "Brak wyników",
        emptyValue: "---"
    },
    validations: {
        required: "Pole jest wymagane",
        email: "Niepoprawny format adresu e-mail",
        confirmed: "Wartości nie są takie same",
        min: "Wymagana długość: 0:{min}",
        max: "Maksymalna długość: 0:{max}",
        min_value: "Minimalna wartość: 0:{min_value}",
        max_value: "Maksymalna wartość: 0:{max_value}",
        is: "",
        regex: "",
        validPassword: "Hasło musi mieć długość co najmniej 8 znaków, zawierać małą i dużą literę, cyfrę, oraz znak specjalny",
        notTheSame: "nie są takie same",
        afterTime: "Godzina zakończenia nie może być mniejsza niż godzina rozpoczęcia",
        afterDate: "Data obecnej kontroli nie może być wcześniejsza niż data poprzedniej kontroli",
        transectValidation: "Podany odcinek oznaczono jako nieskontrolowany",
    },
    SystemRole: {
        ADMIN: "Administrator",
        OBSERVER: "Obserwator",
        REGIONAL_COORDINATOR: "Koordynator regionalny",
        NATIONAL_COORDINATOR: "Koordynator krajowy",
        MAIN_COORDINATOR: "Koordynator główny",
        PBD_MPP_ADMINISTRATOR : "Administrator PBD MPP",
    },
    page_report: {
        showing: "Showing ",
        to: " to ",
        of: " of ",
        entries: " entries ",
    },
};
