<template>
    <div class="archive-positions-map" style="width: 600px;">
        <MapMultiMarkers mapId="archiveMap" height="400px" :zoomControls="false" :positions="positions"
                         :field="field" :markerTypes="markerTypes" :isOn="false" changeIconOnHover
                         :iconOnHover="iconOnHover" />
    </div>
</template>

<script>
    import MapMultiMarkers from "@/components/map/MapMultiMarkers.vue";

    export default {
        name: "ArchivePositionsMap",

        components: {
            MapMultiMarkers,
        },

        props: {
            positions: {
                type: Array,
            },
            field: null,
            selectedPosition: {
                type: Number,
                default: null,
            },
        },

        data() {
            return {
                markerTypes: new Map(),
                iconOnHover: {
                    color: "green",
                    size: "regular",
                    popup: false,
                    dontHidePopup: false,
                },
            };
        },

        beforeMount() {
            this.setMarkerTypes(this.selectedPosition);
        },

        methods: {
            setMarkerTypes(selected) {
                // creates actual map which keeps for every positionId an array with icon color and icon size
                this.positions.forEach((position) => {
                    if (selected !== null && position.positionId === selected) {
                        this.markerTypes.set(position.positionId, {
                            color: "green",
                            size: "regular",
                            popup: false,
                            dontHidePopup: false,
                        });
                    } else {
                        this.markerTypes.set(position.positionId, {
                            color: "green",
                            size: "small",
                            popup: false,
                            dontHidePopup: false,
                        });
                    }
                });
            },
        },

        watch: {
            selectedPosition(value) {
                this.setMarkerTypes(value);
            },
        },
    };
</script>

<style lang="scss">
</style>
