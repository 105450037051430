<template>
    <div class="flex gap-7 select-geobject-section">
        <div class="left-panel">
            <SelectTransectMap :transects="transects" :field="field"
                               v-model:hoverIndex="hoverIndex" v-model:clickedIndex="clickedIndex"
                               @update:hoverIndex="(h) => updateClickedOrHover(h,'hover')" />
        </div>
        <div class="right-panel w-full">
            <div class="flex justify-content-between flex-wrap gap-4">
                <CustomButton label="Powrót do mapy z wyborem transektów" icon="pi pi-chevron-left" rowReverse
                              @click="goBack" class="h-auto" />
                <div class="flex gap-2">
                    <CustomIconButton icon="pi pi-chevron-left" label="Poprzedni" rowReverse @click="goPrevious" />
                    <CustomIconButton icon="pi pi-chevron-right" label="Następny" @click="goNext" />
                </div>
            </div>
            <div class="mt-5">
                <SelectTransectLine v-for="(segments,index) in segmentsInTransects" :key="index"
                                    :transects="segments" :transectNumber="index + 1"
                                    @onClicked="(c) => onClickedOrHover(c,index,'clicked')"
                                    @onHover="(h) => onClickedOrHover(h,index,'hover')"/>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomIconButton from "@/components/form/CustomIconButton.vue";
    import SelectTransectMap from "@/components/transectForm/selectTransect/SelectTransectMap.vue";
    import SelectTransectLine from "@/components/transectForm/selectTransect/SelectTransectLine.vue";

    export default {
        name: "SelectTransect",

        components: {
            SelectTransectLine,
            SelectTransectMap,
            CustomIconButton,
            CustomButton,
        },

        props: {
            transects: {
                type: Array,
                default: () => [],
            },
            segmentsInTransects: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
            },
            activeIndex: {
                type: Number,
            },
            transect: {
                type: Object,
            },
            step: {
                type: Number,
            },
        },

        data() {
            return {
                hoverIndex: null,
            };
        },

        emits: ["update:activeIndex", "update:step", "update:transect"],

        beforeMount() {
            if (this.transects.length > 0) {
                this.$emit("update:activeIndex", 0);
                this.$emit("update:transect", this.transects[0]);
            }
            this.updateClickedOrHover(this.activeIndex, "clicked");
        },

        methods: {
            goNext() {
                for (let i = this.activeIndex + 1; i < this.transects.length; i += 1) {
                    if (!(this.transects[i].notAudited === true)) {
                        this.$emit("update:activeIndex", i);
                        this.$emit("update:transect", this.transects[i]);
                        break;
                    }
                }
            },
            goPrevious() {
                for (let i = this.activeIndex - 1; i >= 0; i -= 1) {
                    if (!(this.transects[i].notAudited === true)) {
                        this.$emit("update:activeIndex", i);
                        this.$emit("update:transect", this.transects[i]);
                        break;
                    }
                }
            },
            goBack() {
                this.$emit("update:step", 1);
                let query = "?step=1";
                if (this.$route.query.controlCode != null) query += `&controlCode=${this.$route.query.controlCode}`;
                window.scrollTo({ top: 0, behavior: "smooth" });
                window.history.pushState(null, "", this.$route.path + query);
            },
            onClickedOrHover(segmentIndex, transectIndex, actionField) {
                // actionField is "clicked" or "hover"
                let counter = 0;
                this.segmentsInTransects.forEach((segments, ti) => {
                    if (segmentIndex != null && ti === transectIndex) {
                        counter += segmentIndex;
                        if (actionField === "clicked") this.clickedIndex = counter;
                        else if (actionField === "hover") this.hoverIndex = counter;
                        segments.forEach((s, si) => { s[actionField] = (si === segmentIndex); });
                    } else {
                        counter += segments.length;
                        segments.forEach((s) => { s[actionField] = false; });
                    }
                });
                if (segmentIndex == null) {
                    if (actionField === "clicked") this.clickedIndex = null;
                    else if (actionField === "hover") this.hoverIndex = null;
                }
            },
            updateClickedOrHover(index, actionField) {
                const counter = index;
                this.segmentsInTransects.forEach((segments, si) => {
                    if (index == null || counter >= segments.length) {
                        segments.forEach((s) => { s[actionField] = false; });
                        const secondCounter = counter - segments.length;
                        if (si === 1) {
                            segments.forEach((s, i) => { s[actionField] = (i === secondCounter); });
                        }
                    } else if (index == null || counter < segments.length) {
                        segments.forEach((s) => { s[actionField] = false; });
                        if (si === 0) {
                            segments.forEach((s, i) => { s[actionField] = (i === counter); });
                        }
                    }
                });
            },
        },

        computed: {
            clickedIndex: {
                get() { return this.activeIndex; },
                set(value) {
                    this.$emit("update:activeIndex", value);
                    this.$emit("update:transect", this.transects[value]);
                },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            activeIndex(value) {
                this.updateClickedOrHover(value, "clicked");
            },
        },
    };
</script>

<style lang="scss">
@import "../../../assets/theme/mytheme/variables";
.colorRed {
    color: $color-red !important;
}
</style>
