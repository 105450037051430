<template>
    <div v-if="loaded" class="flex gap-6">
        <div class="area-container__left">
            <div class="area-map" style="height: 600px; width: 800px;">
                <MapArea mapId="mapArea" :isOn="false" height="600px" :areaField="geoMultiArr" />
            </div>
        </div>
        <div class="area-container__right">
            <div class="area-details">
                <p v-if="dictType === 'gminy'" class="text-xl">
                    <b>Gmina: </b>{{ areaObj.first.name }}
                </p>
                <p v-if="dictType === 'gminy'
                    || dictType === 'powiaty'" class="text-xl">
                    <b>Powiat: </b>
                    <router-link v-if="this.$route.params.dict === 'gminy'" :to="`/areaDicts/powiaty/${areaObj.second.id}`">
                        <span class="link">{{ areaObj.second.name }}</span>
                    </router-link>
                    <span v-else>{{ areaObj.second.name }}</span>
                </p>
                <p v-if="dictType === 'gminy'
                    || dictType === 'powiaty'
                    || dictType === 'wojewodztwa'" class="text-xl">
                    <b>Województwo: </b>
                    <router-link v-if="this.$route.params.dict === 'powiaty' || this.$route.params.dict === 'gminy'" :to="`/areaDicts/wojewodztwa/${areaObj.third.id}`">
                        <span class="link">{{ areaObj.third.name }}</span>
                    </router-link>
                    <span v-else>{{ areaObj.third.name }}</span>
                </p>
                <p v-if="dictType === 'lesnictwa'" class="text-xl">
                    <b>Leśnictwo: </b>{{ areaObj.first.name }}
                </p>
                <p v-if="dictType === 'lesnictwa'
                    || dictType === 'nadlesnictwa'" class="text-xl">
                    <router-link v-if="this.$route.params.dict === 'lesnictwa'" :to="`/areaDicts/nadlesnictwa/${areaObj.second.id}`">
                        <span class="link">{{ areaObj.second.name }}</span>
                    </router-link>
                    <span v-else>{{ areaObj.second.name }}</span>
                </p>
                <p v-if="dictType === 'lesnictwa'
                    || dictType === 'nadlesnictwa'
                    || dictType === 'rdlp'" class="text-xl">
                    <b>RDLP: </b>
                    <router-link v-if="this.$route.params.dict === 'lesnictwa' || this.$route.params.dict === 'nadlesnictwa'" :to="`/areaDicts/rdlp/${areaObj.third.id}`">
                        <span class="link">{{ areaObj.third.name }}</span>
                    </router-link>
                    <span v-else>{{ areaObj.third.name }}</span>
                </p>
                <p v-if="dictType === 'ochk'" class="text-xl">
                    <b>Obszar chronionego krajobrazu: </b>{{ areaObj.first.name }}
                </p>
                <p v-if="dictType === 'parkikrajobrazowe'" class="text-xl">
                    <b>Park krajobrazowy: </b>{{ areaObj.first.name }}
                </p>
                <p v-if="dictType === 'parkikrajnarodowe'" class="text-xl">
                    <b>Park narodowy: </b>{{ areaObj.first.name }}
                </p>
                <p v-if="dictType === 'ramsar'" class="text-xl">
                    <b>Ramsar: </b>{{ areaObj.first.name }}
                </p>
                <p v-if="dictType === 'oso'" class="text-xl">
                    <b>Obszar OSO: </b>{{ areaObj.first.name }}
                </p>
                <p v-if="dictType === 'soo'" class="text-xl">
                    <b>Obszar SOO: </b>{{ areaObj.first.name }}
                </p>
                <p v-if="dictType === 'rezerwaty'" class="text-xl">
                    <b>Rezerwat: </b>{{ areaObj.first.name }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import {
        getGeographyById,
    } from "@/swagger/vue-api-client";
    import MapArea from "@/components/map/MapArea";

    export default {
        name: "AreaDictDetails",

        components: {
            MapArea,
        },

        data() {
            return {
                area: null,
                loaded: false,
                dictType: this.$route.params.dict,
                areaObj: {
                    first: null,
                    second: null,
                    third: null,
                },
                geoMultiArr: [],
            };
        },

        beforeMount() {
            this.loadArea();
        },

        methods: {
            loadArea() {
                this.loaded = false;
                getGeographyById({type: this.$route.params.dict, id: this.$route.params.id})
                    .then((response) => {
                        console.log(response.data);
                        this.area = response.data;
                        this.computedNames();
                        let index = 0;
                        this.area.geometry.forEach((geo, i) => {
                            if (i > index && _.isEqual(this.area.geometry[index], geo)) {
                                this.geoMultiArr.push(this.area.geometry.slice(index, i + 1));
                                index = i + 1;
                            }
                        });
                        this.loaded = true;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loaded = false;
                    });
            },

            computedNames() {
                if (this.dictType === "gminy" || this.dictType === "lesnictwa") {
                    this.areaObj.first = {name: this.area.name};
                    this.areaObj.second = {
                        name: this.area.superGeography?.name,
                        id: this.area.superGeography?.id,
                    };
                    this.areaObj.third = {
                        name: this.area.superGeography?.superGeography?.name,
                        id: this.area.superGeography?.superGeography?.id,
                    };
                } else if (this.dictType === "powiaty" || this.dictType === "nadlesnictwa") {
                    this.areaObj.second = {name: this.area.name};
                    this.areaObj.third = {
                        name: this.area.superGeography?.name,
                        id: this.area.superGeography?.id,
                    };
                } else if (this.dictType === "wojewodztwa" || this.dictType === "rdlp") {
                    this.areaObj.third = {name: this.area.name};
                } else {
                    this.areaObj.first = {name: this.area.name};
                }
            },
        },
    };
</script>

<style lang="scss">
    .link {
        color: var(--text-color);
        &:hover {
            text-decoration: underline;
        }
    }
</style>
