<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 column-gap-2 flex-wrap align-items-center">
            <CustomButton icon="pi pi-copy" style="margin-bottom: 1rem"
                          title="Skopiuj ostatnio dodany gatunek"
                          :disabled="!editing" @click="copyLastSpecies"
                          v-on:keydown.enter="copyLastSpecies" />
            <div class="w-1" style="min-width: 128px;">
                <CustomOfflineAutocomplete label="Kod" name="speciesCode" v-model="newItem.species"
                                           :items="dicts.programBirds" optionLabel="code" searchField="code"
                                           required :showErrors="showErrors" :disabled="!editing"
                                           :customErrorMessage="getIncorrectSpeciesMessage()"/>
            </div>
            <div class="w-1" style="min-width: 128px;">
                <CustomOfflineAutocomplete label="Gatunek" name="speciesName" v-model="newItem.species"
                                           :items="dicts.programBirds" optionLabel="name" searchField="name"
                                           required :showErrors="showErrors" :disabled="!editing"
                                           :customErrorMessage="getIncorrectSpeciesMessage()"/>
            </div>
            <div class="w-1" style="min-width: 128px;">
                <CustomCheckbox label="Nie liczono" name="counted" v-model="notCounted"
                                :disabled="!editing || MAIN_SPECIES.includes(newItem.species?.code)" />
            </div>
            <div class="w-1" style="min-width: 128px;">
                <CustomOfflineAutocomplete label="Kod" name="treeType" v-model="newItem.treeType"
                                           :items="dicts.treeType" optionLabel="code"
                                           searchField="code" :required="newItem.counted"
                                           class="trap-focus" :showErrors="showErrors && newItem.counted"
                                           :disabled="!editing || !newItem.counted"/>
            </div>
            <div class="w-2" style="min-width: 232px;">
                <CustomOfflineAutocomplete label="Nazwa drzewa/ typ szuwaru" name="treeType" v-model="newItem.treeType"
                                           :items="dicts.treeType" optionLabel="label"
                                           searchField="label" :required="newItem.counted"
                                           class="trap-focus" :showErrors="showErrors && newItem.counted"
                                           :disabled="!editing || !newItem.counted"/>
            </div>
            <div class="w-1" style="min-width: 128px;">
                <CustomInputNumber label="L. gniazd" name="nests" v-model="newItem.nests"
                                   :required="newItem.counted"
                                   :showErrors="showErrors && newItem.counted"
                                   :disabled="!editing || !newItem.counted" />
            </div>
            <div class="w-3" style="min-width: 320px;">
                <CustomInputText label="Liczby gniazd na poszczególnych drzewach" name="nestsOnTrees"
                                 v-model="newItem.nestsOnTrees" :required="newItem.counted"
                                 :showErrors="showErrors && newItem.counted" :disabled="!editing || !newItem.counted" />
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem; min-width: 110px;" v-on:keydown.tab="add"
                          class="media-q" />
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomOfflineAutocomplete from "@/components/form/inner/CustomOfflineAutocomplete.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "AddBirdsMCZ",

        components: {
            CustomOfflineAutocomplete,
            CustomInputText,
            CustomCheckbox,
            CustomInputNumber,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts", "MAIN_SPECIES"],

        emits: ["update:modelValue", "addHeron"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyBirds()),
                showErrors: false,
                speciesName: "",
                speciesCode: "",
                notCounted: false,
            };
        },

        methods: {
            getDictLabelWithCodePrefix(item) {
                return item.code + " - " + item.label;
            },
            setSpecies(species) {
                this.newItem.species = species;
            },
            add() {
                if (this.newItem.species == null || this.newItem.species === ""
                    || (this.newItem.counted && (
                        this.newItem.treeType == null || this.newItem.treeType === ""
                        || this.newItem.nests == null || this.newItem.nests === ""
                        || this.newItem.nestsOnTrees == null || this.newItem.nestsOnTrees === ""
                    )) || !ValidateUtils.validateCorrectSpecies(this.newItem, ["species"])) {
                    this.showErrors = true;
                } else {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length + 1,
                    });
                    if (this.MAIN_SPECIES.includes(this.newItem.species.code)) {
                        this.$emit("addHeron", this.newItem);
                    }
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyBirds());
                    this.showErrors = false;
                }
            },
            copyLastSpecies() {
                if (this.birds?.length > 0) {
                    this.newItem.species = this.birds[this.birds.length - 1].species;
                }
            },
            getIncorrectSpeciesMessage() {
                if (!ValidateUtils.validateCorrectSpecies(this.newItem, ["species"])) return "Niepoprawny gatunek";
                return "";
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            notCounted(value) {
                this.newItem.counted = !value;
            },
            "newItem.counted"(value) {
                this.notCounted = !value;
            },
            "newItem.species"(value) {
                if (this.MAIN_SPECIES.includes(value?.code)) {
                    this.notCounted = false;
                }
            },
        },
    };
</script>

<style lang="scss">
    .add-in-table-header {
        margin-bottom: -1rem,
    }
    .media-q {
        @media screen and (max-width: 1785px) {
            position: relative !important;
            right: 0 !important;
        }
    }
</style>
