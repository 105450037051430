<template>
    <ImportTransectDialog v-model="showImportTransectDialog" />
    <div class="transect-table">
        <DataTable :lazy="true" :value="transects" dataKey="id" :paginator="true" :totalRecords="totalRecords"
                   :loading="loading" @page="onPage" @sort="onPage" removableSort :rows="10"
                   :rowsPerPageOptions="[5,10,20,50]" :search-criteria="searchCriteria" size="small"
                   v-model:first="searchCriteria.page.offset" v-model:rows="searchCriteria.page.limit"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink RowsPerPageDropdown"
                   :currentPageReportTemplate="currentPageReportTemplate">
            <Column header="Nazwa" field="name" sortable/>
            <Column header="Kod powierzchni" field="field.name"/>
            <Column header="Program" field="program"/>
            <Column header="Numer Kolejności" field="order" sortable/>
            <Column header="Działania">
                <template #body="slotProps">
                    <div class="flex gap-3">
                        <CustomButton label="Wyświetl szczegóły" @click="viewDetails(slotProps.data.id)" />
                        <CustomButton label="Edytuj" @click="navigateToEditTransect(slotProps.data.id)" />
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        getListOfPrograms,
        listFieldsForProgram,
        searchTransects,
        searchTransectsCount,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import ImportTransectDialog from "@/views/transectTable/ImportTransectDialog.vue";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "TransectTable",
        components: {
            CustomButton,
            DataTable,
            Column,
            ImportTransectDialog,
        },

        props: {
            modelValue: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                transects: [],
                loading: false,
                totalRecords: 0,
                programs: [],
                fields: [],
                selectedPrograms: null,
                selectedFieldNames: null,
                showImportTransectDialog: false,
            };
        },

        watch: {
            selectedPrograms(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.getFields();
                }
            },
            selectedFieldNames() {
                this.search();
            },
            searchCriteria: {
                handler(value) {
                    this.search();
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },

        beforeMount() {
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                });
        },

        mounted() {
            this.search();
        },

        methods: {
            async search() {
                this.loading = true;
                let fieldNames;
                if (this.searchCriteria.fieldNames != null) {
                    fieldNames = this.searchCriteria.fieldNames.map((field) => field.name);
                }
                searchTransects({body: {...this.searchCriteria, fieldNames}})
                    .then((response) => {
                        this.transects = response.data;
                        return searchTransectsCount({body: {...this.searchCriteria, fieldNames}});
                    })
                    .then((response) => {
                        this.totalRecords = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                    });
            },

            onPage(event) {
                this.searchCriteria.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
            },

            getFields() {
                if (!this.selectedPrograms || this.selectedPrograms.length === 0) {
                    this.fields = [];
                    return;
                }
                const promises = this.selectedPrograms.map((program) => listFieldsForProgram({ program }));
                Promise.all(promises)
                    .then((responses) => {
                        this.fields = responses.reduce((acc, response) => acc.concat(response.data), []);
                    })
                    .catch((error) => {
                        console.error("An error occurred:", error);
                    });
            },

            navigateToCreateTransect() {
                this.$router.push("/create-transect");
            },
            viewDetails(id) {
                this.$router.push(`transect/${id}`);
            },

            navigateToEditTransect(id) {
                this.$router.push({path: `/transect/${id}`, query: {isEditMode: "true" }});
            },
            exportData() {
                FileUtils.downloadFile({}, "transekty.xlsx", "/transect/export");
            },
        },

        computed: {
            searchCriteria: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            currentPageReportTemplate() {
                return "Rekordy od "
                    + "{first}"
                    + " do "
                    + "{last}"
                    + " z "
                    + "{totalRecords}"
                    + " (Strona {currentPage}"
                    + " z "
                    + "{totalPages})";
            },
        },
    };
</script>

<style scoped lang="scss">

</style>
