<template>
    <div class="monitoring-program-info-box w-6">
        <HeaderComponent headerText="Informacje" class="mt-0 mb-3" />
        <div class="info-box-container">
            <div>
                <div class="mb-3">
                    <p class="font-bold my-0">Program</p>
                    <p class="mt-0">{{ programEdition.program }}</p>
                </div>
                <div class="mb-3">
                    <p class="font-bold my-0">Rok</p>
                    <p class="mt-0">{{ programEdition.year }}</p>
                </div>
                <div class="mb-3">
                    <p class="font-bold mb-0">Status</p>
                    <p class="mt-0 max-w-12rem">{{ state }}</p>
                </div>
                <div v-if="rejectionCause">
                    <p class="font-bold mb-0">Powód odrzucenia obsady</p>
                    <p class="mt-0"> {{rejectionCause}} </p>
                </div>
            </div>
            <div>
                <div class="mb-3">
                    <p class="font-bold my-0">Koordynator główny</p>
                    <p class="mt-0">{{ programEdition.mainCoordinator.fullName }}</p>
                </div>
                <div class="mb-3">
                    <p class="font-bold mb-0">Koordynatorzy krajowi</p>
                    <p class="mt-0 max-w-19rem">
                        <CustomMultiSelect v-if="editCast && isMainCoordinator && coordinatorsSelect.length > 0"
                                           :items="coordinatorsSelect" itemLabel="fullName"
                                           :emptyValue="false" v-model="nationalCoordinators"
                                           style="margin-top: 8px;" name="nationalCoordinatorsList" dataKey="id" />
                        <span v-else>
                            {{ joinedCoordinators }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomMultiSelect from "../../../components/form/inner/CustomMultiSelect.vue";
    import {
        addNationalCoordinatorToCast,
        getAllNationalCoordinators,
    } from "@/swagger/vue-api-client";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import {SystemRole} from "@/utils/SystemRole";
    import ListUtils from "@/utils/ListUtils";

    export default {
        name: "MonitoringProgramInformation",

        components: {
            HeaderComponent,
            CustomMultiSelect,
        },

        props: {
            programEdition: null,
            modelValue: null,
        },

        inject: ["programCode"],

        data() {
            return {
                coordinatorsSelect: [],
                nationalCoordinators: this.programEdition.nationalCoordinators,
                rejectionCause: this.programEdition.rejectionCause,
                edited: false,
            };
        },

        beforeUpdate() {
            if (this.editCast && this.coordinatorsSelect.length === 0 && this.isMainCoordinator) {
                this.edited = true;
                getAllNationalCoordinators({ program: this.programCode })
                    .then((response) => {
                        this.coordinatorsSelect = response.data;
                        if (this.coordinatorsSelect.length === 0) {
                            this.coordinatorsSelect.push(undefined);
                        }
                    });
            } else if (this.edited && !this.editCast) {
                const nationalCoordinatorsIds = this.nationalCoordinators.map((c) => c.id);
                addNationalCoordinatorToCast({
                    programEditionId: this.$route.params.id,
                    body: {nationalCoordinatorsIds},
                }).then(() => {
                    this.edited = false;
                });
            }
        },

        computed: {
            state() {
                return ProgramEditionState.getStateLabel(this.programEdition.state);
            },
            showHeaderButton() {
                return SystemRole.isMainCoordinator()
                    || (SystemRole.isNationalCoordinator() && this.programEdition.state !== "PROGRAM_FINISHED");
            },
            editCast: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            isMainCoordinator() {
                return SystemRole.isMainCoordinator();
            },
            joinedCoordinators() {
                return ListUtils.join(this.nationalCoordinators, "fullName");
            },
        },
    };
</script>

<style lang="scss">
.monitoring-program-info-box {
    border: 1px solid #dee2e6;
}
.info-box-container {
    padding-left: 12px;
    display: flex;
    gap: 5rem;
    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
    }
}
</style>
