<template>
    <div class="flex flex-wrap justify-content-between">
        <SingleTile v-for="t in tiles" :key="t.id" :style="`background-color: ${t.bgColor};`"
                    :text="t.text" :count="t.count" />
    </div>
</template>

<script>
    import SingleTile from "./SingleTile.vue";

    export default {
        name: "InfoTiles",

        components: {
            SingleTile,
        },

        data() {
            return {
                count: [0, 1, 2, 3, 4],
                tiles: [
                    {
                        id: 0,
                        text: "Formularze do uzupełnienia",
                        // bgColor: "#85a8ff80",
                        bgColor: "#d5233f45",
                        count: 5,
                    },
                    {
                        id: 1,
                        text: "Formularze w wersji roboczej",
                        bgColor: "var(--surface-0)",
                        count: 0,
                    },
                    {
                        id: 2,
                        text: "Formularze w weryfikacji",
                        // bgColor: "#ecff4180",
                        bgColor: "#05683945",
                        count: 1,
                    },
                    {
                        id: 3,
                        text: "Formularze do poprawy",
                        // bgColor: "var(--red-light)",
                        bgColor: "#d5233f95",
                        count: 0,
                    },
                    {
                        id: 4,
                        text: "Zaakceptowane formularze",
                        // bgColor: "var(--secondary-color-light)",
                        bgColor: "#05683995",
                        count: 2,
                    },
                ],
            };
        },
    };
</script>

<style lang="scss">
</style>
