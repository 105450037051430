<template>
    <div class="custom-select-radio relative">
        <span :class="vertical ? ' flex-column' : ''" class="flex flex-1 justify-content-start mt-3 gap-3">
            <span :class="vertical ? 'flex' : 'flex w-9rem'">{{computedLabel}}</span>
            <span :class="vertical ? ' flex-column gap-3' : ''" class="flex flex-1 justify-content-start flex-wrap">
                <CustomRadioButton v-for="(option,index) in options" v-bind:key="index" name="select-radio"
                                   :value="option" :inputId="index.toString()" :label="option.label" v-model="selected"
                                   :disabled="disabled" :class="vertical ? 'w-18rem' : ''" class="pr-1 mr-5"/>
            </span>
        </span>
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
    </div>
</template>

<script>
    import CustomRadioButton from "@/components/form/CustomRadioButton.vue";
    import CustomValidationMessage from "@/components/form/inner/CustomValidationMessage.vue";

    export default {
        name: "CustomSelectRadio",

        components: {
            CustomValidationMessage,
            CustomRadioButton,
        },

        props: {
            options: {
                type: Array,
                default: () => [],
            },
            modelValue: null,
            label: {
                type: String,
                default: "",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            vertical: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            selected: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            showRequiredError() {
                return this.showErrors && (this.selected == null || this.selected === "");
            },
            computedLabel() {
                if (this.label === undefined) return "";
                return this.label + (this.required ? "*" : "");
            },
        },
    };
</script>

<style lang="scss">
.error-msg {
    top: 30px;
}
</style>
