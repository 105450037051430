<template>
    <div class="flex flex-1 column-gap-2 flex-wrap align-items-center">
        <div v-for="(c,index) in columns" :key="index" :class="`w-${c.columnWidth}` + `${c.field === 'fieldName' ? ' hidden' : ''}`">
            <CustomInputText v-if="c.editorType === 'inputText'" v-model="newItem[c.field]"
                             :name="c.field" :label="c.header"
                             :disabled="c.field === 'differentUtilization' && newItem['utilization']?.code !== '5'" />
            <CustomInputNumber v-else-if="c.editorType === 'inputNumber'" v-model="newItem[c.field]"
                               :name="c.field" :label="c.header" />
            <CustomSelectOne v-else-if="c.editorType === 'selectOne'" v-model="newItem[c.field]"
                             :items="[]"
                             :itemLabel="c.editorItemLabel"
                             :itemValue="c.editorItemValue" :emptyValue="c.editorEmptyValue"
                             :name="c.field" :label="c.header" />
            <CustomMultiSelect v-else-if="c.editorType === 'multiselect'" v-model="newItem[c.field]"
                               :items="[]"
                               :itemLabel="c.editorItemLabel"
                               :itemValue="c.editorItemValue" :emptyValue="c.editorEmptyValue"
                               :name="c.field" :label="c.header" />
            <CustomCalendar v-else-if="c.editorType === 'calendar'" v-model="newItem[c.field]" :name="c.field"
                            :label="c.header" />
            <CustomCheckbox v-else-if="c.editorType === 'checkbox'" v-model="newItem[c.field]" :name="c.field"
                            :label="c.header" />
        </div>
        <CustomButton icon="pi pi-plus" @click="addHabitatData" style="margin-bottom: 1rem;" />
    </div>
</template>

<script>
    import _ from "lodash";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {
        addHabitat,
        deleteHabitatById,
    } from "@/swagger/vue-api-client";
    import {DateUtils} from "@/utils/DateUtils";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        name: "HabitatDataDialogAddHabitat",

        components: {
            CustomInputText,
            CustomInputNumber,
            CustomSelectOne,
            CustomButton,
            CustomCalendar,
            CustomCheckbox,
            CustomMultiSelect,
        },

        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
            columns: {
                type: Array,
                required: true,
            },
            gHistoryId: null,
            programCode: null,
            dicts: {
                type: Object,
                default: () => {},
            },
        },

        emits: ["update:modelValue", "reloadTable"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyHabitatData()),
            };
        },

        methods: {
            addHabitatData() {
                console.log(DateUtils.formatFormDate(this.newItem.controlDate));
                const requestBody = {
                    ...this.newItem,
                    controlDate: DateUtils.formatFormDate(this.newItem.controlDate),
                };
                addHabitat({gObjectId: this.gHistoryId, body: {...requestBody, program: this.programCode}})
                    .then(() => {
                        this.$emit("reloadTable");
                    });
            },
            deleteHabitatData(habitatRow) {
                deleteHabitatById({
                    program: habitatRow.program,
                    id: habitatRow.id,
                }).then(() => {
                    const index = this.habitat.indexOf(habitatRow);
                    this.habitat.splice(index, 1);
                });
            },
        },

        computed: {
            habitat: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            habitat: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            newItem: {
                handler(value) {
                    console.log(value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
