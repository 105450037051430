<!-- eslint-disable -->
<template>
    <div class="flex justify-content-end flex-column">
        <div class="flex gap-3">
            <GeographicalFilters v-model="searchCriteria" v-model:matchMode="searchCriteria.matchMode"
                                 :filterBy="matchModeOptions" />
            <div class="flex align-self-end justify-content-end field">
                <CustomButton label="Wyczyść" @click="clear"/>
            </div>
        </div>
        <div class="flex align-items-center gap-3 field justify-content-end">
            <CustomButton label="Eksportuj" @click="exportData"/>
        </div>
    </div>
    <div class="raw-data-table">
        <DataTable :lazy="true" :value="rawData" dataKey="id" :paginator="true" v-model:rows="page.limit"
                   v-model:first="page.offset" :totalRecords="totalRecords" @page="onPage" @sort="onPage"
                   removableSort :rows="10" :rowsPerPageOptions="[5,10,20,50,100]" :loading="loading"
                   scrollable scroll-height="64.5vh" size="small" show-gridlines
                   v-model:filters="searchCriteria" filter-display="menu" @update:filters="search">
            <Column v-for="(c,index) in columns" :key="index" v-show="c.visible" :header="c.header"
                    :field="c.field"
                    :filterField="c.filterField" :showFilterMatchModes="c.showFilterMatchModes"
                    :filterMenuClass="c.filterMenuClass" :sortable="c.sortable">
                <template #body="slotProps">
                    <router-link v-if="c.bodyLink" :to="c.linkTo(slotProps.data)">
                        <span class="link">{{ c.bodyContent(slotProps.data) }}</span>
                    </router-link>
                    <div v-else-if="c.bodyTooltip" :class="c.bodyClass(slotProps.data)"
                         v-tooltip.top="c.bodyTooltipContent(slotProps.data)">
                        {{ c.bodyContent(slotProps.data) }}
                    </div>
                    <div v-else :class="c.bodyClass(slotProps.data)">
                        {{ c.bodyContent(slotProps.data) }}
                    </div>
                </template>
                <template #filter="{filterModel}" v-if="c.filterField != null">
                    <CustomIntervalFilter v-if="c.filterType === 'range' && loadLists" v-model="filterModel.value"
                                          :range="filterLists[c.field]" v-bind="c.filterOtherAttrs"/>
                    <CustomMultiSelect v-else-if="c.filterType === 'dictionary' && loadLists" :label="c.header"
                                       :name="c.field" v-model="filterModel.value" :filter="c.filterFilter"
                                       :items="filterLists[c.filterItemsKey]" :itemLabel="c.filterItemLabel"
                                       :itemValue="c.filterItemValue" :class="c.filterClass"
                                       :emptyValue="c.filterEmptyValue" v-bind="c.filterOtherAttrs"/>
                    <CustomSelectOne v-else-if="c.filterType === 'boolean'" :label="c.header" :name="c.field"
                                     :items="c.filterItems" :itemLabel="c.filterItemLabel" v-model="filterModel.value"
                                     :itemValue="c.filterItemValue" :class="c.filterClass" v-bind="c.filterOtherAttrs"/>
                    <CustomCalendar v-else-if="c.filterType === 'date-range' && loadLists" :name="c.field"
                                    :selectionMode="c.filterSelectionMode" v-model="filterModel.value"
                                    inline :min="new Date(filterLists.controlDate.min)"
                                    :max="new Date(filterLists.controlDate.max)" v-bind="c.filterOtherAttrs"/>
                </template>
                <template #filterclear="{ filterCallback }">
                    <FilterClear v-if="c.filterType !== null" :callback="filterCallback" />
                </template>
                <template #filterapply="{ filterCallback }">
                    <FilterApply v-if="c.filterType !== null" :callback="filterCallback" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Tooltip from "primevue/tooltip";
    import CustomIntervalFilter from "@/components/filters/CustomIntervalFilter.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {FileUtils} from "@/utils/FileUtils";
    import {countForm, getRawDataFilters, searchForm} from "@/swagger/vue-api-client";
    import GeographicalFilters from "@/components/filters/GeographicalFilters.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "RawDataTable",

        components: {
            FilterClear,
            FilterApply,
            CustomCalendar,
            CustomSelectOne,
            CustomMultiSelect,
            CustomIntervalFilter,
            DataTable,
            Column,
            GeographicalFilters,
            CustomButton,
        },
        directives: {
            tooltip: Tooltip,
        },

        props: {
            columns: {
                type: Array,
                required: true,
            },
            filterTags: {
                type: Boolean,
                default: true,
            },
            programCode: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                rawData: [],
                totalRecords: 0,
                loading: false,
                filterLists: {},
                loadLists: false,
                searchCriteria: [],
                page: SearchCriteria.getClearPage(),
                filterDescription: [],
                matchModeOptions: [
                    {value: "FIELD", label: "Powierzchni"},
                ],
            };
        },

        beforeMount() {
            this.columns.forEach((c) => {
                if (c.filterField != null) {
                    this.filterDescription.push({name: c.filterField, type: c.filterType, label: c.header});
                }
            });
        },

        mounted() {
            if (this.filterTags) {
                const type = {};
                if (this.programCode.includes("MPPL")) {
                    type.program = "MPPL";
                    type.qualifier = this.programCode.substring(4).toLowerCase();
                } else if (this.programCode === "MRCLosses") {
                    type.program = "MRC";
                    type.qualifier = "losses";
                } else if (this.programCode.includes("MLS")) {
                    type.program = "MLS";
                    type.qualifier = this.programCode.substring(3).toLowerCase();
                } else {
                    type.program = this.programCode;
                }
                getRawDataFilters({...type})
                    .then((response) => {
                        this.filterLists = response.data;
                        this.filterLists.temperature = [];
                        console.log(this.filterLists);
                        this.loadLists = true;
                    });
            }
            this.clear();
        },

        methods: {
            clear() {
                this.setClearSearchCriteria();
                this.search();
            },
            search() {
                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    const criteria = SearchCriteria.prepareCriteria(
                        this.searchCriteria,
                        this.page,
                        this.filterDescription,
                    );
                    criteria.program = this.programCode;
                    console.log(criteria);
                    searchForm({body: criteria})
                        .then((response) => {
                            this.rawData = response.data;
                            console.log(response.data);
                            return countForm({body: criteria});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        })
                        .catch((error) => { console.log(error); });
                }, 100);
            },
            exportData() {
                const criteria = SearchCriteria.prepareCriteria(
                    this.searchCriteria,
                    this.page,
                    this.filterDescription,
                );
                criteria.program = this.programCode;
                FileUtils.downloadFile(criteria,
                                       `dane_surowe_${this.programCode}.xlsx`,
                                       "/data-raw/export");
            },
            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },
            setClearSearchCriteria() {
                this.searchCriteria = [];
                this.columns.forEach((c) => {
                    if (c.filterField != null) {
                        if (c.filterType === "range" || c.filterType === "date-range") {
                            this.searchCriteria[c.filterField] = {value: null, matchMode: "between"};
                        } else {
                            this.searchCriteria[c.filterField] = "";
                        }
                    }
                });
            },
        },
    };
</script>

<style lang="scss">
@import "../../../assets/theme/mytheme/variables";

.link {
    color: var(--text-color);
    &:hover {
        text-decoration: underline;
        //color: var(--color-dark-gray);
    }
}
.nowrap {
    white-space: nowrap;
}
.filterYear {
    width: 100px
}
.p-slider-range {
    background-color: var(--secondary-color) !important;
}
.p-slider-handle {
    background-color: var(--text-color) !important
}
.p-slider {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    width: 9.5rem;
    margin-left: 2rem;
}

.filter-tag {
    height: 42px;

    &-content {
        i {
            font-size: 1rem;
        }
    }
}
.p-tag {
    background: white !important;
    padding-left: 1rem;
    padding-right: 1rem;
}

.true-style, .false-style {
    padding: 2px 8px;
    width: fit-content;
    border-radius: 4px;
}
.true-style {
    color: var(--secondary-color);
    background-color: $secondaryLightColor;
}
.false-style {
    color: var(--red);
    background-color: $colorLightRed;
}
</style>
